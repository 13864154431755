import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import React, { memo, useCallback, useState } from "react";
import requests from "./HttpServices";

export default function Recaptcha(props) {
  const [token, setToken] = useState();

  const handleReCaptchaVerify = useCallback(
    (token) => {
      setToken(token);
      requests("post", "recaptcha", { recaptcha_response: token }).then(
        (data) => {
          try {
            props.onVerify && props.onVerify(data.response);
          } catch (e) {}
        }
      );
    },
    [setToken]
  );
  return (
    <div>
      <GoogleReCaptcha action="submit" onVerify={handleReCaptchaVerify} />
    </div>
  );
}
