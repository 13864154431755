import React, { Component, Fragment } from "react";
import "./Login/Login.scss";
import { Redirect } from "react-router";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { VisibilityOff } from "@material-ui/icons";
import HttpService from "../../Services/HttpServices";
import requests from "../../Services/HttpServices";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
    };
  }

  componentDidMount() {}

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, error: "" });
  };

  onSuccessLogin(data) {
    const address = {};
    sessionStorage.setItem("user", JSON.stringify(data.session));
    sessionStorage.setItem("User", JSON.stringify(data.session));
    sessionStorage.setItem(
      "ExpiringMemorial",
      JSON.stringify(data.expiring_memorial || false)
    );
    let userId = null;
    try {
      userId = data.session.userdata.user_id;
    } catch (e) {
      userId = null;
    }
    let time;
    try {
      time = data.session.userdata.notificationPreference;
    } catch (e) {
      time = null;
    }
    if (userId) {
      requests("get", "contactData", userId).then((result) => {
        const { data } = result;
        if (data.response === "success") {
          sessionStorage.setItem(
            "userContactPreference",
            JSON.stringify({
              zone: data.data,
              time,
            })
          );
        }
        // sessionStorage.setItem('userContactPreference', JSON.stringify({
        //     zone: [{region: 12}, {
        //         region: 3,
        //         province: 5
        //     }, {region: 5, province: 13, location: 1447}],
        //     time:'weekly'
        // }))
      });
    }
    if (
      data.session.userdata.addresses &&
      data.session.userdata.addresses.length > 0
    ) {
      address.address =
        data.session.userdata.addresses[
          data.session.userdata.addresses.length - 1
        ].address;
      address.number =
        data.session.userdata.addresses[
          data.session.userdata.addresses.length - 1
        ].number;
      address.region =
        data.session.userdata.addresses[
          data.session.userdata.addresses.length - 1
        ].region;
      address.city =
        data.session.userdata.addresses[
          data.session.userdata.addresses.length - 1
        ].location;
      address.province =
        data.session.userdata.addresses[
          data.session.userdata.addresses.length - 1
        ].province;
      address.cap =
        data.session.userdata.addresses[
          data.session.userdata.addresses.length - 1
        ].cap;
      address.nationality =
        data.session.userdata.addresses[
          data.session.userdata.addresses.length - 1
        ].nationID;
      address.complete_address =
        data.session.userdata.addresses[
          data.session.userdata.addresses.length - 1
        ].completeAddress;
    }
    sessionStorage.setItem("UserAddress", JSON.stringify(address));
    window.location.reload();
  }

  onErrLogin(data) {
    requests("get", "logout").then((data2) => {
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("ExpiringMemorial");
      sessionStorage.removeItem("User");
      sessionStorage.removeItem("UserAddress");
      alert(
        "Ci sono problemi a reperire le informazioni del vostro account, si prega di contattare gli amministratori di sistema, verra` reinderizzato al form per i contatti"
      );
      this.props.history.push("contact");
    });
  }

  login = (e) => {
    e.preventDefault();
    const loggedIn = false;
    const { username, password } = this.state;
    if (!username || !password) {
      return this.setState({ error: "Riempi tutti i campi" });
    }
    // eslint-disable-next-line array-callback-return
    const bodyData = {
      email: username,
      password,
      _submitLogin: 1,
    };
    HttpService("POst", "login", bodyData)
      .then((data) => {
        if (data.result !== "fail") {
          if (data.session.userdata.permissions) {
            this.onSuccessLogin(data);
          } else {
            this.onErrLogin(data);
          }
        } else {
          alert("Credenziali errate");
        }
      })
      .catch((err) => {
        this.onErrLogin();
      });
  };

  render() {
    return (
      <>
        <div
          style={{ height: window.innerHeight - 100 }}
          className="login-page columns is-mobile is-centered bg-modal"
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.login(e);
            }}
            className="column media-fix"
          >
            <label className="death-data">Attenzione</label>
            <p style={{ marginBottom: 8 }}>
              Abbiamo rilevato un account per il metodo di contatto associato,
              effettua la login per continuare
            </p>
            <TextField
              onChange={this.handleChange}
              name="username"
              className="login-form mb-20"
              label="Nome utente"
              variant="outlined"
            />
            <TextField
              onChange={this.handleChange}
              InputProps={{
                endAdornment: this.state.visible ? (
                  <VisibilityIcon
                    style={{ cursor: "pointer", color: "black" }}
                    onClick={() => this.setState({ visible: false })}
                  />
                ) : (
                  <VisibilityOff
                    style={{ cursor: "pointer", color: "black" }}
                    onClick={() => this.setState({ visible: true })}
                  />
                ),
              }}
              type={!this.state.visible && "password"}
              name="password"
              className="login-form "
              label="Password"
              variant="outlined"
            />
            {this.state.error && (
              <div className="has-text-danger" style={{ marginBottom: 13 }}>
                {this.state.error}
              </div>
            )}
            <div className="field is-clearfix">
              <button
                style={{ marginRight: 10 }}
                className="button is-primary is-outlined is-pulled-right"
                // onClick={this.login}
              >
                Accedi
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  this.props.close();
                }}
                style={{ marginLeft: 10 }}
                className="button is-primary is-outlined is-pulled-right"
                // onClick={this.login}
              >
                Chiudi
              </button>
            </div>
            <div id="bottom-login">
              <Link to="/forgot-password">
                <label style={{ textAlign: "center" }} id="click-here">
                  Password dimenticata?
                </label>
              </Link>
            </div>
          </form>
        </div>
      </>
    );
  }
}

export default Login;
