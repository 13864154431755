import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import React from "react";
import PropTypes from "prop-types";
import FormLabel from "./TermFormComponents";

function AcceptTerms(props) {
  return (
    <div>
      <FormControlLabel
        className="activate-checkbox"
        control={
          <Checkbox
            color="primary"
            name="checkedC"
            id="term-box"
            onChange={(event, checked) => {
              props.setChecked(checked);
            }}
          />
        }
        label={FormLabel()}
      />

      {!props.checkBox && props.termsError && (
        <label
          style={{
            color: "red",
            textAlign: "left",
            margin: 10,
            marginLeft: 30,
          }}
        >
          Devi accettare i termini
        </label>
      )}
    </div>
  );
}
AcceptTerms.propTypes = {
  checkBox: PropTypes.bool.isRequired,
  termsError: PropTypes.bool.isRequired,
  setChecked: PropTypes.func.isRequired,
};
export default AcceptTerms;
