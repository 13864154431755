import Axios from "axios";
import EventEmitter from "reactjs-eventemitter";

// export const baseURL =
//   window.chrome && window.navigator.userAgent.indexOf("Ed") > -1
//     ? "https://www.digitalmemorial.dlgtek.com/"
//     : "https://digitalmemorial.dlgtek.com/";
const getTheUrl = window.location;

export const baseURL = `${getTheUrl.protocol}//${getTheUrl.host}/`;
// export const baseURL = "https://www.digitalmemorial.it/";
const querystring = require("qs");
// TODO:0006885254&fbclid=IwAR2XupfHn5O_9IFUC8TzsJ-oqsWJAv_w9X_2f30dqbhWvNXPXYTkdHArjTE
const postUrl = {
	login                   : "api/auth/login",
	qrCode                  : "api/order/qrcode",
	dedic                   : "api/order/dedic",
	paypalUpdate            : "api/order/updatePayment",
	memorial                : "api/memorial/order",
	downgradePartner        : "api/admin/partnerdowngrade",
	partner                 : "api/partner/new",
	book                    : "api/condolence-book/new",
	activate                : "api/qrcode/activate",
	addDeceased             : "api/admin/addDeceased",
	lampadaSet              : "api/lampadeset/",
	lampadaCreate           : "api/lampadecreate/",
	lampadeCollegateLoad    : "api/lampadecollegateload/",
	lampadeCollegateAttiva  : "api/lampadecollegateattiva/",
	lampadeCollegateDelete  : "api/lampadecollegatedelete/",
	lampadaAbbonato         : "api/lampadaabbonato/",
	lampadaAbbonatoCreate   : "api/lampadaabbonatocreate/",
	lampadaPagamenti        : "api/lampadapagamenti/",
	lampadaPagamentoCreate  : "api/lampadapagamentocreate/",
	getPartnerType          : "api/partnertype/",
	dedicAdmin              : "api/admin/addDedicToDeceased",
	memorialAdmin           : "api/admin/addMemorialToDeceased",
	addPoster               : "api/obituary/addObituary",
	changeProperty          : "api/admin/changeOwner",
	acceptPartner           : "api/admin/acceptPartner",
	deletePartner           : "api/admin/deletePartner",
	partnerEdit             : "api/partner/edit",
	denyPartner             : "api/admin/denyPartner",
	changePreferece         : "api/account/changeContactPreference",
	changePassword          : "api/account/changePassword",
	modify                  : "api/account/changeAccountData",
	obituaryDelete          : "api/obituary/delete",
	cancelQR                : "api/order/cancelQr",
	cancelDedic             : "api/order/cancelDedication",
	cancelMemorial          : "api/order/cancelMemorial",
	obituaryModify          : "api/obituary/edit",
	registration            : "api/user/register",
	recover                 : "api/account/recoverRequest",
	recoverVerify           : "api/account/recoverCheck",
	completeReset           : "api/account/recoverComplete",
	modifyContactPreferece  : "api/zone/savePreference",
	deceasedDelete          : "api/adminDeceased/delete",
	deceasedModify          : "api/adminDeceased/edit",
	recaptcha               : "api/recaptcha/check",
	contact                 : "api/contact/sendMessage",
	bookCode                : "api/condolence-book/checkcode",
	recoverUserData         : "api/account/recoverUsername",
	obituaryCheck           : "api/obituary/check",
	editDedic               : "api/dedic/edit",
	editMemorial            : "api/memorial/edit",
	deleteDedic             : "api/dedic/delete",
	soldQr                  : "api/qrcode/setSold",
	removeMemorial          : "api/admin/deleteMemorial",
	notNow                  : "api/memorial/notNotify",
	sendCard                : "api/notification/sendvcard",
	updateDeceased          : "api/adminDeceased/updateEnabled",
	updateConto             : "api/adminDeceased/updateContoVendita",
	orderContoVendita       : "api/order/qrcodeContoVendita",
	createManifest          : "api/obituary/preview",
	deceasedAndObituary     : "api/obituary/createWithPage",
	editDeceasedAndObituary : "api/obituary/editWithPage",
	setManifestConfig       : "api/obituaryCard/setConfig",
	checkPhone              : "api/telephone/exists",
	register                : "api/registration/complete",
};
export const getUrl = {
	deceasedCard         : "api/condolenceBook/deceasedCard/",
	deceasedCard2        : "api/condolenceBook/deceasedCardGrid/",
	pdfBook              : "api/condolenceBook/printBook/",
	sentences            : "api/obituary/sentences",
	logout               : "api/auth/logout",
	getAphorism          : "api/aphorism/list",
	getBookLimit         : "api/condolence-book/limit",
	region               : "api/location/getRegioni",
	province             : "api/location/getProvince/",
	location             : "api/location/getComuni/",
	mailExist            : "api/check/mailExists?email=",
	getUsername          : "api/registration/user/",
	cfExist              : "api/check/cfExists?cf=",
	userExist            : "api/check/usernameExists?username=",
	partnerSend          : "api/partner/sendDeceasedData/",
	searchUser           : "api/user/search?q=",
	deceasedData         : "api/deceased/",
	lampadaData          : "api/lampade/",
	lampadeGestoreData   : "api/lampadeGestore/",
	verifyToken          : "api/verifyContact?token=",
	acceptGift           : "api/verifyContact?token=",
	refuseGift           : "api/verifyContact?token=",
	getBookMessages      : "api/condolenceBook/getCondolences?deceasedID=",
	getProductPrice      : "api/product/details?type=",
	getProductPrices     : "api/product/list",
	getDeceasedByOwner   : "api/deceasedbyemail?owneremail=",
	getDeceasedByID      : "api/deceasedbyowner?ownerid=",
	getActivationTable   : "api/getAllActivationRequests",
	dedicDataTable       : "api/partner/getDedicList",
	purchaseDataTable    : "api/getPurchasesDetail",
	getPartner           : "api/getAllPartners",
	getPartnerPage       : "api/partner/get/?partnerid=",
	qrcodeRequest        : "api/admin/generateQrcode?amount=",
	contactData          : "api/zone/getByUser/",
	nationality          : "api/location/getCountries",
	acceptTransfer       : "api/check/transfer?token=",
	unsubscribe          : "api/unsubscribe?token=",
	denyTransfer         : "",
	locationFromCap      : "api/location/locationFromCap/",
	timeNotLabel         : "api/notificationTime",
	manifestBg           : "api/obituary/background",
	getManifestConfig    : "api/obituaryCard/getConfig",
	deceasedObituaryCard : "api/obituaryCard/create/",
};
const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
myHeaders.append("X_REQUESTED_WITH", "xmlhttprequest");
function remove(array, element) {
	const index = array.indexOf(element);
	if (index > -1) {
		array.splice(index, 1);
	}
}
const pending = [];
let pending2  = [];
function search(body) {
	const URL = `${baseURL}api/search?search-region=${body.region || " "}&search-province=${
		body.province || " "
	}&search-location=${body.location || " "}&search-text=${body.text || " "}&max-result=${
		body.maxResults || " "
	}&searchid=${body.searchid || " "}&search-cap=${body.cap || " "}&page=${body.page || 1}&view-filter=${
		body.filter || "all"
	}&order=${body.order || "insertAndType"}&order-dir=${body.orderDir || "disc"}`;
	return new Promise((result) => {
		Axios.get(URL)
			.then((resolve) => {
				result(resolve);
			})
			.catch((err) => result({ err }));
	});
}

function evaluateDedic(body, isAccepted) {
	const URL = `${baseURL}api/dedic/${isAccepted}?dedicID=${body.dedic}&token=${body.token}`;
	return new Promise((result) => {
		Axios.get(URL)
			.then((resolve) => {
				result(resolve);
			})
			.catch((err) => result({ err }));
	});
}

export function getCsrf() {
	return new Promise((result, err) => Axios.get(`${baseURL}api/auth/securitytoken`)
			.then((resolve) => {
				if (resolve != null) {
					result(resolve.data._csrf);
				} else result({ DATA: "NO DATA" });
			})
			.catch((err) => result({ err })));
}

function getFunction(url, getData) {
	let URL = `${baseURL}${getUrl[url]}`;
	if (getData) URL += getData;
	return new Promise((result, err) => Axios.get(URL)
			.then((resolve) => {
				result(resolve);
			})
			.catch((err) => result({ err })));
}

function sendFormDataWithoutText(url, formData) {
	const URL = baseURL + postUrl[url];
	const XHR = new XMLHttpRequest();
	for (const i in pending) {
		pending2[i].abort();
	}
	pending2 = [];
	pending2.push(XHR);
	return new Promise((resolve2, reject) => {
		if (XHR && XHR.readyState > 0 && XHR.readyState < 4) {
			// there is a request in the pipe, abort
			XHR.abort();
		}
		XHR.addEventListener("load", (event) => {});
		XHR.addEventListener("readystatechange", function () {
			if (this.readyState === 4) {
				remove(pending2, XHR);
				try {
					resolve2(this.response);
				} catch (e) {
					if (`${this.status}` === "403") {
						// eslint-disable-next-line prefer-promise-reject-errors
						reject("403");
					} else {
						reject(e);
					}
					console.log(e);
				}
			}
		});
		// Define what happens in case of error
		XHR.addEventListener(" error", function (event) {
			if (`${this.status}` === "403") {
				// eslint-disable-next-line prefer-promise-reject-errors
				reject("403");
			} else {
				reject(event);
			}
		});
		XHR.upload.addEventListener(
			"progress",
			(evt) => {
				if (evt.lengthComputable) {
					const percentComplete = evt.loaded / evt.total;
				}
			},
			false,
		);
		// Set up our request
		XHR.open("POST", URL);

		// Send our FormData object; HTTP headers are set automatically
		XHR.send(formData);
	});
}

function sendFormData(url, formData) {
	const URL = baseURL + postUrl[url];
	const XHR = new XMLHttpRequest();
	pending.push(XHR);
	return new Promise((resolve2, reject) => {
		XHR.addEventListener("load", (event) => {});
		XHR.addEventListener("readystatechange", function () {
			if (this.readyState === 4) {
				remove(pending, XHR);
				try {
					resolve2(JSON.parse(this.responseText));
				} catch (e) {
					console.log(e);
				}
			}
		});
		// Define what happens in case of error
		XHR.addEventListener(" error", (event) => {});
		XHR.upload.addEventListener(
			"progress",
			(evt) => {
				if (evt.lengthComputable) {
					const percentComplete = evt.loaded / evt.total;
					EventEmitter.emit("upload", percentComplete);
					EventEmitter.emit("upload2", percentComplete);
					EventEmitter.emit("upload3", percentComplete);
					EventEmitter.emit("upload4", percentComplete);
					EventEmitter.emit("upload5", percentComplete);
					EventEmitter.emit("upload6", percentComplete);
					// Do something with upload progress
					EventEmitter.emit("upload7", percentComplete);
				}
			},
			false,
		);
		// Set up our request
		XHR.open("POST", URL);

		// Send our FormData object; HTTP headers are set automatically
		XHR.send(formData);
	});
}

function postFunction(url, bodyData) {
	return getCsrf()
		.then((_csrf) => {
			const URL = baseURL + postUrl[url];

			const urlencoded = new URLSearchParams();
			urlencoded.append("csrf_token", _csrf);
			for (const property in bodyData) {
				urlencoded.append(property, bodyData[property]);
			}
			bodyData.csrf_token = _csrf;
			bodyData            = querystring.stringify(bodyData);
			return new Promise((resolve, reject) => {
				const xhr = new XMLHttpRequest();
				pending.push(xhr);
				// xhr.withCredentials = true;

				xhr.addEventListener("readystatechange", function () {
					if (this.readyState === 4) {
						remove(pending, xhr);
						try {
							resolve(JSON.parse(this.responseText));
						} catch (e) {
							resolve({ err: e });
						}
					}
				});

				xhr.open("POST", URL);
				xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
				xhr.setRequestHeader("X_REQUESTED_WITH", "xmlhttprequest");
				xhr.send(urlencoded);
			});
		})
		.catch((err) => sessionStorage.setItem("data", JSON.stringify(err)));
}
function postNoCsrf(url, bodyData) {
	const URL = baseURL + postUrl[url];

	const urlencoded = new URLSearchParams();
	for (const property in bodyData) {
		urlencoded.append(property, bodyData[property]);
	}
	return new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		// xhr.withCredentials = true;

		xhr.addEventListener("readystatechange", function () {
			if (this.readyState === 4) {
				try {
					resolve(JSON.parse(this.responseText));
				} catch (e) {
					console.log(e);
				}
			}
		});

		xhr.open("POST", URL);
		xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
		xhr.setRequestHeader("X_REQUESTED_WITH", "xmlhttprequest");
		xhr.send(urlencoded);
	});
}
export async function handleSaveImg(object, id) {
	const image      = object;
	const file       = new Blob([image], { type: "application/pdf" });
	const saveImg    = document.createElement("a");
	saveImg.target   = "_blank";
	saveImg.href     = object;
	saveImg.download = `Libro${id}.pdf`;
	saveImg.click();
	setTimeout(() => URL.revokeObjectURL(saveImg.href), 60000);
}
function requests(type, url, bodydata) {
	if (type.toLowerCase() === "sendform" || type.toLowerCase() === "formdata") {
		return new Promise((resolve) => sendFormData(url, bodydata).then((data) => {
			resolve(data);
		}));
	}
	if (type.toLowerCase() === "sendformnotext" || type.toLowerCase() === "formdata") {
		return new Promise((resolve, reject) => sendFormDataWithoutText(url, bodydata)
				.then((data) => {
					resolve(data);
				})
				.catch((err) => reject(err)));
	}
	if (type.toLowerCase() === "post") {
		return new Promise((resolve) => postFunction(url, bodydata).then((data) => {
			resolve(data);
		}));
	}
	if (type.toLowerCase() === "get") {
		return new Promise((resolve) => getFunction(url, bodydata).then((data) => {
			resolve(data);
		}));
	}
	if (type.toLowerCase() === "search") {
		return new Promise((resolve) => search(bodydata).then((data) => {
			resolve(data);
		}));
	}
	if (type.toLowerCase() === "evaluatededic") {
		return new Promise((resolve) => evaluateDedic(bodydata, bodydata.isAccepted).then((data) => {
			resolve(data);
		}));
	}
	if (type.toLowerCase() === "nocsrf") {
		return new Promise((resolve) => postNoCsrf(url, bodydata).then((data) => {
			resolve(data);
		}));
	}
}

export default requests;
