import React, { useState } from "react";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import requests, { baseURL } from '../../../Services/HttpServices';
import "./LampadePopup.scss";

function creaLampada(props) {
  const bodydata = {};
  bodydata["idDefunto"] = parseInt(props.idDefunto, 10);
  bodydata["idGestore"] = parseInt(props.idGestore, 10);
  bodydata["idAbbonato"] = 0;
  
	requests("post", "lampadaCreate", bodydata).then((data) => {
		if (data?.result === "success") {
      console.log("Success:" + data?.log); 
      var lampadaData = data?.lampadaData;
      props.open(lampadaData, props.nomeDefunto);
    }
		else { console.log("Fail: " + data?.log); }
	});
}

export default function LampadeCreatePopup(props) {
  let close = true;

  return (
    <div className="lampade-main-container" onClick={() => { close ? props.close() : (close = true); }}>
      <div onClick={() => { close = false; }} className="lampade-card" style={{ maxWidth: 360, minHeight: 170 }}>

        <label className="ttitle">Crea Lampada</label>
        <label className="subbtitle">Sei sicuro di voler creare la Lampada Votiva per <b>{props.nomeDefunto}</b>?</label>
        <div>
          <button style={{marginRight: "10px"}} onClick={() => { creaLampada(props); }}>Si</button>
          <button onClick={() => { props.close(); }}>No</button>
        </div>
      </div>
    </div>
  );
}