let regioni
export default regioni = [
    {
        "ID": "2",
        "name": "Abruzzo"
    },
    {
        "ID": "3",
        "name": "Basilicata"
    },
    {
        "ID": "4",
        "name": "Calabria"
    },
    {
        "ID": "5",
        "name": "Campania"
    },
    {
        "ID": "6",
        "name": "Emilia-Romagna"
    },
    {
        "ID": "7",
        "name": "Friuli-Venezia Giulia"
    },
    {
        "ID": "8",
        "name": "Lazio"
    },
    {
        "ID": "9",
        "name": "Liguria"
    },
    {
        "ID": "10",
        "name": "Lombardia"
    },
    {
        "ID": "11",
        "name": "Marche"
    },
    {
        "ID": "12",
        "name": "Molise"
    },
    {
        "ID": "13",
        "name": "Piemonte"
    },
    {
        "ID": "14",
        "name": "Puglia"
    },
    {
        "ID": "15",
        "name": "Sardegna"
    },
    {
        "ID": "16",
        "name": "Sicilia"
    },
    {
        "ID": "17",
        "name": "Toscana"
    },
    {
        "ID": "18",
        "name": "Trentino-Alto Adige"
    },
    {
        "ID": "19",
        "name": "Umbria"
    },
    {
        "ID": "20",
        "name": "Valle d'Aosta"
    },
    {
        "ID": "21",
        "name": "Veneto"
    }
]
export function getNameFromID(id) {
    let j = -1;
    if (regioni.length>0)
        while (j < regioni.length-1) {
            j++;
            if (regioni[j].ID == id) {

                return regioni[j].name
            }
        }
}
export function getNameFromIDForAllType(id,array) {
    let j = -1;
    if (array.length>0)
        while (j < array.length-1) {
            j++;
            if (array[j].ID == id) {

                return array[j].name
            }
        }
}
export function getIdFromName(name) {
    let j = -1;
    if (regioni)
        while (j < regioni.length) {
            j++;


            if (regioni[j].name == name) {

                return regioni[j].ID
            }
        }
}
