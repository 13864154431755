/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/label-has-associated-control,jsx-a11y/no-static-element-interactions,no-shadow */
import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, IconButton, MenuItem, TextField, Tooltip, Typography } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsIt from 'date-fns/locale/it';
import DateFnsUtils from '@date-io/date-fns';
import { Clear, CloudUpload, Delete, FormatBold, Refresh, RemoveCircleOutline } from '@material-ui/icons';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import { Spinner } from 'react-activity';
import listner from 'reactjs-eventemitter';
import AddressService from '../../FormComponents/AddressComponent';
import CkEditorCustom from '../../FormComponents/CkEditor';
import MultipleReferringPlace from '../../FormComponents/MultipleReferringPlace';
import PhotoInput from '../../FormComponents/PhotoInput';
import { readURL } from '../../FormComponents/FileNamesComponent';
import FormLabel from '../../FormComponents/TermFormComponents';
import requests, { getCsrf } from '../../../Services/HttpServices';
import manifest from '../../../assets/stabmani.png';
import SinglePhotoViewer from '../../FormComponents/singlePhotoViewer';
import FeedbackModal from '../../FormComponents/FeedbackModal';

const bgtemp = 'https://www.digitalmemorial.dlgtek.com/api/images/sfondoManifesto0.jpg';
export default function AddDeceasedPosterAndPage(props) {
	const [state, setState] = useState({
		form: {},
		formError: {},
		autocompleteTemp: {
			ID: 0,
			qrcode: 'Fai generare al sistema un QrCode temporaneo 30gg (Agevolazione per Covid)',
			value: -1
		},
		publishDate: new Date(),
		req: {
			name: false,
			surname: false,
			gender: false,
			graveyardRegion: false,
			graveyardProvince: false,
			graveyardCity: false
		},
		manifestExtraDate: {}
	});
	const [isUploading, setIsUploading] = useState(false);
	const [referingPlace, setReferringPlace] = useState(false);
	const [uploadingPerc, setUploadingPerc] = useState(0);
	const [modalData, setModalData] = useState({ result: '', open: false, label: '' });
	const [photoManifest, setPhotoManifest] = useState(null);
	const [customManifest, setCustomManifest] = useState(null);
	const [sentences, setSentences] = useState([]);
	const [selectExtraDate, setSelectExtraDate] = useState({});
	const [photoLapide, setPhotoLapide] = useState(null);
	const [qrtosend, setQrtosend] = useState(null);
	const [qr, setQr] = useState([]);
	const [bgs, setBgs] = useState([]);
	const [selectedBg, setSelectedBg] = useState(0);
	const [loading, setLoading] = useState(false);
	const [reload, setReload] = useState(false);
	const [toCreate, setToCreate] = useState(false);
	useEffect(() => {
		if (toCreate) {
			setToCreate(false);
			onBlurCreateManifest();
		}
	}, [toCreate]);
	useEffect(() => {
		requests('get', 'manifestBg', '').then(data => {
			if (data.data.data?.obituarybg?.length > 0) setBgs(data.data.data.obituarybg);
		});
		const data = [state.autocompleteTemp];
		requests('get', 'getActivationTable').then(result => {
			result.data.qrcodelist.forEach(e => {
				if (!(e.deceased?.name?.length > 0 || `${e.sold}` === '1')) {
					data.push({ ID: e.ID, qrcode: e.deceased?.ID, value: e.deceased.ID });
				}
			});
			setQr(data);
		});
		requests('get', 'sentences').then(result => {
			const temp = {};
			Object.keys(result.data.data).forEach(key => {
				if (!temp[key]) temp[key] = [];
				result.data.data[key].forEach((e, i) => {
					temp[key].push({ label: e, value: i + 1 });
				});
				temp[key].unshift({ value: -1, label: 'Consigliami una frase' });
			});
			setSelectExtraDate(temp);
		});
		requests('get', 'getAphorism', '').then(data => {
			if (data.data?.response === 'success') {
				setSentences(data.data.data.aphorisms);
			}
		});
		listner.events.upload7 = [];
	}, []);
	useEffect(() => {
		listner.events.upload7.length < 1 &&
			listner.subscribe('upload7', callback => {
				setUploadingPerc(callback);
			});
	});
	const sendData = event => {
		event.preventDefault();
		const formData = new FormData(event.target);
		const object = {};
		formData.forEach(function (value, key) {
			object[key] = value;
		});
		let reqError = false;
		Object.keys(state.req).forEach(e => {
			if (!state.form[e] || state.form[e] === '') {
				const temp = state.formError;
				reqError = true;
				temp[e] = true;
				setState({ ...state, formError: temp });
			}
		});

		let conditionReferring = true;
		/* test luogo */
		const objects = JSON.parse(referingPlace);
		if (objects?.length > 0) {
			objects.forEach(e => {
				if (e.region && e.region !== '') conditionReferring = conditionReferring && e.city;
			});
		}
		if (!objects[0].region) conditionReferring = false;
		let termsError = !state.checkBox;
		if (document.getElementById('term-box')) {
			console.log(document.getElementById('term-box').value);
			termsError = !JSON.parse(document.getElementById('term-box').value);
		}
		setState({
			...state,
			refError: !conditionReferring,
			refSendError: !conditionReferring,
			termsError,
			graveError:
				state.formError.graveyardProvince || state.formError.graveyardRegion || state.formError.graveyardCity
		});
		/* test luogo */
		formData.set('referring-place', referingPlace);
		formData.set('aphorism', state.aphorism);
		formData.set('qrcode', qrtosend?.value || -1);
		formData.set('announce', state.manifestExtraDate?.announce);
		formData.set('family', state.manifestExtraDate?.family);
		formData.set('thanks', state.manifestExtraDate?.thanks);
		formData.set('first_sentence', state.manifestExtraDate?.first_sentence);
		formData.set('funeral_info', state.manifestExtraDate?.funeral_info);
		formData.set('bg', selectedBg);
		if (customManifest) formData.set('photo-custom-manifest', customManifest?.photo);
		else formData.set('photo-custom-manifest', null);

		if (!photoLapide && !reqError && conditionReferring && !termsError) {
			setState({ ...state, openModalPhoto: true, formData });
		} else formData.set('photo-lapide', photoLapide?.photo);
		setTimeout(() => {
			if (document.getElementsByClassName('Mui-error')?.length > 0) {
				document.getElementsByClassName('Mui-error')[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
			}
		}, 100);

		if (!reqError && photoLapide && conditionReferring && !termsError) {
			sendDataHttp(formData);
		}
	};
	// eslint-disable-next-line no-shadow
	function sendDataHttp(formData) {
		setIsUploading(true);
		getCsrf().then(csrf => {
			formData.set('csrf_token', csrf);
			formData.set('publish_date', moment(state.publishDate).toDate().getTime() / 1000);
			requests('sendform', 'deceasedAndObituary', formData).then(result => {
				if (result.response === 'success') {
					setModalData({ open: true, result: 'Successo', label: 'Defunto inserito con successo!' });
				}
				setIsUploading(false);
			});
		});
	}
	const setValue = event => {
		const temp = state.form;
		temp[event.target.name] = event.target.value;
		setState({ ...state, form: temp });
	};
	const setValueWithouEvent = (value, prop) => {
		const temp = state.form;
		temp[prop] = value;
		setState({ ...state, form: temp });
	};
	const isErrorField = value => {
		return state.formError[value] && !state.form[value];
	};
	const onRicherChange = (property, value, bold, red) => {
		const temp = state.manifestExtraDate;
		temp[property] = JSON.stringify({ bold, red, value });
		setState({ ...state, manifestExtraDate: temp });
	};
	const refP2 = referingPlace && JSON.parse(referingPlace);
	const onBlurCreateManifest = obj => {
		let refp23 = referingPlace && JSON.parse(referingPlace);
		if (obj?.refp2) {
			refp23 = [...obj.refp2];
			setReferringPlace(JSON.stringify(obj.refp2));
			setReload(!reload);
		}
		if (!state.customManifest) {
			let bodyData = {
				qrcode: state.qrToSend?.value || -1,
				name: state.form.name,
				surname: state.form.surname,
				gender: state.form.gender,
				age: state.form.age,
				manifest_photo: photoManifest?.photo || obj?.manifest_photo || null,
				bg: selectedBg,
				...obj
			};
			if (refp23?.length > 0) {
				const graveyardRegion = refp23[0].region;
				const graveyardProvince = refp23[0].province;
				const graveyardCity = refp23[0].location || refp23[0].city;
				const graveyardCap = refp23[0].cap;
				bodyData = { ...bodyData, graveyardRegion, graveyardProvince, graveyardCity, graveyardCap };
			}
			setCustomManifest(null);
			bodyData = {
				...bodyData,
				publish_date: moment(state.publishDate).toDate().getTime() / 1000,
				...state.manifestExtraDate
			};
			if (
				bodyData.name &&
				bodyData.surname &&
				bodyData.gender &&
				bodyData.graveyardProvince &&
				bodyData.graveyardRegion &&
				bodyData.graveyardCity &&
				// bodyData.graveyardCap &&
				bodyData.publish_date
			) {
				setState({ ...state, manifest: null });
				const formData = new FormData();

				// eslint-disable-next-line guard-for-in,no-restricted-syntax
				for (const key in bodyData) {
					formData.append(key, bodyData[key]);
				}
				window.stop();
				setLoading(true);
				getCsrf().then(csrf => {
					formData.append('csrf_token', csrf);
					requests('sendformnotext', 'createManifest', formData)
						.then(result => {
							if (result) {
								setLoading(false);
								setState({ ...state, manifest: result });
							}
						})
						.catch(err => {
							console.log({ err });
						});
				});
			}
		}
	};
	return (
		<div className="flex-column" style={{ alignItems: 'center' }}>
			{isUploading && (
				<div className="div-upload">
					<Spinner size={100} />
					<Typography className="label-upload">
						Attendi il caricamento dei files {parseInt(`${parseFloat(`${uploadingPerc}`) * 100}`, 10)}%
					</Typography>
				</div>
			)}
			{modalData.open && (
				<FeedbackModal
					result={modalData.result}
					isPartnerAdd={() => {
						window.history.pushState(
							{ deceasedName: `${state.form?.name} ${state.form.surname}` },
							'title',
							'/partner-panel#deceased'
						);
						props.getTableData();
						props.reload();
					}}
					close={() => {
						// setModalData({ ...modalData, open: false });
						window.scrollTo(0, 0);
						window.location.reload();
					}}
					label={modalData.label}
				/>
			)}
			{state.openModalPhoto && photoLapideModal(state?.formData)}
			{state.photoViewer && state.imgToZoom && (
				<SinglePhotoViewer
					img={state.imgToZoom}
					handleClose={() => setState({ ...state, photoViewer: false, imgToZoom: null })}
				/>
			)}
			<span className="admin-content-subtitle">Aggiungi defunto</span>
			&nbsp;
			<div className="activate-form-div new-add-page" id="formpage">
				<form className="activate-form" onSubmit={sendData}>
					<span className="death-data">Dati Defunto</span>
					&nbsp;
					<Autocomplete
						renderInput={params => <TextField {...params} label="Scegli un qrcode" variant="outlined" />}
						options={qr}
						componentName="qrcode"
						value={qrtosend || state.qrToSend || state.autocompleteTemp}
						onChange={(event, value, reason, details) => {
							onBlurCreateManifest({ qrcode: value });
							setState({ ...state, qrToSend: value || state.autocompleteTemp });
							setQrtosend(value || state.autocompleteTemp);
						}}
						getOptionLabel={option => {
							return `${option.qrcode}`;
						}}
					/>
					<TextField
						className="activate-field req"
						value={state.form.name || ''}
						onChange={setValue}
						label="Nome *"
						name="name"
						onBlur={onBlurCreateManifest}
						error={isErrorField('name')}
						helperText={isErrorField('name') && 'Campo obbligatorio'}
					/>
					<TextField
						className="activate-field req"
						value={state.form.surname || ''}
						onChange={setValue}
						label="Cognome *"
						onBlur={onBlurCreateManifest}
						name="surname"
						error={isErrorField('surname')}
						helperText={isErrorField('surname') && 'Campo obbligatorio'}
					/>
					<div className="isFlexRow justify-between">
						<TextField
							className="activate-field"
							label="Età"
							onBlur={onBlurCreateManifest}
							name="age"
							value={state.form.age}
							onChange={setValue}
						/>
						<TextField
							className="activate-field req"
							select
							onChange={setValue}
							label="Sesso *"
							onBlur={onBlurCreateManifest}
							name="gender"
							value={state.form.gender}
							error={isErrorField('gender')}
							helperText={isErrorField('gender') && 'Campo obbligatorio'}
						>
							<MenuItem value="M">M</MenuItem>
							<MenuItem value="F">F</MenuItem>
						</TextField>
					</div>
					&nbsp;
					<div className="activate-date-field">
						<MuiPickersUtilsProvider locale={DateFnsIt} utils={DateFnsUtils}>
							<KeyboardDatePicker
								className=" ml--5 date "
								inputVariant="filled"
								format="dd/MM/yyyy"
								margin="normal"
								style={{ backgroundColor: 'white' }}
								value={state.form.born_date || null}
								onChange={event => setValueWithouEvent(event, 'born_date')}
								id="date"
								name="born_date"
								label="Data di nascita"
							/>
							<KeyboardDatePicker
								className="m-12 date"
								inputVariant="filled"
								format="dd/MM/yyyy"
								onChange={event => setValueWithouEvent(event, 'death_date')}
								value={state.form.death_date || null}
								margin="normal"
								name="death_date"
								id="date"
								label="Data di morte"
							/>
						</MuiPickersUtilsProvider>
					</div>
					<span className="death-data">Dati Manifesto</span>
					<div className="buy-form-paragraph " id="multipleReferringPlace">
						Luogo del funerale
					</div>
					<MultipleReferringPlace
						obituary
						req
						partnerpanel
						onChange={data => {
							const refP = JSON.parse(data);
							let condition =
								refP?.length > 0 &&
								refP[refP.length - 1].province &&
								refP[refP.length - 1].city &&
								refP[refP.length - 1].region;
							condition = condition && refP[refP.length - 1].region;
							setState({
								...state,
								refError: !condition,
								refSendError: false
							});
							setReferringPlace(data);
							const condition2 = refP?.length > 0 && refP[0].province && refP[0].city && refP[0].region;
							if (condition2) onBlurCreateManifest({ refp2: refP });
						}}
						error={state.refSendError}
					/>
					{state.refSendError &&
						(!(!refP2[0].city || refP2[0].city === '') ? (
							<span style={{ color: 'red', marginTop: 12 }}>
								Inserisci regione, provincia e città per ogni riga parzialmente compilata
							</span>
						) : (
							<span style={{ color: 'red', marginTop: 12 }}>La prima riga è obbligatoria</span>
						))}
					<div className="buy-form-paragraph" id="multipleReferringPlace">
						Dati del funerale
					</div>
					&nbsp;
					<MuiPickersUtilsProvider locale={DateFnsIt} utils={DateFnsUtils}>
						<KeyboardDatePicker
							inputVariant="filled"
							format="dd/MM/yyyy"
							style={{
								backgroundColor: 'white',
								width: '100%'
							}}
							onChange={event => setValueWithouEvent(event, 'funeral_date')}
							id="date"
							value={state.form.funeral_date || null}
							name="funeral_date"
							label="Data del funerale"
						/>
					</MuiPickersUtilsProvider>
					<MuiPickersUtilsProvider locale={DateFnsIt} utils={DateFnsUtils}>
						<KeyboardDatePicker
							inputVariant="filled"
							format="dd/MM/yyyy"
							style={{
								backgroundColor: 'white',
								width: '100%',
								marginTop: 30
							}}
							value={state.publishDate}
							onChange={event => setState({ ...state, publishDate: event })}
							onBlur={onBlurCreateManifest}
							id="date"
							name="publish_date"
							className="req"
							label="Data di pubblicazione *"
						/>
					</MuiPickersUtilsProvider>
					<RicherField
						onChange={(value, bold, red) => onRicherChange('first_sentence', value, bold, red)}
						value={state.manifestExtraDate?.first_sentence}
						onBlur={onBlurCreateManifest}
						placeholder={"Es. E' venuto a mancare improvvisamente all'affetto dei suoi cari."}
						age={state.form.age}
						withSelect
						selectData={
							(selectExtraDate?.frasiAnni?.length > 0 && selectExtraDate.frasiAnni) || [
								{ value: -1, label: 'Consigliami una frase' }
							]
						}
						onSelectChange={data => {
							document.getElementById('first_sentence').value = data;
							onRicherChange('first_sentence', data, false, false);
							setToCreate(true);
							setReload(!reload);
						}}
						label="Frase iniziale"
						name="first_sentence"
					/>
					<RicherField
						onChange={(value, bold, red) => onRicherChange('family', value, bold, red)}
						onBlur={onBlurCreateManifest}
						age={state.form.age}
						withSelect
						value={state.manifestExtraDate?.family}
						selectData={
							(selectExtraDate?.riferimenti?.length > 0 && selectExtraDate.riferimenti) || [
								{ value: -1, label: 'Consigliami una frase' }
							]
						}
						onSelectChange={data => {
							document.getElementById('family').value = data;
							onRicherChange('family', data, false, false);
							setTimeout(() => {
								onBlurCreateManifest();
							}, 100);
							setReload(!reload);
						}}
						label="Riferimenti familiari"
						placeholder="Es. Vedova Rossi."
						name="family"
					/>
					<RicherField
						onChange={(value, bold, red) => onRicherChange('announce', value, bold, red)}
						onBlur={onBlurCreateManifest}
						age={state.form.age}
						value={state.manifestExtraDate?.announce}
						withSelect
						selectData={
							(selectExtraDate?.annuncio?.length > 0 && selectExtraDate.annuncio) || [
								{ value: -1, label: 'Consigliami una frase' }
							]
						}
						onSelectChange={data => {
							document.getElementById('announce').value = data;
							onRicherChange('announce', data, false, false);
							setTimeout(() => {
								onBlurCreateManifest();
							}, 100);
							setReload(!reload);
						}}
						label="Annuncio"
						placeholder="Es. Ne danno il triste annuncio i figli, la famiglia e i parenti tutti"
						name="announce"
					/>
					<RicherField
						onChange={(value, bold, red) => onRicherChange('funeral_info', value, bold, red)}
						label="Informazioni del funerale"
						age={state.form.age}
						withSelect
						value={state.manifestExtraDate?.funeral_info}
						selectData={
							(selectExtraDate?.funerale?.length > 0 && selectExtraDate.funerale) || [
								{ value: -1, label: 'Consigliami una frase' }
							]
						}
						onSelectChange={data => {
							document.getElementById('funeral_info').value = data;
							onRicherChange('funeral_info', data, false, false);
							setTimeout(() => {
								onBlurCreateManifest();
							}, 100);
							setReload(!reload);
						}}
						onBlur={onBlurCreateManifest}
						name="funeral_info"
						placeholder="Es. 'I funerali si svolgeranno in Campobasso nella Cattedrale Maggiore alle ore 11:00 del giorno 03.01.2021'"
					/>
					<RicherField
						onChange={(value, bold, red) => onRicherChange('thanks', value, bold, red)}
						age={state.form.age}
						onBlur={onBlurCreateManifest}
						value={state.manifestExtraDate?.thanks}
						withSelect
						selectData={
							(selectExtraDate?.ringraziamenti?.length > 0 && selectExtraDate.ringraziamenti) || [
								{ value: -1, label: 'Consigliami una frase' }
							]
						}
						onSelectChange={data => {
							document.getElementById('thanks').value = data;
							onRicherChange('thanks', data, false, false);
							setTimeout(() => {
								onBlurCreateManifest();
							}, 100);
							setReload(!reload);
						}}
						label="Ringraziamenti"
						name="thanks"
						placeholder="Es. Si ringrazia per la partecipazione.."
					/>
					<PhotoInput
						name="lapide2"
						accept={['.png', '.jpeg', '.jpg']}
						setFileName={(name, photo) => {
							setState({ ...state, filename2: name, filename: name });
							onBlurCreateManifest({ manifest_photo: photo });
							readURL(photo).then(data => {
								// eslint-disable-next-line eqeqeq
								setPhotoManifest({ url: data, photo, name });
								setPhotoLapide({ url: data, photo, name });
							});
						}}
					/>
					<div className="file-upload">
						<label className="pointer" htmlFor="photo-lapide2" color="blue" title="">
							<p className="pointer-label">
								{photoManifest?.name || (
									<>
										<b className="bold-underline" style={{ color: 'black' }}>
											Carica foto del defunto
										</b>{' '}
										per il manifesto (Max 15MB per foto)'
									</>
								)}
							</p>
						</label>
					</div>
					&nbsp;
					{photoManifest && (
						<div className="is-relative">
							<img
								alt="lapide"
								src={photoManifest?.url}
								style={{ minWidth: 'unset', objectFit: 'contain' }}
							/>
							<RemoveCircleOutline
								className="remove-photo"
								onClick={() => {
									onBlurCreateManifest({ manifest_photo: null });
									setPhotoManifest(null);
									document.getElementById('photo-lapide2').value = null;
								}}
							/>
						</div>
					)}
					&nbsp;
					<span style={{ fontSize: 20 }}>Sfondo Manifesto</span>
					<div
						style={{
							display: 'flex',
							width: '100%',
							overflowX: 'auto',
							textAlign: 'left',
							padding: '10px 0',
							overflowY: 'hidden'
						}}
					>
						{bgs.map((e, i) => (
							<img
								src={e}
								alt="bg"
								onClick={() => {
									setState({ ...state, bgManifest: e });
									setSelectedBg(i);
									setTimeout(() => {
										onBlurCreateManifest({ bg: i });
									}, 300);
								}}
								className="manifest"
							/>
						))}
					</div>
					&nbsp;
					<Typography style={{ fontSize: 20, fontWeight: 'bold' }}>Manifesto che verrà caricato</Typography>
					<div style={{ position: 'relative' }}>
						{state.customManifest && (
							<IconButton
								className="clear-button"
								style={{ position: 'absolute', cursor: 'pointer', right: 10, top: 10 }}
								onClick={() => {
									setCustomManifest(null);
									setTimeout(() => {
										onBlurCreateManifest();
									}, 100);
									setState({ ...state, customManifest: null });
								}}
							>
								<Clear color="error" />
							</IconButton>
						)}
						{!state.manifest &&
							(loading ? (
								<div className="absolutexy-center">
									<CircularProgress size={30} color="primary" />
									<Typography>Creazione manifesto personalizzato</Typography>
								</div>
							) : (
								<Typography className="absolutexy-center text-manifest">
									Compila il form con i dati obbligatori per visualizzare l'anteprima completa
								</Typography>
							))}
						{state.manifest && !state.customManifest && !loading && (
							<Tooltip title="Aggiorna con i nuovi dati">
								<IconButton
									className="refresh-button"
									style={{
										position: 'absolute',
										cursor: 'pointer',
										right: -20,
										top: 10,
										left: 'auto'
									}}
									color="primary"
									onClick={() => onBlurCreateManifest({})}
								>
									<Refresh color="primary" />
								</IconButton>
							</Tooltip>
						)}
						<img
							src={
								state.customManifest || state.manifest || (bgs.length > 0 && bgs[selectedBg]) || bgtemp
							}
							style={{ cursor: 'zoom-in' }}
							onClick={() =>
								setState({
									...state,
									imgToZoom: state.customManifest || state.manifest || state.bgManifest || bgtemp,
									photoViewer: true
								})
							}
							alt="manifesto"
						/>
					</div>
					<label className="manifest-button" id="titleLogo" htmlFor="photo-custom-manifest">
						<p>Se vuoi sostituire il manifesto generato automaticamente, clicca qui</p>
					</label>
					<PhotoInput
						name="custom-manifest"
						setFileName={(name, photo) => {
							setState({ ...state, customManifestName: name });
							readURL(photo).then(data => {
								setCustomManifest({ url: data, photo, name });

								setState({ ...state, customManifest: data });
							});
						}}
					/>
					<span className="death-data">Dati Lapide Digitale</span>
					<div className="buy-form-paragraph">Dati del Cimitero</div>
					<div className="Partner-row-fields">
						<TextField
							className="partner-field "
							helperText="ad esempio Cimitero di Roma"
							label="Nome cimitero"
							name="admin-add-graveyard-name"
						/>
					</div>
					<AddressService
						req
						forceErrorWithReq
						names="page-graveyard"
						form={state.form}
						formError={state.formError}
						province={state.provincie}
						comuni={state.comuni}
						graveyardData
					/>
					{/* <TextField className="partner-field " label="Indirizzo" name="admin-add-graveyard-address" /> */}
					<TextField
						className="partner-field "
						label="Coordinate di sepoltura"
						// value={this.state.graveyardAddress}
						// onChange={event => {
						// 	this.setState({ graveyardAddress: event.target.value });
						name="admin-add-graveyard-address"
					/>
					<CkEditorCustom
						title="Aforismi"
						selectSentence={sentences}
						withSelect
						onChange={editor => {
							setState({ ...state, aphorism: editor.getData() });
						}}
						initData={state.oldAphorism || ''}
					/>
					<TextField
						name="admin-add-deceased-aphorism"
						className="text-area"
						id="outlined-multiline-static"
						style={{ display: 'none' }}
						label="Aforism"
						value={state.aphorism}
						onChange={e => {
							setState({ ...state, aphorism: e.target.value });
						}}
						multiline
						rows={8}
						variant="outlined"
					/>
					<PhotoInput
						name="lapide"
						setFileName={(name, photo) => {
							setState({ ...state, filename: name });
							readURL(photo).then(data => setPhotoLapide({ url: data, photo, name }));
						}}
					/>
					<div className="file-upload">
						<label className="pointer" htmlFor="photo-lapide" color="blue" title="">
							<p className="pointer-label">
								{photoLapide?.name || (
									<>
										<b className="bold-underline" style={{ color: 'black' }}>
											Carica foto del defunto
										</b>{' '}
										per la lapide digitale (Max 15MB per foto)'
									</>
								)}
							</p>
						</label>
					</div>
					&nbsp;
					{photoLapide && (
						<div className="is-relative">
							<img
								alt="lapide"
								src={photoLapide.url}
								style={{ minWidth: 'unset' }}
								className="photod-style memorial-page-photo"
							/>
							<RemoveCircleOutline
								className="remove-photo"
								onClick={() => {
									setPhotoLapide(null);
									document.getElementById('photo-lapide').value = null;
								}}
							/>
						</div>
					)}
					&nbsp;
					<FormControlLabel
						className="activate-checkbox"
						value={state.checkBox || false}
						control={
							<Checkbox
								id="term-box"
								onChange={(event, checked) => setState({ ...state, checkBox: checked })}
								color="primary"
								name="terms"
							/>
						}
						label={FormLabel()}
					/>
					{!state.checkBox && state.termsError && (
						<span
							style={{
								color: 'red',
								textAlign: 'left',
								margin: 10,
								marginLeft: 30
							}}
						>
							Devi accettare i termini
						</span>
					)}
					<div className="bottom-activate-form">
						<button onClick={event => {}} type="submit" className="activate-submit">
							Procedi
						</button>
					</div>
				</form>
			</div>
		</div>
	);
	function photoLapideModal(formdata) {
		let close2 = true;
		return (
			<div
				className="modal-bg"
				onClick={() => {
					if (close2) {
						setState({ ...state, openModalPhoto: false });
						// this.props.history.push('admin-panel')
					} else close2 = !close2;
				}}
			>
				<div
					className="inner"
					onClick={() => {
						close2 = false;
					}}
				>
					<Typography style={{ fontSize: 17 }}>Attenzione!!</Typography>
					<Typography style={{ fontSize: 12, marginTop: 15 }}>
						Non hai caricato la foto della lapide, desideri continuare?
					</Typography>
					<div
						style={{
							flexDirection: 'row',
							display: 'flex',
							justifyContent: 'space-between'
						}}
					>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								setState({ ...state, openModalPhoto: false });

								sendDataHttp(formdata);
							}}
						>
							SI
						</Button>
						<Button
							color="primary"
							style={{ marginLeft: 20 }}
							onClick={() => {
								setState({ ...state, openModalPhoto: false });

								// this.props.history.push('admin-panel')
							}}
							variant="contained"
						>
							NO
						</Button>
					</div>
				</div>
			</div>
		);
	}
}
function RicherField(props) {
	const [bold, setBold] = useState(false);
	const [red, setRed] = useState(false);
	const [text, setText] = useState('');
	const [sentences, setSentences] = useState([]);
	useEffect(() => {
		if (props.age) {
			const temp = [];
			props.selectData.forEach(e => {
				const data = { value: e.value, label: e.label.replace('[anni]', props.age) };
				temp.push(data);
			});
			setSentences(temp);
		} else {
			setSentences(props.selectData);
		}
	}, [props.age, props.selectData, props.selectData?.length]);
	useEffect(() => {
		if (props.value) {
			let toset = '';
			try {
				toset = JSON.parse(props.value)?.value;
			} catch (e) {
				console.log(e);
			}
			if (toset) setText(toset);
		}
	}, [props.value]);
	const classField = ` ${bold && ' force-bold '}${red && ' force-red '}`;
	return (
		<div className={`activate-field${classField}   flex-column`} style={{ position: 'relative' }}>
			<span style={{ fontSize: 20, textAlign: 'left' }}>{props.label}</span>
			&nbsp;
			{props.withSelect && (
				<TextField
					select
					defaultValue={-1}
					onChange={(e, boh, boh2) => {
						props.onSelectChange(boh.props.children);
					}}
					variant="outlined"
				>
					{sentences?.length > 0 && sentences.map(e => <MenuItem value={e.value}>{e.label}</MenuItem>)}
				</TextField>
			)}
			&nbsp;
			<div
				style={{
					width: '100%',
					left: 0,
					top: 0,
					border: '1px solid #c4c4c4',
					display: 'flex',
					justifyContent: 'flex-start',
					borderRadius: 4
				}}
			>
				<Tooltip title="Testo in rosso">
					<IconButton
						onClick={() => {
							if (text !== '') {
								props.onChange(text, bold, !red);
								setTimeout(() => {
									props.onBlur();
								}, 50);
							}
							setRed(!red);
						}}
						style={{
							width: 30,
							height: 30,
							fontWeight: 'bold',
							backgroundColor: red && '#dedede'
						}}
						size="small"
					>
						<p style={{ color: 'red', marginTop: -2 }}>A</p>
					</IconButton>
				</Tooltip>
				<Tooltip title="Testo in grassetto">
					<IconButton
						onClick={() => {
							if (text !== '') {
								props.onChange(text, !bold, red);
								setTimeout(() => {
									props.onBlur();
								}, 50);
							}
							setBold(!bold);
						}}
						size="small"
						style={{ backgroundColor: bold && '#dedede' }}
					>
						<FormatBold style={{ color: 'black' }} />
					</IconButton>
				</Tooltip>
			</div>
			<TextField
				placeholder={props.placeholder}
				onBlur={props.onBlur}
				value={text}
				onChange={e => {
					setText(e.target.value);
					props.onChange(e.target.value, bold, red);
				}}
				style={{ width: '100%' }}
				variant="outlined"
				multiline
				rows={2}
				id={props.name}
				name={props.name}
			/>
		</div>
	);
}
