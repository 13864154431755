import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import addressServiceGetData from '../FormComponents/addressService';
import requests from '../../Services/HttpServices';

const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: 48 * 4.5 + 8,
			width: 250
		}
	}
};
export default function SearchBarDedic(props) {
	const [regioni, setRegioni] = useState();
	const [regione, setRegione] = useState('');
	const [text, setText] = useState('');
	const [open, setOpen] = useState(false);
	const [searchdata, setSearchdata] = useState(null);
	const [searching, setSearching] = useState(false);
	const [selected, setSelected] = useState(false);
	if (!regioni && !searching) {
		setSearching(true);
		addressServiceGetData('region').then(data => {
			setRegioni(data);
			setSearching(false);
		});
	}
	const localStorageExist = localStorage.getItem('search') && JSON.parse(localStorage.getItem('search')).region;

	useEffect(() => {
		if (!regione && props.memorialData.id) {
			const initRegion =
				props.memorialData && props.memorialData.region ? props.memorialData.region : localStorageExist;
			setRegione(initRegion || 12);
			requests('search', null, {
				region: initRegion,
				province: ' ',
				location: ' ',
				text: ' ',
				searchid: props.memorialData.id || '',
				filter: 'page'
			}).then(result => {
				if (result.data.result === 'success') {
					const httpData = [];
					for (let j = 0; j < 1; j++) {
						for (const i in result.data.searchResult) {
							result.data.searchResult[i].showPage === '1' && httpData.push(result.data.searchResult[i]);
						}
					}
					setSearchdata(httpData);
					if (props.memorialData && props.memorialData.id) {
						for (const e of httpData) {
							if (e.ID == parseInt(props.memorialData.id)) {
								setSelected(e);
								props.setData(e);
							}
						}
					} else setSelected(null);
				} else {
					setSearchdata([]);
				}
			});
		}
	}, [props.memorialData]);
	return (
		<div>
			<div
				className="dedic-search-container"
				onClick={() => {
					selected && alert("Il defunto e' stato gia' selezionato");
				}}
				style={{ position: 'relative' }}
			>
				{selected && (
					<div
						style={{
							backgroundColor: 'rgba(0,0,0,0.2)',
							position: 'absolute',
							left: 0,
							top: 0,
							width: '100%',
							height: '100%'
						}}
					/>
				)}
				<label className="buy-form-paragraph">Ricerca defunto</label>
				<div className="dedic-search" style={{ position: 'relative' }}>
					<TextField
						className="dedic-search-field"
						style={{ width: 200 }}
						key="search-region"
						disabled={selected}
						onChange={event => {
							setRegione(event.target.value);
							requests('search', null, {
								region: event.target.value,
								province: ' ',
								location: ' ',
								text: ' ',
								filter: 'page'
							}).then(result => {
								setSelected('');
								props.setData('');
								if (result.data.result === 'success') {
									const temp = [];
									result.data.searchResult.length > 0 &&
										result.data.searchResult.map((e, i) => {
											e.showPage == '1' && temp.push(e);
										});
									setSearchdata(temp);
								} else {
									setSearchdata([]);
								}
							});
						}}
						label="Regione *"
						value={regione}
						variant="outlined"
						name="search-region"
						select
					>
						{regioni ? (
							regioni.map(e => (
								<MenuItem key={e.ID} value={e.ID}>
									{e.name}
								</MenuItem>
							))
						) : (
							<MenuItem>No connection</MenuItem>
						)}
					</TextField>
					<Autocomplete
						className="dedic-search-field"
						id="combo-box-demo"
						key="search-text"
						onOpen={(event, reasom) => {
							// event.stopPropagation()
							if (event.type === 'mousedown') setOpen(false);
							else setOpen(true);
						}}
						noOptionsText="Nessuna pagina trovata per la regione scelta"
						open={open}
						name={`${props.name}-search`}
						value={selected}
						defaultValue={selected}
						onClick={event => event.preventDefault()}
						options={searchdata}
						autoSelect={false}
						disabled={selected}
						onClose={() => {
							setOpen(false);
						}}
						onChange={(event, value, reason, details) => {
							setSelected(value);
							setOpen(false);
							props.setData(value);
						}}
						getOptionLabel={option => {
							if (option.name) return `${option.name || ''} ${option.surname || ''}`;
							return '';
						}}
						renderOption={option => (
							<>
								<img
									style={{ width: 50 }}
									src={
										option.photoLapide ||
										'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
									}
								/>
								&nbsp;
								<label>{option.name}</label>
								&nbsp;
								<label>{option.surname}</label>
							</>
						)}
						style={{ width: 300 }}
						renderInput={params => <TextField {...params} label="Cerca il tuo caro" variant="outlined" />}
					/>
				</div>
			</div>
		</div>
	);
}
