import React, { useLayoutEffect, useState } from "react";
import {
	Button, CircularProgress, InputAdornment, TextField, Typography,
} from "@material-ui/core";
import { VisibilityOff } from "@material-ui/icons";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link, useHistory } from "react-router-dom";
import { generatePassword } from "../../../Services/RandomUtils";
import HttpServices, { baseURL } from "../../../Services/HttpServices";
import requests from "../../../Services/HttpServices";

function PostRegistration(props) {
	const [password, setPassword] = useState(generatePassword(8));
	const [passwordErr, setPasswordErr] = useState(false);
	const [success, setSuccess] = useState(false);
	const [request, setRequest] = useState(false);
	const [username, setUsername] = useState("");
	const history = useHistory();
	const [loading, setLoading] = useState(true);
	const [visible, setVisible] = useState(false);
	const urlSlice = window.location.href.split("?");
	const [extra, setExtra] = useState("");
	let token;
	let flag;
	let condolenceMessage = "Le condoglianze sono state aggiunte con successo";
	const toastStyleDestop = {
		padding    : 12,
		border     : "2px solid",
		lineHeight : window.innerWidth < 600 && "22px",
		textAlign  : "center",
		margin     : 12,
		marginTop  : -20,
		color      : "black",
	};
	const toastStyleMobile = {
		padding    : 5,
		border     : "2px solid",
		lineHeight : " 22px",
		textAlign  : "center",
		margin     : " -25px -20px 12px",
		color      : "black",
		fontSize   : " 14px",
	};
	const toastStyle = window.innerWidth < 600 ? toastStyleMobile : toastStyleDestop;
	let searchParams;
	if (urlSlice.length > 1) {
		const [, param] = urlSlice;
		searchParams = new URLSearchParams(param);
		token = searchParams?.get("token");
		flag = searchParams?.get("condolenceFlag");
	}
	useLayoutEffect(() => {
		if (searchParams?.get("condolenceFlag")) {
			const bodyData = {
				// deceasedID: params.get("id"),
				smsCode : searchParams?.get("token"),
			};
			HttpServices("post", "bookCode", bodyData)
				.then((result) => {
					setSuccess(result.response === "success");
					if (result.response !== "success") condolenceMessage = "Errore imprevisto, riprovare piu tardi";
					setRequest(true);
				});
		} else {
			setRequest(true);
		}
	}, []);
	useLayoutEffect(() => {
		console.log("qui");
		if (token) {
			HttpServices("get", "getUsername", token)
				.then((data) => {
					setLoading(false);
					console.log("reques");
					if (data?.data?.response === "success") {
						console.log("succ");
						setExtra("Scegli una password per completare la registrazione");
						setUsername(data.data.data.username);
					}
				});
		}
	}, [token]);
	console.log(success);
	if (!success && condolenceMessage !== "Errore imprevisto, riprovare piu tardi") condolenceMessage = "";
	if (!request) {
		return (
			<div>
				<div
					className="login-page"
					style={{
						height        : window.innerHeight - 200,
						display       : "flex",
						overflowY     : "auto",
						flexDirection : "column",
						placeItems    : "center",
						padding       : 32,
					}}
				>
					<CircularProgress
						size={64}
						style={{
							margin : "auto",
							color  : "black",
						}}
					/>
				</div>
			</div>
		);
	}
	const sendData = (object) => {
		if (password.length < 8) {
			setPasswordErr(true);
		} else {
			setVisible(false);
			setTimeout(() => {
				setLoading(true);
				HttpServices("post", "register", object)
					.then((data) => {
						if (data.response === "success") {
							HttpServices("post", "login", {
								email        : username,
								password,
								_submitLogin : 1,
							})
								.then((response) => {
									if (response.result === "success") onSuccessLogin(response);
									setLoading(false);
								});
						} else {
							setLoading(false);
						}
					});
			}, 500);
		}
	};

	function onSuccessLogin(data) {
		const address = {};
		console.log(data?.session, data);
		sessionStorage.setItem("user", JSON.stringify(data.session));
		sessionStorage.setItem("User", JSON.stringify(data.session));
		sessionStorage.setItem("ExpiringMemorial", JSON.stringify(data.expiring_memorial || false));
		let userId = null;
		try {
			userId = data.session.userdata.user_id;
		} catch (e) {
			userId = null;
		}
		let time;
		try {
			time = data.session.userdata.notificationPreference;
		} catch (e) {
			time = null;
		}
		if (userId) {
			requests("get", "contactData", userId)
				.then(result => {
					const { data } = result;
					if (data.response === "success") {
						sessionStorage.setItem(
							"userContactPreference",
							JSON.stringify({
								zone : data.data,
								time
							})
						);
					}
					history.push("/home?name=&region=&province=&location=&cap=&page=0#newLogin");

				});
		}
		if (data.session.userdata.addresses?.length > 0) {
			address.address = data.session.userdata.addresses[data.session.userdata.addresses.length - 1].address;
			address.number = data.session.userdata.addresses[data.session.userdata.addresses.length - 1].number;
			address.region = data.session.userdata.addresses[data.session.userdata.addresses.length - 1].region;
			address.city = data.session.userdata.addresses[data.session.userdata.addresses.length - 1].location;
			address.province = data.session.userdata.addresses[data.session.userdata.addresses.length - 1].province;
			address.cap = data.session.userdata.addresses[data.session.userdata.addresses.length - 1].cap;
			address.nationality = data.session.userdata.addresses[data.session.userdata.addresses.length - 1].nationID;
			address.complete_address =
				data.session.userdata.addresses[data.session.userdata.addresses.length - 1].completeAddress;
		}
		sessionStorage.setItem("UserAddress", JSON.stringify(address));
		window.scrollTo(0, 0);
	}

	if (!token || !username) {
		return (
			<div>
				<div
					className="login-page"
					style={{
						height        : window.innerHeight + 200,
						display       : "flex",
						overflowY     : "auto",
						flexDirection : "column",
						placeItems    : "center",
						padding       : 32,
					}}
				>
					{flag && (
						<div>
							<Typography
								style={toastStyle}
							>
								{(condolenceMessage !== "" && extra !== "") ? `${condolenceMessage}, ${extra}` : `${condolenceMessage} ${extra}`}
							</Typography>

						</div>
					)}
					<div
						style={{
							width         : window.innerWidth < 440 ? 320 : 400,
							display       : "flex",
							flexDirection : "column",
							padding       : 25,
							paddingTop    : 12,
							borderRadius  : 9,
							height        : "max-content",
							boxShadow     : "0 0 5px 1px rgba(0,0,0,.4)",
							background    : "white",
							position      : "relative",
						}}
					>
						{loading && (
							<div
								style={{
									position       : "absolute",
									zIndex         : 2000000,
									borderRadius   : 9,
									background     : "white",
									left           : 0,
									right          : 0,
									bottom         : 0,
									top            : 0,
									display        : "flex",
									justifyContent : "center",
									alignItems     : "center",
								}}
							>
								<CircularProgress
									size={64}
									style={{
										margin : "auto",
										color  : "black",
									}}
								/>
							</div>
						)}
						<Typography
							variant="h5"
							style={{
								textAlign : "center",
								color     : "black",
							}}
						>
							Errore
						</Typography>
						<Typography variant="h6" style={{ textAlign : "center" }}>
							Attenzione, il token che ha inserito non è valido o è scaduto, riprovare...
						</Typography>
						<p>Per un eventuale supporto tecnico è possibile contattare</p>
						<p>
							{" "}
							il numero telefonico
							{" "}
							<strong>389 89 96 166</strong>
						</p>
						<p style={{
							textAlign : "center",
							marginTop : 6,
						}}
						>
							<b>
								<p>DAL LUNEDÌ AL VENERDÌ</p>
								<p> DALLE ORE 09:00 ALLE 13:00 E</p>
								<p>DALLE 15:00 ALLE 18:00</p>
							</b>
						</p>
					</div>
				</div>
			</div>
		);
	}
	return (
		<>
			<div
				className="login-page"
				style={{
					height        : window.innerHeight,
					overflowY     : "auto",
					flexDirection : "column",
					display       : "flex",
					placeItems    : "center",
					padding       : 32,
				}}
			>
				{flag && (
					<div>
						<Typography style={toastStyle}>
							{(condolenceMessage !== "" && extra !== "") ? `${condolenceMessage}, ${extra}` : `${condolenceMessage} ${extra}`}
						</Typography>
					</div>
				)}
				<form
					onSubmit={(event) => {
						event.preventDefault();
						const formData = new FormData(event.target);
						const object = {};
						formData.forEach((value, key) => {
							object[key] = value;
						});
						sendData({
							...object,
							username,
							newPassword:password,
						});
					}}
					style={{
						width         : window.innerWidth < 440 ? 305 : 400,
						display       : "flex",
						flexDirection : "column",
						padding       : window.innerWidth > 600 ? 25 : 16,
						paddingTop    : window.innerWidth > 600 ? 12 : 8,
						borderRadius  : 9,
						height        : "max-content",
						boxShadow     : "0 0 5px 1px rgba(0,0,0,.4)",
						background    : "white",
						position      : "relative",
					}}
				>
					{loading && (
						<div
							style={{
								position       : "absolute",
								zIndex         : 2000000,
								borderRadius   : 9,
								background     : "white",
								left           : 0,
								right          : 0,
								bottom         : 0,
								top            : 0,
								display        : "flex",
								justifyContent : "center",
								alignItems     : "center",
							}}
						>
							<CircularProgress size={64} color="primary" style={{ margin : "auto" }}/>
						</div>
					)}
					<Typography
						variant="h5"
						style={{
							textAlign    : "center",
							marginBottom : 16,
							color        : "black",
						}}
					>
						Completa registrazione
					</Typography>
					<TextField
						label="Username"
						value={username}
						name={"username"}
						disabled
						autoComplete={"username"}
						inputProps={{autoComplete:'username'}}
						variant="outlined"
						style={{ width : "100%" }}
					/>
					<TextField label="token" value={token} name="registrationToken" style={{ display : "none" }}/>
					<TextField label="token" value={username} name="username" style={{ display : "none" }}/>
					<div style={{
						display        : "flex",
						justifyContent : "flex-end",
						marginTop      : 12,
					}}
					>
						<TextField
							value={password}
							type={visible ? "text" : "password"}
							autocomplete="password"
							name="password"
							error={passwordErr && !(password.length >= 8)}
							helperText={
								passwordErr
								&& !(password.length >= 8)
								&& "La password deve contenere almeno 8 caratteri"
							}
							label="Password"
							onChange={(event) => setPassword(event.target.value)}
							InputProps={{
								autoComplete:"password",
								endAdornment : (
									<InputAdornment position="end" style={{ marginRight : -8 }}>
										{visible ? (
											<VisibilityIcon
												style={{
													cursor : "pointer",
													color  : "black",
												}}
												onClick={() => setVisible(false)}
											/>
										) : (
											<VisibilityOff
												style={{
													cursor : "pointer",
													color  : "black",
												}}
												onClick={() => setVisible(true)}
											/>
										)}
									</InputAdornment>
								),
							}}
							variant="outlined"
							style={{ width : "100%" }}
						/>
					</div>
					<div
						style={{
							width         : "100%",
							display       : "flex",
							flexDirection : "row-reverse",
						}}
					>
						<Button
							variant="contained"
							type="submit"
							style={{
								width     : 97,
								marginTop : 6,
							}}
							color="primary"
						>
							Procedi
						</Button>
						&nbsp;
						<Button
							variant="contained"
							onClick={() => history.push("/")}
							style={{
								marginRight : 6,
								marginTop   : 6,
							}}
							color="primary"
						>
							Non ora
						</Button>
					</div>
				</form>
			</div>
			<div className="bottom bottom-fix-margin" style={{ position : "static" }}>
				<div className="contact">
					<div className="termini">
						<label
							style={{
								display       : "flex",
								flexDirection : window.innerWidth > 600 ? "row" : "column",
							}}
						>
							© 2017-
							{new Date().getFullYear()}
							{" "}
							I.M.E. &nbsp;
							<p className="email " style={{ color : "white" }}>
								<Link to="/terms" style={{ color : "white" }}>
									Termini e condizioni d'uso
								</Link>
							</p>
							{window.innerWidth > 600 && <p>/</p>}
							<p className="email" style={{ color : "white" }}>
								<Link to="/buy-terms" style={{ color : "white" }}>
									Condizioni di vendita
								</Link>
							</p>
						</label>
					</div>
				</div>
			</div>
		</>
	);
}

export default PostRegistration;
