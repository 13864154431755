import React, { useState } from "react";
import "./giveProperty.scss";
import TextField from "@material-ui/core/TextField";
import { InfoRounded, SearchRounded } from "@material-ui/icons";
import { Spinner } from "react-activity";
import validate from "../../../formValidationService";
import requests from "../../../Services/HttpServices";
import AreYouSure from "../../FormComponents/AreYouSureModal";
import ChangeAreYouSure from "../../Auth/ContactVerify/changePropertyAreUSure";
import InfoModal from "../../FormComponents/InfoModal";

export default function GiveProperty(props) {
  const [notFound, setNotFound] = useState(null);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState(null);
  const [send] = useState(null);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(null);
  const [receivedData, setReceivedData] = useState(null);
  const [pendingError, setPendingError] = useState(null);
  const [bodydata, setBodydata] = useState({});
  const [showInfo, setShowInfo] = useState(false);

  const [success, setSuccess] = useState(null);
  if (send && loading === null) {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  function sendDataChange(bodyData) {
    requests("post", "changeProperty", bodyData).then((data) => {
      if (data.response === "success") {
        setSuccess(true);
      } else {
        if (data.errorCode === "PENDING_REQUEST") {
          setPendingError(true);
        }
        data.errorCode === "UPDATE_FAILED" && alert("errore imprevisto");
        data.errorCode === "OWNER_NOT_FOUND" && alert("utente non trovato");
        data.errorCode === "DECEASED_NOT_FOUND" && alert("defunto non trovato");
      }
    });
  }

  return (
    <div className="bg-modal">
      {pendingError && (
        <ChangeAreYouSure
          handler={(value, value1) => {}}
          data={bodydata}
          closeHandler={() => setPendingError(false)}
        />
      )}
      {showInfo && (
        <InfoModal
          label="Questa funzione permette di cedere la proprietà della lapide digitale ad un altro utente"
          handleClose={() => setShowInfo(false)}
        />
      )}
      {!success ? (
        <div className="main-container give-property-media">
          <label
            className="buy-form-paragraph mt-0"
            style={{
              marginTop: 0,
              marginBottom: 20,
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Cedi proprietà
            {window.innerWidth < 900 && (
              <InfoRounded color="primary" onClick={() => setShowInfo(true)} />
            )}
          </label>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (!error) {
                setLoading(true);
                requests("get", "searchUser", search).then((data) => {
                  setLoading(false);
                  if (
                    data.data.result == false ||
                    data.data.result == "fail" ||
                    data.data.response == "fail"
                  ) {
                    setReceivedData([]);
                  } else if (data.data.data.users.length > 0) {
                    const temp = [];
                    data.data.data.users.forEach((e, i) => {
                      const elem = {
                        name: e.name,
                        surname: e.surname,
                        username: e.username,
                        ID: e.ID,
                      };
                      temp.push(elem);
                    });
                    setReceivedData(temp);
                    setNotFound(null);
                  } else {
                    setReceivedData([]);
                    setNotFound("Utente non trovato");
                  }
                });
              }
            }}
          >
            <div id="search">
              <TextField
                variant="outlined"
                label="Ricerca un utente"
                id="field"
                name="user-search"
                value={search}
                error={error}
                helperText={error && "Campo non valido"}
                onChange={(event) => {
                  const formError = {};
                  const temp = validate(
                    event.target.value,
                    "username",
                    formError
                  );
                  setError(temp.username);
                  setSearch(event.target.value);
                }}
              />

              <SearchRounded
                style={{ cursor: "pointer" }}
                onClick={() => {
                  document.getElementById("search-button").click();
                }}
              />
            </div>
            <div style={{ marginTop: 20 }}>{loading && <Spinner />}</div>
            {receivedData && receivedData.length > 0 && (
              <div className="search-header">
                <div>Nome Completo</div>
                <p>Nome utente</p>
              </div>
            )}
            <div
              style={{
                maxHeight: (window.innerHeight * 6) / 10,
                overflowY: "scroll",
              }}
              id="search-user"
            >
              {!loading &&
                receivedData &&
                receivedData.map((e, i) => (
                  <label
                    className={selected === e && "userSelected"}
                    onClick={() => {
                      setSelected(e);
                    }}
                  >
                    <div>{`${e.name} ${e.surname}`}</div>
                    <p>{e.username}</p>
                  </label>
                ))}
            </div>
            <button style={{ display: "none" }} id="search-button" />
          </form>
          {selected && (
            <label style={{ color: "red", marginTop: 16 }}>
              Attenzione!! l'operazione e` irreversibile, sicuro di voler
              continuare?
            </label>
          )}
          {notFound && (
            <label style={{ color: "red", marginTop: 16 }}>{notFound}</label>
          )}
          <div id="button-field">
            <button
              onClick={() => {
                props.close();
              }}
            >
              NO
            </button>
            <button
              style={{
                backgroundColor: !selected && "lightgrey",
                boxShadow: !selected && "none",
              }}
              disabled={!selected}
              onClick={() => {
                const bodyData = {};
                bodyData["deceased-id"] =
                  (props.selectedQr && JSON.stringify(props.selectedQr)) ||
                  props.deceasedID;
                bodyData["new-owner-id"] = selected.ID;
                bodyData["change-type"] = props.type;
                setBodydata(bodyData);
                sendDataChange(bodyData);
              }}
            >
              SI
            </button>
          </div>
        </div>
      ) : (
        <div className="main-container">
          <label>
            La richiesta di trasferimento è stata inviata con successo
          </label>
          <div id="button-field">
            <button
              onClick={() => {
                window.location.reload();
              }}
            >
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
