import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import FormLabel from "./TermFormComponents";

function AcceptBuyTerms(props) {
  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <FormControlLabel
        className="activate-checkbox"
        control={
          <Checkbox
            color="primary"
            name="checkedC"
            id="term-box-buy"
            onChange={(event, checked) => {
              props.setChecked(checked);
            }}
          />
        }
        label={
          <label htmlFor="term-box-buy" className="activate-checkbox font-bold">
            'DICHIARO di aver preso visione del documento{" "}
            <Link target="blank" className="terms-link" to="./buy-terms">
              “CONDIZIONI DI VENDITA”
            </Link>
            '
          </label>
        }
      />

      {!props.checkBox && props.termsError && (
        <label
          style={{
            color: "red",
            textAlign: "left",
            margin: 10,
            marginLeft: 30,
            alignSelf: "flex-start",
          }}
        >
          Devi accettare i termini
        </label>
      )}
    </div>
  );
}
AcceptBuyTerms.propTypes = {
  checkBox: PropTypes.bool.isRequired,
  termsError: PropTypes.bool.isRequired,
  setChecked: PropTypes.func.isRequired,
};
export default AcceptBuyTerms;
