import React from "react";
import PropTypes from "prop-types";

function InfoModal(props) {
  let close = false;
  return (
    <div
      className="bg-modal"
      onClick={() => {
        close ? props.handleClose() : (close = true);
      }}
    >
      <div
        id="info-container"
        style={{ display: "flex", flexDirection: "column" }}
        onClick={() => (close = false)}
      >
        <div
          className="buy-form-paragraph"
          style={{ textAlign: "center", marginTop: 0, marginBottom: 12 }}
        >
          Informazioni
        </div>
        <label style={{ textAlign: "center" }}>{props.label}</label>
        <button
          style={{ alignSelf: "flex-end" }}
          onClick={() => props.handleClose()}
        >
          Chiudi
        </button>
      </div>
    </div>
  );
}

InfoModal.propTypes = {
  label: PropTypes.string,
  handleClose: PropTypes.func,
};
export default InfoModal;
