import React, { Component, Fragment } from "react";
import { Redirect } from "react-router";
import withContext from "../../../WithContextFile";
import TextField from "@material-ui/core/TextField";
import VisibilityIcon from "@material-ui/icons/Visibility";
import "./ResetPasswordForm.scss";
import { VisibilityOff } from "@material-ui/icons";
import requests from "../../../Services/HttpServices";
import FeedbackModal from "../../FormComponents/FeedbackModal";

class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resetPassword: "",
      password: "",
      oldPassword: "",
      autocompleteFix: true,
    };
  }

  componentDidMount() {}

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, error: "" });
  };

  resetPassword = () => {
    const { resetPassword, password, oldPassword } = this.state;
    if (!resetPassword || !password || !oldPassword) {
      return this.setState({ error: "Riempi tutti i campi" });
    }
    if (resetPassword !== password)
      return this.setState({ error: "Le password non corrispondono" });

    this.setState({ password: "", resetPassword: "" });
    const bodyData = {};
    bodyData["old-password"] = this.state.oldPassword;
    bodyData["new-password"] = this.state.password;
    bodyData["confirm-password"] = this.state.resetPassword;
    requests("post", "changePassword", bodyData)
      .then((data) => {
        if (data.response === "success") {
          this.setState({
            feedbackModal: true,
            modalResult: "Successo",
            modalText: "Password cambiata correttamente",
          });
        } else if (data.message) {
          this.setState({
            feedbackModal: true,
            modalResult: "Errore",
            modalText: data.message,
          });
        } else
          this.setState({
            feedbackModal: true,
            modalResult: "Errore",
            modalText: "Errore imprevisto riprovare piu` tardi",
          });
      })
      .finally(() => {
        this.setState({ oldPassword: "", password: "", resetPassword: "" });
      });
  };

  render() {
    return (
      <>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            document.getElementById("submit").click();
          }}
          className="reset-password-form"
          style={{ width: 400, boxShadow: "black" }}
        >
          {this.state.feedbackModal && (
            <FeedbackModal
              result={this.state.modalResult}
              close={() => {
                this.setState({ feedbackModal: false });
                // window.location.reload()
                // this.setState({name: '',number:'',selectedValue:''})
              }}
              label={this.state.modalText}
            />
          )}
          <label className="death-data">Reset password</label>
          <p>Scegli una nuova password per il tuo account</p>
          <TextField
            onChange={this.handleChange}
            name="oldPassword"
            value={this.state.oldPassword}
            className="login-form "
            InputProps={{
              endAdornment: this.state.visible ? (
                <VisibilityIcon
                  style={{ cursor: "pointer", color: "black" }}
                  onClick={() => this.setState({ visible: false })}
                />
              ) : (
                <VisibilityOff
                  style={{ cursor: "pointer", color: "black" }}
                  onClick={() => this.setState({ visible: true })}
                />
              ),
            }}
            type={this.state.visible ? "Text" : "Password"}
            label="Vecchia password"
            variant="outlined"
          />
          <TextField
            onChange={this.handleChange}
            name="password"
            aria-autocomplete="none"
            value={this.state.password}
            className="login-form "
            autoComplete="none"
            InputProps={{
              endAdornment: this.state.visible ? (
                <VisibilityIcon
                  style={{ cursor: "pointer", color: "black" }}
                  onClick={() => this.setState({ visible: false })}
                />
              ) : (
                <VisibilityOff
                  style={{ cursor: "pointer", color: "black" }}
                  onClick={() => this.setState({ visible: true })}
                />
              ),
            }}
            type={this.state.visible ? "Text" : "Password"}
            label="Nuova password"
            variant="outlined"
          />
          <TextField
            onChange={this.handleChange}
            name="resetPassword"
            value={this.state.resetPassword}
            className="login-form "
            InputProps={{
              endAdornment: this.state.visible ? (
                <VisibilityIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => this.setState({ visible: false })}
                />
              ) : (
                <VisibilityOff
                  style={{ cursor: "pointer" }}
                  onClick={() => this.setState({ visible: true })}
                />
              ),
            }}
            type={this.state.visible ? "Text" : "Password"}
            label="Conferma nuova password"
            variant="outlined"
          />
          {this.state.error && (
            <div className="has-text-danger" style={{ marginBottom: 13 }}>
              {this.state.error}
            </div>
          )}
          <div className="field is-clearfix">
            <button
              style={{ color: "black" }}
              className="button is-primary is-outlined is-pulled-right"
              // onClick={this.resetPassword}
            >
              Salva
            </button>
          </div>
        </form>
        <button
          id="submit"
          style={{ display: "none" }}
          className="button is-primary is-outlined is-pulled-right"
          onClick={this.resetPassword}
        >
          Salva
        </button>
      </>
    );
  }
}

export default withContext(ResetPasswordForm);
