import React, {
	useState, useCallback, useLayoutEffect, useRef,
} from "react";
import {
	Button, CircularProgress, InputAdornment, TextField, Typography,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { VisibilityOff } from "@material-ui/icons";
import { generatePassword } from "./RandomUtils";

export const GoogleRecaptchaExample = () => {
	const [password, setPassword]       = useState(generatePassword(8));
	const [passwordErr, setPasswordErr] = useState(false);
	const [success, setSuccess]         = useState(false);
	const [request, setRequest]         = useState(false);
	const [username, setUsername]       = useState("");
	const history                       = useHistory();
	const [loading, setLoading]         = useState(false);
	const [visible, setVisible]         = useState(false);
	const urlSlice                      = window.location.href.split("?");
	const [extra, setExtra]             = useState("");
	const inputElement                  = useRef();
	let token;
	let flag;
	let condolenceMessage               = "Le condoglianze sono state aggiunte con successo";
	const toastStyleDestop              = {
		padding    : 12,
		border     : "2px solid",
		lineHeight : window.innerWidth < 600 && "22px",
		textAlign  : "center",
		margin     : 12,
		marginTop  : -20,
		color      : "black",
	};
	const toastStyleMobile              = {
		padding    : 5,
		border     : "2px solid",
		lineHeight : " 22px",
		textAlign  : "center",
		margin     : " -25px -20px 12px",
		color      : "black",
		fontSize   : " 14px",
	};
	const toastStyle                    = window.innerWidth < 600 ? toastStyleMobile : toastStyleDestop;
	useLayoutEffect(() => {
		setUsername("asdassddsadada");
		setTimeout(() => {
			setNativeValue("");
		}, 100);
	}, []);
	console.log(success);
	if (!success && condolenceMessage !== "Errore imprevisto, riprovare piu tardi") condolenceMessage = "";
	const sendData = (object) => {
		history.push("/login");
	};
	function setNativeValue(v) {
		const input = document.getElementById("pwd");

		const lastValue = input.value;

		input.value += "v";

		const event = new Event("input", { bubbles: true });

		const tracker = input._valueTracker;

		if (tracker) {
			tracker.setValue(lastValue);
		}

		input.dispatchEvent(event);
	}

	return (
		<>
			<div
				className="login-page"
				style={{
					height        : window.innerHeight,
					overflowY     : "auto",
					flexDirection : "column",
					display       : "flex",
					placeItems    : "center",
					padding       : 32,
				}}
			>
				{flag && (
					<div>
						<Typography style={toastStyle}>
							{(condolenceMessage !== "" && extra !== "") ? `${condolenceMessage}, ${extra}` : `${condolenceMessage} ${extra}`}
						</Typography>
					</div>
				)}
				<form
					onSubmit={(event) => {
						event.preventDefault();
						const formData = new FormData(event.target);
						const object   = {};
						formData.forEach((value, key) => {
							object[key] = value;
						});
						sendData({
							...object,
							username,
						});
					}}
					style={{
						width         : window.innerWidth < 440 ? 305 : 400,
						display       : "flex",
						flexDirection : "column",
						padding       : window.innerWidth > 600 ? 25 : 16,
						paddingTop    : window.innerWidth > 600 ? 12 : 8,
						borderRadius  : 9,
						height        : "max-content",
						boxShadow     : "0 0 5px 1px rgba(0,0,0,.4)",
						background    : "white",
						position      : "relative",
					}}
				>
					{loading && (
						<div
							style={{
								position       : "absolute",
								zIndex         : 2000000,
								borderRadius   : 9,
								background     : "white",
								left           : 0,
								right          : 0,
								bottom         : 0,
								top            : 0,
								display        : "flex",
								justifyContent : "center",
								alignItems     : "center",
							}}
						>
							<CircularProgress size={64} color="primary" style={{ margin: "auto" }} />
						</div>
					)}
					<Typography
						variant="h5"
						style={{
							textAlign    : "center",
							marginBottom : 16,
							color        : "black",
						}}
					>
						Completa registrazione
					</Typography>
					<TextField
						label="Username"
						value={username}
						name="username"
						disabled
						autoComplete="username"
						inputProps={{ autoComplete: "username" }}
						variant="outlined"
						style={{ width: "100%" }}
					/>
					<TextField label="token" value={token} name="registrationToken" style={{ display: "none" }} />
					<TextField label="token" value={username} name="username" style={{ display: "none" }} />
					<div style={{
						display        : "flex",
						justifyContent : "flex-end",
						marginTop      : 12,
					}}
					>
						<TextField
							value={password}
							ref={inputElement}
							id="pwd"
							type={visible ? "text" : "password"}
							autocomplete="password"
							name="password"
							error={passwordErr && !(password.length >= 8)}
							helperText={
								passwordErr
								&& !(password.length >= 8)
								&& "La password deve contenere almeno 8 caratteri"
							}
							label="Password"
							onChange={(event) => {
								setPassword(event.target.value);
							}}
							InputProps={{
								autoComplete : "password",
								endAdornment : (
									<InputAdornment position="end" style={{ marginRight: -8 }}>
										{visible ? (
											<VisibilityIcon
												style={{
													cursor : "pointer",
													color  : "black",
												}}
												onClick={() => setVisible(false)}
											/>
										) : (
											<VisibilityOff
												style={{
													cursor : "pointer",
													color  : "black",
												}}
												onClick={() => setVisible(true)}
											/>
										)}
									</InputAdornment>
								),
							}}
							variant="outlined"
							style={{ width: "100%" }}
						/>
					</div>
					<div
						style={{
							width         : "100%",
							display       : "flex",
							flexDirection : "row-reverse",
						}}
					>
						<Button
							variant="contained"
							type="submit"
							style={{
								width     : 97,
								marginTop : 6,
							}}
							color="primary"
						>
							Procedi
						</Button>
						&nbsp;
						<Button
							variant="contained"
							onClick={() => history.push("/")}
							style={{
								marginRight : 6,
								marginTop   : 6,
							}}
							color="primary"
						>
							Non ora
						</Button>
					</div>
				</form>
			</div>
			<div className="bottom bottom-fix-margin" style={{ position: "static" }}>
				<div className="contact">
					<div className="termini">
						<label
							style={{
								display       : "flex",
								flexDirection : window.innerWidth > 600 ? "row" : "column",
							}}
						>
							© 2017-
							{new Date().getFullYear()}
							{" "}
							I.M.E. &nbsp;
							<p className="email " style={{ color: "white" }}>
								<Link to="/terms" style={{ color: "white" }}>
									Termini e condizioni d'uso
								</Link>
							</p>
							{window.innerWidth > 600 && <p>/</p>}
							<p className="email" style={{ color: "white" }}>
								<Link to="/buy-terms" style={{ color: "white" }}>
									Condizioni di vendita
								</Link>
							</p>
						</label>
					</div>
				</div>
			</div>
		</>
	);
};
