import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import requests from "../../Services/HttpServices";

function Nationality(props) {
  const [nations, setNations] = useState([]);
  const [selectedNations, setSelectedNations] = useState("105");
  useEffect(() => {
    if (!props.disable) {
      requests("get", "nationality").then((data) => {
        if (data.data && data.data.response === "success") {
          setNations(data.data.data.countries);
        }
      });
    }
  }, []);
  useEffect(() => {
    setSelectedNations(props.startData);
  }, [props.startData]);
  return (
    <div style={{ textAlign: "start", marginTop: props.modify && -40 }}>
      <TextField
        className="activate-field mt-20impo"
        fullWidth
        select
        name={`${props.name}-country`}
        value={selectedNations}
        onChange={(e) => {
          setSelectedNations(e.target.value);
          props.setData(e.target.value);
        }}
        label="Nazione"
      >
        {nations ? (
          nations.map((e) => (
            <MenuItem value={e.id}>
              {e.nicename === "Italy" ? "Italia" : e.nicename}
            </MenuItem>
          ))
        ) : (
          <MenuItem value="105">Italiana</MenuItem>
        )}
      </TextField>
    </div>
  );
}

Nationality.propTypes = {
  disable: PropTypes.bool,
  setData: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};
export default Nationality;
