/* eslint-disable array-callback-return,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/label-has-associated-control,react/button-has-type,react/no-access-state-in-setstate,jsx-a11y/no-noninteractive-element-interactions,no-shadow,react/jsx-no-target-blank,react/no-unused-state,no-unused-expressions */
import React, { Component } from "react";
import withContext from "../../WithContextFile";
import "./AdminPanel.scss";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import CropFreeIcon from "@material-ui/icons/CropFree";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Page from "@material-ui/icons/MenuBook";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
	AddCircleOutlined,
	AssignmentInd,
	CreateOutlined,
	DeleteOutlined,
	ExpandLess,
	ExpandMore,
	FilterFrames,
	VisibilityOutlined,
} from "@material-ui/icons";
import { Spinner } from "react-activity";
import { BigPlayButton, Player } from "video-react";
import Collapse from "@material-ui/core/Collapse";
import moment from "moment";
import ModifyDeceasedPage from "./ModifyDeceasedPage";
import AreYouSureGeneral from "../FormComponents/AreYouSureModalGeneral";
import AreYouSureModalGeneral from "../FormComponents/AreYouSureModalGeneral";
import SuccessToast from "./helperComponent/SuccessToast";
import DoYouWanToDelete from "./DoYouWanToDelete";
import ModifyObituaryData from "./ModifyObituaryData";
import dedics2 from "../../assets/Stab/Dedic request";
import Partner from "../Partner/Partner";
import PartnerPagePanel from "../Partner-page/Partner-page-panel";
import azienda from "../../assets/Stab/Azienda";
import requests, { baseURL } from "../../Services/HttpServices";
import GiveProperty from "./PartnerPanelPages/givePropertyModal";
import AddDeceasedPoster from "./PartnerPanelPages/AddDeceasedPoster";
import ResetPasswordForm from "../Auth/Password-reset/ResetPasswordForm";
import ContactPreferenceForm from "../FormComponents/ContactPreferencesForm";
import ModifyAccount from "../Auth/ModifyAccount/ModifyAccount";
import PartnerPanelTableWrapper, {
	createDeceasedDataPartnerTable,
	createDedicDataPartnerTable,
	createObituaryDataPartnerTable,
	createQRDataPartnerTable,
} from "./PartnerPanelPages/PartnerPanelTableWrapper";
import FeedbackModal from "../FormComponents/FeedbackModal";
import AddDeceasedPosterAndPage from "./PartnerPanelPages/AddDeceasedPosterAndPage";
import ModifyDeceasedPosterAndPage from "./PartnerPanelPages/ModifyDeceasedPosterAndPage";
import { mergeObjs } from "../../Services/RandomUtils";

let notModal = false;

let newRows     = [];
let newOBRows   = [];
const subtitles = [
	" necrologi",
	"Gestione dediche",
	"Gestione pagina partner",
	"Aggiungi necrologio",
	"Gestione account",
	"Gestione qrCode",
];
let deceased    = [];
let obituary    = [];
let user;
let dataModal;
let dedics      = dedics2;
const pathNames = [
	"pages",
	"dedic",
	"partner-page",
	"aggiungi-necrologio",
	"account",
	"qrcode",
	"deceased",
	"aggiungi-defunto",
];
let pageError   = true;
let qrCodes     = 0;
let radioValue;

class AdminPanel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			type         : 0,
			modal        : false,
			order        : "asc",
			orderBy      : "calories",
			showDeceased : false,
			radioValue   : "",
			aziendaData  : null,
			gettingData  : false,
		};
		let radioContact;
		try {
			radioContact = this.state.loggedUser?.preferred_contact?.type;
		} catch (e) {
			console.log(e);
			radioContact = "";
		}

		this.setState({ radioValue: radioContact });
	}

	componentDidMount() {
		try {
			if (JSON.parse(sessionStorage.getItem("User"))) {
				user = JSON.parse(sessionStorage.getItem("User"))?.userdata;
				this.dedicDataTable(true);
				this.qrCodeDataTable(true);
				user
				&& requests("get", "getPartnerPage", user.partnerid)
					.then((result) => {
						if (result.data.response === "success") {
							this.setState({ aziendaData: result.data.data });
						}
					});
				this.setState({ gettingData: true });
				requests("get", "getDeceasedByID", user.user_id)
					.then((data) => {
						this.setState({ gettingData: false });
						if (`${data.data.response}` === "success") {
							deceased = [];
							obituary = [];
							data.data.deceased_list.map((e, i) => {
								data.data.deceased_list[i].region   = e.graveRegionName;
								data.data.deceased_list[i].province = e.graveProvinceName;
								data.data.deceased_list[i].location = e.graveLocationName;
								e.name !== "" && e.showPage == "1"
									? deceased.push(data.data.deceased_list[i])
									: obituary.push(data.data.deceased_list[i]);
							});
							this.getAddressLabel(true);
							// this.getAddressLabel()
						}
					});
			} else {
				console.log("errore");
			}
		} catch (e) {
			console.log("errore");
		}
	}

	getDeceasedByOwner() {
		requests("get", "getDeceasedByID", user.user_id)
			.then((data) => {
				this.setState({ gettingData: false });
				if (`${data.data.response}` === "success") {
					deceased = [];
					obituary = [];
					data.data.deceased_list.map((e, i) => {
						data.data.deceased_list[i].region   = e.graveRegionName;
						data.data.deceased_list[i].province = e.graveProvinceName;
						data.data.deceased_list[i].location = e.graveLocationName;
						e.name !== "" && e.showPage == "1"
							? deceased.push(data.data.deceased_list[i])
							: obituary.push(data.data.deceased_list[i]);
					});
					this.getAddressLabel(true);
					// this.getAddressLabel()
				}
			});
	}

	getAddressLabel(value) {
		window.location.hash === "#pages" && this.deceasedDataTable();
		window.location.hash === "#deceased" && this.deceasedDataTable();
		this.setState({ requesting: false });
	}

	showSuccessTost(sold) {
		this.setState({ successToastOpacity: 100 });
		setTimeout(() => {
			const interval = setInterval(() => {
				this.setState({
					successToastOpacity: this.state.successToastOpacity - 3,
				});
				if (this.state.successToastOpacity <= 0) {
					clearInterval(interval);
				}
			}, 25);
		}, 500);
	}

	render() {
		let user;
		try {
			 user = sessionStorage.getItem("User") && JSON.parse(sessionStorage.getItem("User")).userdata;
		} catch (e) {

		}
		if (!user) {
			window.location.href = "/home";
			// pageError = false
		} else if (user.permissions.platformAccess == 1) {
			window.location.href = "/admin-panel";
			pageError            = true;
		} else if (user.permissions.platformAccess == 0) {
			window.location.href = "/client-panel";
			pageError            = true;
		} else if (window.location.hash) {
			pageError       = false;
			const pathIndex = pathNames.indexOf(window.location.hash.slice(1, window.location.hash.length));
			if (pathIndex === -1) {
				radioValue = user.preferred_contact.type;
				this.setState({ radioValue });
				window.history.pushState("object or string", "Title", "/partner-panel#deceased");
				this.setState({ type: 0 });
				this.deceasedDataTable();
			} else if (this.state.type !== pathIndex) {
				radioValue = user.preferred_contact.type;
				this.setState({ radioValue });
				this.setState({ type: pathIndex });
				pathIndex === 0 && this.deceasedDataTable();
				pathIndex === 6 && this.deceasedDataTable();
				pathIndex === 1 && this.dedicDataTable();
				pathIndex === 5 && this.qrCodeDataTable();
				// pathIndex === 2 && this.partnerDataTable()
				// pathIndex === 1 && this.dedicDataTable()
			}
		} else {
			window.history.pushState("object or string", "Title", "/partner-panel#deceased");
			radioValue = user.preferred_contact.type;
			this.setState({ radioValue });
			this.deceasedDataTable();
			pageError = false;
		}
		if (pageError) return <></>;
		return (
			<>
				{" "}
				{this.state.choiceModal
				&& (
					<AreYouSureModalGeneral
						closeHandler={() => this.setState({ choiceModal: false })}
						text={this.state.choiceModalText}
						accept={() => {
							this.setState({ choiceModal: false });
							this.state.choiceModalFunc();
						}}
					/>
				)}
				{this.state.feedbackModal && (
					<FeedbackModal
						result={this.state.feedbackModalResult}
						close={() => this.setState({ feedbackModal: false })}
						label={this.state.feedbackModalLabel}
					/>
				)}
				{this.state.modalLoading && (
					<div className="modal-bg">
						<div
							className="flex flex-column"
							style={{
								alignItems     : "center",
								justifyContent : "center",
							}}
						>
							<CircularProgress color="primary" />
							{" "}
							<Typography className="text-center" style={{ color: "white" }}>
								{this.state.loadingLabel}
							</Typography>
						</div>
					</div>
				)}
				{this.state.cardModal && (
					<FeedbackModal
						result="Scarica Cartoncini QR"
						ID={this.state.cardID}
						deceasedName={this.state.cardDeceased}
						deceasedObituaryCard
						condolenceIndication
						cardGridModal={() => {
							if (this.state.cardGridModalFunc) this.state.cardGridModalFunc();
						}}
						cardModal={() => {
							if (this.state.cardModalFunc) this.state.cardModalFunc();
						}}
						close={() => this.setState({ cardModal: false })}
						label="Scegli la tipologia di cartoncino da scaricare"
					/>
				)}
				{this.state.modifyModal && (
					<ModifyObituaryData
						data={this.state.modifyData}
						closeHandler={() => {
							this.setState({ modifyModal: false });
						}}
					/>
				)}
				{this.state.modifyAll && (
					<ModifyDeceasedPosterAndPage
						data={this.state.modifyData}
						closeHandler={() => {
							this.setState({ modifyAll: false });
						}}
					/>
				)}
				{this.state.openModifyDeceased && (
					<ModifyDeceasedPage
						partner
						data={this.state.modifyData}
						closeHandler={() => {
							this.setState({ openModifyDeceased: false });
						}}
					/>
				)}
				{this.state.propertyModal && (
					<GiveProperty
						type="deceasedPage"
						deceasedID={this.state.selectedID}
						close={() => {
							this.setState({ propertyModal: false });
						}}
					/>
				)}
				<div className="bg-white dashboard">
					<div id="second-in">
						<SuccessToast
							label={this.state.toastLabel}
							successToastOpacity={this.state.successToastOpacity}
						/>
						{this.state.areUSure && (
							<AreYouSureModalGeneral
								text="Sei sicuro di voler impostare il qrcode come assegnato?"
								closeHandler={() => this.setState({ areUSure: false })}
								accept={() => {
									requests("post", "soldQr", this.state.sureData)
										.then((data) => {
											if (data.response === "success") {
												this.setState({
													toastLabel: " impostato come assegnato ",
												});
												window.location.reload();
												// this.showSuccessTost();
											} else {
												alert(data.message);
											}
										})
										.finally(() => {
											this.setState({ areUSure: false });
										});
								}}
							/>
						)}
						<div id="upper">
							<div id="card-row">
								<div
									id="card0"
									onClick={() => {
										document.getElementById("card-row")
											.scrollIntoView({ behavior: "smooth" });
										this.deceasedDataTable();
										window.history.pushState(
											"object or string",
											"Title",
											"/partner-panel#deceased",
										);
										this.setState({ showDeceased: true });
										this.setState({ type: 6 });
									}}
									className="ml-0 card-fix"
								>
									<div>
										<label id="title-card">Gestione Lapidi digitali</label>
										<label>{deceased.length || 0}</label>
									</div>
									<div id="icon">
										<AssignmentInd style={{ fontSize: 19 }} />
									</div>
								</div>
								<div
									id="card"
									onClick={() => {
										document.getElementById("card-row")
											.scrollIntoView({ behavior: "smooth" });
										this.deceasedDataTable();
										window.history.pushState("object or string", "Title", "/partner-panel#pages");
										this.setState({ showDeceased: false });

										this.setState({ type: 0 });
									}}
									className="card-fix"
								>
									<div>
										<label id="title-card">Gestione Necrologi</label>
										<label>{obituary.length || 0}</label>
									</div>
									<div id="icon">
										<FilterFrames style={{ fontSize: 19 }} />
									</div>
								</div>
								<div
									id="card"
									className="card-fix"
									onClick={() => {
										document.getElementById("card-row")
											.scrollIntoView({ behavior: "smooth" });
										this.qrCodeDataTable();
										window.history.pushState("object or string", "Title", "/partner-panel#qrcode");
										this.setState({ type: 5 });
									}}
								>
									<div>
										<label id="title-card">Gestione qrCode</label>
										<label>{qrCodes || 0}</label>
									</div>
									<div id="icon" style={{ backgroundColor: "orange" }}>
										<CropFreeIcon style={{ fontSize: 19 }} />
									</div>
								</div>
								<div
									id="card"
									className="card-fix"
									onClick={() => {
										document.getElementById("card-row")
											.scrollIntoView({ behavior: "smooth" });
										this.dedicDataTable();
										window.history.pushState("object or string", "Title", "/partner-panel#dedic");
										this.setState({ type: 1 });
									}}
								>
									<div>
										<label id="title-card">Gestione dediche</label>
										<label>{dedics.length || 0}</label>
									</div>
									<div id="icon" style={{ backgroundColor: "lightgreen" }}>
										<BorderColorIcon style={{ fontSize: 19 }} />
									</div>
								</div>
								<div
									id="card"
									className="card-fix"
									onClick={() => {
										document.getElementById("card-row")
											.scrollIntoView({ behavior: "smooth" });
										window.history.pushState(
											"object or string",
											"Title",
											"/partner-panel#partner-page",
										);
										this.setState({ type: 2 });
									}}
								>
									<div id="page-detail">
										<label style={{
											color    : "#2d2b2b",
											fontSize : 20,
										}}
										>
											Gestione pagina partner
										</label>
										<div id="icon" style={{ backgroundColor: "lightsalmon" }}>
											<Page style={{ fontSize: 19 }} />
										</div>
									</div>
								</div>
								<div
									id="card"
									className="card-fix"
									onClick={() => {
										document.getElementById("card-row")
											.scrollIntoView({ behavior: "smooth" });
										window.history.pushState("object or string", "Title", "/partner-panel#account");
										this.setState({ type: 4 });
									}}
								>
									<div id="page-detail" className="place-fix">
										<label style={{
											color    : "#2d2b2b",
											fontSize : 22,
										}}
										>
											Gestione account
										</label>

										<div id="icon" style={{ backgroundColor: "skyblue" }}>
											<AccountCircleIcon style={{ fontSize: 19 }} />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div id="content" style={{ position: "relative" }}>
							{this.state.type === 0 && (
								<button
									color="primary"
									style={{ minWidth: 250 }}
									className="search-button panel-button-resize partnerpanel-button toRight"
									onClick={() => {
										this.setState({
											choiceModal     : true,
											choiceModalText : (
												<p>
													Sei sicuro di voler aggiungere
													{" "}
													<b
														className="underline"
													>
														SOLO
													</b>
													{" "}
													il necrologio
												</p>),
											choiceModalFunc: () => {
												window.history.pushState(
													"object or string",
													"Title",
													"/partner-panel#aggiungi-necrologio",
												);
												this.setState({ type: 3 });
											},
										});
										// this.props.history.push('nuovo-necrologio')
									}}
								>
									<AddCircleOutlined className="extra-button-icon-style" />
									Aggiungi necrologio
								</button>
							)}
							{(this.state.type === 0 || this.state.type === 6) && (
								<button
									color="primary"
									className="search-button panel-button-resize partnerpanel-button left btn-fix"
									style={{ color: "black" }}
									onClick={() => {
										// this.props.history.push('nuovo-necrologio')
										window.history.pushState(
											"object or string",
											"Title",
											"/partner-panel#aggiungi-defunto",
										);
										this.setState({ type: 3 });
									}}
								>
									<AddCircleOutlined className="extra-button-icon-style" />
									Aggiungi necrologio + Lapide
								</button>
							)}
							{this.state.type === 5 && (
								<button
									color="primary"
									style={{ minWidth: "max-content" }}
									className="search-button panel-button-resize partnerpanel-button"
									onClick={() => {
										requests("get", "qrcodeRequest", 1)
											.then((data) => {
												if (data.data.response === "success") {
													window.history.pushState(
														{ deceasedIds: data.data.deceasedIDs[0] },
														"title",
														"/partner-panel#qrcode",
													);
													window.location.reload();
												}
											});
									}}
								>
									<AddCircleOutlined className="extra-button-icon-style" />
									Richiedi QrCode temporaneo (30gg)
								</button>
							)}
							{this.state.type === 6 && (
								<button
									color="primary"
									style={{ minWidth: 250 }}
									className="search-button panel-button-resize partnerpanel-button toRight"
									onClick={() => {
										this.setState({
											choiceModal     : true,
											choiceModalText : (
												<p>
													Sei sicuro di voler attivare
													{" "}
													<b
														className="underline"
													>
														SOLO
													</b>
													{" "}
													la lapide digitale
												</p>),
											choiceModalFunc: () => this.props.history.push("/activate-qrCode"),
										});
									}}
								>
									<AddCircleOutlined className="extra-button-icon-style" />
									Attiva lapide digitale
								</button>
							)}
							<div className="panel-title-div" style={{ marginBottom: this.state.type !== 0 && 33 }}>
								<label
									className={`admin-content-subtitle ${
										`${this.state.type}` === "0" || `${this.state.type}` === "6"
											? " partner-title-150"
											: `${this.state.type}` === "5" && " partner-title-80"
									}`}
									style={{
										marginBottom : !this.state.type === 0 && 33,
										margin       : this.state.type === 0 && "10px auto",
										display      : "flex",
									}}
								>
									{this.state.type === 0 && "Gestione "}
									{this.state.type === 6 && "Gestione Lapidi digitali"}
									{this.state.type === 0 && <p>&nbsp;</p>}
									<p
										onClick={() => {
											this.state.showDeceased && this.setState({ showDeceased: false });
										}}
									>
										{this.state.type !== 0
											? subtitles[this.state.type]
											: this.state.showDeceased
												? "Lapidi"
												: "Necrologi"}
									</p>
								</label>
							</div>
							{this.state.type === 4 && (
								<div
									id="collapsable-container"
									style={{
										display       : "flex",
										flexDirection : "column",
									}}
								>
									<div
										onClick={() => {
											this.setState({
												collapse  : !this.state.collapse,
												collapse1 : false,
												collapse2 : false,
											});
										}}
										className="collapsable-list"
									>
										<div
											style={{
												display       : "flex",
												flexDirection : "row",
												margin        : "auto",
											}}
										>
											<label style={{
												margin      : "auto",
												paddingLeft : 24,
											}}
											>
												{" "}
												Cambia password
											</label>
											<div>{this.state.collapse ? <ExpandLess /> : <ExpandMore />}</div>
										</div>
									</div>

									<Collapse in={this.state.collapse} timeout="auto" unmountOnExit>
										<div style={{
											display        : "flex",
											justifyContent : "center",
										}}
										>
											<ResetPasswordForm />
										</div>
									</Collapse>

									<div
										onClick={() => {
											this.setState({
												collapse1 : !this.state.collapse1,
												collapse2 : false,
												collapse  : false,
											});
										}}
										className="collapsable-list"
									>
										<div
											style={{
												display       : "flex",
												flexDirection : "row",
												margin        : "auto",
											}}
										>
											<label style={{
												margin      : "auto",
												paddingLeft : 24,
											}}
											>
												{" "}
												Cambia preferenze contatto
											</label>
											<div>{this.state.collapse1 ? <ExpandLess /> : <ExpandMore />}</div>
										</div>
									</div>

									<Collapse in={this.state.collapse1} timeout="auto" unmountOnExit>
										<div id="preferences-panel">
											<div id="toCenterFix">
												<ContactPreferenceForm
													logged
													setting
													email={user.email}
													phone={user.telephone}
													handler={(event, value) => {
														this.setState({ radioValue: value });
													}}
													value={this.state.radioValue}
													error={this.state.radioError}
													label="Cambia la tua preferenza contatto"
												/>
											</div>
										</div>
										<div
											className="field is-clearfix"
											style={{
												marginLeft   : "auto",
												marginRight  : "auto",
												marginBottom : 20,
											}}
										/>
									</Collapse>

									<div
										onClick={() => {
											this.setState({
												collapse2 : !this.state.collapse2,
												collapse1 : false,
												collapse  : false,
											});
										}}
										className="collapsable-list"
									>
										<div
											style={{
												display       : "flex",
												flexDirection : "row",
												margin        : "auto",
											}}
										>
											<label style={{
												margin      : "auto",
												paddingLeft : 24,
											}}
											>
												{" "}
												Cambia dati account
											</label>
											<div>{this.state.collapse2 ? <ExpandLess /> : <ExpandMore />}</div>
										</div>
									</div>
									<Collapse in={this.state.collapse2} timeout="auto" unmountOnExit>
										<div style={{
											display        : "flex",
											justifyContent : "center",
										}}
										>
											<ModifyAccount partner azienda={this.state.aziendaData} />
										</div>
									</Collapse>
								</div>
							)}
							{this.state.gettingData && this.state.type !== 5 && (
								<div style={{ margin: "auto" }}>
									<Spinner />
								</div>
							)}
							{this.state.qrLoad && this.state.type === 5 && (
								<div style={{ margin: "auto" }}>
									<Spinner />
								</div>
							)}
							{this.state.type === 2 && (
								<div>
									<PartnerPagePanel data={azienda} partnerID={user ? user.partnerid : 8} />
								</div>
							)}
							{this.state.type === 3 && <AddDeceasedPoster />}
							{this.state.type === 7 && (
								// eslint-disable-next-line no-undef,react/no-unused-state
								<AddDeceasedPosterAndPage
									getTableData={() => this.getDeceasedByOwner()}
									reload={() => this.setState({ reload: !this.state.reload })}
								/>
							)}
							{(this.state.type === 1
								|| this.state.type === 0
								|| this.state.type === 5
								|| this.state.type === 6)
							&& this.newTable(this.state.type === -0)}
						</div>
					</div>
					{this.state.modal && this.modal()}
					{this.state.dedicModal && this.dedicModal()}
					{this.state.modalClear && (
						<DoYouWanToDelete
							close={() => {
								this.setState({ modalClear: false });
							}}
							submit={() => this.clearOperationSendData(this.state.modalClearIndex)}
							text={this.state.labelModalClear}
						/>
					)}
				</div>
			</>
		);
	}

	newTable(table2) {
		return (
			<PartnerPanelTableWrapper
				orderID={this.state.newOrderID}
				filter={{}}
				table2={table2}
				history={this.props.history}
				deleteAllOperation={() => {
					alert("delete all");
				}}
				setChecked={(data) => {
					this.setState({ checked: data });
				}}
				setFilter={(data) => {
					this.setState({ filter: data });
				}}
				setNumber={() => {
				}}
				setAllNumber={() => {
				}}
				type={this.state.type}
				data={table2 ? newOBRows : newRows}
				actionData={(
					<div>
						<CreateOutlined fontSize="small" className="dash-icon" />
						<VisibilityOutlined fontSize="small" className="dash-icon" />
						<DeleteOutlined fontSize="small" className="dash-icon" />
					</div>
				)}
			/>
		);
	}

	dedicModal() {
		const isPhoto       = this.state.dedicData.dedic.type.indexOf("foto") > 0;
		const isVideo       = this.state.dedicData.dedic.type.indexOf("video") > 0;
		const ownerFullName = this.state.dedicData.dedicOwner?.name
			? `${this.state.dedicData.dedicOwner.name} ${this.state.dedicData.dedicOwner.surname}`
			: "Sconosiuto";
		return (
			<div
				onClick={() => {
					if (!notModal) {
						this.setState({ dedicModal: false });
					} else {
						notModal = false;
					}
				}}
				className="admin-panel-modal"
			>
				<CloseIcon id="crossIcon" style={{ zIndex: 3000 }} />
				<div className="flex">
					<div
						style={{ marginBottom: 40 }}
						onClick={() => {
							notModal = true;
						}}
					>
						<div id="purchase-modal">
							<label id="title-modal">Dettagli dedica</label>
							<p className="death-data">Anagrafica</p>
							&nbsp;
							<p>
								Cliente:
								{ownerFullName}
							</p>
							&nbsp;
							{this.state.dedicData.dedicOwner.username && (
								<p>
									User:
									{this.state.dedicData.dedicOwner.username}
								</p>
							)}
							<p className="death-data">Dettagli dedica</p>
							&nbsp;
							<p>
								Tipologia dedica:
								{this.state.dedicData.dedic.type}
							</p>
							&nbsp;
							<p>Dedica:</p>
							<label
								dangerouslySetInnerHTML={{
									__html: this.state.dedicData.dedic.text,
								}}
							/>
							&nbsp;
							{isPhoto && this.state.dedicData.dedic.dedicMedia && (
								<div style={{ textAlign: "center" }}>
									Foto:
									<img
										alt={`dedic${this.state.dedicData.dedicOwner.name}`}
										src={this.state.dedicData.dedic.dedicMedia.filename}
									/>
								</div>
							)}
							{isVideo && this.state.dedicData.dedic.dedicMedia && (
								<Player
									playsInline
									poster="/assets/poster.png"
									src={this.state.dedicData.dedic.dedicMedia.filename}
								>
									<BigPlayButton position="center" />
								</Player>
							)}
							<div className="bottom-modal-submit">
								<Button
									title="Accetta"
									className="button"
									variant="contained"
									onClick={() => {
										this.setState({ dedicModal: false });
										this.checkOperation(dataModal);
									}}
									style={{ color: "white" }}
									color="primary"
								>
									Accetta
								</Button>
								<Button
									title="Rifiuta"
									className="button"
									variant="contained"
									onClick={() => {
										this.setState({ dedicModal: false });
										this.deleteOperation(dataModal);
									}}
									style={{ color: "white" }}
									color="primary"
								>
									Rifiuta
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	qrCodeDataTable(value) {
		const data = [];
		newRows    = [];
		if (!this.state.qrLoad) {
			this.setState({ qrLoad: true });
			requests("get", "getActivationTable")
				.then((result) => {
					result.data.response === "fail" && alert(result.data.message);
					this.setState({ qrLoad: false });
					if (result.data.response === "success") {
						result.data.qrcodelist.map((e) => {
							!(e.deceased && e.deceased.name && e.deceased.name.length > 0) && data.push(e);
						});
						qrCodes = data.length;
						data
						&& !value
						&& data
							.sort((a, b) => (a.deceased.ID > b.deceased.ID ? 1 : -1))
							.map((element) => {
								if (element.ID) {
									newRows.push(
										createQRDataPartnerTable(
											element.ID,
											<div>
												<a href={element.url} target="_blank">
													<img alt="qrCode" width={75} src={element.url} />
												</a>
												{" "}
											</div>,
											`000${element.deceased.ID}`,
											<div>
												<a href={element.url_condoglianze} target="_blank">
													<img alt="qrCode" width={75} src={element.url_condoglianze} />
												</a>
											</div>,
											element.deceased?.contoVendita || "-",
											(dataw) => this.setState(dataw),
											element,
											element.sold,
										),
									);
								}
							});
						this.setState({ lodaded: true });
					}
				});
		}
	}

	clearOperationSendData(i) {
		this.setState({ toastLabel: "cancellato" });
		if (this.state.type === 1) {
			const bodyData = {
				isAccepted : "deny",
				token      : this.state.dedicToken,
				dedic      : this.state.dedicDataID,
			};
			requests("evaluatededic", null, bodyData)
				.then(() => {
					this.dedicDataTable();
					this.setState({ loaded: true });
					this.showSuccessTost();
				});
		}
		if (this.state.type === 0) {
			!this.state.showDeceased
				? requests("post", "obituaryDelete", { obituaryID: this.state.obituaryID })
					.then((result) => {
						if (result.response === "success") window.location.reload();
					})
				: (
					requests("post", "deceasedDelete", { deceasedID: deceased[i].ID })
						.then((result) => {
							if (result.response === "success") window.location.reload();
						})
				);
		}
	}

	clearOperation(i) {
		const labelModalClear = ["la pagina selezionata", "il necrologio di: ", "la richiesta dedica"];
		if (this.state.type === 0) {
			this.setState({
				modalClear      : true,
				labelModalClear : `${labelModalClear[1] + obituary[i].name} ${obituary[i].surname}`,
				modalClearIndex : i,
			});
		}
		if (this.state.type === 1) {
			this.setState({
				modalClear      : true,
				labelModalClear : labelModalClear[2],
				modalClearIndex : i,
			});
		}
	}

	checkOperation(i) {
		if (this.state.type === 1) {
			const bodyData = {
				isAccepted : "active",
				token      : this.state.dedicToken,
				dedic      : this.state.dedicDataID,
			};
			requests("evaluatededic", null, bodyData)
				.then((data) => {
					this.dedicDataTable();
					this.setState({ loaded: true });
					this.setState({ toastLabel: "accettato" });
					this.showSuccessTost();
				});
		}
	}

	modal() {
		return (
			<div
				onClick={() => {
					if (!notModal) {
						this.setState({ modal: false });
					} else {
						notModal = false;
					}
				}}
				className="admin-panel-modal"
			>
				<CloseIcon id="crossIcon" style={{ zIndex: 3000 }} />
				<div className="flex">
					<div
						style={{ marginBottom: 40 }}
						onClick={() => {
							notModal = true;
						}}
					>
						<Partner data={azienda} type="modal" />
					</div>
				</div>
			</div>
		);
	}

	dedicDataTable(value) {
		requests("get", "dedicDataTable")
			.then((data) => {
				const dedic = data.data.data;
				dedics      = dedic;
				this.setState({ load: !this.state.load });
				!value && this.dedicDataTable2();
			})
			.catch((e) => {
				this.setState({ load: !this.state.load });
				!value && this.dedicDataTable2();
			});
	}

	deceasedDataTable() {
		newRows   = [];
		newOBRows = [];
		deceased.sort((a, b) => (a.dateOfDeath > b.dateOfDeath ? 1 : -1));
		deceased
			.sort((a, b) => {
				if (!a.dateOfDeath) return 1;
				if (!b.dateOfDeath) return -1;
				if (a.dateOfDeath === "0000-00-00") return 1;
				if (b.dateOfDeath === "0000-00-00") return -1;
				return a.dateOfDeath >= b.dateOfDeath ? 1 : -1;
			})
			.map((e, i) => {
				newRows.push(
					createDeceasedDataPartnerTable(
						e.ID,
						`${e.name} ${e.surname}`,
						`${e.location} (${e.province}) ${e.graveAddress}`,
						`${
							e.dateOfDeath === "0000-00-00" || !e.dateOfDeath
								? "Non disponibile"
								: moment(e.dateOfDeath)
									.format("DD/MM/YYYY")
						}`,
						(data) => this.setState(data),
						e,
						() => {
							const obituary2 = e;
							const newData   = helperSetNewData(obituary2);
							let newData2    = {};
							let modify;
							// newData.pageID = baseURL.indexOf('dlgteksssssssssss') >= 0 ? newData.pageID : false;
							if (newData.pageID) {
								const deceased2 = obituary.find((e) => e.ID === newData.pageID);
								if (deceased2 !== -1) {
									newData2 = helperSetNewData(deceased2);
									modify   = mergeObjs(newData, newData2);
								} else {
									modify = newData;
								}
							} else {
								modify = newData;
							}
							this.setState({
								modifyModal        : !this.state.showDeceased && !newData.pageID,
								modifyAll          : !!newData.pageID,
								modifyData         : modify,
								openModifyDeceased : this.state.showDeceased && !newData.pageID,
							});
						},
						<div>
							<a href={e.url} target="_blank">
								<img alt="qrCode" width={75} src={e.url} />
							</a>
							{" "}
						</div>,
						<div>
							<a href={e.url_condoglianze} target="_blank">
								<img alt="qrCode" width={75} src={e.url_condoglianze} />
							</a>
						</div>,
						() => {
							this.setState({ toastLabel: " inviato per messaggio " });
							this.showSuccessTost();
						},
						e.qrCodeID,
						(data) => this.setState({ newOrderID: data }),
						(open, label) => {
							this.setState({
								modalLoading : open,
								loadingLabel : label,
							});
						},
						(ID, name, cardFunc, cardGridFunc) => {
							this.setState({
								cardID            : ID,
								cardDeceased      : name,
								cardModalFunc     : cardFunc,
								cardGridModalFunc : cardGridFunc,
								cardModal         : true,
							});
						},
					),
				);
			});
		obituary
			.sort((a, b) => (a.dateOfDeath > b.dateOfDeath ? 1 : -1))
			.map((e, i) => {
				newOBRows.push(
					createObituaryDataPartnerTable(
						e.ID,
						`${e.name} ${e.surname}`,
						`${e.location} (${e.province}) ${e.graveAddress}`,
						`${
							e.dateOfDeath === "0000-00-00" || !e.dateOfDeath
								? "Non disponibile"
								: moment(e.dateOfDeath)
									.format("DD/MM/YYYY")
						}`,
						(data) => this.setState(data),
						e,
						() => {
							const obituary2 = e;
							const newData   = helperSetNewData(obituary2);
							let newData2    = {};
							let modify;
							// newData.pageID = baseURL.indexOf('dlgtek') >= 0 ? newData.pageID : false;
							if (newData.pageID) {
								const deceased2 = deceased.find((e) => e.ID === newData.pageID);
								if (deceased2 !== -1) {
									newData2 = helperSetNewData(deceased2);
									modify   = mergeObjs(newData, newData2);
								} else {
									modify = newData;
								}
							} else {
								modify = newData;
							}
							this.setState({
								modifyModal        : !this.state.showDeceased && !newData.pageID,
								modifyAll          : !!newData.pageID,
								modifyData         : modify,
								openModifyDeceased : this.state.showDeceased && !newData.pageID,
							});
						},
					),
				);
			});
		this.setState({ loaded: true });
	}

	createSortHandler = (property) => (event) => {
		const isAsc = this.state.orderBy === property && this.state.order === "asc";
		this.setState({ order: isAsc ? "desc" : "asc" });
		this.setState({ orderBy: property });
	};

	dedicDataTable2() {
		this.setState({ page: 0 });
		newRows = [];
		// eslint-disable-next-line array-callback-return
		this.state.type === 1
		&& dedics.map((e, i) => {
			const dedicOwner = (e && e.dedicOwner) || null;
			e.deceased
			&& newRows.push(
				createDedicDataPartnerTable(
					e.ID,
					dedicOwner?.name ? `${dedicOwner.name} ${dedicOwner.surname}` : "Sconosciuto",
					`${e.deceased.name} ${e.deceased.surname}`,
					e.dedic.type,
					(data) => this.setState(data),
					e,
					() => {
						const bodyData = {
							isAccepted : "active",
							token      : e.dedic.token,
							dedic      : e.dedic.ID,
						};
						requests("evaluatededic", null, bodyData)
							.then((data) => {
								this.dedicDataTable();
								this.setState({ loaded: true });
								this.setState({ toastLabel: "accettato" });
								this.showSuccessTost();
							});
					},
				),
			);
		});
		this.setState({ loaded: true });
	}
}

function helperSetNewData(obituary) {
	const newData = {
		ID              : obituary.ID,
		condolence      : obituary.condolenceCounter,
		pageID          : obituary.pageID,
		gender          : obituary.gender,
		age             : obituary.staticAge,
		city            : obituary.verification_code,
		family          : obituary.familyReference,
		verified        : obituary.verified,
		publicationDate : obituary.obituaryPublicationDate,
		thanks          : obituary.thanks,
		manifest_photo  :
			obituary.deceasedImageManifesto
			&& `${obituary.deceasedImageManifesto}` != "0"
			&& `api${obituary.deceasedImageManifesto}`,
		qrcodeAttivazione : obituary.qrcodeAttivazione,
		deceasedName      : obituary.name,
		deceasedSurname   : obituary.surname,
		date              : obituary.date,
		orderID           : obituary.orderID,
		staticAge         : obituary.staticAge,
		bornDate          : obituary.dateOfBirth,
		aphorism          : obituary.aphorism,
		deathDate         : obituary.dateOfDeath,
		graveyardCap      : obituary.graveCap || obituary.graveyardCap,
		graveyardRegion   : obituary.graveRegion || obituary.graveyardRegion,
		graveyardProvince : obituary.graveProvince || obituary.graveyardProvince,
		graveyardCity     : obituary.graveLocation || obituary.graveyardLocation,
		graveyardName     : obituary.graveName || obituary.graveyardName,
		graveyardAddress  : obituary.graveAddress || obituary.graveyardAddress,
		firstSentence     : obituary.firstSentence || obituary.firstSentence,
		first_sentence    : obituary.firstSentence || obituary.firstSentence,
		manifest          : obituary.linkManifesto,
		notice            : obituary.notice,
		editorData        : obituary.obituaryNote || "",
		funeralDate       : obituary.funeralDate,
		fileurl           : obituary.lapide,
		qrCodeID          : obituary.qrcodeID,
		filename          : obituary.lapide,
		referringPlace    : obituary.referringPlace,
		showPage          : obituary.showPage,
		funeral           : obituary.funeral,
	};
	return newData;
}

export default withContext(AdminPanel);
