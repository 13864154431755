import React, { Component, Fragment } from "react";
import requests from "../../../Services/HttpServices";

class PostPartner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
    };
  }

  render() {
    return (
      <>
        <div style={{ height: window.innerHeight - 250 }}>
          <p
            className="death-data"
            style={{
              alignSelf: "center",
              marginTop: 20,
              marginBottom: 20,
              margin: "auto",
              textAlign: "center",
            }}
          >
            Grazie per esserti registrato
          </p>
          <p>
            {" "}
            Riceverai un messaggio, secondo il metodo di contatto da te scelto,
            entro 48h solo se la richiesta può essere accettata.
          </p>
          &nbsp;
          <label className="technic-support" style={{ paddingTop: 20 }}>
            Per un eventuale supporto tecnico è possibile contattare il numero
            telefonico 389 89 96 166
            <p>
              <b>
                DAL LUNEDÌ AL VENERDÌ DALLE ORE 09:00 ALLE 13:00 E DALLE 15:00
                ALLE 18:00
              </b>
            </p>
          </label>
        </div>
      </>
    );
  }
}

export default PostPartner;
