import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import addressServiceGetData from "./addressService";
import validate from "../../formValidationService";
import requests from "../../Services/HttpServices";
import PhoneNumber from "./PhoneNumber";
import Nationality from "./Nationality";
import { suggestUsername } from "../../Services/formServices";

const gettingRegion = false;
let timerUserCheck = null;
export default function UserFormData(props) {
  const { names } = props;
  const [timeout, setTimeouts] = useState(null);
  const [timeout2, setTimeouts2] = useState(null);
  const { form } = props;
  const { formError } = props;
  const [disable, setDisable] = useState(null);
  const [regioni, setRegioni] = useState(null);
  const [province, setProvince] = useState(props.provincie);
  const [comuni, setComuni] = useState(props.comuni);
  const [loaded, setLoaded] = useState(false);
  const [userError, setUserError] = useState(null);
  let [initData, setInitData] = useState(null);
  if (!initData && form.nationality) initData = form.nationality;
  props.province && !province && setProvince(props.province);
  props.comuni && !comuni && setComuni(props.comuni);
  if (props.disable) disable !== props.disable && setDisable(props.disable);
  else disable === null && setDisable(false);

  function setAndValidateForm(
    data,
    attribute,
    isNumeric,
    form,
    formError,
    minLenght,
    exact
  ) {
    form[attribute] = data;
    formError = validate(data, attribute, formError, isNumeric);
    if (exact)
      if (form[attribute] && form[attribute].length !== exact) {
        formError[attribute] = true;
      }
    if (minLenght) {
      if (form[attribute] && form[attribute].length < minLenght) {
        formError[attribute] = true;
        attribute === "username" &&
          setUserError("L'username deve essere lungo almeno 6 caratteri");
      }
    }
    if (attribute === "email" || attribute === "phone") {
      formError.account = false;
      if (form.email === "") formError.email = false;
      if (
        form.phone === "" ||
        !form.phone ||
        (form.phone && form.phone.length > 3)
      )
        formError.phone = false;
    }
    setLoaded(!loaded);
  }

  const handleNameSurnameUpdate = (e, name) => {
    setAndValidateForm(e.target.value, name, false, form, formError);
    if (form.name && form.surname) {
      if (timerUserCheck) {
        clearTimeout(timerUserCheck);
        timerUserCheck = null;
      }
      timerUserCheck = setTimeout(
        () =>
          suggestUsername(form.name, form.surname).then((result) => {
            form.username = result;
            setLoaded(!loaded);
          }),
        500
      );
    }
  };
  return (
    <div
      style={{ display: "flex", flexDirection: "column" }}
      id="user-form-data"
    >
      {!props.notusername && (
        <TextField
          className="activate-field"
          label="Nome utente *"
          name={`${names}-username`}
          id="username"
          style={{ display: props.notusername && "none" }}
          value={form.username}
          onChange={(event) => {
            setUserError(null);
            setAndValidateForm(
              event.target.value,
              "username",
              false,
              form,
              formError,
              6
            );
            if (props.openModalEmail && !props.disable && !formError.username) {
              props.load();
              timeout2 && clearTimeout(timeout2);
              setTimeouts2(
                setTimeout(() => {
                  requests("get", "userExist", form.username).then((data) => {
                    if (
                      data.data.result == false ||
                      data.data.result == "fail" ||
                      data.data.response === "fail"
                    ) {
                    } else {
                      setUserError("Username non disponibile");
                      data.data.data.username &&
                        props.openModalEmail(data.data.data.username);
                    }
                  });
                }, 600)
              );
            }
          }}
          error={userError || formError.username}
          helperText={userError || (formError.username && "Campo obbligatorio")}
          disabled={disable}
        />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: props.modify ? "row" : "column",
          justifyContent: props.modify && "space-between",
        }}
      >
        <TextField
          className="activate-field"
          label="Nome *"
          name={`${names}-name`}
          value={form.name}
          onChange={(event) => handleNameSurnameUpdate(event, "name")}
          error={formError.name}
          helperText={formError.name && "Campo obbligatorio"}
          disabled={disable}
        />

        <TextField
          className="activate-field"
          label="Cognome *"
          name={`${names}-surname`}
          value={form.surname}
          onChange={(event) => handleNameSurnameUpdate(event, "surname")}
          error={formError.surname}
          helperText={formError.surname && "Campo obbligatorio"}
          disabled={disable}
        />
      </div>

      {false && (
        <TextField
          className="activate-field"
          label="Codice fiscale *"
          name={`${names}-cf`}
          value={form.cf}
          style={{ marginBottom: !props.disable && 40 }}
          onChange={(event) => {
            setAndValidateForm(
              event.target.value,
              "cf",
              false,
              form,
              formError,
              null
            );
            if (props.openModalEmail && !props.disable && !formError.cf) {
              props.load();
              timeout2 && clearTimeout(timeout2);
              setTimeouts2(
                setTimeout(() => {
                  requests("get", "cfExist", form.cf).then((data) => {
                    if (
                      data.data.result == false ||
                      data.data.result == "fail" ||
                      data.data.response === "fail"
                    ) {
                    } else
                      data.data.data.username &&
                        props.openModalEmail(data.data.data.username);
                  });
                }, 600)
              );
            }
          }}
          error={formError.username}
          helperText={formError.username && "Campo obbligatorio"}
          disabled={disable}
        />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: props.modify ? "row" : "column",
          justifyContent: props.modify && "space-between",
        }}
      >
        <TextField
          className="activate-field"
          label="Email "
          value={form.email}
          id={`${names}-email`}
          name={`${names}-email`}
          onChange={(event) => {
            props.load && props.load();
            setAndValidateForm(
              event.target.value,
              "email",
              false,
              form,
              formError
            );
            // if (props.openModalEmail && !props.disable && !formError.email) {
            //     props.load()
            //     timeout && clearTimeout(timeout)
            //     setTimeouts(setTimeout(() => {
            //         requests('get', 'mailExist', form.email).then(data => {
            //             if (data.data.result == false || data.data.result == 'fail') {
            //             } else data.data.isActive && (props.openModalEmail())
            //
            //         })
            //     }, 600))
            // }
          }}
          error={formError.email}
          style={{ marginBottom: !disable && 40 }}
          helperText={formError.email && "Mail non valida"}
          disabled={disable}
        />
        <div
          style={{ display: props.disable ? "none" : props.modify && "flex" }}
        >
          <PhoneNumber
            name="phone"
            load={() => props.load}
            modify={props.modify}
            width={props.modify && 272}
            disabled={disable}
            initValue={form.phone}
            setValue={(value) => {
              setAndValidateForm(value, "phone", true, form, formError, 12);
              props.load && props.load();
            }}
            error={formError.phone}
          />
        </div>
      </div>
      {formError.account && (
        <label style={{ color: "red" }}>
          Inserisci una mail o un numero di telefono valido
        </label>
      )}

      <TextField
        className="activate-field"
        label="Numero di cellulare "
        name={`${names}-telephone`}
        style={{ display: !props.disable && "none" }}
        value={form.phone}
        onChange={(event) => {
          props.load && props.load();
          setAndValidateForm(
            event.target.value,
            "phone",
            true,
            form,
            formError
          );
        }}
        error={formError.phone}
        helperText={formError.phone && "Numero di telefono non valido"}
        disabled={disable}
      />

      <Nationality
        name={props.names}
        modify={props.modify}
        setData={(data) => {
          setAndValidateForm(data, "nationality", false, form, formError);
          props.nationalityChange();
        }}
        startData={(form.nationality && form.nationality) || "105"}
      />
      {(form.nationality == "105" || !form.nationality) && (
        <TextField
          name={props.ismodify ? "modify-cf" : "cf"}
          id="cf"
          style={{ marginTop: 20 }}
          className="mt-20"
          label={props.partnerModify ? "Codice fiscale" : "Codice fiscale *"}
          helperText={
            formError.CF &&
            (!props.partnerModify || (props.partnerModify && form.CF !== "")) &&
            "Campo non valido"
          }
          error={
            formError.CF &&
            (!props.partnerModify || (props.partnerModify && form.CF !== ""))
          }
          value={form.CF}
          onChange={(event) =>
            setAndValidateForm(
              event.target.value,
              "CF",
              false,
              form,
              formError,
              16,
              16
            )
          }
        />
      )}
    </div>
  );
}
