import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import requests from "../../../Services/HttpServices";

export default function BookVerify(props) {
  const { search } = window.location;
  const [success, setSuccess] = useState(null);
  const [request, setRequest] = useState(null);
  const params = new URLSearchParams(search);

  useEffect(() => {
    const bodyData = {
      deceasedID: params.get("id"),
      smsCode: params.get("token"),
    };
    requests("post", "bookCode", bodyData).then((result) => {
      if (result.response === "success") {
        setSuccess(true);
        setRequest(true);
      } else {
        setSuccess(false);
        setRequest(true);
      }
    });
  }, []);
  if (!request)
    return (
      <div
        style={{
          width: "100%",
          height: 700,
          display: "flex",
          justifyContent: "center",
          padding: 20,
        }}
      >
        <CircularProgress />
      </div>
    );
  if (!success)
    return (
      <div
        style={{
          width: "100%",
          height: 700,
          display: "flex",
          flexDirection: "column",
          gap: 12,
          padding: 20,
          alignContent: "center",
          alignItem: "center",
        }}
      >
        <p className="buy-form-paragraph" style={{ margin: "0 auto" }}>
          Attenzione!
        </p>
        <p style={{ margin: "0 auto", textAlign: "center" }}>
          Il link non è più valido
        </p>
        <label
          className="technic-support"
          style={{ paddingTop: 20, textAlign: "center" }}
        >
          <p>Per un eventuale supporto tecnico è possibile contattare</p>
          <p>
            {" "}
            il numero telefonico <strong>389 89 96 166</strong>
          </p>
          <p style={{ textAlign: "center", marginTop: 12 }}>
            <b>
              <p>DAL LUNEDÌ AL VENERDÌ</p>
              <p> DALLE ORE 09:00 ALLE 13:00 E</p>
              <p>DALLE 15:00 ALLE 18:00</p>
            </b>
          </p>
        </label>
      </div>
    );
  return (
    <div
      style={{
        width: "100%",
        height: 700,
        display: "flex",
        flexDirection: "column",
        gap: 12,
        padding: 20,
        alignContent: "center",
        alignItem: "center",
      }}
    >
      <p className="buy-form-paragraph" style={{ margin: "0 auto" }}>
        Successo
      </p>
      <p style={{ margin: "0 auto", textAlign: "center" }}>
        Condoglianza pubblicata con successo,{" "}
        <Link to={`/memorial-book?${params.get("id")}`}>
          clicca qui per visualizzarla nella pagina dedicata
        </Link>
      </p>
    </div>
  );
}
