import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Axios from "axios";
import { Spinner } from "react-activity";
import { Typography } from "@material-ui/core";

import MenuItem from "@material-ui/core/MenuItem";
import "./AddGiftAdmin.scss";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import DateFnsIt from "date-fns/locale/it";
import DateFnsUtils from "@date-io/date-fns";

import { RemoveCircleOutline } from "@material-ui/icons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { BigPlayButton, Player } from "video-react";
import moment from "moment/moment";
import { readURL } from "../../FormComponents/FileNamesComponent";
import CkEditorCustom from "../../FormComponents/CkEditor2";
import validate from "../../../formValidationService";
import requests, { baseURL } from "../../../Services/HttpServices";
import PhotoInput from "../../FormComponents/PhotoInput";
import VideoInput from "../../FormComponents/VideoInput";

import FeedbackModal from "../../FormComponents/FeedbackModal";

const deceased = null;
const formclass = "";
const deceasedID = null;
let purchaseType = null;
const label = null;
let close = true;

class ModifyGiftAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _csrf: "",
      modal: false,
      selected: "",
      radioValue: "",
      loggedUser: null,
      productPrice: "",
      deceasedData: {},
      form: {
        // dedic: '',
      },
      formError: {},
      openPicker: true,
      dateAndTimePicker: null,
    };
  }

  setInitialData(dedicData) {
    const { form } = this.state;
    form.dedic = dedicData.text;
    form.date = dedicData.time;
    form.autor = dedicData.showAuthor;
    let photod = null;
    let filename = null;
    let videoName = null;
    let videod = null;
    if (dedicData && dedicData.media) {
      const temp = dedicData.media.filename.split("/");
      if (dedicData.media.type !== "video") {
        photod = dedicData.media.filename;
        filename = temp[temp.length - 1];
      } else {
        videod = dedicData.media.filename;
        videoName = temp[temp.length - 1];
      }
    }
    this.setState({ form, fileName: filename, photod, videod, videoName });
  }

  componentDidMount() {
    this.props.dedicData && this.setState({ dedicData: this.props.dedicData });
    this.props.deceased && this.setState({ deceasedData: this.props.deceased });
    this.setInitialData(this.props.dedicData);
  }

  render() {
    purchaseType = this.state.dedicData?.type;
    return (
      <div
        className="bg-modal"
        onClick={() => {
          close ? this.props.handleClose() : (close = true);
        }}
      >
        <div
          style={{ overflow: "auto", paddingBottom: 0, marginTop: 0 }}
          onClick={() => (close = false)}
        >
          {this.state.uploading && (
            <div className="div-upload">
              <Spinner size={100} />
              <Typography className="label-upload">
                Attendi il caricamento dei files{" "}
                {parseInt(parseFloat(this.state.percentage) * 100)}%
              </Typography>
            </div>
          )}
          {this.state.successModal && this.successModal()}
          <div className="contact-container ">
            <div className="memorial-container memorial-container-activate">
              <div className="activate-form-div fix-height">
                <label className="contact-title">Modifica dono</label>

                <form
                  style={{ position: "relative" }}
                  className="activate-form "
                  onSubmit={(event) => {
                    event.preventDefault();
                    const formData = new FormData(event.target);
                    formData.set("dedic-id", this.state.dedicData.ID);
                    if (this.state.checkBox)
                      formData.set(
                        "dedic-force-publish",
                        this.state.dedicData.ID
                      );

                    const object = {};
                    const insertDate = new Date(this.state.form.date);
                    formData.set(
                      "insert-time",
                      insertDate && insertDate.getTime()
                    );
                    formData.forEach(function (value, key) {
                      object[key] = value;
                    });
                    formData.set("deceased-id", deceased);
                    this.send(formData);
                  }}
                >
                  <div className="search-gift-type">
                    <TextField
                      value={
                        this.state.dedicData ? this.state.dedicData.type : ""
                      }
                      style={{ width: "100%" }}
                      name="dedic-type"
                      select
                      variant="outlined"
                      label="Tipologia di dedica"
                      disabled
                    >
                      <MenuItem value="dedica-cero">Dedica e cero</MenuItem>
                      <MenuItem value="dedica-fiori">Dedica e fiori</MenuItem>
                      <MenuItem value="dedica-foto">Dedica e foto</MenuItem>
                      <MenuItem value="dedica-video">Dedica e video</MenuItem>
                    </TextField>
                  </div>
                  <MuiPickersUtilsProvider
                    locale={DateFnsIt}
                    utils={DateFnsUtils}
                  >
                    <KeyboardDateTimePicker
                      style={{ marginTop: 10 }}
                      name="insert-time"
                      format="dd/MM/yyyy HH:mm"
                      ampm={false}
                      label="Inserisci la data di creazione"
                      value={moment(this.state.form.date).toDate()}
                      onChange={(value) => {
                        const { form } = this.state;
                        form.date = value;
                        this.setState({ form });
                      }}
                      openPicker={this.state.openPicker}
                    />
                  </MuiPickersUtilsProvider>
                  <label className="death-data">Dati Defunto</label>
                  <div className="admin-add-gift-deceased">
                    <img
                      alt="photo-lapide"
                      style={{ maxWidth: 100, maxHeight: 100 }}
                      src={
                        this.state.deceasedData &&
                        this.state.deceasedData["photo-lapide"]
                      }
                    />
                    <div id="deceased">
                      <label>
                        {this.state.deceasedData.name}{" "}
                        {this.state.deceasedData.surname}
                      </label>
                      <label
                        dangerouslySetInnerHTML={{
                          __html: this.state.deceasedData.aphorism,
                        }}
                      />
                      <label>
                        {this.state.deceasedData.dateOfBirth}-
                        {this.state.deceasedData.dateOfDeath}
                      </label>
                    </div>
                  </div>
                  {this.state.formError.nameTo && !this.state.form.nameTo && (
                    <label style={{ color: "red" }}>
                      Scegli un defunto per proseguire
                    </label>
                  )}
                  {this.state.dedicData && (
                    <CkEditorCustom
                      customKey={`modify-dedic${this.state.form.date}`}
                      title="Dedica"
                      onChange={(editor) => {
                        const temp = this.state.form;
                        temp.dedic = editor.getData();
                        this.setState({ form: temp });
                      }}
                      fix
                      initData={this.state.form.dedic || ""}
                      error={this.state.formError.dedic}
                    />
                  )}
                  <TextField
                    className="text-area"
                    id="outlined-multiline-static"
                    label="Dedica da lascire"
                    style={{ display: "none" }}
                    value={this.state.form.dedic}
                    multiline
                    name="dedic"
                    rows={8}
                    variant="outlined"
                    onChange={(event) => {
                      this.setAndValidateForm(event.target.value, "dedic");
                    }}
                    helperText={
                      this.state.formError.dedic ? "Campo obbligatorio" : ""
                    }
                    error={this.state.formError.dedic}
                  />
                  <input
                    style={{ display: "none" }}
                    name="dedic-type"
                    value={purchaseType}
                  />
                  <TextField
                    className="activate-field"
                    value={this.state.form.autor}
                    onChange={(e) => {
                      const { form } = this.state;
                      form.autor = e.target.value;
                      this.setState(form);
                    }}
                    name="dedic-sign"
                    label="Firma la tua dedica (Facoltativo)"
                  />
                  {purchaseType &&
                    (purchaseType.indexOf("foto") > 0 ||
                      purchaseType.indexOf("video") > 0) && (
                      <div>
                        {purchaseType.indexOf("foto") > 0 ? (
                          <div className="file-upload">
                            <label
                              className="pointer"
                              htmlFor="photo-dedic"
                              color="blue"
                              title=""
                            >
                              <p className="pointer-label">
                                {" "}
                                {this.state.fileName ||
                                  "Carica foto (Max 15MB per foto)"}
                              </p>
                            </label>
                          </div>
                        ) : (
                          <div className="file-upload">
                            <label
                              className="pointer"
                              htmlFor="dedic-video-upload"
                              color="blue"
                              title=""
                            >
                              <p className="pointer-label">
                                {" "}
                                {this.state.videoName ||
                                  "Carica video (Max 15MB)"}
                              </p>
                            </label>
                          </div>
                        )}
                        {this.state.videod && (
                          <div
                            style={{
                              margin: "10px auto",
                              width: "min-content",
                            }}
                          >
                            <Player
                              fluid={false}
                              style={{ margin: "auto" }}
                              height={300}
                              playsInline
                              poster="/assets/poster.png"
                              src={this.state.videod}
                            >
                              <BigPlayButton position="center" />
                            </Player>
                          </div>
                        )}
                        {this.state.photod && (
                          <div className="is-relative">
                            <img
                              style={{
                                maxHeight: 300,
                                margin: "auto",
                                marginTop: 20,
                                paddingLeft: 20,
                                position: "relative",
                              }}
                              src={this.state.photod}
                            />
                            <RemoveCircleOutline
                              className="remove-photo"
                              onClick={() => {
                                this.setState({
                                  fileName: "",
                                  photod: null,
                                  erased: true,
                                });
                                document.getElementById(
                                  "photo-dedic"
                                ).value = null;
                              }}
                            />
                          </div>
                        )}
                        {(this.state.photoError || this.state.videoError) && (
                          <label style={{ color: "red" }}>
                            Carica almeno un file
                          </label>
                        )}
                      </div>
                    )}
                  {/*                            <FormControlLabel style={{marginTop: 20}} value={this.state.checkBox}
                                              className={'activate-checkbox'}
                                              control={<Checkbox color={"primary"} name="notification-flag"
                                                                 id={'notification-flag'}
                                                                 value={this.state.checkBox}
                                                                 onChange={(event, checked) => this.setState({checkBox: checked})}/>}
                                              label={'Spunta per inviare una notifica agli utenti'}/> */}
                  &nbsp;
                  {this.state.dedicData?.status === "pending" && (
                    <FormControlLabel
                      className="activate-checkbox"
                      control={
                        <Checkbox
                          color="primary"
                          name="dedic-force-publish"
                          id="term-box"
                          onChange={(event, checked) =>
                            this.setState({ checkBox: checked })
                          }
                        />
                      }
                      label="Accetta la dedica"
                    />
                  )}
                  &nbsp;
                  <div className="bottom-activate-form">
                    <button
                      className="activate-submit"
                      type="submit"
                      onClick={(event) => {}}
                    >
                      Procedi
                    </button>
                  </div>
                  {purchaseType && purchaseType.indexOf("video") > 0 && (
                    <VideoInput
                      name="dedic"
                      setVideoName={(name, video) => {
                        this.setState({ videoName: name });
                        readURL(video).then((data) =>
                          this.setState({ videod: data })
                        );
                      }}
                    />
                  )}
                  {purchaseType && purchaseType.indexOf("foto") > 0 && (
                    <PhotoInput
                      name="dedic"
                      setFileName={(name, photo) => {
                        this.setState({ fileName: name });
                        readURL(photo).then((data) =>
                          this.setState({ photod: data })
                        );
                      }}
                    />
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  send(formdata) {
    let { formError } = this.state;
    let error = false;
    for (const property in this.state.form) {
      if (
        property !== "province" &&
        property !== "address" &&
        property !== "city" &&
        property !== "location" &&
        property !== "region" &&
        property !== "civic" &&
        property !== "route" &&
        property !== "cap" &&
        property !== "complete_address" &&
        property !== "autor" &&
        property !== "CF"
      ) {
        formError = validate(this.state.form[property], property, formError);
        error = error || formError[property];
      }
    }
    if (purchaseType.indexOf("foto") > 0) {
      error = error || !this.state.fileName;
      this.setState({ photoError: !this.state.fileName });
    }
    if (purchaseType.indexOf("video") > 0) {
      error = error || !this.state.videoName;
      this.setState({ videoError: !this.state.videoName });
    }
    if (!error) {
      getCsrf().then((csrf) => {
        this.setState({ csrf_token: csrf });
        formdata.set("csrf_token", csrf);
        this.setState({ uploading: true });
        this.state.checkBox && formdata.set("notification-flag", true);
        requests("sendform", "editDedic", formdata).then((data) => {
          this.setState({ uploading: false });
          if (data.result !== "fail") {
            this.setState({
              modalResult: "Successo",
              modalText: "Dedica modificata con successo",
            });
            // alert('success')
            // this.setState({orderId: data.orderid})
          }
          this.setState({ successModal: true });
        });
      });
    }
    // this.setState({modal: true})
  }

  setAndValidateForm(data, attribute) {
    const { form } = this.state;
    let { formError } = this.state;
    form[attribute] = data;
    this.setState({ form });
    formError = validate(data, attribute, formError);
    this.setState({ formError });
  }

  successModal() {
    return (
      <FeedbackModal
        result={this.state.modalResult}
        close={() => {
          this.setState({ successModal: false });
          this.props.getDedicData();
          this.props.handleClose();
        }}
        label={this.state.modalText}
      />
    );
  }
}

function getCsrf() {
  return new Promise((result, err) =>
    Axios.get(`${baseURL}api/auth/securitytoken`)
      .then((resolve) => {
        if (resolve != null) {
          result(resolve.data._csrf);
        } else result({ DATA: "NO DATA" });
      })
      .catch((err) => {
        return result({ err });
      })
  );
}

export default ModifyGiftAdmin;
