/* eslint-disable jsx-a11y/label-has-associated-control,react/button-has-type,jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions,no-return-assign */
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { BigPlayButton, Player } from "video-react";
import moment from "moment";
import Thumbnail from "react-video-thumbnail";
import ReactImageVideoLightbox from "react-image-video-lightbox";
import { Chip, Collapse } from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCross } from "@fortawesome/free-solid-svg-icons";
import withContext from "../../WithContextFile";
import stabd from "../../assets/Stab/defunto";
import "./Memorial-page.css";
import book from "../../assets/book.gif";
import fiori from "../../assets/imgStore/fiori.png";
import lumino from "../../assets/imgStore/lumino.png";
import demo from "../../assets/imgStore/demo.png";
import play from "../../assets/imgStore/play.png";
import requests, { baseURL } from "../../Services/HttpServices";
import LampadePopup from "./../ControlPanel/ClientPanelPages/LampadePopup";
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';
import addressServiceGetData from "../FormComponents/addressService";
import { getIdFromName } from "./regioni";
import TooLongTextWrapper from "../FormComponents/TooLongText";
import { MEMORIAL_BOOK_DELAY } from "../../Services/formServices";
import bookgif from "../../assets/bookgif.gif";

let doit;
let stab              = JSON.parse(JSON.stringify(stabd));
let stabLink          = "/partner-page";
let photoClass;
let images;
const dedicsImages    = [fiori, lumino];
const dedicsTypeArray = ["dedica-fiori", "dedica-cero", "dedica-foto", "dedica-video"];
let deceasedID        = null;
let lampadaTrovata    = false;
let lampadaStato      = 0;
let lampadaModalita   = 0;
let lampadaData;
let lampadeCollegate = [];
let userid = null;
let regionName;
const ImageRef        = new Array(11).fill(null);

function dateToString(date, time) {
	let day   = parseInt(date.getDate(), 10);
	let month = parseInt(date.getMonth(), 10) + 1;
	if (parseInt(day, 10) < 10) day = `0${day}`;
	if (month < 10) month = `0${month}`;
	const sDate = `${day}/${month}/${date.getFullYear()}`;
	if (!time) return sDate;

	const sMinutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
	const sHour    = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
	const sSecond  = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
	return `${sDate}  ${sHour}:${sMinutes}:${sSecond}`;
}
const isSafari =	/constructor/i.test(window.HTMLElement)
	|| (function (p) {
		return p.toString() === "[object SafariRemoteNotification]";
	}(
		// eslint-disable-next-line no-undef
		!window.safari || (typeof safari !== "undefined" && safari.pushNotification),
	));
class MemorialPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			lightBox      : false,
			lightBoxIndex : 0,
			focusedImage  : -2,
			resized       : false,
		};
		photoClass = "-resized";

		images = stab && stab.photos;
	}

	componentDidMount() {
		this.onStart();
		requests("get", "getBookLimit").then((data) => {
			if (data.data.response === "success") {
				this.setState({ bookLimit: parseInt(data.data.data.condolenceLimit, 10) });
			} else this.setState({ bookLimit: MEMORIAL_BOOK_DELAY });
		});

		window.onresize = () => {
			window.removeEventListener("scroll", this.handleScroll);
			window.innerWidth < 430 && window.addEventListener("scroll", this.handleScroll);
			clearTimeout(doit);
			doit = setTimeout(() => {
				this.setState({ resized: !this.state.resized });
			}, 100);
		};
		requests("get", "getProductPrices", "").then((data) => {
			if (data.data?.data?.products?.length > 0) {
 data.data.data.products.forEach((e) => {
					this.setState({ [e.name]: e.price });
 });
			}
		});
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.handleScroll);
		deceasedID = null;
		stab       = null;
	}

	handleScroll = (event) => {
		ImageRef.length > 0
			&& ImageRef.map((e, i) => {
				if (e && this.isScrolledIntoView(e, i)) i <= 11 && this.state.focusedImage !== i && this.setState({ focusedImage: i });
				// else this.setState({focusedImage: -1})
			});
	};

	onStart() {
		stab             = JSON.parse(JSON.stringify(stabd));
		const { search } = window.location;
		const param      = search.slice(1, search.length);
		deceasedID       = param;
		if (deceasedID) {

			//Leggi i dati della Lampada
			try { userid = JSON.parse(sessionStorage.getItem("User")).userdata.user_id; } catch (e) {}
			requests("get", "lampadaData", parseInt(deceasedID, 10)).then((data) => {
				if (data?.data?.result === "success") {
					lampadaTrovata = true;
					lampadaStato = parseInt(data.data.lampadaData.statoLampadaEffettivo, 10);
					lampadaModalita = parseInt(data.data.lampadaData.statoLampadaImpostato, 10);
					lampadaData = data.data.lampadaData;

					//Leggi i dati delle LampadeCollegate
					const bodydata = {};
					bodydata["seriale"] = parseInt(lampadaData.serialeLampada, 10);
					requests("post", "lampadeCollegateLoad", bodydata).then((data) => {
						if (data?.result === "success") {
							lampadeCollegate = data?.lampadeCollegate;
						}
						else {
							lampadeCollegate = [];
						}
					});
				}
				else {
					lampadaTrovata = false;
					lampadaStato = 0;
					lampadaModalita = 0;
				}
			});

			requests("get", "deceasedData", parseInt(deceasedID, 10)).then((data) => {
				if (data?.data?.result === "success") {
					if (data.data.partner) stabLink = `/partner-page?${data.data.partner.ID}`;

					stab.name          = data.data.deceasedData.name;
					stab.hasPhoto      = true;
					stab.surname       = data.data.deceasedData.surname;
					stab.bornDate      = data.data.deceasedData.dateOfBirth.indexOf("-0") >= 0
						|| data.data.deceasedData.dateOfBirth == data.data.deceasedData.dateOfDeath
							? ""
							: data.data.deceasedData.dateOfBirth;
					stab.deathDate     = data.data.deceasedData.dateOfDeath;
					stab.mainPhoto     = data.data["photo-lapide"];
					stab.azienda       = null;
					stab.time          = data.data.deceasedData.time;
					stab.graveyardName = data.data.deceasedData.graveyardName;

					if (data.data.partner) {
						stab.azienda = data.data.partner.name;
					}

					const haveToPulse = !(
						stab.time
						&& moment(stab.time)
							.add(this.state.bookLimit || MEMORIAL_BOOK_DELAY, "days")
							.toDate()
							.getTime() < new Date().getTime()
					);

					this.setState({ haveToPulse });
					stab.firstPhrases = data.data.deceasedData.aphorism;
					regionName        = data.data.deceasedData.graveRegion;
					if (data.data.deceasedData.graveyardName) stab.cimitery = data.data.deceasedData.graveyardName;
					else stab.cimitery = "";
					stab.dedics = [];
					images      = data.data.deceasedData.photo || [];
					data.data.dedic.map((e, i) => {
						let newDedic;
						if (e.status === "accepted") {
							newDedic = {
								type   : dedicsTypeArray.indexOf(e.type),
								date   : dateToString(moment(e.time).toDate(), true),
								src    : e.media && e.media.filename,
								status : e.status,
								label  : e.text,
								sign   : e.showAuthor != 0 ? e.showAuthor : "",
							};
						} else {
							newDedic = {
								type   : e.type === "dedica-cero" ? 1 : parseInt(0),
								date   : dateToString(moment(e.time).toDate(), true),
								src    : e.media && e.media.filename,
								status : e.status,
								label  : " ",
								sign   : "",
							};
						}
						stab.dedics.unshift(newDedic);
					});
					if (data.data.memorial) {
						if (
							moment(data.data?.memorial?.expirationDate).toDate().getTime()
							< moment(new Date()).toDate().getTime()
						) {
 this.setState({
 	expired        : true,
 	expirationDate : moment(data.data?.memorial?.expirationDate).format("DD/MM/YYYY"),
 });
						}
						stab.bio = data.data.memorial.bio || "";
						images   = data.data.memorialMedia;
					} else stab.bio = "";
					addressServiceGetData("region").then((region) => {
						addressServiceGetData("province", region).then((province) => {
							// stab.cimitery = data.data.deceasedData.graveAddress
							this.setState({ loaded: true });
						});
					});
				} else {
					this.setState({ loaded: true });
					alert(`Defunto con id ${deceasedID} non trovato, verra\` reinderizzatto alla home`);
					this.props.history.push("./home");
				}
			});
		} else this.setState({ loaded: true });
	}

	isScrolledIntoView(el, i) {
		if (el) {
			const rect       = el.getBoundingClientRect();
			const elemTop    = rect.top;
			const elemBottom = rect.bottom;
			const condition  = elemTop >= 100 && elemBottom <= 500;
			const isVisible  = condition;
			return isVisible;
		}
		return null;
	}

	render() {
		if (!images) images = stab && stab.photos;
		let bookImage = book;
		if (this.state.haveToPulse) bookImage = bookgif;
		return (
			<>
				{this.state.showLampadePopup && (
					<LampadePopup
						lampadaData = {this.state.lampadaDataState}
						lampadeCollegate = {this.state.lampadeCollegateState}
						nomeDefunto = {this.state.showLampadePopupNome}
						close={() => this.setState({ showLampadePopup: null })}
					/>
				)}

				{this.state.loaded ? (
					<div className="memorial-page-main-div">
						<div className="memorial-page-marble mb-20">
							<div
								className={`memorial-page-container${photoClass}`}
								style={{
									display        : "flex",
									justifyContent : "center",
									alignItems     : "center",
									position       : !stab.hasPhoto && window.innerWidth >= 940 && "relative",
								}}
							>
								{/* Parte superiore mobile start */}
								{stab.thereIsBook && window.innerWidth < 940 && (
									<Link
										style={{
											flexDirection : "column",
											marginTop     : -10,
											position      : "absolute",
											right         : window.innerWidth >= 461 ? "-5px" : -6,
											top           : !stab.hasPhoto && window.innerWidth <= 550 ? 80 : 100,
										}}
										to={{ pathname: "/memorial-book", search: deceasedID }}
										className="memorial-book-div mobile scale075"
									>
										{window.innerWidth >= 461 && (
											<label className="memorial-book-title">Libro delle condoglianze</label>
										)}
										<img
											alt="book"
											style={{ width: 85 }}
											className="memorial-book"
											src={bookImage}
										/>
									</Link>
								)}
								{/* Parte superiore mobile end */}

								{stab.hasPhoto && (
									<img
										alt="caro"
										className={`memorial-page-photo${photoClass}`}
										src={stab.mainPhoto}
									/>
								)}

								<div
									className={stab.thereIsBook && "memorial-data-div"}
									style={{ marginLeft: !stab.hasPhoto && 0 }}
								>
									{stab.thereIsBook && window.innerWidth >= 940 && (
										<Link
											to={{ pathname: "/memorial-book", search: deceasedID }}
											className={`memorial-book-div desktop ${!stab.hasPhoto && "nophotofix"}`}
										>
											<label className="memorial-book-title">Libro delle condoglianze</label>
											<img alt="book" className="memorial-book" src={bookImage} />
										</Link>
									)}
									<div className="memorial-anagraphics">
										<label className="memorial-name-label">{`${stab.name} ${stab.surname}`}</label>
										{stab.thereIsBook && <p className="desktop">&nbsp;</p>}
										{stab.thereIsBook && window.innerWidth >= 940 && stab.hasPhoto ? (
											<div style={{ marginTop: 8, marginBottom: 8 }} className="flex-column">
												<label
													className="memorial-name-label font-size-25"
													style={{
														display        : "flex",
														justifyContent : "space-between",
														width          : 150,
														alignSelf      : "center",
													}}
												>
													<p
														style={{
															fontSize    : 28,
															marginRight : -7,
															marginLeft  : isSafari ? 3 : 1,
														}}
													>
														✶&nbsp;
													</p>
													<p>{stab.bornDate?.indexOf("-0") < 0 && stab.bornDate}</p>
												</label>

												<label
													className="memorial-name-label font-size-25"
													style={{
														display    : "flex",
														width      : 150,
														alignSelf  : "center",
														alignItems : "center",
													}}
												>
													<FontAwesomeIcon
														style={{
															fontSize     : 22,
															marginRight  : stab.deathDate?.indexOf("-0") < 0 ? 8 : 4,
															marginLeft   : 5,
															marginBottom : 1,
														}}
														icon={faCross}
													/>
													{stab.deathDate?.indexOf("-0") < 0 && stab.deathDate}
												</label>
											</div>
										) : (
											<label
												className="memorial-name-label font-size-25 noMax"
												style={{
													minWidth       : 280,
													margin         : "auto",
													maxWidth       : "unset",
													display        : "flex",
													justifyContent : "center",
												}}
											>
												<p
													style={{
														textAlign: "center",
													}}
												>
													✶&nbsp;
													{stab.bornDate?.indexOf("-0") < 0 && `${stab.bornDate}`}
												</p>
												&nbsp; &nbsp; &nbsp;
												<p
													style={{
														textAlign: "center",
													}}
												>
													<FontAwesomeIcon
														style={{
															fontSize     : 18,
															marginRight  : stab.deathDate?.indexOf("-0") < 0 ? 8 : 4,
															marginBottom : 1,
														}}
														icon={faCross}
													/>
													{stab.deathDate?.indexOf("-0") < 0 && stab.deathDate}
												</p>
											</label>
										)}
										{stab.thereIsBook && <p className="desktop">&nbsp;</p>}

										{/* <label className={'memorial-name-label font-size-19'}>{stab.graveyardName}</label> */}
										{stab.cimitery && (
											<label className="memorial-name-label font-size-19 cimitery mb-20">
												{stab.cimitery}
											</label>
										)}
										{/* {stab.cimitery && <p>&nbsp;</p>} */}
										{stab.azienda
											&& window.innerWidth >= 940
											&& (stab.hasPhoto ? (
												<Link to={stabLink} className="memorial-azienda">
													<p>Questo servizio è </p>
													<p>stato distribuito da</p>
													<p>{stab.azienda}</p>
												</Link>
											) : (
												<Link to={stabLink} className="memorial-azienda">
													<p>
														Questo servizio è stato distribuito da
														{` ${stab.azienda}`}
													</p>
												</Link>
											))}
									</div>
								</div>

								{lampadaTrovata && lampadaStato == 0 && (
									<Tooltip title="Lampada Votiva">
										<IconButton
											size="small"
											style={{ height: "min-content", padding: 20 }}
											onClick={e => {
												e.stopPropagation();
												if (userid == lampadaData.idGestore) { this.setState({ lampadaDataState: lampadaData, lampadeCollegateState: lampadeCollegate, showLampadePopupNome: `${stab.name} ${stab.surname}`, showLampadePopup: true }); }
											}}
											className="icon-button"
										>
										<img
											className="memorial-page-lamp-photo"
											style={{ marginLeft: 0 }}
											src={"/api/images/lamp-spenta.gif"}
										/>
										</IconButton>
									</Tooltip>
								)}
								{lampadaTrovata && lampadaStato == 1 && (lampadaModalita == 1 || lampadaModalita == 0) && (
									<Tooltip title="Lampada Votiva">
										<IconButton
											size="small"
											onClick={e => {
												e.stopPropagation();
												if (userid == lampadaData.idGestore) { this.setState({ lampadaDataState: lampadaData, lampadeCollegateState: lampadeCollegate, showLampadePopupNome: `${stab.name} ${stab.surname}`, showLampadePopup: true }); }
											}}
											className="icon-button"
										>
										<img
											className="memorial-page-lamp-photo"
											style={{ marginLeft: 0 }}
											src={"/api/images/lamp-accesa-sempre.gif"}
										/>
										</IconButton>
									</Tooltip>
								)}
								{lampadaTrovata && lampadaStato == 1 && lampadaModalita == 2 && (
									<Tooltip title="Lampada Votiva">
										<IconButton
											size="small"
											onClick={e => {
												e.stopPropagation();
												if (userid == lampadaData.idGestore) { this.setState({ lampadaDataState: lampadaData, lampadeCollegateState: lampadeCollegate, showLampadePopupNome: `${stab.name} ${stab.surname}`, showLampadePopup: true }); }
											}}
											className="icon-button"
										>
										<img
											className="memorial-page-lamp-photo"
											style={{ marginLeft: 0 }}
											src={"/api/images/lamp-accesa-fiamma.gif"}
										/>
										</IconButton>
									</Tooltip>
								)}
								{lampadaTrovata && lampadaStato == 1 && lampadaModalita == 3 && (
									<Tooltip title="Lampada Votiva">
										<IconButton
											size="small"
											onClick={e => {
												e.stopPropagation();
												if (userid == lampadaData.idGestore) { this.setState({ lampadaDataState: lampadaData, lampadeCollegateState: lampadeCollegate, showLampadePopupNome: `${stab.name} ${stab.surname}`, showLampadePopup: true }); }
											}}
											className="icon-button"
										>
										<img
											className="memorial-page-lamp-photo"
											style={{ marginLeft: 0 }}
											src={"/api/images/lamp-accesa-evanescenza.gif"}
										/>
										</IconButton>
									</Tooltip>
								)}
								{lampadaTrovata && lampadaStato > 1 && (
									<Tooltip title="Lampada Votiva">
										<IconButton
											size="small"
											onClick={e => {
												e.stopPropagation();
												if (userid == lampadaData.idGestore) { this.setState({ lampadaDataState: lampadaData, lampadeCollegateState: lampadeCollegate, showLampadePopupNome: `${stab.name} ${stab.surname}`, showLampadePopup: true }); }
											}}
											className="icon-button"
										>
										<img
											className="memorial-page-lamp-photo"
											style={{ marginLeft: 0 }}
											src={"/api/images/lamp-spenta.gif"}
										/>
										</IconButton>
									</Tooltip>
								)}
							</div>

							<label
								style={{ marginTop: 0 }}
								className="memorial-first-label"
								dangerouslySetInnerHTML={{ __html: stab.firstPhrases }}
							/>
							{stab.azienda && window.innerWidth < 940 && (
								<Link to={stabLink} className="memorial-azienda">
									<p>
										Questo servizio è stato distribuito da
										{` ${stab.azienda}`}
									</p>
								</Link>
							)}
							{stab.thereIsBook && window.innerWidth > 940 && (
								<Link
									to={{ pathname: "/memorial-book", search: deceasedID }}
									className="memorial-book-div mobile"
								>
									<label className="memorial-book-title">Libro delle condoglianze</label>
									<img alt="book" className="memorial-book" src={bookImage} />
									{stab.thereIsBook && <p>&nbsp;</p>}
								</Link>
							)}
						</div>

						<div className="memorial-page-container">
							{/* sezione memorial */}
							<div
								style={{
									display        : "flex",
									flexDirection  : "row",
									flexWrap       : "wrap",
									justifyContent : "center",
								}}
							>
								{window.innerWidth >= 600 && images && images.map((e, i) => this.RenderImages(e, i))}
								{window.innerWidth < 600
									&& images
									&& images.map((e, i) => i < 2 && this.RenderImages(e, i))}

								{window.innerWidth < 600 && (
									<Collapse in={this.state.morePhoto || false}>
										{" "}
										{images && images.map((e, i) => i >= 2 && this.RenderImages(e, i))}
									</Collapse>
								)}
								{window.innerWidth < 600 && images.length > 2 && (
									<label
										style={{
											display        : "flex",
											justifyContent : "center",
											alignContent   : "center",
											width          : "100%",
											alignItems     : "center",
											textAlign      : "center",
											color          : "#fa0",
										}}
										onClick={() => this.setState({ morePhoto: !this.state.morePhoto })}
									>
										{this.state.morePhoto ? "Visualizza meno foto" : "Visualizza altre foto"}
										{this.state.morePhoto ? <ArrowDropUp /> : <ArrowDropDown />}
									</label>
								)}
							</div>
							{stab.bio && (
								<div className="memorial-bio-div" style={{ position: "relative" }}>
									{this.state.expired && (
										<Chip
											label={`Memorial scaduto il ${this.state.expirationDate}`}
											style={{
												position        : "absolute",
												right           : window.innerWidth > 500 ? 20 : 10,
												top             : window.innerWidth > 500 ? 20 : 6,
												color           : "white",
												backgroundColor : "black",
											}}
										/>
									)}
									<label className="memorial-who">CHI ERA</label>
									<TooLongTextWrapper line={4}>
										<label
											className="memorial-bio"
											dangerouslySetInnerHTML={{ __html: stab.bio }}
										/>
									</TooLongTextWrapper>
								</div>
							)}
							{/* sezione dediche */}
							{stab.dedics && (
								<div style={{ marginTop: !stab.bio && 0 }} className="memorial-dedics">
									{stab.dedics.length > 0 && (
										<div className="memorial-dedics-header">
											<label className="memorial-remember-label"> LO RICORDANO COSI':</label>
										</div>
									)}
									{stab.dedics.map((e, index) => (
										<div key={e.date + index} className="memorial-dedic-content">
											<label className="memorial-dedic-date">{e.date}</label>
											{e.type < 2 ? (
												<img
													alt="dedic"
													className="memorial-dedic-image"
													src={dedicsImages[e.type]}
												/>
											) : e.type === 2 ? (
												<img
													alt="uploaded"
													className="memorial-dedic-uploaded-image"
													src={e.src}
												/>
											) : (
												<div style={{ margin: "0 auto" }}>
													<Player
														fluid={false}
														style={{ margin: "auto" }}
														height={
															window.innerWidth > 1000
																? 500
																: window.innerWidth > 600
																? 300
																: 140
														}
														playsInline
														poster="/assets/poster.png"
														src={e.src}
													>
														<BigPlayButton position="center" />
													</Player>
												</div>
											)}

											<TooLongTextWrapper line={4}>
												{e.status == "pending" && (
													<label className="memorial-bio">In attesa di accettazione</label>
												)}
												{e.label && (
													<label
														className="memorial-bio"
														dangerouslySetInnerHTML={{ __html: e.label }}
													/>
												)}
											</TooLongTextWrapper>
											{e.sign && <label className="memorial-bio text-end">{e.sign}</label>}
											<button
												className="activate-submit"
												style={{ height: 56, fontSize: 16, marginBottom: 12 }}
												onClick={() => {
													document
														.getElementById("Doni-scroll")
														.scrollIntoView({ behavior: "smooth" });
												}}
											>
												Lascia un pensiero anche tu
											</button>
											<div className="divider" />
										</div>
									))}
								</div>
							)}
						</div>
						<div id="Doni" className="text-container width100">
							{stab.dedics.length > 0 ? (
								<label id="Doni-scroll" className="memorial-buy-header">
									Anch'io voglio lasciare un pensiero
								</label>
							) : (
								<label className="memorial-buy-header">Sii il primo a lasciare un pensiero</label>
							)}
							<p className="buy-duty">Esegui 3 semplici passaggi:</p>

							<div className="buy-duty-buttons width700">
								<div className="buy-button-div">
									<button className="buy-duty-button">1</button>
									<label className="buy-button-bottom-label">
										Scegli tra una delle quattro opzioni
									</label>
								</div>
								<div className="buy-button-div">
									<button className="buy-duty-button">2</button>
									<label className="buy-button-bottom-label">
										Riempi i campi e scrivi la tua dedica
									</label>
								</div>
								<div className="buy-button-div">
									<button className="buy-duty-button">3</button>
									<label className="buy-button-bottom-label">
										Completa la procedura di acquisto su PayPal
									</label>
								</div>
							</div>
							<div className="card-row">
								{this.priceCard(
									this.state["dedica-cero"] || 2,
									"Dedica",
									"e cero",
									lumino,
									"dedica e cero",
									"dedica-cero",
								)}
								{this.priceCard(
									this.state["dedica-fiori"] || 3,
									"Dedica",
									"e fiori",
									fiori,
									"dedica e fiori",
									"dedica-fiori",
								)}
								{this.priceCard(
									this.state["dedica-foto"] || 4,

									"Dedica",
									"e foto",
									demo,
									"dedica e foto",
									"dedica-foto",
								)}
								{this.priceCard(
									this.state["dedica-video"] || 5,
									"Dedica",
									"e video",
									play,
									"dedica e video",
									"dedica-video",
								)}
							</div>
						</div>
						{this.videoImageViewer()}
					</div>
				) : (
					<div style={{ height: 800 }} />
				)}
			</>
		);
	}

	priceCard(price, title, subTitle, img, route, type) {
		subTitle = subTitle || "";
		return (
			<div className="pricing-card">
				<div className="inner-card">
					<img alt="dedic" className="inner-image" src={img} />
				</div>
				<div className="second-half-card">
					<div className="div-label-card">
						<label className="card-label">
							<p>
								<b>{title}</b>
								{" "}
								{` ${subTitle}`}
							</p>
						</label>
					</div>
					<label className="card-label-cost">al costo di:</label>
					<div className="pricing">
						<label className="price">
							€
							{parseFloat(price).toFixed(2).replace(".", ",")}
						</label>
						<Link
							to={{
								pathname : "/flowers",
								query    : {
									message : route,
									price,
									type,
									region  : regionName && getIdFromName(regionName),
									deceasedID,
								},
								search: `${type}&${deceasedID}`,
							}}
							className="add-item"
						>
							Aggiungi
						</Link>
					</div>
				</div>
			</div>
		);
	}

	RenderImages(e, i) {
		if (e.type === "photo") {
			return (
				<img
					className="memorial-person-image"
					style={{
						width:
							this.state.focusedImage === i && this.state.focusedImage !== this.state.blurImage
								? 320
								: 200,
						height:
							this.state.focusedImage === i && this.state.focusedImage !== this.state.blurImage
								? 320
								: 200,
					}}
					ref={(image) => (ImageRef[i] = image)}
					onClick={() => {
						this.setState({ lightBoxIndex: i, lightBox: true });
					}}
					src={e.file}
					alt={e.description}
					key={e.description + i}
				/>
			);
		}
		return (
			<div
				ref={(image) => (ImageRef[i] = image)}
				style={{
					width  : this.state.focusedImage === i ? 320 : 200,
					height : this.state.focusedImage === i ? 320 : 200,
					margin : window.innerWidth < 600 ? "auto" : "unset",
				}}
				key={`video${i}`}
				className="memorial-video-thumbnail"
			>
				<div
					style={{
						display: "none",
					}}
				>
					<Thumbnail
						snapshotAtTime={2}
						videoUrl={e.file}
						thumbnailHandler={(thumbnail) => this.setState({ thumbnailData: thumbnail })}
					/>
				</div>
				{!this.state.thumbnailData ? (
					<img
						style={{
							width     : 100,
							height    : 100,
							objectFit : "cover",
							padding   : 5,
							marginTop : 50,
						}}
						alt="placeholder"
						onClick={() => {
							this.setState({ lightBoxIndex: i, lightBox: true });
						}}
						src={play}
						className=""
					/>
				) : (
					<div
						style={{
							position  : "relative",
							width     : this.state.focusedImage === i ? 320 : 200,
							height    : this.state.focusedImage === i ? 320 : 200,
							alignSelf : "center",
						}}
					>
						<img
							alt="thumbnail"
							style={{
								width     : this.state.focusedImage === i ? 320 : 200,
								height    : this.state.focusedImage === i ? 320 : 200,
								objectFit : "cover",
								padding   : 5,
							}}
							onClick={() => {
								this.setState({ lightBoxIndex: i, lightBox: true });
							}}
							src={this.state.thumbnailData}
							className=""
						/>
						<img
							style={{
								left     : 10,
								right    : 10,
								top      : 10,
								bottom   : 10,
								margin   : "auto",
								position : "absolute",
								width    : "79px",
							}}
							alt="placeholder"
							onClick={() => {
								this.setState({
									lightBoxIndex : i,
									lightBox      : true,
								});
							}}
							src={play}
							className=""
						/>
					</div>
				)}
			</div>
		);
	}

	videoImageViewer() {
		if (this.state.lightBox) {
			const lightboxImages = [];
			images.forEach((e, i) => {
				lightboxImages.push({
					type   : e.type,
					url    : e.file,
					altTag : "memorial-photo",
				});
			});
			return (
				<div className="light-box">
					<ReactImageVideoLightbox
						data={lightboxImages}
						startIndex={this.state.lightBoxIndex}
						showResourceCount
						onCloseCallback={() => this.setState({ lightBox: false })}
					/>
				</div>
			);
		}
		return null;
	}
}
export default withContext(MemorialPage);
