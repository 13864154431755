import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from "@material-ui/core";
import {
	CreateOutlined,
	DeleteOutlined,
	VisibilityOutlined,
} from "@material-ui/icons";
import React from "react";
import moment from "moment";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import requests from '../../../Services/HttpServices';
import GeneralTable from "../../NewComponent/GeneralTable";
import Checkbox from "@material-ui/core/Checkbox";

let closeModalFixed = false;

export default function LampadePanelTableWrapper(props) {
	const filter = {
		serialeLampada  : "",
		idDefunto : "",
		statoLampada  : "",
		modalita  : "",
	};
	const tableHeaderName = [
		{
			id       : "selezione",
			maxWidth : 50,
			label    : " ",
			align    : "center",
		},
		{
			id       : "serialeLampada",
			maxWidth : 110,
			label    : "Seriale",
			align    : "center",
		},
		{
			id       : "nomeDefunto",
			label    : "Defunto",
			align    : "center",
		},
		{
			id       : "dataMorte",
			label    : "Morte",
			align    : "center",
		},
		{
			id       : "cimitero",
			label    : "Cimitero",
			align    : "center",
		},
		{
			id    : "nomeAbbonato",
			label : "Abbonato",
			align : "center",
		},
		{
			id    : "scadenza",
			maxWidth : 130,
			label : "Scadenza",
			align : "center",
		},
		{
			id       : "statoLampada",
			label    : "Stato",
			align    : "center",
		},
		{
			id    : "modalita",
			label : "Modalità",
			align : "center",
		},
		{
			id    : "azioni",
			maxWidth : 100,
			label : "Azioni",
			align : "center",
		},
	];
	return (
		<div>
			<a href="#" id="tempLink" style={{ display: "none" }} target="_blank" />
			<GeneralTable
				initOrder="live"
				partner
				type={props.type}
				filter={filter}
				deleteAllOperation={props.deleteAllOperation}
				setChecked={props.setChecked}
				setFilter={props.setFilter}
				setNumber={() => {
				}}
				setAllNumber={() => {
				}}
				headData={tableHeaderName}
				onRowClick={props.onRowClick}
				clickable
				data={props.data}
				actionData={(
					<div>
						<CreateOutlined fontSize="small" className="dash-icon" />
						<VisibilityOutlined fontSize="small" className="dash-icon" />
						<DeleteOutlined fontSize="small" className="dash-icon" />
					</div>
				)}
			/>
		</div>
	);
}

export function createLampadeDataPartnerTable(lampadaData, setState) {

	//Lampada
	let serialeLampada = lampadaData.serialeLampada;
	let statoLampada = "❗ In anomalia";
  	if (lampadaData.statoLampadaEffettivo == 1) { statoLampada = "✅ Accesa"; }
  	if (lampadaData.statoLampadaEffettivo == 0) { statoLampada = "❌ Spenta"; }
	let modalita = "Spenta";
	if (lampadaData.statoLampadaImpostato == 1) { modalita = "Sempre Accesa"; }
	if (lampadaData.statoLampadaImpostato == 2) { modalita = "Effetto Fiamma"; }
	if (lampadaData.statoLampadaImpostato == 3) { modalita = "Evanescenza"; }
	let scadenza = moment(lampadaData.dataTermineAccensione).format("DD/MM/YYYY");

	//Defunto
	let nomeDefunto = lampadaData.defunto.name + " " + lampadaData.defunto.surname;
	let dataMorte = lampadaData.defunto.dateOfDeath === "0000-00-00" || !lampadaData.defunto.dateOfDeath ? "Non disponibile" : lampadaData.defunto.dateOfDeath;
	let cimitero = lampadaData.defunto.graveLocation + " (" + lampadaData.defunto.graveProvince + ")";
	
	//Abbonamento
	let nomeAbbonato = "Nessuno";
	if (lampadaData.abbonato != null) { nomeAbbonato = lampadaData.abbonato.nome + " " + lampadaData.abbonato.cognome; }
	

	//Selezione
	const selezione = (
		<div>
			<Checkbox color="primary" name="selezione" value={1} />
		</div>
	);

	//Azioni
	const azioni = (
		<div>
			<Tooltip title="Gestisci Lampada">
				<IconButton
					size="small"
					onClick={e => {
						e.stopPropagation();
						const bodydata = {};
  						bodydata["seriale"] = parseInt(lampadaData.serialeLampada, 10);
  						requests("post", "lampadeCollegateLoad", bodydata).then((data) => {
							if (data?.result === "success") {
      							setState({ lampadaDataState: lampadaData, lampadeCollegateState: data?.lampadeCollegate, showLampadePopupNome: nomeDefunto, showLampadePopup: true })
    						}
							else {
								setState({ lampadaDataState: lampadaData, lampadeCollegateState: [], showLampadePopupNome: nomeDefunto, showLampadePopup: true })
							}
						});
					}}
					className="icon-button"
				>
				<img
    				id="lamp-status"
    				src={`/api/images/lamp-${lampadaData.statoLampadaEffettivo == 1 ? 'accesa' : lampadaData.statoLampadaEffettivo == 0 ? 'spenta' : 'anomalia'}.png`}
    				style={{ height: 38 }}
   					className="dash-icon"
  				/>
				</IconButton>
			</Tooltip>

			{lampadaData.abbonato != null && (
				<Tooltip title="Gestisci Abbonato">
					<IconButton
						size="small"
						onClick={e => {
							e.stopPropagation();
							const bodydata = {};
  							bodydata["seriale"] = parseInt(lampadaData.serialeLampada, 10);
							bodydata["abbonato"] = parseInt(lampadaData.abbonato.idAbbonato, 10);
  							requests("post", "lampadaPagamenti", bodydata).then((data) => {
								if (data?.result === "success") {
									setState({ abbonatoDataState: lampadaData.abbonato, serialeLampadaState: lampadaData.serialeLampada, lampadaTermineState: lampadaData.dataTermineAccensione, storicoPagamentiState: data?.lampadaPagamenti, showAbbonatoPopup: true })
    							}
								else {
									setState({ abbonatoDataState: lampadaData.abbonato, serialeLampadaState: lampadaData.serialeLampada, lampadaTermineState: lampadaData.dataTermineAccensione, storicoPagamentiState: [], showAbbonatoPopup: true })
								}
							});
						}}
						className="icon-button"
					>
					<AccountCircleIcon style={{ fontSize: 24 }} />
					</IconButton>
				</Tooltip>
			)}
		</div>
	);

	return {
		selezione,
		serialeLampada,
		nomeDefunto,
		dataMorte,
		cimitero,
		nomeAbbonato,
		scadenza,
		statoLampada,
		modalita,
		azioni
	};
}