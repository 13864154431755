/* eslint-disable no-shadow,react/no-access-state-in-setstate,class-methods-use-this,jsx-a11y/click-events-have-key-events,jsx-a11y/label-has-associated-control,jsx-a11y/no-noninteractive-element-interactions */
import React, { Component, Fragment } from "react";
import { Redirect } from "react-router";
import { Spinner } from "react-activity";
import { Collapse } from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import ReactImageVideoLightbox from "react-image-video-lightbox";
import "./Partner-page.css";
import Thumbnail from "react-video-thumbnail";
import Azienda from "../../assets/Stab/Azienda";
import play from "../../assets/imgStore/play.png";
import requests from "../../Services/HttpServices";
import { getNameFromID, getNameFromIDForAllType } from "../memorial-page/regioni";
import addressServiceGetData from "../FormComponents/addressService";
import PartnerNotFound from "./Partner-not-found";
import withContext from "../../WithContextFile";
import PartnerPageSocials from "../FormComponents/PartnerPageSocials";

let images        = [];
let partnerSite;
let azienda       = Azienda;
let partnerId;
const centerStyle = {
	position  : "absolute",
	left      : "50%",
	transform : " translate(-50%,-200px)",
};

class PartnerPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			radioValue    : "",
			lightBoxIndex : 0,
			lightBox      : false,
			gallery       : false,
		};
		if (this.props.data) {
			azienda = this.props.data;
		}

		azienda.photos.map((e, i) => {
			images.push(e);
			images.push(e);
			images.push(e);
		});
	}

	getPartnerSite(site) {
		let partnerSite;
		if (site.indexOf("http") < 0) {
			partnerSite = `https://${site}`;
		} else partnerSite = site;
		return partnerSite;
	}

	showSuccessTost() {
		this.setState({ successToastOpacity: 100 });
		setTimeout(() => {
			const interval = setInterval(() => {
				this.setState({
					successToastOpacity: this.state.successToastOpacity - 4,
				});
				if (this.state.successToastOpacity <= 0) clearInterval(interval);
			}, 25);
		}, 500);
	}

	componentDidMount() {
		if (this.props.history) {
			partnerId =				this.props.history.location.search
				&& this.props.history.location.search.slice(1, this.props.history.location.search.length);
		}
		if (this.props.partnerID) partnerId = this.props.partnerID;
		if (partnerId) {
			this.setState({ loading: true });
			requests("get", "getPartnerPage", partnerId)
				.then((result) => {
					if (result.data.response === "success") {
						this.setState({ found: true });
						azienda.sitoWeb     = result.data.data.website;
						azienda.nome        = result.data.data.name;
						azienda.tipologia   = result.data.data.type;
						azienda.descrizione = result.data.data.description;
						azienda.Email       = result.data.data.email;
						azienda.header      = result.data.data.headerText;
						azienda.logo        = result.data.data.logo;
						azienda.instagram   = result.data.data.instagram;
						azienda.facebook    = result.data.data.facebook;
						azienda.twitter     = result.data.data.twitter;
						azienda.linkedin    = result.data.data.linkedin;
						azienda.youtube     = result.data.data.youtube;
						if (result.data.data.address) {
							const addressData = result.data.data.address;
							if (addressData.nationID == "105") {
								addressServiceGetData("province", addressData.region).then((data) => {
									addressServiceGetData("location", addressData.province).then((data2) => {
										azienda.route = `${addressData.address} ${
											addressData.number
										} ${getNameFromIDForAllType(
											addressData.location,
											data2,
										)} ${addressData.cap} (${getNameFromIDForAllType(addressData.province, data)})  ${getNameFromID(
											addressData.region,
										)}`;
										this.setState({ load: !this.state.load });
									});
								});
							} else {
								azienda.route = addressData.completeAddress;
							}
						}
						azienda.PI        = result.data.data.VAT;
						azienda.CF        = result.data.data.CF || result.data.data.VAT;
						azienda.cellulare = result.data.data.mobile;
						azienda.telefono  = result.data.data.telephone;
						images            = [];
						result.data.data.media.map((e, i) => {
							const immagine = {
								url    : e.url,
								altTag : "photo",
								type   : e.type == "image" ? "photo" : "video",
							};
							e.type == "image" ? images.push(immagine) : images.unshift(immagine);
						});
						// images = result.data.data.media
						this.setState({ load: !this.state.load });
					}
				})
				.finally(() => {
					this.setState({ loading: false });
				});
		}
	}

	render() {
		return !(!this.state.found && partnerId) ? (
			<>
				<div className="contact-container bg-white">
					{!this.state.loading ? (
						<div className="partner-page-container">
							<label className="partner-page-title">{azienda.nome}</label>
							<label className="partner-page-title partner-page-subtitle">{azienda.tipologia}</label>
							{azienda.logo && azienda.logo !== "https://digitalmemorial.dlgtek.com/api/" && (
								<img
									style={{
										width         : 300,
										marginBottom  : 40,
										paddingBottom : 20,
										alignSelf     : "center",
									}}
									alt="logo"
									src={azienda.logo}
								/>
							)}
							<div
								style={{
									overflowX : !this.state.gallery && "hidden",
									padding   : !this.state.gallery && "0",
								}}
							>
								<div
									style={{
										display        : "flex",
										flexDirection  : "row",
										flexWrap       : "wrap",
										justifyContent : "center",
										paddingLeft    : 0,
										maxWidth       : "100%",
									}}
								>
									<div
										style={{
											height   : !this.state.gallery && 20,
											maxWidth : "100%",
										}}
									>
										{!this.state.gallery ? (
											<label
												className="readMore"
												style={{
													justifyContent : "center",
													display        : "flex",
													alignItems     : "center",
													...centerStyle,
													transform      : " translate(-50%,-50px)",
												}}
												onClick={() => this.setState({ gallery: !this.state.gallery })}
											>
												Visualizza informazioni
												<ArrowDropDown />
											</label>
										) : (
											<label
												className="readMore"
												style={{
													justifyContent : "center",
													display        : "flex",
													alignItems     : "center",
													...centerStyle,
													transform      : " translate(-50%,-50px)",
												}}
												onClick={() => this.setState({ gallery: !this.state.gallery })}
											>
												Riduci informazioni
												<ArrowDropUp />
											</label>
										)}
										<Collapse in={this.state.gallery}>
											{images[0] && images[0].type !== "photo" && (
												<div
													key={`video${0}`}
													className="memorial-video-thumbnail"
													style={{
														margin   : "auto",
														position : !this.state.gallery && "relative",
														// ...centerStyle,
													}}
												>
													<div
														style={{
															width   : 200,
															height  : 200,
															display : "none",
														}}
													>
														<Thumbnail
															snapshotAtTime={10}
															videoUrl={images[0] && images[0].url}
															thumbnailHandler={(thumbnail) => this.setState({ thumbnailData: thumbnail })}
														/>
													</div>
													{!this.state.thumbnailData && this.state.gallery ? (
														<img
															style={{
																width     : 100,
																height    : 100,
																objectFit : "cover",
																padding   : 5,
																marginTop : 50,
															}}
															alt="placeholder"
															onClick={() => {
																this.setState({
																	lightBoxIndex : 0,
																	lightBox      : true,
																});
															}}
															src={play}
															className=""
														/>
													) : (
														this.state.gallery && (
															<div style={{ position: "relative" }}>
																<img
																	style={{
																		width     : 200,
																		height    : 200,
																		objectFit : "cover",
																		padding   : 5,
																	}}
																	alt="thumbnail"
																	onClick={() => {
																		this.setState({
																			lightBoxIndex : 0,
																			lightBox      : true,
																		});
																	}}
																	src={this.state.thumbnailData}
																	className=""
																/>
																<img
																	style={{
																		left     : "30%",
																		right    : "50%",
																		top      : "30%",
																		bottom   : 0,
																		position : "absolute",
																		width    : "79px",
																	}}
																	alt="placeholder"
																	onClick={() => {
																		this.setState({
																			lightBoxIndex : 0,
																			lightBox      : true,
																		});
																	}}
																	src={play}
																	className=""
																/>
															</div>
														)
													)}
												</div>
											)}
											<div
												className="partner-foto-viewer"
												style={{
													overflowX : !this.state.gallery && "hidden",
													padding   : !this.state.gallery && "0",
												}}
											>
												<div
													className="partner-foto-container"
													style={{
														display       : "flex",
														flexDirection : "row",
														flexWrap      : "wrap",
														paddingLeft   :
															images?.length <= 4 && window.innerWidth > 600 && 0,
														justifyContent:
															images?.length <= 4 && window.innerWidth > 600 && "center",
														width: parseInt(images?.length / 2 + 0.9, 10) * 210 + 40,
													}}
												>
													{images.map((e, i) => {
														if (images[i].type === "photo") {
															return (
																<img
																	className="memorial-person-image img-contained"
																	onClick={() => {
																		this.setState({
																			lightBoxIndex : i,
																			lightBox      : true,
																		});
																	}}
																	key={e.altTag + i}
																	src={e?.url}
																	alt={e.altTag}
																/>
															);
														}
													})}
												</div>
											</div>
											<div
												dangerouslySetInnerHTML={{
													__html: azienda.descrizione,
												}}
											/>
										</Collapse>
									</div>
								</div>
							</div>
							&nbsp;
							<div className="divider" />
							<label className="partner-page-footer text-center uppercase">
								<p>{azienda.nome}</p>
								&nbsp;
								<p>Sede legale e operativa:</p>
								<p>{azienda.route}</p>
								<p>
									P.I.
									{" "}
									{azienda.PI}
									{" "}
									C.F.
									{" "}
									{azienda.CF}
								</p>
								&nbsp;
								{(azienda.telefono || azienda.cellulare) && (
									<p>
										{azienda.telefono && `Tel./Fax: ${azienda.telefono} `}
										{azienda.cellulare?.length > 6
											&& `Mobile: ${azienda.cellulare.replace("-", " ")}`}
									</p>
								)}
								<div style={{ display: "flex", flexDirection: "column" }}>
									{azienda.sitoWeb && (
										<label>
											{" "}
											Web site:
											{" "}
											<a
												className="lowercase"
												target="blank"
												href={this.getPartnerSite(azienda.sitoWeb)}
											>
												{" "}
												{azienda.sitoWeb}
											</a>
										</label>
									)}
									{azienda.Email && (
										<label>
											{" "}
											E-mail:
											{" "}
											<a className="lowercase" href={`mailto:${azienda.Email}`}>
												{azienda.Email}
											</a>
										</label>
									)}
								</div>
							</label>
							<PartnerPageSocials azienda={azienda} />
						</div>
					) : (
						<Spinner />
					)}
					&nbsp;
					{this.videoImageViewer()}
				</div>
			</>
		) : (
			<>
				{this.state.loading ? (
					<div style={{ minHeight: 700, fontSize: 30 }}>
						<Spinner />
					</div>
				) : (
					<PartnerNotFound />
				)}
			</>
		);
	}

	videoImageViewer() {
		if (this.state.lightBox) {
			return (
				<div className="light-box">
					<ReactImageVideoLightbox
						data={images}
						startIndex={this.state.lightBoxIndex}
						showResourceCount
						onCloseCallback={() => this.setState({ lightBox: false })}
					/>
				</div>
			);
		}
		return null;
	}
}

export default withContext(PartnerPage);
