import React from 'react';

const MAX_SIZE_FILE = 15000000;
const ACCEPTED_FORMAT = ['.png', '.jpeg', '.jpg', '.bmp', '.gif'];

function checkFormat(string) {
	let check = false;
	for (const i in ACCEPTED_FORMAT) {
		check = check || string.toLowerCase().includes(ACCEPTED_FORMAT[i].toLowerCase());
	}
	return check;
}

export default function FileUpload50(props) {
	return (
		<div>
			{new Array(50).fill(0).map((e, i) => {
				return (
					<input
						type="file"
						// value={this.state.files[i]}
						id={`${props.name}-file${i}`}
						name={`${props.name}-file${i}`}
						accept="image/png, image/jpeg, image/gif"
						style={{ display: 'none' }}
						onChange={e => {
							const check = checkFormat(e.target.files[0].name);
							if (e.target.files[0].size < MAX_SIZE_FILE && check) {
								const temp = props.files;
								temp.push({ file: e.target.files[0].name, id: i, image: e.target.files[0] });
								props.setFileIndex();
								props.setFile(temp);
							} else {
								if (!check) alert('Formato del file non valido');
								else alert('la dimensione massima supportata e` di 15MB');
								document.getElementById(`${props.name}-file${i}`).value = null;
							}
						}}
					/>
				);
			})}
		</div>
	);
}
