import React, { useEffect, useState } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from '@material-ui/core/TableBody';
import ReactTooltip from 'react-tooltip';
import { Switch, Tooltip } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import Checkbox from '@material-ui/core/Checkbox';
import './qrcoderequests.scss';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { SwapHorizontalCircle } from '@material-ui/icons';
import AbsoluteFooter from './AbsoluteFooter';
import RequestForm from './RequestForm';
import GiveProperty from '../PartnerPanelPages/givePropertyModal';
import requests from '../../../Services/HttpServices';
import DoYouWanToDelete from '../DoYouWanToDelete';

let tableHeaderName = [];
const rows = [
	{ name: 'asd', code: '11111111112', fat: 'asd' },
	{ name: 'asdd', code: '3333333333332', fat: 'asd' }
];
const checkStyle = { width: 10, marginLeft: 20 };
export default function QrCodeRequest(props) {
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);
	const [modal, setModal] = useState(false);
	const [selected, setSelected] = useState(false);
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('code');
	const [allChecked, setAllChecked] = useState(false);
	const [check, setCheck] = useState([]);
	const [num, setNumSelected] = useState(0);
	const [nameCheck, setNameCheck] = useState([]);
	const [code, setCodes] = useState([]);
	const [rows, setRows] = useState([]);
	const [modalClear, setModalClear] = useState(false);
	const [indexClicked, setIndexClicked] = useState(0);
	useEffect(() => {
		setRows(props.data);
	}, [props.data]);

	function deleteDeceased() {
		requests('post', 'deceasedDelete', {
			deceasedID: rows[indexClicked].qrcodeAttivazione
		}).then(result => {
			if (result.response === 'success') {
				window.location.reload();
			} else alert(result.message);
		});
	}
	function allCheckedHandler(checked) {
		if (checked) {
			rows &&
				rows.map((e, i) => {
					const temp = nameCheck;
					temp.push(e.qrcodeAttivazione);
					setNameCheck(temp);
				});
			setNumSelected(rows.length);
		} else {
			setNameCheck([]);
			setNumSelected(0);
		}

		setAllChecked(!allChecked);
	}

	tableHeaderName = [
		{
			label: (
				<p style={checkStyle}>
					<FormControlLabel
						checked={num == rows.length}
						control={
							<Checkbox
								color="primary"
								checked={num == rows.length}
								onChange={(event, checked) => {
									allCheckedHandler(checked);
								}}
							/>
						}
					/>
				</p>
			),
			id: 'checkbox',
			row: 'name',
			width: 50,
			align: 'left',
			disablePadding: true
		},
		{ label: 'QrCode', id: 'qrcode', row: 'qrcodeAttivazione', width: 300 },
		{ label: 'Codice di verifica', id: 'verify', width: 300 },
		{ label: 'Temporanei', id: 'temp', width: 300 },
		{ label: 'URL immagine', id: 'url', width: 300 },
		{ label: 'Azioni', id: 'action', width: 100 }
	];
	const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
	const checks = check;
	return (
		<div>
			<RequestForm
				handlerData={data => {
					rows.unshift(data);
					setPage(0);
				}}
			/>
			{modal && (
				<GiveProperty
					close={() => {
						setModal(false);
					}}
					type="qrCode"
					selectedQr={selected}
				/>
			)}
			{modalClear && (
				<DoYouWanToDelete
					close={() => {
						setModalClear(false);
					}}
					submit={() => deleteDeceased()}
					text=" il qrCode selezionato"
				/>
			)}
			<div
				style={{
					position: 'relative',
					maxWidth: 1200,
					padding: 0,
					backgroundColor: 'white',
					paddingTop: 0
				}}
			>
				<div style={{ position: 'relative', maxWidth: 1200, paddingTop: 50 }}>
					<TableContainer component={Paper}>
						<Table className="admin-table2" size="small" aria-label="custom pagination table">
							<TableHead>
								<TableRow>
									{tableHeaderName.map((headCell, i) => (
										<TableCell
											style={{
												textAlign: headCell.align || 'center',
												maxWidth: headCell.width,
												width: headCell.width
											}}
											padding={headCell.disablePadding ? 'none' : 'default'}
											sortDirection={
												orderBy === headCell.row && headCell.row === 'fat' ? order : false
											}
										>
											{headCell.row === 'fat' ? (
												<TableSortLabel
													active={orderBy === 'fat' && headCell.row === 'fat'}
													direction={
														this.state.orderBy === headCell.row && headCell.row === 'fat'
															? order
															: 'asc'
													}
													onClick={
														headCell.row === 'fat' && this.createSortHandler(headCell.row)
													}
												>
													{headCell.label}
													{orderBy === headCell.row && headCell.row === 'fat' ? (
														<span style={{ display: 'none' }}>
															{order === 'desc'
																? 'sorted descending'
																: 'sorted ascending'}
														</span>
													) : null}
												</TableSortLabel>
											) : (
												headCell.label
											)}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{(rowsPerPage > 0
									? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									: rows
								).map((row, index) => (
									<TableRow key={row.qrcodeAttivazione + index + Math.random()}>
										<TableCell
											padding="none"
											style={{
												textAlign: 'left',
												maxWidth: tableHeaderName[0].width,
												width: tableHeaderName[0].width
											}}
											scope="row"
										>
											<p style={checkStyle}>
												<FormControlLabel
													checked={nameCheck.indexOf(row.qrcodeAttivazione) >= 0}
													control={
														<Checkbox
															color="primary"
															checked={nameCheck.indexOf(row.qrcodeAttivazione) >= 0}
															onChange={(event, checked) => {
																if (checked) {
																	setNumSelected(num + 1);
																	const temp = nameCheck;
																	temp.push(row.qrcodeAttivazione);
																	const temp2 = code;
																	temp2.push(row.qrcodeAttivazione);
																	setCodes(temp2);
																	setNameCheck(temp);
																} else {
																	const temp = nameCheck;
																	const temp2 = code;
																	temp2.splice(
																		nameCheck.indexOf(row.qrcodeAttivazione),
																		1
																	);
																	temp.splice(
																		nameCheck.indexOf(row.qrcodeAttivazione),
																		1
																	);
																	setNumSelected(num - 1);
																}
															}}
														/>
													}
												/>
											</p>
										</TableCell>
										<TableCell
											style={{
												textAlign: 'center',
												width: tableHeaderName[1].width,
												maxWidth: tableHeaderName[1].width,
												position: 'relative'
											}}
											align="center"
										>
											{row.qrcodeAttivazione}{' '}
											{row.pending == '1' && <div className="qrchip">In accettazione</div>}
										</TableCell>
										<TableCell
											style={{
												textAlign: 'center',
												width: tableHeaderName[2].width,
												maxWidth: tableHeaderName[2].width
											}}
											align="right"
										>
											{row.city}
										</TableCell>
										<TableCell>
											<Switch
												defaultChecked={row.contoVendita === 'Si'}
												onChange={(event, checked) => {
													requests('post', 'updateConto', { deceasedID: row.ID }).then(
														response => {
															props.updateData && props.updateData(row.ID, checked);
														}
													);
												}}
											/>
										</TableCell>
										<TableCell
											style={{
												textAlign: 'center',
												width: tableHeaderName[3].width,
												maxWidth: tableHeaderName[3].width
											}}
											align="right"
										>
											<a href={row.link} target="_blank">
												{' '}
												Visualizza qr-code
											</a>
										</TableCell>
										<TableCell
											style={{
												textAlign: 'center',
												width: tableHeaderName[4].width,
												maxWidth: tableHeaderName[4].width
											}}
											align="right"
										>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
													textAlign: 'center',
													alignContent: 'center',
													width: 'auto',
													alignSelf: 'center'
												}}
											>
												<div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
													<ReactTooltip />
													<Tooltip title="Cedi qr-code">
														<SwapHorizontalCircle
															onClick={() => {
																const temp = [];
																temp.push(row.qrcodeAttivazione);
																setSelected(temp);
																setModal(true);
															}}
															style={{
																color: 'grey',
																cursor: 'pointer'
															}}
														/>
													</Tooltip>
												</div>
											</div>
										</TableCell>
									</TableRow>
								))}

								{emptyRows > 0 && (
									<TableRow style={{ height: 42.8 * emptyRows }}>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
							<TableFooter>
								<TableRow>
									<TablePagination
										style={{ maxWidth: 520 }}
										rowsPerPageOptions={[5, 10, 25]}
										colSpan={3}
										count={rows.length}
										rowsPerPage={rowsPerPage}
										className="pagination-mobile"
										page={page}
										onChangeRowsPerPage={event => setRowsPerPage(event.target.value)}
										onChangePage={(event, newPage) => setPage(newPage)}
										// ActionsComponent={TablePaginationActions}
									/>
								</TableRow>
							</TableFooter>
						</Table>
					</TableContainer>
					{num > 0 && (
						<div style={{ width: 50 }}>
							<AbsoluteFooter
								openModal={() => {
									setSelected(nameCheck);
									setModal(true);
								}}
								num={num}
								id={code}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map(el => el[0]);
}
