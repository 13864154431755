import React, { useState } from "react";
import "../../FormComponents/Modal.scss";
import { Typography } from "@material-ui/core";
import requests from "../../../Services/HttpServices";
import FeedbackModal from "../../FormComponents/FeedbackModal";

export default function ChangeAreYouSure(props) {
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [modalText, setModalText] = useState(false);
  const [modalResult, setModalResult] = useState(false);
  let close = true;
  return (
    <div
      className="modal-bg"
      onClick={() => {
        close ? props.closeHandler() : (close = !close);
      }}
    >
      <div
        className="inner"
        onClick={() => {
          close = false;
        }}
      >
        {feedbackModal && (
          <FeedbackModal
            result={modalResult}
            close={() => {
              setFeedbackModal(false);
              props.closeHandler();
            }}
            label={modalText}
          />
        )}
        <Typography style={{ fontSize: 17 }}>Attenzione </Typography>
        <Typography style={{ fontSize: 12, marginTop: 15 }}>
          Il caro selezionato è stato già trasferito e sta aspettando la
          risposta del ricevente, desidera procedere lo stesso?
        </Typography>
        <div className="submit-container">
          <div
            onClick={() => {
              props.closeHandler();
            }}
          >
            No
          </div>
          <div
            onClick={() => {
              const bodydata = {};
              bodydata["deceased-id"] = props.data["deceased-id"];
              bodydata["new-owner-id"] = props.data["new-owner-id"];
              bodydata["change-type"] = props.data["change-type"];
              bodydata.force = 1;
              requests("post", "changeProperty", bodydata)
                .then((result) => {
                  if (result.response === "success") {
                    sessionStorage.setItem(
                      "User",
                      JSON.stringify(result.session)
                    );
                    setFeedbackModal(true);
                    setModalResult("Successo");
                    setModalText(
                      "La richiesta di trasferimento è stata inviata con successo"
                    );
                  } else {
                    setFeedbackModal(true);
                    setModalResult("Errore");
                    setModalText(result.message);
                  }
                })
                .catch((err) => {
                  console.log({ err });
                });
            }}
          >
            Si
          </div>
        </div>
      </div>
    </div>
  );
}
