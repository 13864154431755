import { Close } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import moment from "moment";
import requests, { baseURL } from "../../Services/HttpServices";
import AreYouSureModalGeneral from "./AreYouSureModalGeneral";

export default function AlertModal(props) {
  const [data, setData] = useState(null);
  const [price, setPrice] = useState(null);
  const [choicheModal, setChoicheModal] = useState(false);
  useEffect(() => {
    setData(props.data);
  }, [props.data]);
  useEffect(() => {
    requests("get", "getProductPrice", "servizio-memorial").then((data) => {
      if (data.data.product_details) {
        setPrice(data.data.product_details.price);
      }
    });
  }, []);

  function singleEntry(option) {
    return (
      <div className="entry-container">
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            style={{ width: 50, height: 50 }}
            alt="file-not-found"
            src={
              option.deceasedLapide ||
              "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
            }
          />
          &nbsp;
          <div className="flex-column flex">
            <label>{`${option.deceasedName} ${option.deceasedSurname}`}</label>
            {option.expirationDate && (
              <label style={{ opacity: "0.7", fontSize: 13 }}>
                {"Data di scadenza: "}
                {moment(parseInt(option.expirationDate, 10) * 1000).format(
                  "DD/MM/YYYY"
                )}
              </label>
            )}
          </div>
        </div>
        <div
          className="tocenter"
          style={{ display: "flex", alignItems: "center", gap: 12 }}
        >
          <button
            className="modal-button"
            style={{ width: "auto" }}
            onClick={(e) => {
              e.preventDefault();
              window.location.href = `${baseURL}memorial-activate?deceasedSelected=${option.deceasedID}`;
              props.onClose();
            }}
          >
            <p>Paga</p>
            <p>
              {price
                ? parseFloat(price).toFixed(2)
                : parseFloat("6.0").toFixed(2)}
              €
            </p>
          </button>
          <button
            className="modal-button"
            style={{ width: "auto" }}
            onClick={(e) => {
              e.preventDefault();
              notNow(option.ID);
            }}
          >
            Non mostrare più
          </button>
        </div>
      </div>
    );
  }
  function notNow(id) {
    const bodyData = {
      memorialID: id,
    };
    let selectedData;
    if (props.data.length > 0) {
      selectedData = props.data.findIndex((el) => el.ID === id);
    }
    // eslint-disable-next-line no-shadow
    requests("post", "notNow", bodyData).then((data) => {
      if (props.setData) props.setData(selectedData);
    });
  }
  if (!data) return null;
  return (
    <div className="obscure" style={{ display: "flex", position: "fixed" }}>
      {choicheModal && (
        <AreYouSureModalGeneral closeHandler={() => setChoicheModal(false)} />
      )}
      <div className="alert-container">
        <div className="relative flex-column min-w-500-alert">
          {/* <Close className="close-icon" onClick={props.onClose} /> */}
          <p className="alert-title">Rinnovo servizio memorial</p>
          <p className="alert-text">
            Il sistema ha rilevato i seguenti memorial che stanno per scadere:
          </p>
          <div className="flex-column" style={{ gap: 12 }}>
            {data.map((e, i) => singleEntry(e))}
          </div>
          <button
            className="modal-button"
            style={{ marginLeft: "auto", width: "auto" }}
            onClick={(e) => {
              e.preventDefault();
              props.onClose();
            }}
          >
            Non adesso
          </button>
        </div>
      </div>
    </div>
  );
}
