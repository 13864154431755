import React, {useState} from "react";
import './Modal.scss'
import {Typography} from "@material-ui/core";
import requests from "../../Services/HttpServices";
import FeedbackModal from "./FeedbackModal";

export default function AreYouSure(props) {
    const [feedbackModal, setFeedbackModal] = useState(false)
    const [modalText, setModalText] = useState(false)
    const [modalResult, setModalResult] = useState(false)
    let close = true
    return (<div className={'modal-bg'} onClick={() => {
        close ? props.closeHandler() : close = !close
    }}>
        <div className={'inner'} onClick={() => {
            close = false
        }}>
            {feedbackModal && <FeedbackModal result={modalResult} close={() => {
                setFeedbackModal(false)
                props.closeHandler()
            }} label={modalText}/>}
            <Typography style={{fontSize: 17}}> Sei sicuro di voler cambiare preferenze contatto?</Typography>
            <Typography style={{fontSize: 12, marginTop: 15}}>Questo cambiamento verra` applicato alle preferenze dell'
                account</Typography>
            <div className={'submit-container'}>
                <div onClick={() => {
                    props.closeHandler()
                }}>
                    No
                </div>
                <div onClick={() => {
                    let bodydata = {}
                    bodydata['contact-preference'] = props.data
                    requests('post', 'changePreferece', bodydata).then((result) => {
                        props.handler(null, props.data)
                        if (result.response === 'success') {
                            sessionStorage.setItem('User', JSON.stringify(result.session));
                            setFeedbackModal(true);
                            setModalResult('Successo');
                            setModalText('Preferenza cambiata con successo');
                        } else {
                            setFeedbackModal(true);
                            setModalResult('Errore');
                            setModalText(result.message);
                        }
                    }).catch(err => {
                        console.log({err})
                    })

                }}>
                    Si
                </div>
            </div>
        </div>
    </div>)
}
