import TextField from "@material-ui/core/TextField";
import requests from "../../Services/HttpServices";
import React, {useState} from "react";
import validate from "../../formValidationService";
import PropTypes from 'prop-types';

function UsernameField(props) {
    let form = props.form
    let formError = props.formError
    const [userError, setUserError] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [timeout2, setTimeouts2] = useState(null)

    function setAndValidateForm(data, attribute, isNumeric, form, formError, minLenght, exact) {
        form[attribute] = data;
        formError = validate(data, attribute, formError, isNumeric)
        if (exact) if (form[attribute] && form[attribute].length !== exact) {
            formError[attribute] = true
        }
        if (minLenght) {
            if (form[attribute] && form[attribute].length < minLenght) {
                formError[attribute] = true
                setUserError('L\'username deve essere lungo almeno 6 caratteri')
            }
        }
        if (attribute === 'email' || attribute === 'phone') {
            formError.account = false
            if (form.email === '') formError.email = false
            if (form.phone === '' || !form.phone || (form.phone && form.phone.length > 3)) formError.phone = false
        }
        setLoaded(!loaded)
    }

    return <div style={{marginTop: '10px', marginBottom: 10, minWidth: '45%'}}>
        <TextField label={'Nome utente *'}
                   fullWidth
                   name={props.names + '-username'}
                   id={'username'}
                   style={{display: props.notusername && 'none'}}
                   value={form.username}
                   onChange={event => {
                       setUserError(null)
                       setAndValidateForm(event.target.value, 'username', false, form, formError, 6)
                       if (!formError.username) {
                           timeout2 && clearTimeout(timeout2)
                           setTimeouts2(setTimeout(() => {
                               requests('get', 'userExist', form.username).then(data => {
                                   if (data.data.result == false || data.data.result == 'fail' || data.data.response === 'fail') {
                                       setUserError(false)
                                       formError.username = form.username !== '' ? false : true
                                       props.load()
                                   } else {
                                       setUserError('Username non disponibile')
                                       formError.username = true
                                       // data.data.data.username && (props.openModalEmail(data.data.data.username))
                                       props.load()
                                   }

                               })
                           }, 600))
                       }
                   }}
                   error={userError || formError.username}
                   helperText={userError || (formError.username && 'Campo obbligatorio')}

        /></div>
}

UsernameField.propTypes = {
    form: PropTypes.object.isRequired,
    formError: PropTypes.object.isRequired,
    names: PropTypes.string.isRequired,
    load: PropTypes.func
}
export default UsernameField;
