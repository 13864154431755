import {Button, Typography} from "@material-ui/core";
import React from "react";

export default function ObituaryExistModal(props) {
    let close2 = true
    return (<div className={'modal-bg'} onClick={() => {
        if (close2) {
            props.close();
            // this.props.history.push('admin-panel')
        } else close2 = !close2
    }}>
        <div className={'inner'} onClick={() => {
            close2 = false
        }}>
            <Typography style={{fontSize: 17}}>Attenzione!!</Typography>
            <Typography style={{fontSize: 12, marginTop: 15}}>
                Abbiamo rilevato un necrologio con gli stessi dati desideri continuare con il caricamento?
            </Typography>
            <div style={{flexDirection: "row", display: "flex", justifyContent: "space-between"}}>
                <Button variant={"contained"} color={"primary"} onClick={() => {
                    props.accept()
                    props.close()

                }}>SI</Button>
                <Button color={"primary"} style={{marginLeft: 20}} onClick={() => {
                    props.close()
                    // this.props.history.push('admin-panel')
                }} variant={"contained"}>NO</Button>
            </div>
        </div>

    </div>)
}
