import React from "react";
import "./ObituaryNote.scss";

export default function ObituaryNote(props) {
  let close = true;
  return (
    <div
      className="obituary-main-container"
      onClick={() => {
        close ? props.close() : (close = true);
      }}
    >
      <div
        onClick={() => {
          close = false;
        }}
        className="obituary-card"
        style={{ maxWidth: 360 }}
      >
        <label>
          {props.isLapid ? "Info luogo di sepoltura" : "Info funerale"}
        </label>
        {(props.graveyardAddress || props.graveyardName) && (
          <label className="obituary-note-extra">
            {props.graveyardName && <p> {props.graveyardName}</p>}
            {props.graveyardAddress && <p> {props.graveyardAddress}</p>}
          </label>
        )}
        {props.note && props.note !== "NULL" && !props.isLapid && (
          <div dangerouslySetInnerHTML={{ __html: props.note }} />
        )}

        <div style={{alignSelf: "flex-end"}}>
          {props.vidCerimonia && props.vidCerimonia !== "NULL" && !props.isLapid && (
            <button 
              style={{width: "auto", marginRight: "5px"}}
              onClick={() => { window.open(props.vidCerimonia, "_blank"); }}
            >
              Apri Video Cerimonia</button>
          )}
          <button onClick={() => { props.close(); }}>Chiudi</button>
        </div>
      </div>
    </div>
  );
}
