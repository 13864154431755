import React from "react";
import './Modal.scss'
export default function PartnerAreUSure(props){
    return <div className={'modal-bg'}>
        <div className={'inner'}>
            <label className={'buy-form-paragraph'} style={{margin:'0 auto'}}>Attenzione</label>
            Non hai caricato immagini, sei sicuro di voler continuare?
            <div style={{display:"flex",justifyContent:"space-between"}}>
            <button onClick={()=>{props.close()}} className={'search-button'}>
                NO
            </button>
            <button onClick={()=>{props.submit()}} className={'search-button'}>
                SI
            </button>
            </div>
        </div>
    </div>
}
