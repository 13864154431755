/* eslint-disable eqeqeq,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/label-has-associated-control,react/no-access-state-in-setstate,react/sort-comp */
import React, { Component } from "react";
import "./AdminPanel.scss";
import {
	Button, CircularProgress, TextField, Typography,
} from "@material-ui/core";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import SportsKabaddiIcon from "@material-ui/icons/SportsKabaddi";
import CropFreeIcon from "@material-ui/icons/CropFree";
import CloseIcon from "@material-ui/icons/Close";
import {
	AddCircleOutlined,
	CropFreeTwoTone,
	ExpandLess,
	ExpandMore,
	SettingsRounded,
	FilterFrames,
	AssignmentInd,
	CreateOutlined,
	VisibilityOutlined,
	DeleteOutlined,
} from "@material-ui/icons";
import { BigPlayButton, Player } from "video-react";
import Collapse from "@material-ui/core/Collapse";
import { Spinner } from "react-activity";
import moment from "moment";
import AreYouSureGeneral from "../FormComponents/AreYouSureModalGeneral";
import AddMemorialAdmin from "./AdminPanelPages/AddMemorialAdmin";
import ModifyMemorialAdmin from "./AdminPanelPages/ModifyMemorialAdmin";
import dedics2 from "../../assets/Stab/Dedic request";
import acquisti from "../../assets/Stab/Acquisti";
import requests from "../../Services/HttpServices";
import GiveProperty from "./PartnerPanelPages/givePropertyModal";
import QrCodeRequest from "./QrCodeRequest/QrCodeRequest";
import AddDeceased from "./AdminPanelPages/AddDeceased";
import AddDeceasedPoster from "./PartnerPanelPages/AddDeceasedPoster";
import PartnerPagePanel from "../Partner-page/Partner-page-panel";
import ResetPasswordForm from "../Auth/Password-reset/ResetPasswordForm";
import ModifyAccount from "../Auth/ModifyAccount/ModifyAccount";
import ModifyObituaryData from "./ModifyObituaryData";
import ModifyDeceasedPage from "./ModifyDeceasedPage";
import DoYouWanToDelete from "./DoYouWanToDelete";
import SuccessToast from "./helperComponent/SuccessToast";
import ViewDedicList from "./AdminPanelPages/ViewDedicList";
import { setDataForControlPanel } from "../../Services/RandomUtils";
import withContext from "../../WithContextFile";
import AdminPanelTableWrapper, {
	createActivationDataTable,
	createDeceasedDataTable,
	createDedicDataTable,
	createObituaryDataTable,
	createPartnerDataTable,
} from "./AdminPanelPages/AdminPanelTableWrapper";
import FeedbackModal from "../FormComponents/FeedbackModal";
import PhoneNumber from "../FormComponents/PhoneNumber";
import HttpServices from "../../Services/HttpServices";

let notModal = false;

let activationStab = [];
let newRows        = [];
const dedicType    = ["Fiori", "Lume", "Foto", "Video", "QrCode", "Memorial"];
const pathNames    = [
	"qrcode",
	"dedic",
	"partners",
	"pages",
	"necrologi",
	"richiedi-qrcode",
	"aggiungi-defunto",
	"aggiungi-necrologi",
	"modify",
	"visualizza-dediche",
	"aggiungi-memorial",
	"modifica-memorial",
];
const subtitles    = ["attivazione", "dediche", "partner", "Gestione Lapidi Digitali"];
let aziendaData    = [];
let dataModal;
let purchase       = acquisti;
let dedics         = dedics2;
let obituary       = [];
let qrCodes        = [];
let pageError      = true;
let stopListen;
class AdminPanel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			type           : 0,
			page           : 0,
			rowsPerPage    : 5,
			partnermodal   : false,
			partnerRequest : false,
			dedicRequest   : false,
			allRequest     : false,
		};
	}

	componentDidMount() {
		stopListen = this.props.history.listen((location) => {
			if (this.props.history.action === "POP") {
				this.setState({ dedicModal: false, partnermodal: false });
			}
		});
		this.setState({ load: false });
		activationStab = [];
		purchase       = [];
		dedics         = [];
		aziendaData    = [];
		purchase       = [];
		obituary       = [];
		qrCodes        = [];
		HttpServices("get", "getManifestConfig").then((data) => {
			if (data.data.response === "success") {
				let number = "";
				if (data.data.data.manifestoWapp.indexOf("-") < 0) number = `+39-${data.data.data.manifestoWapp}`;
				else number = data.data.data.manifestoWapp;
				this.setState({
					manifestEmail  : data.data.data.manifestoEmail,
					manifestNumber : number,
				});
			}
		});
		const flag = !(
			window.location?.hash && pathNames.indexOf(window.location.hash.slice(1, window.location.hash.length)) === 0
		);
		this.dedicDataTable(true);
		this.getQrCode(null, null, flag);
		this.getPartner();
	}

	componentWillUnmount() {
		stopListen && stopListen();
	}

	createObituaryData(id, owner, _obituary, obituaryData) {
		return createObituaryDataTable(id, owner, _obituary, (data) => this.setState(data), obituaryData);
	}

	createDeceasedData(id, owner, deceased, qrcode, isMemorial, dedicNumber, deceasedData, index, enabled) {
		return createDeceasedDataTable(
			id,
			owner,
			deceased,
			qrcode,
			(data) => this.setState(data),
			isMemorial,
			dedicNumber || 0,
			this.props.history,
			deceasedData,
			(enable) => {
				newRows[index].enabled  = enable ? "1" : "0";
				purchase[index].enabled = enable ? "1" : "0";
				this.setState({ load: !this.state.load });
			},
			enabled,
			(enable) => {
				this.purchaseDataTable(true);
			},

			index,
			(enable) => {
				newRows[index].contoVendita  = enable ? "Si" : "No";
				purchase[index].contoVendita = enable ? "Si" : "No";
				this.setState({ load: !this.state.load });
			},
		);
	}

	// eslint-disable-next-line react/sort-comp
	createActivationData(id, name, address, aCode, qrCode, data) {
		return createActivationDataTable(id, name, address, aCode, qrCode, (state) => this.setState(state), data);
	}

	createPartnerData(id, partner, type, sdi, iva, partnerData) {
		return createPartnerDataTable(
			id,
			partner,
			type,
			sdi,
			iva,
			partnerData,
			(data) => this.setState(data),
			() => this.checkOperation(),
		);
	}

	createDedicData(id, name, deceasedData, dedic, time, token, dedicData) {
		return createDedicDataTable(
			id,
			name,
			deceasedData,
			dedic,
			time,
			token,
			dedicData,
			() => this.setState({
				alertGeneral         : true,
				alertGeneralText     : `Sei sicuro di voler accettare la richiesta dedica di ${name} ?`,
				dedicToEvaluate      : dedicData.dedic.ID,
				dedicTokenToEvaluate : dedicData.dedic.token,
				alertGeneralFunction : () => this.checkOperation(),
			}),
			() => this.setState({
				modalClear           : true,
				labelModalClear      : `la richiesta dedica dell'utente ${name} `,
				dedicToEvaluate      : dedicData.dedic.ID,
				dedicTokenToEvaluate : dedicData.dedic.token,
			}),
			() => {
				this.setState({
					dedicToShow          : dedicData,
					dedicModal           : true,
					dedicToEvaluate      : dedicData.dedic.ID,
					dedicTokenToEvaluate : dedicData.dedic.token,
				});
			},
		);
	}

	getPartner(value) {
		if (!this.state.partnerRequest) {
			this.setState({ partnerRequest: true });
			requests("get", "getPartner")
				.then((data) => {
					aziendaData = [];
					this.setState({ partnerRequest: false });
					aziendaData = data.data.partnerlist.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
					value && this.partnerDataTable();
					this.setState({ load: !this.state.load });
				})
				.catch((e) => {
					this.setState({ load: !this.state.load });
					// alert('errore sessione')
				});
		}
	}

	getQrCode(goto, pages, third) {
		if (!this.state.allRequest) {
			this.setState({ allRequest: true });
			!this.state.loadingData && this.setState({ loadingData: true });
			requests("get", "getActivationTable")
				.then((data) => {
					activationStab = [];
					purchase       = [];
					obituary       = [];
					qrCodes        = [];
					this.setState({ allRequest: false });
					if (data && data.data && data.data.qrcodelist) {
 data.data.qrcodelist.map((e, index) => {
 	const newData = setDataForControlPanel(e);
 	// eslint-disable-next-line no-unused-expressions
 	if (!(e.order?.status === "pending") || !e.order) {
								!e.deceased?.name
									? !(e.deceased.active == "0" && e.orderID == "0" && e.deceased.name == "")
									  && activationStab.push(newData)
									: e.deceased
									  && e.deceased.active == "1"
									  && e.deceased.showPage != "0"
									  && purchase.push(newData);
								e.deceased.showPage == "0" && obituary.push(newData);
								e.deceased?.active == "0"
									&& e.orderID == "0"
									&& e.verified == "0"
									&& e.deceased?.name == ""
									&& e.owner?.level_access == "1"
									&& qrCodes.push(newData);
 	} else console.log({ e });
 });
					}
					// activationStab = newData
					!goto && !pages && !third && this.activationDataTable();
					purchase.sort((a, b) => (a.deceasedName.toLowerCase() > b.deceasedName.toLowerCase() ? 1 : -1));
					obituary.sort((a, b) => (a.deceasedName.toLowerCase() > b.deceasedName.toLowerCase() ? 1 : -1));
					goto && this.obituaryDataTable();
					pages && this.purchaseDataTable();
					this.setState({ load: !this.state.load });
					this.setState({ loadingData: false });
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}

	dedicModal() {
		const { dedicToShow } = this.state;
		const isPhoto         = dedicToShow.dedic.type.indexOf("foto") > 0;
		const isVideo         = dedicToShow.dedic.type.indexOf("video") > 0;
		const ownerFullName   = dedicToShow.dedicOwner?.name
			? `${dedicToShow.dedicOwner?.name} ${dedicToShow.dedicOwner?.surname}`
			: "Sconosciuto";
		return (
			<div
				onClick={() => {
					if (!notModal) {
						this.setState({ dedicModal: false });
					} else {
						notModal = false;
					}
				}}
				className="admin-panel-modal"
			>
				<CloseIcon id="crossIcon" style={{ zIndex: 3000 }} />
				<div className="flex">
					<div
						style={{ marginBottom: 40 }}
						onClick={() => {
							notModal = true;
						}}
					>
						<div id="purchase-modal">
							<label id="title-modal">Dettagli dedica</label>
							<p className="death-data">Anagrafica</p>
							&nbsp;
							<p>
								Cliente:
								{ownerFullName}
							</p>
							&nbsp;
							{dedicToShow.dedicOwner?.username ? (
								<p>
									User:
									{dedicToShow.dedicOwner.username}
								</p>
							) : (
								<p>User: Sconosciuto</p>
							)}
							<p className="death-data">Dettagli dedica</p>
							&nbsp;
							<p>
								Tipologia dedica:
								{dedicToShow.dedic.type}
							</p>
							&nbsp;
							<p>Dedica:</p>
							<label
								dangerouslySetInnerHTML={{
									__html: dedicToShow.dedic.text,
								}}
							/>
							&nbsp;
							{isPhoto && dedicToShow.dedic.dedicMedia && (
								<div style={{ textAlign: "center" }}>
									Foto:
									<img alt={"dedicOwner}"} src={dedicToShow.dedic.dedicMedia.filename} />
								</div>
							)}
							{isVideo && dedicToShow.dedic.dedicMedia && (
								<Player
									on
									playsInline
									poster="/assets/poster.png"
									src={dedicToShow.dedic.dedicMedia.filename}
								>
									<BigPlayButton position="center" />
								</Player>
							)}
							<div className="bottom-modal-submit">
								<Button
									title="Accetta"
									className="button"
									variant="contained"
									onClick={() => {
										this.setState({ dedicModal: false });
										this.checkOperation(dataModal);
									}}
									style={{ color: "white" }}
									color="primary"
								>
									Accetta
								</Button>
								<Button
									title="Rifiuta"
									className="button"
									variant="contained"
									onClick={() => {
										this.setState({ dedicModal: false });
										this.clearOperationSendData(dataModal);
									}}
									style={{ color: "white" }}
									color="primary"
								>
									Rifiuta
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		let user;
		try {
			user = sessionStorage.getItem("User") && JSON.parse(sessionStorage.getItem("User")).userdata;
		} catch (e) {

		}
		if (!user) {
			window.location.href = "/home";
			pageError            = true;
		} else if (`${user.permissions.platformAccess}` === "2") {
			pageError            = true;
			window.location.href = "/partner-panel";
		} else if (`${user.permissions.platformAccess}` === "0") {
			pageError            = true;
			window.location.href = "/client-panel";
		} else if (window.location.hash) {
			pageError = false;

			const pathIndex = pathNames.indexOf(window.location.hash.slice(1, window.location.hash.length));
			if (pathIndex === -1) {
				window.history.pushState("object or string", "Title", "/admin-panel#qrcode");
				this.setState({ type: 0 });
				this.getQrCode(null, null, null);
			} else if (this.state.type !== pathIndex) {
				this.setState({ type: pathIndex });
				pathIndex === 0 && this.getQrCode(null, null, null);
				pathIndex === 4 && this.getQrCode(true);
				pathIndex === 3 && this.getQrCode(null, true);
				pathIndex === 1 && this.dedicDataTable();
				pathIndex === 2 && this.getPartner(true);
			}
		} else {
			pageError = false;
			window.history.pushState("object or string", "Title", "/admin-panel#qrcode");
			this.getQrCode(null, null, null);
		}

		if (pageError) return <></>;
		return (
			<>
				<SuccessToast label={this.state.toastLabel} successToastOpacity={this.state.successToastOpacity} />
				{this.state.feedbackModal && (
					<FeedbackModal
						result={this.state.feedbackModalResult}
						close={() => this.setState({ feedbackModal: false })}
						label={this.state.feedbackModalLabel}
					/>
				)}
				{this.state.modalLoading && (
					<div className="modal-bg">
						<div className="flex flex-column" style={{ alignItems: "center", justifyContent: "center" }}>
							<CircularProgress color="primary" />
							{" "}
							<Typography className="text-center" style={{ color: "white" }}>
								{this.state.loadingLabel}
							</Typography>
						</div>
					</div>
				)}
				<div className="bg-white dashboard">
					<div id="second-in">
						<div id="upper">
							<div id="card-row">
								{this.state.showQrCode && this.showQrCode()}
								<div
									id="card"
									onClick={() => {
										document.getElementById("card-row").scrollIntoView({ behavior: "smooth" });
										window.history.pushState("object or string", "Title", "/admin-panel#qrcode");
										this.activationDataTable();
										this.getQrCode();
										this.setState({ type: 0 });
									}}
									className="ml-0 card-fix"
								>
									<div>
										<label id="title-card">Gestione QrCode Venduti OnLine</label>
										<label>{(activationStab && activationStab.length) || 0}</label>
									</div>
									<div id="icon">
										<CropFreeIcon style={{ fontSize: 19 }} />
									</div>
								</div>
								<div
									id="card"
									className="card-fix"
									onClick={() => {
										document.getElementById("card-row").scrollIntoView({ behavior: "smooth" });
										window.history.pushState(
											"object or string",
											"Title",
											"/admin-panel#richiedi-qrcode",
										);
										// this.partnerDataTable();
										// this.getPartner(true);
										this.setState({ type: 5 });
									}}
								>
									<div>
										<label id="title-card">Gestione Nuovi QrCode</label>
										<label>{(qrCodes && qrCodes.length) || 0}</label>
									</div>
									<div id="icon" style={{ backgroundColor: "lightblue" }}>
										<CropFreeTwoTone style={{ fontSize: 20 }} />
										{" "}
									</div>
								</div>
								<div
									id="card"
									className="card-fix"
									onClick={() => {
										document.getElementById("card-row").scrollIntoView({ behavior: "smooth" });
										window.history.pushState("object or string", "Title", "/admin-panel#dedic");
										this.dedicDataTable2();

										this.dedicDataTable();
										this.setState({ type: 1 });
									}}
								>
									<div>
										<label id="title-card">Gestione Dediche</label>
										<label>{(dedics && dedics.length) || 0}</label>
									</div>
									<div id="icon" style={{ backgroundColor: "lightgreen" }}>
										<BorderColorIcon style={{ fontSize: 19 }} />
									</div>
								</div>
								<div
									id="card"
									className="card-fix"
									onClick={() => {
										document.getElementById("card-row").scrollIntoView({ behavior: "smooth" });
										window.history.pushState("object or string", "Title", "/admin-panel#necrologi");
										// this.partnerDataTable()
										this.obituaryDataTable();
										this.setState({ type: 4 });
									}}
								>
									<div>
										<label id="title-card">Gestione Necrologi</label>
										<label>{(obituary && obituary.length) || 0}</label>
									</div>
									<div id="icon" style={{ backgroundColor: "lightgrey" }}>
										<FilterFrames style={{ fontSize: 19 }} />
									</div>
								</div>
								<div
									id="card"
									className="card-fix"
									onClick={() => {
										document.getElementById("card-row").scrollIntoView({ behavior: "smooth" });
										window.history.pushState("object or string", "Title", "/admin-panel#partners");
										this.partnerDataTable();
										this.getPartner(true);
										this.setState({ type: 2 });
									}}
								>
									<div>
										<label id="title-card">Gestione Partner</label>
										<label>{(aziendaData && aziendaData.length) || 0}</label>
									</div>
									<div id="icon" style={{ backgroundColor: "lightblue" }}>
										<SportsKabaddiIcon style={{ fontSize: 20 }} />
									</div>
								</div>
								<div
									id="card"
									className="card-fix"
									onClick={() => {
										document.getElementById("card-row").scrollIntoView({ behavior: "smooth" });
										window.history.pushState("object or string", "Title", "/admin-panel#pages");
										this.purchaseDataTable();
										this.setState({ type: 3 });
									}}
								>
									<div>
										<label id="title-card">Gestione Lapidi Digitali</label>
										<label>{(purchase && purchase.length) || 0}</label>
									</div>
									<div id="icon" style={{ backgroundColor: "lightsalmon" }}>
										<AssignmentInd style={{ fontSize: 19 }} />
									</div>
								</div>
							</div>
						</div>
						<div id="content" style={{ position: "relative" }}>
							<label
								className={`admin-content-subtitle ${
									(this.state.type === 4 || this.state.type === 3) && " partner-title-80"
								}`}
							>
								{this.state.type < 3 && this.state.type !== 0 && "Gestione "}
								{this.state.type === 4 && "Gestione Necrologi"}
								{this.state.type === 5 && "Gestione nuovi qrCode"}
								{this.state.type === 6 && "Aggiungi Lapide Digitale"}
								{this.state.type === 7 && "Aggiungi Necrologio"}
								{this.state.type === 8 && "Gestisci account amministratore"}
								{this.state.type === 0 && "Gestione QrCode Venduti OnLine"}
								{this.state.type !== 0 && subtitles[this.state.type]}
							</label>
							{this.state.loadingData && (
								<div style={{ margin: "auto" }}>
									<Spinner />
								</div>
							)}
							{!this.state.loadingData && this.state.type <= 4 && this.newTable()}
							{this.state.type === 5 && (
								<QrCodeRequest
									data={qrCodes.sort((a, b) => (a.ID > b.ID ? 1 : -1))}
									updateData={(id, checked) => {
										const index                 = qrCodes.findIndex((e) => e.ID === id);
										qrCodes[index].contoVendita = checked ? "Si" : "No";
										this.setState({ load: !this.state.load });
									}}
								/>
							)}
							{this.state.type === 6 && <AddDeceased />}
							{this.state.type === 7 && <AddDeceasedPoster />}
							{this.state.type === 9 && <ViewDedicList deceasedID={this.state.dedicDeceasedID} />}
							{this.state.type === 10 && <AddMemorialAdmin deceasedID={this.state.dedicDeceasedID} />}
							{this.state.type === 11 && (
								<ModifyMemorialAdmin
									forceLoad={() => this.setState({ forceLoad: !this.state.forceLoad })}
									deceasedID={this.state.dedicDeceasedID}
								/>
							)}

							{this.state.type === 8 && (
								<div id="collapsable-container" style={{ display: "flex", flexDirection: "column" }}>
									<div
										onClick={() => {
											this.setState({
												collapse  : !this.state.collapse,
												collapse3 : false,
												collapse1 : false,
												collapse2 : false,
											});
										}}
										className="collapsable-list"
									>
										<div
											style={{
												display       : "flex",
												flexDirection : "row",
												margin        : "auto",
											}}
										>
											<label style={{ margin: "auto", paddingLeft: 24 }}> Cambia password</label>
											<div>{this.state.collapse ? <ExpandLess /> : <ExpandMore />}</div>
										</div>
									</div>

									<Collapse in={this.state.collapse} timeout="auto" unmountOnExit>
										<div style={{ display: "flex", justifyContent: "center" }}>
											<ResetPasswordForm />
										</div>
									</Collapse>

									<div
										onClick={() => {
											this.setState({
												collapse2 : !this.state.collapse2,
												collapse3 : false,
												collapse1 : false,
												collapse  : false,
											});
										}}
										className="collapsable-list"
									>
										<div
											style={{
												display       : "flex",
												flexDirection : "row",
												margin        : "auto",
											}}
										>
											<label style={{ margin: "auto", paddingLeft: 24 }}>
												{" "}
												Cambia dati account
											</label>
											<div>{this.state.collapse2 ? <ExpandLess /> : <ExpandMore />}</div>
										</div>
									</div>
									<Collapse in={this.state.collapse2} timeout="auto" unmountOnExit>
										<div style={{ display: "flex", justifyContent: "center" }}>
											<ModifyAccount />
										</div>
									</Collapse>
									<div
										onClick={() => {
											this.setState({
												collapse2 : false,
												collapse3 : !this.state.collapse3,
												collapse1 : false,
												collapse  : false,
											});
										}}
										className="collapsable-list"
									>
										<div
											style={{
												display       : "flex",
												flexDirection : "row",
												margin        : "auto",
											}}
										>
											<label style={{ margin: "auto", paddingLeft: 24 }}>
												Gestisci dati manifesto + lapide
											</label>
											<div>{this.state.collapse3 ? <ExpandLess /> : <ExpandMore />}</div>
										</div>
									</div>
									<Collapse in={this.state.collapse3} timeout="auto" unmountOnExit>
										<form
											onSubmit={(event) => {
												event.preventDefault();
												const formData = new FormData(event.target);
												const object   = {};
												// formData.set('editor', this.state.editor)
												formData.forEach((value, key) => {
													object[key] = value;
												});
												HttpServices("post", "setManifestConfig", object).then((response) => {
													if (response.response === "success") {
														this.setState({
															feedbackModalResult : "Successo",
															feedbackModal       : true,
															feedbackModalLabel  : "Configurazione aggiornata con successo",
														});
													}
												});
											}}
										>
											<div
												style={{
													display        : "flex",
													justifyContent : "center",
													alignItems     : "center",
													flexDirection  : "column",
													maxWidth       : 320,
													margin         : "auto",
												}}
											>
												<TextField
													style={{ maxWidth: 320, width: 320, marginBottom: 12 }}
													name="manifestoEmail"
													onChange={(ev) => this.setState({ manifestEmail: ev.target.value })}
													value={this.state.manifestEmail || ""}
													label="email"
												/>
												&nbsp;
												<PhoneNumber
													name="manifestoWapp"
													initValue={this.state.manifestNumber || ""}
												/>
											</div>
											<div
												className="field is-clearfix"
												style={{ margin: "auto", paddingBottom: 12 }}
											>
												<button className="button is-primary is-outlined is-pulled-right mt-16">
													Salva
												</button>
											</div>
										</form>
									</Collapse>
								</div>
							)}
							{this.state.type !== 8 && (
								<button
									color="primary"
									style={{ alignItems: "start" }}
									className="extra-button-main-style2 right search-button cool-effect"
									onClick={() => {
										window.history.pushState("object or string", "Title", "/admin-panel#modify");
										this.setState({ type: 8 });
									}}
								>
									<SettingsRounded className="extra-button-icon-style " />
									<p id="text" style={{ paddingTop: 2 }}>
										{" "}
										Gestisci dati account
									</p>
								</button>
							)}
							{this.state.type === 4 && (
								<button
									color="primary"
									className="extra-button-main-style left search-button "
									style={{ minWidth: "fit-content" }}
									onClick={() => {
										window.history.pushState(
											"object or string",
											"Title",
											"/admin-panel#aggiungi-necrologi",
										);
										this.setState({ type: 7 });
									}}
								>
									<AddCircleOutlined className="extra-button-icon-style" />
									Aggiungi necrologio
								</button>
							)}
							{this.state.type === 3 && (
								<button
									color="primary"
									variant="contained"
									style={{ minWidth: "fit-content" }}
									className="extra-button-main-style left search-button "
									onClick={() => {
										window.history.pushState(
											"object or string",
											"Title",
											"/admin-panel#aggiungi-defunto",
										);
										this.setState({ type: 6 });
									}}
								>
									<AddCircleOutlined className="extra-button-icon-style" />
									{" "}
									Aggiungi Lapide Digitale
								</button>
							)}
						</div>
					</div>
					{this.state.partnermodal && this.partnerModal()}
					{this.state.purchaseModal && this.purchaseModal()}
					{this.state.modalClear && (
						<DoYouWanToDelete
							close={() => {
								this.setState({ modalClear: false });
							}}
							submit={() => this.clearOperationSendData(this.state.modalClearIndex)}
							text={this.state.labelModalClear}
						/>
					)}
					{this.state.alertGeneral && (
						<AreYouSureGeneral
							text={this.state.alertGeneralText}
							closeHandler={() => this.setState({ alertGeneral: false })}
							accept={() => this.state.alertGeneralFunction()}
						/>
					)}
					{this.state.openModifyObituary && (
						<ModifyObituaryData
							data={this.state.obituaryModifyData}
							closeHandler={() => {
								this.setState({ openModifyObituary: false });
							}}
						/>
					)}
					{this.state.openModifyDeceased && (
						<ModifyDeceasedPage
							data={this.state.pageModifyData}
							closeHandler={() => {
								this.setState({ openModifyDeceased: false });
							}}
						/>
					)}
					{this.state.dedicModal && this.dedicModal()}
					{this.state.propertyModal && (
						<GiveProperty
							type="deceasedPage"
							deceasedID={this.state.selectedID}
							close={() => {
								this.setState({ propertyModal: false });
							}}
						/>
					)}
				</div>
			</>
		);
	}

	newTable() {
		return (
			<AdminPanelTableWrapper
				filter={{}}
				admin
				reload={() => {
					this.setState({ load: !this.state.load });
				}}
				history={this.props.history}
				deleteAllOperation={() => {
					alert("delete all");
				}}
				setChecked={(data) => {
					this.setState({ checked: data });
				}}
				setFilter={(data) => {
					this.setState({ filter: data });
				}}
				setNumber={() => {}}
				setAllNumber={() => {}}
				type={this.state.type}
				data={newRows}
				actionData={(
					<div>
						<CreateOutlined fontSize="small" className="dash-icon" />
						<VisibilityOutlined fontSize="small" className="dash-icon" />
						<DeleteOutlined fontSize="small" className="dash-icon" />
					</div>
				)}
			/>
		);
	}

	openModal(i) {
		dataModal = i + this.state.rowsPerPage * this.state.page;
		this.state.type === 3 && this.setState({ purchaseModal: true });
		this.state.type === 1 && this.setState({ dedicModal: true, dataModal: i });
	}

	showSuccessTost() {
		this.setState({ successToastOpacity: 100 });
		setTimeout(() => {
			const interval = setInterval(() => {
				this.setState({
					successToastOpacity: this.state.successToastOpacity - 4,
				});
				if (this.state.successToastOpacity <= 0) clearInterval(interval);
			}, 25);
		}, 500);
	}

	clearOperationSendData(i) {
		this.setState({ toastLabel: "cancellato" });
		if (this.state.type === 2) {
			requests("post", "denyPartner", { partnerid: this.state.partnerToDelete }).then((data) => {
				window.location.reload();
				this.showSuccessTost("delete");
			});
		}
		if (this.state.type === 4) {
			requests("post", "obituaryDelete", { obituaryID: this.state.obituaryToDelete }).then((result) => {
				if (result.response === "success") {
					this.getQrCode(true);
					this.showSuccessTost("delete");
				}
			});
		}
		if (this.state.type === 3) {
			requests("post", "deceasedDelete", { deceasedID: this.state.deceasedTableID }).then((result) => {
				if (result.response === "success") {
					this.getQrCode(null, true, null);
					this.showSuccessTost("delete");
				} else alert(result.message);
			});
		}
		if (this.state.type === 0) {
			this.deleteDeceased();
		}
		if (this.state.type === 1) {
			this.evaluateDedic("deny");
		}
	}

	evaluateDedic(type) {
		const bodyData = {
			isAccepted : type,
			token      : this.state.dedicTokenToEvaluate,
			dedic      : this.state.dedicToEvaluate,
		};
		requests("evaluatededic", null, bodyData).then((data) => {
			this.dedicDataTable();
			this.setState({ toastLabel: type === "deny" ? "rifiutato" : "accettato", alertGeneral: false });
			this.showSuccessTost(type === "deny" ? "delete" : null);
		});
	}

	deleteDeceased() {
		requests("post", "deceasedDelete", {
			deceasedID: this.state.deceasedToDelete,
		}).then((result) => {
			if (result.response === "success") {
				this.getQrCode();
				this.showSuccessTost("delete");
			} else alert(result.message);
		});
	}

	checkOperation(i) {
		if (this.state.type === 2) {
			requests("post", "acceptPartner", { partnerid: this.state.partnerToAccept }).then((data) => {
				window.location.reload();
			});
		}
		if (this.state.type === 1) {
			this.evaluateDedic("active");
		}
	}

	purchaseModal() {
		return (
			<div
				onClick={() => {
					if (!notModal) {
						this.setState({ purchaseModal: false });
					} else {
						notModal = false;
					}
				}}
				className="admin-panel-modal"
			>
				<CloseIcon id="crossIcon" style={{ zIndex: 3000 }} />
				<div className="flex">
					<div
						style={{ marginBottom: 40 }}
						onClick={() => {
							notModal = true;
						}}
					>
						<div id="purchase-modal">
							<label id="title-modal">Dettagli acquisto</label>
							<p className="death-data">Anagrafica</p>
							&nbsp;
							<p>
								Cliente:
								{`${this.state.deceasedToShow?.name} ${this.state.deceasedToShow?.surname}`}
							</p>
							&nbsp;
							<p>
								Numero di telefono:
								{this.state.deceasedToShow?.phoneNumber}
							</p>
							&nbsp;
							{this.state.deceasedToShow?.email && (
								<p>
									Email:
									{this.state.deceasedToShow?.email}
								</p>
							)}
							<p className="death-data">Indirizzo</p>
							&nbsp;
							<p>
								Citta:
								{" "}
								{`${this.state.deceasedToShow?.city} (${this.state.deceasedToShow?.province})`}
							</p>
							&nbsp;
							<p>
								Indirizzo:
								{" "}
								{`${this.state.deceasedToShow?.route} N.${this.state.deceasedToShow?.civic}`}
							</p>
							<p className="death-data">Dettagli acquisto</p>
							&nbsp;
							<p>
								Tipologia acquisto:
								{dedicType[this.state.deceasedToShow?.type]}
							</p>
							&nbsp;
							<p>
								Costo:
								{dedicType[this.state.deceasedToShow?.type]}
							</p>
							{this.state.deceasedToShow?.deceased && (
								<div>
									<p className="death-data">Dettagli defunto</p>
									&nbsp;
									{this.state.deceasedToShow?.deceased.name && (
										<p>
											Nome:
											{" "}
											{`${this.state.deceasedToShow?.deceased.name} ${this.state.deceasedToShow?.surname}`}
										</p>
									)}
									&nbsp;
									<p>
										Codice QrCode:
										{this.state.deceasedToShow?.deceased.qrcode}
									</p>
									&nbsp;
									{this.state.deceasedToShow?.deceased.dedic && (
										<p>
											Dedica:
											{this.state.deceasedToShow?.deceased.dedic}
										</p>
									)}
									&nbsp;
									{this.state.deceasedToShow?.deceased.bio && (
										<p>
											Bio:
											{this.state.deceasedToShow?.deceased.bio}
										</p>
									)}
									&nbsp;
								</div>
							)}
							<div className="bottom-modal-submit" style={{ flexDirection: "row-reverse" }}>
								<Button
									title="Rifiuta"
									className="button"
									variant="contained"
									onClick={() => {
										this.setState({ purchaseModal: false });
									}}
									style={{ color: "white" }}
									color="primary"
								>
									Chiudi
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	showQrCode() {
		let close = true;
		return (
			<div
				onClick={() => {
					if (!close) {
						this.setState({ showQrCode: false });
					} else {
						close = false;
					}
				}}
				style={{
					display        : "flex",
					justifyContent : "center",
					flexDirection  : "column",
				}}
				className="admin-panel-modal"
			>
				<CloseIcon
					id="crossIcon"
					onClick={() => {
						this.setState({ showQrCode: false });
					}}
					style={{ zIndex: 3000 }}
				/>
				<div className="flex">
					<div
						style={{ marginBottom: 40 }}
						onClick={() => {
							close = true;
						}}
					>
						<img src={this.state.qrCodeImg} alt="qrCode" />
					</div>
				</div>
			</div>
		);
	}

	partnerModal() {
		return (
			<div
				onClick={() => {
					if (!notModal) {
						this.setState({ partnermodal: false });
						this.getPartner(true);
					} else {
						notModal = false;
					}
				}}
				className="admin-panel-modal"
			>
				<CloseIcon id="crossIcon" style={{ zIndex: 3000 }} />
				<div className="flex">
					<div
						style={{ marginBottom: 40, padding: 20, borderRadius: 5 }}
						onClick={() => {
							notModal = true;
						}}
					>
						<PartnerPagePanel partnerID={this.state.partnerToShow} type="modal" />
					</div>
				</div>
			</div>
		);
	}

	/* TABLE UTILS */
	partnerDataTable() {
		if (window.location.hash === "#partners") {
			this.setState({ page: 0 });
			newRows = [];
			// eslint-disable-next-line array-callback-return
			aziendaData = aziendaData.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
			aziendaData.forEach((e, i) => {
				newRows.push(this.createPartnerData(e.ID, e.name, e.type, e.SDI, e.VAT, e));
			});
		}
	}

	obituaryDataTable() {
		if (window.location.hash === "#necrologi") {
			this.setState({ page: 0 });
			newRows = [];
			obituary
				.sort((a, b) => (a.deceasedName.toLowerCase() > b.deceasedName.toLowerCase() ? 1 : -1))
				.forEach((e, i) => {
					newRows.push(
						this.createObituaryData(
							e.ID,
							`${e.name} ${e.surname}`,
							`${e.deceasedName} ${e.deceasedSurname}`,
							e,
						),
					);

					this.setState({ load: !this.state.load });
				});
		}
		this.setState({ load: !this.state.load });
	}

	// tutto
	activationDataTable() {
		if (window.location.hash === "#qrcode") {
			this.setState({ page: 0 });
			newRows = [];
			try {
				activationStab.forEach((e, i) => {
					newRows.push(
						this.createActivationData(e.ID, `${e.name} ${e.surname}`, e.address, e.city, `000${e.ID}`, e),
					);
				});
			} catch (re) {
				console.log({ re });
			}
		}
	}

	dedicDataTable(value) {
		if (!this.state.dedicRequest) {
			this.setState({ dedicRequest: true });
			requests("get", "dedicDataTable")
				.then((data) => {
					this.setState({ dedicRequest: false });
					if (data && data.data && data.data.data) {
						const dedic = data.data.data;
						dedics      = dedic;
						this.setState({ load: !this.state.load });
						!value && this.dedicDataTable2();
					}
				})
				.catch((e) => {
					console.log(e);
					this.setState({ dedicRequest: true });
					this.setState({ load: !this.state.load });
					!value && this.dedicDataTable2();
				});
		}
	}

	dedicDataTable2() {
		if (window.location.hash === "#dedic") {
			this.setState({ page: 0 });
			newRows = [];
			dedics
				.sort((a, b) => (a.deceased.name.toLowerCase() > b.deceased.name.toLowerCase() ? 1 : -1))
				.forEach((e) => {
					const dedicOwner = (e && e.dedicOwner && e.dedicOwner) || {};
					try {
						newRows.push(
							this.createDedicData(
								e.dedic?.ID,
								`${dedicOwner?.name} ${dedicOwner?.surname}`,
								`${e.deceased?.name} ${e.deceased?.surname}`,
								e.dedic?.type,
								moment(e.dedic?.time).format("DD/MM/YYYY HH:mm:ss"),
								e.dedic?.token,
								e,
							),
						);
					} catch (errr) {
						console.log(errr);
					}
				});
		}
	}

	purchaseDataTable(initPage) {
		if (window.location.hash === "#pages") {
			if (!initPage) {
				this.setState({ page: 0 });
			}
			newRows = [];
			purchase
				.sort((a, b) => (a.deceasedName.toLowerCase() > b.deceasedName.toLowerCase() ? 1 : -1))
				.forEach((e, i) => {
					try {
						newRows.push(
							this.createDeceasedData(
								e.ID,
								`${e.name} ${e.surname}`,
								`${e.deceasedName} ${e.deceasedSurname}`,
								e.qrcodeAttivazione,
								e.deceasedMemorial && `${e.deceasedMemorial}` !== "0",
								e.dedicCounter && `${e.dedicCounter}` !== "0" && e.dedicCounter,
								e,
								newRows.length,
								e.enabled,
							),
						);
					} catch (re) {
						console.log("crash in ", i, e, re);
					}
				});
		}
	}
}

export default withContext(AdminPanel);
