import React, { Fragment, useEffect, useState } from "react";
import { Spinner } from "react-activity";
import { Button, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router";
import Axios from "axios";
import listner from "reactjs-eventemitter";
import { Link } from "react-router-dom";
import FileUpload10 from "../../FormComponents/FileUpload10";
import FileNamesComponent from "../../FormComponents/FileNamesComponent";
import VideoInput from "../../FormComponents/VideoInput";
import requests, { baseURL } from "../../../Services/HttpServices";
import CkEditorCustom from "../../FormComponents/CkEditor";
import FeedbackModal from "../../FormComponents/FeedbackModal";

let globalData = {};

const fileIndex = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

export default function MemorialAdmin(props) {
  const [uploading, setUploading] = useState(null);
  const [load, setLoad] = useState(false);
  const [bio, setBio] = useState("");
  const [bioError, setBioError] = useState("");

  const [files, setFiles] = useState([]);
  const [fileVideoName, setFileVideoName] = useState("");
  const [openModalFile, setOpenModalFile] = useState("");
  const [percentage, setPercentage] = useState("");
  const [fileError, setFileError] = useState("");
  const [modalResult, setModalResult] = useState("");
  const [modalText, setModalText] = useState("");
  const [feedbackModal, setFeedbackModal] = useState("");
  const history = useHistory();
  const deceasedId =
    props.deceasedID ||
    (history.location.search && history.location.search.split("=")[1]);
  useEffect(() => {
    listner.events.upload4 = [];
    listner.events.upload4.length < 1 &&
      listner.subscribe("upload4", (callback) => {
        setPercentage(callback);
      });
  });
  return (
    <>
      {openModalFile && fileMissingModal(globalData)}
      {uploading && (
        <div className="div-upload">
          <Spinner size={100} />
          <Typography className="label-upload">
            Attendi il caricamento dei files{" "}
            {parseInt(parseFloat(percentage) * 100)}%
          </Typography>
        </div>
      )}
      <div className="contact-container " style={{ padding: 20 }}>
        {feedbackModal && (
          <FeedbackModal
            result={modalResult}
            close={() => {
              setFeedbackModal(false);
              modalResult === "Successo" && history.push("/admin-panel#pages");
            }}
            label={modalText}
          />
        )}

        <div className="memorial-container memorial-container-activate">
          <div className="activate-form-div" style={{ minHeight: "auto" }}>
            <label className="contact-title">Attiva memorial</label>
            <form
              id="#form"
              className="activate-form"
              onSubmit={(event) => {
                const formData = new FormData(event.target);
                const formKeys = formData.keys();
                const object = {};
                formData.forEach(function (value, key) {
                  object[key] = value;
                });
                formData.set("deceased-id", deceasedId);
                event.preventDefault();
                send(formData);
              }}
            >
              <CkEditorCustom
                title="Biografia defunto"
                onChange={(editor) => {
                  setBio(editor.getData());
                }}
                data={bio}
                error={bioError && !bio}
              />
              <TextField
                className="text-area"
                value={bio}
                id="outlined-multiline-static"
                name="deceased-bio"
                label="Biografia"
                multiline
                style={{ display: "none" }}
                rows={8}
                variant="outlined"
                onChange={(event) => {
                  setBio(event.target.value);
                }}
                helperText={!bio && bioError ? "Campo obbligatorio" : ""}
                error={bioError && !bio}
              />
              <div>
                <FileUpload10
                  setFileIndex={() => {
                    fileIndex.shift();
                    setLoad(!load);
                  }}
                  setFile={(temp) => setFiles(temp)}
                  name="memorial"
                  files={files}
                />
                <div className="file-upload">
                  <label
                    className="pointer"
                    htmlFor={`memorial-file${fileIndex[0]}`}
                    color="blue"
                    title=""
                  >
                    <p className="pointer-label">
                      {files.length + 1 > 10
                        ? "Tutti i file caricati"
                        : `Carica immagine ${files.length + 1} di 10` +
                          ` (Max 15MB per foto)`}
                    </p>
                  </label>
                </div>
                <FileNamesComponent
                  name="memorial"
                  files={files}
                  setFile={(temp) => setFiles(temp)}
                  setFileIndex={(temp, i) => {
                    fileIndex.unshift(temp[i].id);
                    setLoad(!load);
                  }}
                />
              </div>
              <div>
                <div className="file-upload">
                  <label
                    className="pointer"
                    htmlFor="memorial-video-upload"
                    color="blue"
                    title=""
                  >
                    <p className="pointer-label">
                      {" "}
                      {fileVideoName || "Carica Video (Max 15MB)"}
                    </p>
                  </label>
                </div>
                {fileError && !fileVideoName && files.length < 1 && (
                  <label style={{ color: "red" }}> Carica almeno un file</label>
                )}
              </div>
              &nbsp;
              <VideoInput
                name="memorial"
                setVideoName={(videoName) => {
                  setFileVideoName(videoName);
                }}
              />
              <div className="bottom-activate-form">
                <button
                  type="submit"
                  onClick={(e) => {
                    // e.preventDefault()
                    // this.send()
                  }}
                  className="activate-submit"
                >
                  Procedi
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );

  function send(formData) {
    const error = bio === "";
    let fileerror;
    setBioError(error);
    if (!fileVideoName && files.length < 1) {
      fileerror = true;
    }
    globalData = formData;
    if (!error) {
      if (fileerror) {
        setOpenModalFile(true);
      } else getData(formData);
    }
  }

  function fileMissingModal(formdata) {
    let close2 = true;
    return (
      <div
        className="modal-bg"
        onClick={() => {
          if (close2) {
            setOpenModalFile(false);
            // this.props.history.push('admin-panel')
          } else close2 = !close2;
        }}
      >
        <div
          className="inner"
          onClick={() => {
            close2 = false;
          }}
        >
          <Typography style={{ fontSize: 17 }}>Attenzione!!</Typography>
          <Typography style={{ fontSize: 12, marginTop: 15 }}>
            Non hai caricato nessun media, desideri continuare?
          </Typography>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setOpenModalFile(false);

                getData(globalData);
              }}
            >
              SI
            </Button>
            <Button
              color="primary"
              style={{ marginLeft: 20 }}
              onClick={() => {
                setOpenModalFile(false);

                // this.props.history.push('admin-panel')
              }}
              variant="contained"
            >
              NO
            </Button>
          </div>
        </div>
      </div>
    );
  }

  function getData(formdata) {
    const formData = new FormData();
    formdata.forEach(function (value, key) {
      if (value.length <= 0) {
      } else if (value.size === 0) {
      } else {
        formData.set(key, value);
      }
    });
    getCsrf().then((data) => {
      setUploading(true);
      formData.set("csrf_token", data);
      requests("sendform", "memorialAdmin", formData).then((result) => {
        setUploading(false);
        if (result.response !== "fail") {
          setModalResult("Successo");
          setFeedbackModal(true);
          setModalText("Memorial aggiunto con successo");
        } else if (result.response === "fail") {
          setModalResult("Errore");
          setFeedbackModal(true);
          setModalText(result.message);
        }
      });
    });
  }

  function getCsrf() {
    return new Promise((result, err) =>
      Axios.get(`${baseURL}api/auth/securitytoken`)
        .then((resolve) => {
          if (resolve != null) {
            result(resolve.data._csrf);
          } else result({ DATA: "NO DATA" });
        })
        .catch((err) => {
          return result({ err });
        })
    );
  }
}
