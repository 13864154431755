import React, { Component, Fragment } from "react";
import { Redirect } from "react-router";
import { Typography } from "@material-ui/core";
import "./Buy-qrCode.css";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import qrCode from "../../assets/qrCode.png";
import validate, { locationValidation } from "../../formValidationService";
import PaymentModal from "../PaymentModal/PaymentModal";
import HttpServices from "../../Services/HttpServices";
import addressServiceGetData from "../FormComponents/addressService";
import AddressService, { buildCap } from "../FormComponents/AddressComponent";
import UserFormData from "../FormComponents/userFormData";
import FormLabel from "../FormComponents/TermFormComponents";
import ExistsModal from "../FormComponents/ExistsModal";
import Nationality from "../FormComponents/Nationality";
import UsernameField from "../FormComponents/UsernameField";
import ModalLogin from "../Auth/ModalLogin";
import AcceptBuyTerms from "../FormComponents/AcceptBuyTerms";
import requests from "../../Services/HttpServices";
import withContext from "../../WithContextFile";

let closeModalFixed  = false;
let form;
let closeExistModal  = true;
let regioni;
const paypalclientId = "AX-JToydoHdJt309EJDeRzShWrHhqNWwfN_oNqGy9zaxLmyQUROur8Wag3DsK9oooSMZ0fPHvV43dhss";
const openedWindows  = [];

class BuyQrCode extends Component {
	constructor(props) {
		super(props);
		this.state   = {
			existModalLabel:
				"Un account per l' indirizzo email inserito e` gia` esistente, si prega di inserire una password",
			modal      : false,
			radioValue : "",
			form       : {
				name        : "",
				surname     : "",
				email       : "",
				phone       : "",
				CF          : "",
				route       : "",
				civic       : "",
				username    : "",
				city        : "",
				province    : "",
				cap         : "",
				region      : "",
				nationality : "105",
			},
			fatturazione: {
				name     : "",
				surname  : "",
				route    : "",
				civic    : "",
				city     : "",
				province : "",
				cap      : "",
				region   : "",
			},
			userAddress   : null,
			extraData     : false,
			formError     : {},
			formError2    : {},
			loggedUser    : null,
			existModal    : false,
			buyTerms      : false,
			buyTermsError : false,
		};
		window._open = window.open; // saving original function
		window.open  = function (url, name, params, bool) {
			openedWindows.push(window._open(url, "_parent", params, bool));
			// you can store names also...
		};
	}

	componentDidMount() {
		const regions = [];
		addressServiceGetData("region")
			.then((data) => {
				regioni = data;
				this.setState({ regionsLoaded: true });
			});
		requests("get", "getProductPrices", "")
			.then((data) => {
				if (data.data?.data?.products?.length > 0) {
					data.data.data.products.forEach((e) => {
						if (e.name === "qr-code") {
							this.setState({ qrPrice: e.price });
						}
						if (e.name === "shipping") {
							this.setState({ shipPrice: e.price });
						}
					});
				}
			});
	}

	setInitialData() {
		const { form } = this.state;
		form.name      = this.state.loggedUser.name;
		form.surname   = this.state.loggedUser.surname || "Cognome";
		form.email     = this.state.loggedUser.email;
		form.username  = this.state.loggedUser.username;
		form.phone     = this.state.loggedUser.telephone;
		form.CF        = this.state.loggedUser.cf;
		if (sessionStorage.getItem("UserAddress")) {
			const userAddress     = JSON.parse(sessionStorage.getItem("UserAddress"));
			form.route            = userAddress.address;
			form.civic            = userAddress.number;
			form.city             = userAddress.city;
			form.province         = userAddress.province;
			form.cap              = userAddress.cap;
			form.region           = userAddress.region;
			form.nationality      = userAddress?.nationality || "105";
			form.complete_address = userAddress.complete_address;
		}
		this.setState({ form });
	}

	render() {
		if (!this.state.loggedUser && sessionStorage.getItem("User")) {
			try {
				this.setState({
					loggedUser  : JSON.parse(sessionStorage.getItem("User")).userdata,
					userAddress : JSON.parse(sessionStorage.getItem("UserAddress")),
				});
			} catch (e) {

			}
			// this.setState({userAddress: JSON.parse(sessionStorage.getItem('UserAddress'))})
		}
		if (this.state.loggedUser && this.state.form.name == "") {
			this.setInitialData();
			this.state.userAddress?.region
			&& addressServiceGetData("province", this.state.userAddress.region)
				.then((resolve) => {
					this.setState({ provincie: resolve });
					addressServiceGetData("location", this.state.userAddress.province)
						.then((resolve) => {
							const locSelected = resolve.find(
								(x) => x.ID === (this.state.form.location || this.state.form.city),
							);
							let capArr        = [];
							if (locSelected.capList) capArr = buildCap(locSelected);
							capArr.unshift(locSelected.cap);
							this.setState({
								comuni : resolve,
								caps   : capArr,
							});
						});
				});
		}

		return !this.props.context.user ? (
			<>
				<div className="contact-container">
					{this.state.loginModal && (
						<ModalLogin
							close={() => {
								this.setState({ loginModal: false });
							}}
						/>
					)}
					&nbsp;
					<Link to="./1000">
						<img alt="qrCode" src={qrCode} />
					</Link>
					<label className="activate-title">Acquista Lapide Digitale / QrCode</label>
					<label className="buy-subtitle">
						<p>
							Acquistando una “Lapide Digitale / QrCode” avrai la possibilità di creare una Pagina-Ricordo
							sul portale www.digitalmemorial.it dedicata a un tuo defunto.
						</p>
						&nbsp;
						<p>
							La pagina mostrerà cognome, nome, data di nascita, data di morte, aforisma, foto lapide e
							luogo di sepoltura.
						</p>
						&nbsp;
						<p>
							Potrai quindi apporre la targhetta QrCode sulla lapide reale e dare la possibilità a
							chiunque di accedervi direttamente tramite un qualsiasi dispositivo (Smartphone, Tablet,
							ecc) leggendone il codice;
						</p>
						&nbsp;
						<p>
							Inoltre, la pagina sarà anche raggiungibile visitando il sito www.digitalmemorial.it e
							digitando nei campi di ricerca i dati del defunto.
						</p>
						&nbsp;
						<p>
							Chiunque poi visiti la pagina potrà visualizzare tutti i contenuti disponibili e inoltre
							avrà anche la possibilità di donare cero/fiori virtuali, foto, video, con eventuale dedica
							associata, che sarà visibile a tutti solo dopo l'avallo del proprietario della pagina; nel
							caso in cui il proprietario della pagina non avalli la richiesta, il dono cero/fiori sarà
							pubblicato in forma anonima, mentre nel caso di foto e video il dono verrà sostituito con
							fiori virtuali anch'essi pubblicati in forma anonima.
						</p>
						&nbsp;
						<p>
							Dopo l’acquisto riceverai via mail i dati per poter attivare la lapide digitale e
							successivamente la targhetta “QrCode” direttamente a casa tua via posta ordinaria o via
							corriere espresso.
						</p>
						&nbsp;
						<p className="buy-price">
							Costo Lapide Digitale / QrCode €
							{parseFloat(this.state.qrPrice || "1")
								.toFixed(2)
								?.replace(".", ",")}
							+ €
							{parseFloat(this.state.shipPrice || "1")
								.toFixed(2)
								?.replace(".", ",")}
							(spedizione)
						</p>
					</label>
					&nbsp;
					<p className="buy-duty">Esegui 2 semplici passaggi:</p>
					<div className="buy-duty-buttons">
						<div className="buy-button-div">
							<button className="buy-duty-button">1</button>
							<label className="buy-button-bottom-label">
								<p>Compila il modulo sottostante</p>
								e premi il tasto
								{" "}
								<p className="underline uppercase">procedi</p>
							</label>
						</div>
						<div className="buy-button-div">
							<button className="buy-duty-button">2</button>
							<label className="buy-button-bottom-label">
								<p>Completa la procedura di</p>
								{" "}
								acquisto su PayPal
							</label>
						</div>
					</div>
					<div className="activate-form-div">
						<form
							id="form"
							ref={form}
							className="activate-form"
							onSubmit={(event) => {
								event.preventDefault();
								const formData = new FormData(event.target);
								const formKeys = formData.keys();
								if (this.state.extraData) {
									formData.set("purchase-shipping-flag", 1);
								}
								do {
								} while (!formKeys.next().done);
								this.send(formData);
							}}
						>
							<label className="death-data">Dati per la Fatturazione</label>
							<UserFormData
								notusername
								partnerModify={this.state.loggedUser?.platformAccess == "2"}
								load={() => {
									this.setState({ load: true });
								}}
								nationalityChange={() => {
									this.setState({ reload: true });
								}}
								isAccount={!!this.state.loggedUser}
								openModalEmail={(data) => {
									this.setState({
										existModal : true,
										modalname  : data,
									});
								}}
								names="purchase"
								form={this.state.form}
								formError={this.state.formError}
								disable={this.state.loggedUser !== null}
							/>
							<label className="buy-form-paragraph">Dati indirizzo</label>
							<AddressService
								nationality={this.state.form.nationality || "105"}
								state={this.state}
								names="purchase"
								province={this.state.provincie}
								comuni={this.state.comuni}
								form={this.state.form}
								caps={this.state.caps}
								formError={this.state.formError}
								regioni={regioni}
							/>
							<label
								onClick={() => {
									this.setState({ extraData: !this.state.extraData });
								}}
								className="buy-form-paragraph click-buy-form"
							>
								Dati per la Spedizione diversi dai dati di fatturazione?
							</label>
							{this.state.extraData && (
								<div style={{
									display       : "flex",
									flexDirection : "column",
								}}
								>
									<TextField
										className="activate-field"
										onChange={(event) => {
											this.setAndValidateTaxAddress(event.target.value, "name");
										}}
										error={this.state.formError2.name}
										label="Nome destinatario *"
										helperText={this.state.formError2.name ? "Campo obbligatorio" : ""}
										name="shipping-name"
									/>
									<TextField
										className="activate-field"
										onChange={(event) => {
											this.setAndValidateTaxAddress(event.target.value, "surname");
										}}
										error={this.state.formError2.surname}
										label="Cognome destinatario *"
										helperText={this.state.formError2.surname ? "Campo obbligatorio" : ""}
										name="shipping-surname"
									/>
									<label className="buy-form-paragraph">Dati indirizzo</label>
									<Nationality
										name="shipping"
										setData={(data) => {
											this.setAndValidateTaxAddress(data, "nationality", true);
											this.setState({ reload: true });
										}}
										startData="105"
									/>
									{this.state.fatturazione.nationality == "105" && <p>&nbsp;</p>}
									<AddressService
										state={this.state}
										nationality={this.state.fatturazione.nationality || "105"}
										names="shipping"
										form={this.state.fatturazione}
										formError={this.state.formError2}
									/>
								</div>
							)}
							&nbsp;
							<FormControlLabel
								className="activate-checkbox"
								control={<Checkbox color="primary" id="term-box" name="_submitPurchase" value={1} />}
								onChange={(event, checked) => this.setState({ checkBox: checked })}
								label={FormLabel()}
							/>
							{this.state.termsError && !this.state.checkBox && (
								<label
									style={{
										color      : "red",
										textAlign  : "left",
										margin     : 10,
										marginLeft : 30,
									}}
								>
									Devi accettare i termini
								</label>
							)}
							<AcceptBuyTerms
								checkBox={this.state.buyTerms}
								termsError={this.state.buyTermsError}
								setChecked={(c) => {
									this.setState({ buyTerms: c });
								}}
							/>
							{!this.state.loggedUser && (
								<FormControlLabel
									name="register-flag"
									className="activate-checkbox"
									control={<Checkbox color="primary" name="register-flag" value={1} />}
									onChange={(event, checked) => this.setState({ register: checked })}
									label={<label className="activate-checkbox">'Registrami al portale' </label>}
								/>
							)}
							{this.state.register && (
								<UsernameField
									formError={this.state.formError}
									form={this.state.form}
									names="purchase"
									load={() => this.setState({ userLoad: true })}
								/>
							)}
							<button type="submit" className="activate-submit">
								Procedi
							</button>
						</form>
						&nbsp;
					</div>
					&nbsp;
					{this.state.modal && (
						<div
							onClick={() => {
								if (!closeModalFixed) {
									this.state.paymentNotError
										? this.setState({ modal: false })
										: this.props.history.push({
											pathname : "/paypal-error",
											query    : {
												orderId : this.state.orderId,
												type    : "qrcode",
											},
										});
								} else {
									closeModalFixed = false;
								}
							}}
							className="modalPurchase"
						>
							<div
								onClick={() => {
									closeModalFixed = true;
								}}
							>
								<PaymentModal
									paymentType="qrCode"
									setError={() => this.setState({ paymentNotError: true })}
									payClientId={paypalclientId}
									shippingAddress={{
										address  : this.state.form.route,
										civic    : this.state.form.civic,
										city     : this.state.form.city,
										region   : this.state.form.region,
										province : this.state.form.province,
										cap      : this.state.form.cap,
									}}
									price={
										this.state.qrPrice && this.state.shipPrice
											? parseFloat(this.state.qrPrice) + parseFloat(this.state.shipPrice)
											: 107
									}
									showAddress
									successComponent={this.purchaseSuccess}
									isRegistering={this.state.register}
									isAccount={!!this.state.loggedUser}
									taxAddress={this.state.fatturazione}
									userData={{
										name    : this.state.form.name,
										surname : this.state.form.surname,
										CF      : this.state.form.CF,
										email   : this.state.form.email,
									}}
									orderId={this.state.orderId}
									quantity={1}
									purchase={`QrCode + Pagina Ricordo + spedizione (${parseFloat(this.state.shipPrice)
										.toFixed(2)
										.replace(".", ",")}€)`}
								/>
							</div>
						</div>
					)}
				</div>
				{this.state.existModal /* this.existsModal() */ && (
					<ExistsModal
						email={this.state.form.email}
						username={this.state.modalname}
						deniedModal={() => {
							this.setState({ existModal: false });
							const temp = this.state.form;
							// temp.email = '';
							// temp.CF = '';
							// temp.username = ''
							this.setState({ form: temp });
						}}
						label={
							"Un account per l' indirizzo email inserito e` gia` esistente, si prega di inserire una password"
						}
					/>
				)}
				{this.state.existModal2 && this.existsModal()}
			</>
		) : (
			<Redirect to="/products" />
		);
	}

	purchaseSuccess(register, account, history) {
		history.push({
			pathname : "./successo",
			query    : {
				register,
				account,
				data: true,
			},
		});
		// return <PurchaseSuccessComponent register={register} account={account}/>
	}

	setAndValidateForm(data, attribute, isNumeric) {
		const { form }    = this.state;
		let { formError } = this.state;
		form[attribute]   = data;

		this.setState({ form });
		formError = validate(data, attribute, formError, isNumeric);
		if (attribute === "email" || attribute === "phone") {
			formError.account = false;
		}
		this.setState({ formError });
	}

	setAndValidateTaxAddress(data, attribute, isNumeric) {
		const form2        = this.state.fatturazione;
		let { formError2 } = this.state;
		form2[attribute]   = data;
		this.setState({ form2 });

		formError2 = validate(data, attribute, formError2, isNumeric);
		this.setState({ formError2 });
	}

	send(formData) {
		// this.setState({modal: true})
		let { formError }  = this.state;
		let { formError2 } = this.state;
		let error          = false;
		let error2         = false;
		for (const property in this.state.form) {
			if (this.state.loggedUser && property === "email") {
			} else if (
				(property !== "username" || this.state.register)
				&& !this.state.formError.username
				&& (property !== "phone" || (this.state.form.phone && this.state.form.phone.length >= 9))
				&& (property !== "email" || this.state.form.email !== "")
				&& (property !== "CF" || this.state.form.nationality == "105")
			) {
				if (
					!(property === "address" || property === "location" || property === "complete_address")
					|| this.state.form.nationality != "105"
				) {
					formError = validate(this.state.form[property], property, formError);
					if (this.state.loggedUser?.platformAccess == "2") {
						formError.CF = this.state.form.CF !== "" && this.state.form.CF.length !== 16;
					}
					error = error || formError[property];
				}
			}
		}
		if (this.state.form.email == "") {
			this.state.formError.email = false;
		}
		if (this.state.register) {
			error = error || formError.username;
		}
		if (this.state.form.phone || (this.state.form.phone && this.state.form.phone.length <= 3)) {
			this.state.formError.phone = false;
		}
		if (
			formError.email
			|| (!this.state.form.email
				&& ((this.state.form.phone && this.state.form.phone.length < 9) || !this.state.form.phone))
		) {
			this.setState({ accountError: true });
			error             = true;
			formError.account = true;
		} else {
			formError.account = false;
			this.setState({ accountError: false });
		}
		if (!this.state.checkBox) {
			this.setState({ termsError: true });
			error = true;
		}
		if (!this.state.buyTerms) {
			error = true;
			this.setState({ buyTermsError: true });
		}
		if (this.state.extraData) {
			for (const property in this.state.fatturazione) {
				formError2 = validate(this.state.fatturazione[property], property, formError2);
				error2     = error2 || formError2[property];
				if (error2 === true) {
				}
			}
		}
		formError = locationValidation(this.state.form, formError);
		if (`${this.state.loggedUser?.platformAccess}` === "2") {
			formError.CF = this.state.form.CF !== "" && !(this.state.form.CF?.length === 16);
			error        = error || formError.CF;
		} else {
			formError.CF = this.state.form.CF?.length !== 16;
			error        = error || formError.CF;
		}
		this.setState({ formError });

		if (!error && !error2) {
			const object = {};
			if (!this.state.form.phone) {
				formData.delete("phone");
				formData.delete("purchase-telephone");
			}
			formData.forEach((value, key) => {
				object[key] = value;
			});

			if (this.state.loggedUser) {
				object["purchase-name"]      = this.state.form.name;
				object["purchase-surname"]   = this.state.form.surname;
				object["purchase-cf"]        = this.state.form.CF;
				object["purchase-telephone"] = this.state.form.phone;
				object["purchase-email"]     = this.state.form.email;
			}
			// this.setState({object})

			// object['register-flag'] = 0
			this.sendPurchaseData(object);
		}
	}

	checkUser(object) {
		let exist = false;
		HttpServices("get", "mailExist", this.state.form.email)
			.then((data) => {
				exist           = data.data.result;
				const isAccount = data.data.isActive;
				let existModalLabel;
				if (!isAccount && data.data.result !== "fail" && data.data.result != false) {
					existModalLabel = isAccount
						? "Un account per l' indirizzo email inserito e` gia` esistente, si prega di inserire la password del proprio account"
						: "Un acquisto precedente con questa email e` gia` stato effettuato, si consiglia di effettuare la registrazione,vuole procedere lo stesso?";
					this.setState({
						existModal2: true,
						existModalLabel,
						object,
						isAccount,
					});
				} else {
					this.sendPurchaseData(object);
				}
			});
	}

	sendPurchaseData(object) {
		HttpServices("post", "qrCode", object)
			.then((data) => {
				if ((data.result !== "fail", data.response !== "fail")) {
					this.setState({ modal: true });
					this.setState({ orderId: data.data.orderid });
				} else if (data.errorCode == "TELEPHONE_ALREADY_EXISTS") {
					this.setState({ loginModal: true });
					const temp = this.state.formError;
					temp.phone = true;
					this.setState({ formError: temp });
				} else if (data.errorCode == "EMAIL_ALREADY_EXISTS") {
					this.setState({ loginModal: true });
					const temp = this.state.formError;
					temp.email = true;
					this.setState({ formError: temp });
					document.getElementById("purchase-email")
						.focus();
				} else {
					alert(data.message);
				}
			});
	}

	existsModal() {
		return (
			<div
				className="modal-bg"
				onClick={() => {
					closeExistModal
					&& this.setState({
						existModal2: false,
					});
					closeExistModal = true;
				}}
			>
				<div
					className="inner"
					onClick={() => {
						closeExistModal = false;
					}}
				>
					<Typography style={{ marginBottom: 12 }} align="center">
						Attenzione!
					</Typography>
					<Typography style={{ marginBottom: this.state.isAccount && 17 }}>
						{" "}
						{this.state.existModalLabel}
					</Typography>
					<div className="submit-container">
						<div style={{ width: 80 }} onClick={(event) => this.setState({ existModal2: false })}>
							Annulla
						</div>
						<div
							style={{ width: 80 }}
							onClick={() => {
								this.sendPurchaseData(this.state.object);
								this.setState({ existModal2: false });
							}}
						>
							Procedi
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withContext(BuyQrCode);
