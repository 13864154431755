import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import { ArrowRight, ArrowRightAlt } from "@material-ui/icons";

export default function HomeSingleCardWizard({ searchID }) {
	// region variables
	const [start, setStart] = useState(false);
	const [load, setLoad]   = useState(false);
	// endregion
	const resetWizard = () => {
		localStorage.setItem("wizard", "true");
		document.getElementById("main-container").ontouchstart = () => {};
		document.getElementById("main-container").onscroll     = () => {};
		document.getElementById("main-container").onclick = () => {};
		setLoad(!load);
	};
	// region lifeCycle
	// onStart
	useEffect(() => {
		if (start) {
			document.getElementById("main-container").ontouchstart = resetWizard;
			document.getElementById("main-container").onscroll     = resetWizard;
			document.getElementById("main-container").onclick      = resetWizard;
		}
	}, [start]);
	// endregion

	// region functions
	if (start !== (window.location.href.indexOf("searchid") >= 0 && !localStorage.getItem("wizard"))) setStart(window.location.href.indexOf("searchid") >= 0 && !localStorage.getItem("wizard"));

	if (!start) {
		return <div />;
	}
	//
	// endregion
	return (
		<div className="wizard">
			<div style={{ display: "flex", alignItems: "center" }}>
				<Typography color="primary" style={{ fontSize: 28, lineHeight: "32px" }}>
					Clicca il libro per le condoglianze
				</Typography>
				<ArrowRightAlt color="primary" style={{ fontSize: 50 }} />
			</div>
		</div>
	);
}
