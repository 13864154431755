import React, { Component, Fragment } from "react";
import TextField from "@material-ui/core/TextField";
import Axios from "axios";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Spinner } from "react-activity";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import CKEditor from "@ckeditor/ckeditor5-react";
import ReCAPTCHA from "react-google-recaptcha/lib/esm/recaptcha-wrapper";
import { Typography } from "@material-ui/core";
import withContext from "../../WithContextFile";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import "./Partner.css";
import validate from "../../formValidationService";
import AddressService from "../FormComponents/AddressComponent";
import addressServiceGetData from "../FormComponents/addressService";
import ContactPreferenceForm from "../FormComponents/ContactPreferencesForm";
import FormLabel from "../FormComponents/TermFormComponents";
import FileNamesComponent, {
  readURL,
} from "../FormComponents/FileNamesComponent";
import FileUpload10 from "../FormComponents/FileUpload10";
import VideoInput from "../FormComponents/VideoInput";
import PhotoInput from "../FormComponents/PhotoInput";
import requests, { baseURL } from "../../Services/HttpServices";
import listner from "reactjs-eventemitter";
import PartnerAreUSure from "../FormComponents/PartnerAreUSure";
import FileUpload50 from "../FormComponents/FileUpload50";
import PhoneNumber from "../FormComponents/PhoneNumber";
import Recaptcha from "../../Services/Recaptcha";
import Nationality from "../FormComponents/Nationality";
import FeedbackModal from "../FormComponents/FeedbackModal";
import { suggestUsername } from "../../Services/formServices";

const fileIndex = [
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
];
let timerUserCheck;
class Partner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      editor: "",
      fieldActivate: false,
      nameField: "",
      form: {
        name: "",
        type: "partner",
        CF: "",
        PI: "",
        route: "",
        civic: "",
        city: "",
        username: "",
        province: "",
        phone: "",
        SDI: "",
        // email: '',
        altPhone: "",
        website: "",
        region: "",
        cap: "",
        nationality: "105",
      },
      comuni: null,
      provincie: null,
      formError: {},
      radioValue: "",
      recaptchaValue: null,
    };
  }

  componentDidMount() {
    listner.events.upload3 = [];
    listner.events.upload3.length < 1 &&
      listner.subscribe("upload3", (callback) => {
        this.setState({ percentage: callback });
      });
    if (this.props.data) {
      const logoNames = this.props.data.logo.split("/");

      const { form } = this.state;
      form.name = this.props.data.name;
      form.type = this.props.data.headertext;
      form.PI = this.props.data.VAT;
      form.CF = this.props.data.CF;
      form.route = this.props.data.address;
      form.type = this.props.data.type;
      form.civic = this.props.data.number;
      form.city = this.props.data.location;
      form.phone = this.props.data.mobile;
      form.email = this.props.data.email;
      form.province = this.props.data.province;
      form.website = this.props.data.website;
      form.altPhone = this.props.data.telephone;
      form.region = this.props.data.region;
      form.cap = this.props.data.cap;
      this.setState({ logoName: logoNames[logoNames.length - 1] });
      this.setState({ form });
    }
  }

  handleNameUpdate = (e) => {
    if (this.state.form.name) {
      if (timerUserCheck) {
        clearTimeout(timerUserCheck);
        timerUserCheck = null;
      }
      timerUserCheck = setTimeout(
        () =>
          suggestUsername(this.state.form.name.toLowerCase(), "").then(
            (result) => {
              const { form } = this.state;
              form.username = result.replace(/[^a-zA-Z0-9]/g, "");
              this.setState({ form });
            }
          ),
        500
      );
    }
  };

  render() {
    if (this.props.data) {
      !this.state.provincie &&
        this.props.data.region &&
        addressServiceGetData("province", this.props.data.region).then(
          (data) => {
            this.setState({ provincie: data });
          }
        );
      !this.state.comuni &&
        this.props.data.province &&
        addressServiceGetData("location", this.props.data.province).then(
          (data) => {
            this.setState({ comuni: data });
          }
        );
    }
    return (
      <>
        {this.state.uploading && (
          <div className="div-upload">
            <Spinner size={100} />
            <Typography className="label-upload">
              Attendi il caricamento dei files{" "}
              {Math.round(parseFloat(`${this.state.percentage}`) * 100) || 0}%
            </Typography>
          </div>
        )}
        {this.state.feedbackModal && (
          <FeedbackModal
            result={this.state.modalResult}
            close={() => {
              this.state.modalFunction && this.state.modalFunction();
              this.setState({ feedbackModal: false });
            }}
            label={this.state.modalText}
          />
        )}
        <div
          className="contact-container"
          style={{ width: this.props.type === "modal" ? 711 : "auto" }}
        >
          {!this.props.type && (
            <label className="title-price"> Dati Azienda Partner</label>
          )}
          {!this.props.type && <p>&nbsp;</p>}
          <div className="partner-main-div">
            <p className="contact-title">Dati azienda</p>
            <form
              onSubmit={(event) => {
                event.preventDefault();
                const formData = new FormData(event.target);
                const object = {};
                formData.set("editor", this.state.editor);
                formData.forEach(function (value, key) {
                  if (value.length <= 0) {
                    formData.delete(key);
                  } else if (value.size === 0) {
                    formData.delete(key);
                  } else {
                    object[key] = value;
                  }
                });
                this.setState({ recaptcha: true });
                if (
                  !this.state.recaptchaScore ||
                  this.state.recaptchaScore == "false"
                ) {
                  alert(
                    "Abbiamo rilevato un utilizzo improprio dell'applicazione verra' reinderizzato a google"
                  );
                  window.location.href = "https://www.google.com";
                } else this.send(formData);
                // this.send()
              }}
            >
              <div className="partner-form">
                <TextField
                  className="partner-field"
                  name="partner-name"
                  value={this.state.form.name}
                  onChange={(event) => {
                    this.setAndValidateForm(event.target.value, "name");
                    this.handleNameUpdate(event);
                  }}
                  error={this.state.formError.name}
                  label="Denominazione *"
                  helperText={
                    this.state.formError.name ? "Campo obbligatorio" : ""
                  }
                />
                <TextField
                  className="partner-field"
                  name="partner-username"
                  value={this.state.form.username}
                  id="username"
                  onChange={(event) => {
                    this.setAndValidateForm(
                      event.target.value.replace(/[^a-zA-Z0-9]/g, ""),
                      "username"
                    );
                  }}
                  style={{ display: "none" }}
                  error={this.state.formError.username}
                  label="Nome utente *"
                  helperText={
                    this.state.formError.username ? "Campo obbligatorio" : ""
                  }
                />
                <TextField
                  className="partner-field"
                  name="partner-username"
                  value={this.state.form.username}
                  disabled
                  id="username"
                  onChange={(event) => {
                    this.setAndValidateForm(
                      event.target.value.replace(/[^a-zA-Z0-9]/g, ""),
                      "username"
                    );
                  }}
                  error={this.state.formError.username}
                  label="Nome utente *"
                  helperText={
                    this.state.formError.username ? "Campo obbligatorio" : ""
                  }
                />

                <InputLabel style={{fontSize: 13, marginTop: 10, color: "#4a4a4a", textAlign: "left", transform: "translate(0px, 18px) scale(1)"}} variant="filled" id="partner-type-label">Tipologia Azienda *</InputLabel>
                <Select
                  className="partner-field"
                  labelId="partner-type-label"
					        variant="outlined"
					        name="partner-type"
                  style={{ textAlign: "left" }}
                  value={this.state.form.type || "partner"}
					        onChange={(event) => {
                    this.setAndValidateForm(event.target.value, "type");
                  }}
                  error={this.state.formError.type}
					        input={<Input color="primary" />}
				        >
					        <MenuItem key="partner" value={"partner"}>Onoranze Funebri</MenuItem>
					        <MenuItem key="gestorelampade" value={"gestorelampade"}>Gestore Lampade</MenuItem>
				        </Select>


                <TextField
                  className="partner-field"
                  name="partner-cf"
                  value={this.state.form.CF}
                  onChange={(event) => {
                    this.setAndValidateForm(event.target.value, "CF");
                  }}
                  label="Codice Fiscale "
                />
                <TextField
                  className="partner-field"
                  name="partner-pi"
                  value={this.state.form.PI}
                  onChange={(event) => {
                    this.setAndValidateForm(event.target.value, "PI");
                  }}
                  error={this.state.formError.PI}
                  label="Partita IVA *"
                  helperText={
                    this.state.formError.PI ? "Campo obbligatorio" : ""
                  }
                />
                {/* <TextField className={'partner-field'} name={'partner-phone'} value={this.state.form.phone} */}
                {/*           onChange={(event) => { */}
                {/*               this.setAndValidateForm(event.target.value, 'phone') */}
                {/*           }} error={this.state.formError.phone} label={'Numero di cellulare *'} */}
                {/*           helperText={this.state.formError.phone ? 'Campo obbligatorio' : ''} */}
                {/* /> */}
                <TextField
                  className="partner-field"
                  name="partner-email"
                  style={{ marginBottom: 35 }}
                  id="email"
                  value={this.state.form.email}
                  onChange={(event) => {
                    this.setAndValidateForm(event.target.value, "email");
                  }}
                  error={this.state.formError.email}
                  label="Indirizzo email "
                  helperText={
                    this.state.formError.email ? "Email non valida" : ""
                  }
                />
                <PhoneNumber
                  name="partner-phone"
                  setValue={(value) => {
                    this.setAndValidateForm(value, "phone", 12);
                  }}
                  error={this.state.formError.phone}
                />
                {this.state.noContactError && (
                  <label
                    style={{
                      color: "red",
                      fontSize: 13,
                      marginTop: -14,
                      marginBottom: 8,
                      textAlign: "start",
                      alignSelf: "flex-start",
                    }}
                  >
                    Devi inserire una mail o un numero di telefono valido per
                    poterti registrare
                  </label>
                )}
                <TextField
                  name="partner-alt-phone"
                  value={this.state.form.altPhone}
                  onChange={(event) => {
                    const { form } = this.state;
                    form.altPhone = event.target.value;
                    this.setState({ form });
                  }}
                  label="Numero di telefono fisso"
                />
                <TextField
                  className="partner-field"
                  name="partner-website"
                  value={this.state.form.website}
                  onChange={(event) => {
                    const { form } = this.state;
                    form.website = event.target.value;
                    this.setState({ form });
                  }}
                  label="Indirizzo web"
                />
                <TextField
                  className="partner-field"
                  name="partner-SDI"
                  value={this.state.form.SDI}
                  onChange={(event) => {
                    this.setAndValidateForm(event.target.value, "SDI");
                  }}
                  error={this.state.formError.SDI}
                  label="SDI *"
                  helperText={
                    this.state.formError.SDI ? "Campo obbligatorio" : ""
                  }
                />
                &nbsp;
                <label className="buy-form-paragraph">Dati indirizzo</label>
                <Nationality
                  name="partner"
                  setData={(data) => {
                    this.setAndValidateForm(data, "nationality");
                    this.setState({ reload: true });
                  }}
                  startData="105"
                />
                <AddressService
                  nationality={this.state.form.nationality || "105"}
                  state={this.state}
                  names="partner"
                  partner
                  comuni={this.state.comuni}
                  province={this.state.provincie}
                  form={this.state.form}
                  formError={this.state.formError}
                />
                <div style={{ marginTop: 40 }} className="App">
                  <input
                    style={{ display: "none" }}
                    name="csrf_token"
                    value={this.state.csrf_token}
                  />
                  <div
                    className="buy-form-paragraph"
                    style={{ marginBottom: 20 }}
                  >
                    {" "}
                    Descrizione azienda
                  </div>

                  <CKEditor
                    onInit={(editor) => {
                      // Insert the toolbar before the editable area.
                      editor.ui
                        .getEditableElement()
                        .parentElement.insertBefore(
                          editor.ui.view.toolbar.element,
                          editor.ui.getEditableElement()
                        );
                    }}
                    onChange={(event, editor, data) => {
                      this.setState({ editor: editor.getData() });
                    }}
                    editor={DecoupledEditor}
                    data={this.props.data && this.props.data.description}
                    config={{
                      toolbar: [
                        "heading",
                        "Cut",
                        "Copy",
                        "Paste",
                        "|",
                        "alignment",
                        "bold",
                        "italic",
                        "FontSize",
                        "Fontfamily",
                        "TextColor",
                        "link",
                        "numberedList",
                        "bulletedList",
                        "|",
                        "undo",
                        "redo",
                        "|",
                        "underline",
                        "subscript",
                        "superscript",
                      ],
                      heading: {
                        options: [
                          {
                            model: "paragraph",
                            title: "Paragraph",
                            class: "ck-heading_paragraph",
                          },
                          {
                            model: "heading1",
                            view: "h1",
                            title: "Heading 1",
                            class: "ck-heading_heading1",
                          },
                          {
                            model: "heading2",
                            view: "h2",
                            title: "Heading 2",
                            class: "ck-heading_heading2",
                          },
                          {
                            model: "heading3",
                            view: "h3",
                            title: "Heading 3",
                            class: "ck-heading_heading3",
                          },
                        ],
                      },
                    }}
                  />
                </div>
                <div className="file-upload">
                  <label
                    style={{ marginTop: 12 }}
                    className="pointer"
                    htmlFor="photo-partner"
                    color="blue"
                    title=""
                  >
                    {" "}
                    {this.state.logoName ||
                      (this.props.data ? "Cambia logo" : "Carica logo")}
                  </label>
                </div>
                <PhotoInput
                  name="partner"
                  setFileName={(name, photo) => {
                    this.setState({ logoName: name });
                    readURL(photo).then((data) =>
                      this.setState({ photo: data })
                    );
                  }}
                />
                {this.state.photo && (
                  <img
                    style={{
                      margin: "auto",
                      maxHeight: 300,
                      marginTop: 20,
                    }}
                    src={this.state.photo}
                  />
                )}
                {!this.props.data && (
                  <div>
                    <div className="file-upload">
                      <label
                        className="pointer"
                        htmlFor={`partner-file${fileIndex[0]}`}
                        color="blue"
                        title=""
                      >
                        <p className="pointer-label">
                          {this.state.files.length + 1 > 50
                            ? "Tutti i file caricati"
                            : `Carica immagine ${
                                this.state.files.length + 1
                              } di 50` + ` (Max 15MB per foto)`}
                        </p>
                      </label>
                    </div>
                    <FileUpload50
                      setFileIndex={() => {
                        fileIndex.shift();
                      }}
                      setFile={(temp) => this.setState({ files: temp })}
                      name="partner"
                      files={this.state.files}
                    />
                    <FileNamesComponent
                      name="partner"
                      files={this.state.files}
                      setFile={(temp) => this.setState({ files: temp })}
                      setFileIndex={(temp, i) => fileIndex.unshift(temp[i].id)}
                    />

                    <div className="file-upload">
                      <label
                        className="pointer"
                        htmlFor="partner-video-upload"
                        color="blue"
                        title=""
                      >
                        <p className="pointer-label">
                          {this.state.fileVideoName ||
                            "Carica video (Max 200MB)"}
                        </p>
                      </label>
                    </div>
                  </div>
                )}
                {this.state.fileError &&
                  !this.state.fileVideoName &&
                  this.state.files.length < 1 && (
                    <label style={{ color: "red" }}>
                      {" "}
                      Carica almeno un file
                    </label>
                  )}
              </div>
              {!(this.props.type && this.props.type === "modal") && (
                <div>
                  {" "}
                  &nbsp;
                  <ContactPreferenceForm
                    handler={(event, value) => {
                      this.setState({ radioValue: value });
                    }}
                    logged={false}
                    value={this.state.radioValue}
                    email={this.state.form.email}
                    phone={this.state.form.phone}
                    error={this.state.radioError}
                    label="Per eventuali comunicazioni"
                  />
                  {!this.state.radioValue && this.state.radioError && (
                    <label
                      style={{
                        color: "red",
                        textAlign: "left",
                        margin: 10,
                        marginLeft: 30,
                      }}
                    >
                      Devi effettuare una scelta
                    </label>
                  )}
                  &nbsp;
                  <FormControlLabel
                    className="activate-checkbox"
                    control={
                      <Checkbox
                        color="primary"
                        name="checkedC"
                        id="term-box"
                        onChange={(event, checked) =>
                          this.setState({ checkBox: checked })
                        }
                      />
                    }
                    label={FormLabel()}
                  />
                  {!this.state.checkBox && this.state.termsError && (
                    <label
                      style={{
                        color: "red",
                        textAlign: "left",
                        margin: 10,
                        marginLeft: 30,
                      }}
                    >
                      Devi accettare i termini
                    </label>
                  )}{" "}
                </div>
              )}
              <VideoInput
                maxSize={200000000}
                name="partner"
                setVideoName={(name) => {
                  this.setState({ fileVideoName: name });
                }}
              />
              <div className="bottom-contact-submit">
                <div className="recaptcha">
                  <Recaptcha
                    onVerify={(point) => {
                      this.setState({ recaptchaScore: point });
                    }}
                  />
                </div>
                <button
                  className="activate-submit"
                  onClick={() => {
                    // this.send()
                  }}
                >
                  {" "}
                  Invia
                </button>
              </div>
            </form>
          </div>
          {!this.props.type && <p>&nbsp;</p>}
        </div>
        {this.state.areUSure && (
          <PartnerAreUSure
            close={() => {
              this.setState({ areUSure: false });
            }}
            submit={() => {
              this.setState({ areUSure: false });
              this.sendData(this.state.formdata);
            }}
          />
        )}
      </>
    );
  }

  action(data) {}

  send(formdata) {
    let { formError } = this.state;
    let error = false;
    for (const property in this.state.form) {
      if (
        (property !== "phone" ||
          (this.state.form.phone && this.state.form.phone.length >= 9)) &&
        (property !== "email" || this.state.form.email !== "") &&
        (property !== "CF" || this.state.form.nationality == "105")
      ) {
        if (
          !(
            property === "address" ||
            property === "location" ||
            property === "complete_address"
          ) ||
          this.state.form.nationality != "105"
        ) {
          if (property != "website" && property != "altPhone") {
            formError = validate(
              this.state.form[property],
              property,
              formError
            );
            if (property !== "CF") error = error || formError[property];
          }
        }
      }
    }
    this.setState({ formError });
    if (
      !this.state.form.email &&
      (!this.state.form.phone ||
        (this.state.form.phone && this.state.form.phone.length < 8))
    ) {
      error = true;
      this.setState({ noContactError: true });
      this.setState({
        feedbackModal: true,
        modalText: "Devi inserire un metodo di contatto",
        modalResult: "Errore",
        modalFunction: () => {
          document.getElementById("email").focus();
        },
      });
    }
    let fileError = false;
    if (this.props.data) fileError = false;
    this.setState({ fileError });

    if (!this.state.checkBox) this.setState({ termsError: true });
    if (this.state.form.username.length < 6) {
      const modalFunction = () => {
        document.getElementById("username").focus();
      };
      error = true;
      this.setState({
        feedbackModal: true,
        modalResult: "Errore",
        modalText: "Errore l'username deve contenere almeno 6 caratteri",
        modalFunction,
      });
    }
    if (!error && this.state.checkBox) {
      if (this.state.files.length < 1 && !this.state.fileVideoName) {
        this.setState({ areUSure: true, formdata });
      } else this.sendData(formdata);
    }
  }

  sendData(formdata) {
    const newFormData = new FormData();
    formdata.forEach(function (value, key) {
      if (value.length <= 0) {
      } else if (value.size === 0) {
      } else {
        newFormData.set(key, value);
      }
    });
    newFormData.forEach(function (value, key) {});
    this.setState({ uploading: true });
    getCsrf().then((csrf) => {
      this.setState({ csrf_token: csrf });
      formdata.set("csrf_token", csrf);
      newFormData.set("csrf_token", csrf);
      this.setState({ uploading: true });
      requests("sendform", "partner", newFormData).then((data) => {
        this.setState({ uploading: false });
        let modalFunction = null;
        if (data.errorCode && data.errorCode.indexOf("USER") > -1) {
          modalFunction = () => {
            document.getElementById("username").focus();
          };
        }
        if (data.errorCode && data.errorCode.indexOf("EMAIL") > -1) {
          modalFunction = () => {
            document.getElementById("email").focus();
          };
        }
        data.response !== "fail"
          ? this.props.history.push("partner-registrato")
          : this.setState({
              feedbackModal: true,
              modalResult: "Errore",
              modalText: data.message,
              modalFunction,
            });
      });
    });
  }

  setAndValidateForm(data, attribute, minLenght) {
    this.setState({ noContactError: false });
    const { form } = this.state;
    let { formError } = this.state;
    form[attribute] = data;
    this.setState({ form });
    formError = validate(data, attribute, formError);
    if (minLenght)
      if (form[attribute] < minLenght) {
        formError[attribute] = true;
      }
    this.setState({ formError });
  }

  // to update the changes in the input and activate it
  updateInputValue(e) {
    this.setState({
      inputValue: e.target.value,
    });
    this.activateField(e);
    e.preventDefault();
  }
}

function getCsrf() {
  return new Promise((result, err) =>
    Axios.get(`${baseURL}api/auth/securitytoken`)
      .then((resolve) => {
        if (resolve != null) {
          result(resolve.data._csrf);
        } else result({ DATA: "NO DATA" });
      })
      .catch((err) => {
        return result({ err });
      })
  );
}

export default withContext(Partner);
