import data from "./Aforism";
function randomDate(start, end, startHour, endHour) {
    var date = new Date(+start + Math.random() * (end - start));
    var hour = startHour + Math.random() * (endHour - startHour) | 0;
    date.setHours(hour);
    return date;
}
let dedics = [
    {
        authorName:'Maria',
        text: data[parseInt(Math.random()*data.length-1+'')],
        time:randomDate(new Date(2020, 0, 1), new Date(), 0, 24)
    },
    {
        authorName:'Mario',
        text: data[parseInt(Math.random()*data.length-1+'')],
        time: randomDate(new Date(2020, 0, 1), new Date(), 0, 24)
    },
    {
        authorName:'Daria',
        text: data[parseInt(Math.random()*data.length-1+'')],
        time: randomDate(new Date(2020, 0, 1), new Date(), 0, 24)
    },
    {
        authorName:'Dario',
        text: data[parseInt(Math.random()*data.length-1+'')],
        time: randomDate(new Date(2020, 0, 1), new Date(), 0, 24)
    },
    {
        authorName:'Anonimo',
        text: data[parseInt(Math.random()*data.length-1+'')],
        time: randomDate(new Date(2020, 0, 1), new Date(), 0, 24)
    },
    {
        authorName:'Marta',
        text: data[parseInt(Math.random()*data.length-1+'')],
        time: randomDate(new Date(2020, 0, 1), new Date(), 0, 24)
    },
    {
        authorName:'Maria',
        text: data[parseInt(Math.random()*data.length-1+'')],
        time: randomDate(new Date(2020, 0, 1), new Date(), 0, 24)
    },
]
export  default dedics;
