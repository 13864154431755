/* eslint-disable array-callback-return */
import React, { Component, Fragment } from "react";
import withContext from "../../WithContextFile";
import "./AdminPanel.scss";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import CropFreeIcon from "@material-ui/icons/CropFree";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Collapse from "@material-ui/core/Collapse";
import {
	CreateOutlined, DeleteOutlined, ExpandLess, ExpandMore, VisibilityOutlined,
} from "@material-ui/icons";
import GiveProperty from "./PartnerPanelPages/givePropertyModal";
import addressServiceGetData from "../FormComponents/addressService";
import { getNameFromID, getNameFromIDForAllType } from "../memorial-page/regioni";
import requests from "../../Services/HttpServices";
import ContactPreferenceForm from "../FormComponents/ContactPreferencesForm";
import ModifyAccount from "../Auth/ModifyAccount/ModifyAccount";
import ResetPasswordForm from "../Auth/Password-reset/ResetPasswordForm";
import dedics2 from "../../assets/Stab/Dedic request";
import LampadePopup from "./ClientPanelPages/LampadePopup";
import LampadeCreatePopup from "./ClientPanelPages/LampadeCreatePopup";
import ModifyAccountNotification from "../Auth/ModfyAccountNotification/ModifyAccountNotification";
import DoYouWanToDelete from "./DoYouWanToDelete";
import SuccessToast from "./helperComponent/SuccessToast";
import ClientPanelTableWrapper, { createDeceasedDataClientTable } from "./ClientPanelPages/ClientPanelTableWrapper";
import FeedbackModal from "../FormComponents/FeedbackModal";
import { Button, CircularProgress, Typography } from "@material-ui/core";

const dedics = dedics2;
let newRows  = [];
let activationStab = [];
let rows           = [];
const pathNames    = ["pages", "dedic", "account"];
const subtitles    = ["Gestione cari", "Gestione dediche", "Gestione account"];
let radioValue     = "sms";
let pageError      = true;
let user;

class AdminPanel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pagesNumber : null,
			radioValue  : "0",
			contactData : {},
		};
		let radioContact;
		try {
			radioContact = this.state.loggedUser.preferred_contact.type;
		} catch (e) {
			radioContact = "";
		}

		this.setState({ radioValue: radioContact });
	}

	componentDidMount() {
		window.innerWidth < 768 && this.setState({ menu: true });
		this.setState({ contactData: this.getContactData() });

		try {
			user = JSON.parse(sessionStorage.getItem("User")).userdata;
		} catch (e) {
			console.log(e);
			this.setState({ loaded: true });
		}
		radioValue = user.preferred_contact.type;
		this.setState({ radioValue });
		this.setState({ email: user.email });
		requests("get", "getDeceasedByID", user.user_id)
			.then((data) => {
				if (data.data.response == "success") {
					activationStab = data.data.deceased_list;
					this.setState({ pagesNumber: activationStab.length });
					activationStab.map((element, index) => {
						const name                   = getNameFromID(element.graveRegion);
						activationStab[index].region = name;
						addressServiceGetData("province", element.graveRegion)
							.then((province) => {
								const provinceName             = getNameFromIDForAllType(element.graveProvince, province);
								activationStab[index].province = provinceName;
								addressServiceGetData("location", element.graveProvince)
									.then((locations) => {
										const locationName             = getNameFromIDForAllType(element.graveLocation, locations);
										activationStab[index].location = locationName;
										this.activationDataTable();
									});
							});
					});
					this.setState({ loaded: true });
				} else {
					this.setState({ pagesNumber: 0 });
					this.setState({ loaded: true });
				}
			});
	}

	render() {
		let user;
		try {
			user = sessionStorage.getItem("User") && JSON.parse(sessionStorage.getItem("User")).userdata;
		} catch (e) {

		}

		if (!user) {
			window.location.href = "/home";
			// pageError = false
		} else if (user.permissions.platformAccess == 2) {
			window.location.href = "/partner-panel";
			pageError            = true;
		} else if (user.permissions.platformAccess == 1) {
			window.location.href = "/admin-panel";
			pageError            = true;
		} else if (window.location.hash) {
			pageError = false;

			const pathIndex = pathNames.indexOf(window.location.hash.slice(1, window.location.hash.length));
			if (pathIndex === -1) {
				window.history.pushState("object or string", "Title", "/client-panel#account");
				this.setState({ type: 2 });
			} else if (this.state.type !== pathIndex) {
				this.setState({ type: pathIndex });
				this.activationDataTable();
			}
		} else {
			pageError = false;
			window.history.pushState("object or string", "Title", "/client-panel#account");
		}

		if (pageError) return <></>;
		return (
			<>
				{this.state.showLampadePopup && (
					<LampadePopup
						lampadaData = {this.state.lampadaDataState}
						lampadeCollegate = {this.state.lampadeCollegateState}
						nomeDefunto = {this.state.showLampadePopupNome}
						close={() => this.setState({ showLampadePopup: null })}
					/>
				)}
				{this.state.showLampadeCreatePopup && (
					<LampadeCreatePopup
						idDefunto = {this.state.createLampadaDefunto}
						idGestore = {this.state.createLampadaGestore}
						nomeDefunto = {this.state.showLampadeCreatePopupNome}
						open={(lampadaData, name) => this.setState({ lampadaDataState: lampadaData, showLampadePopupNome: name, showLampadePopup: true, showLampadeCreatePopup: null })}
						close={() => this.setState({ showLampadeCreatePopup: null })}
					/>
				)}

				{this.state.feedbackModal && (
					<FeedbackModal
						result={this.state.feedbackModalResult}
						close={() => this.setState({ feedbackModal: false })}
						label={this.state.feedbackModalLabel}
					/>
				)}
				{this.state.modalLoading && (
					<div className="modal-bg">
						<div
							className="flex flex-column"
							style={{
								alignItems     : "center",
								justifyContent : "center",
							}}
						>
							<CircularProgress color="primary" />
							{" "}
							<Typography className="text-center" style={{ color: "white" }}>
								{this.state.loadingLabel}
							</Typography>
						</div>
					</div>
				)}
				<div style={{ minHeight: 700 }} className="bg-white dashboard">
					<SuccessToast label={this.state.toastLabel} successToastOpacity={this.state.successToastOpacity} />
					{this.state.loaded && (
						<div id="second-in">
							<div id="upper" style={{ height: 300 }}>
								<div id="card-row">
									<div
										id="card"
										style={{ display: "none" }}
										onClick={() => {
											this.activationDataTable();
											window.history.pushState(
												"object or string",
												"Title",
												"/client-panel#pages",
											);
											this.setState({ type: 0 });
										}}
										className="ml-0 card-fix"
									>
										<div>
											<label id="title-card">Gestione cari</label>
											<label>{this.state.pagesNumber}</label>
										</div>
										<div id="icon">
											<CropFreeIcon style={{ fontSize: 19 }} />
										</div>
									</div>
									<div
										id="card"
										style={{ display: "none" }}
										className="card-fix"
										onClick={() => {
											window.history.pushState(
												"object or string",
												"Title",
												"/client-panel#dedic",
											);
											this.setState({ type: 1 });
										}}
									>
										<div>
											<label id="title-card">Gestione Dediche</label>
											<label>{(dedics && dedics.length) || 0}</label>
										</div>
										<div id="icon" style={{ backgroundColor: "lightgreen" }}>
											<BorderColorIcon style={{ fontSize: 19 }} />
										</div>
									</div>
									<div
										id="card"
										className="card-fix"
										style={{ display: "none" }}
										onClick={() => {
											window.history.pushState(
												"object or string",
												"Title",
												"/client-panel#account",
											);
											this.setState({ type: 2 });
										}}
									>
										<div id="page-detail" className="place-fix">
											<label style={{ color: "#2d2b2b" }}>Gestisci account</label>
											<div id="icon" style={{ backgroundColor: "lightsalmon" }}>
												<AccountCircleIcon style={{ fontSize: 19 }} />
											</div>
										</div>
									</div>
								</div>
							</div>
							<div id="content">
								<label className="admin-content-subtitle">{subtitles[this.state.type]}</label>
								{this.state.type === 2 && (
									<div
										id="collapsable-container"
										style={{
											display       : "flex",
											flexDirection : "column",
										}}
									>
										<div
											onClick={() => {
												this.setState({
													collapse  : !this.state.collapse,
													collapse3 : false,
													collapse1 : false,
													collapse2 : false,
													collapse4 : false,
												});
											}}
											className="collapsable-list"
										>
											<div
												style={{
													display       : "flex",
													flexDirection : "row",
													margin        : "auto",
												}}
											>
												<label style={{
													margin      : "auto",
													paddingLeft : 24,
												}}
												>
													{" "}
													Cambia password
												</label>
												<div>{this.state.collapse ? <ExpandLess /> : <ExpandMore />}</div>
											</div>
										</div>

										<Collapse in={this.state.collapse} timeout="auto" unmountOnExit>
											<div style={{
												display        : "flex",
												justifyContent : "center",
											}}
											>
												<ResetPasswordForm />
											</div>
										</Collapse>

										<div
											onClick={() => {
												this.setState({
													collapse1 : !this.state.collapse1,
													collapse3 : false,
													collapse2 : false,
													collapse  : false,
													collapse4 : false,
												});
											}}
											className="collapsable-list"
										>
											<div
												style={{
													display       : "flex",
													flexDirection : "row",
													margin        : "auto",
												}}
											>
												<label style={{
													margin      : "auto",
													paddingLeft : 24,
												}}
												>
													{" "}
													Cambia preferenze contatto
												</label>
												<div>{this.state.collapse1 ? <ExpandLess /> : <ExpandMore />}</div>
											</div>
										</div>

										<Collapse in={this.state.collapse1} timeout="auto" unmountOnExit>
											<div id="preferences-panel">
												<div id="toCenterFix">
													<ContactPreferenceForm
														setting
														logged
														email={user.email}
														phone={user.telephone}
														handler={(event, value) => {
															this.setState({ radioValue: value });
														}}
														value={this.state.radioValue}
														error={this.state.radioError}
														label="Cambia la tua preferenza contatto"
													/>
												</div>
											</div>
										</Collapse>

										<div
											onClick={() => {
												this.setState({
													collapse2 : !this.state.collapse2,
													collapse3 : false,
													collapse1 : false,
													collapse  : false,
													collapse4 : false,
												});
											}}
											className="collapsable-list"
										>
											<div
												style={{
													display       : "flex",
													flexDirection : "row",
													margin        : "auto",
												}}
											>
												<label style={{
													margin      : "auto",
													paddingLeft : 24,
												}}
												>
													{" "}
													Cambia dati account
												</label>
												<div>{this.state.collapse2 ? <ExpandLess /> : <ExpandMore />}</div>
											</div>
										</div>
										<Collapse in={this.state.collapse2} timeout="auto" unmountOnExit>
											<div style={{
												display        : "flex",
												justifyContent : "center",
											}}
											>
												<ModifyAccount />
											</div>
										</Collapse>
										<div
											onClick={() => {
												this.setState({
													collapse3 : !this.state.collapse3,
													collapse2 : false,
													collapse1 : false,
													collapse  : false,
													collapse4 : false,
												});
											}}
											className="collapsable-list"
										>
											<div
												style={{
													display       : "flex",
													flexDirection : "row",
													margin        : "auto",
												}}
											>
												<label style={{
													margin      : "auto",
													paddingLeft : 24,
												}}
												>
													{" "}
													Cambia preferenze notifica
												</label>
												<div>{this.state.collapse3 ? <ExpandLess /> : <ExpandMore />}</div>
											</div>
										</div>
										<Collapse in={this.state.collapse3} timeout="auto" unmountOnExit>
											<div
												style={{
													display        : "flex",
													justifyContent : "center",
													marginBottom   : 15,
												}}
											>
												<ModifyAccountNotification
													setting
													sessionData={
														this.state.contactData
														&& this.state.contactData.zone
														&& this.state.contactData.zone.length > 0
														&& this.state.contactData.zone
													}
													timeData={this.state.contactData && this.state.contactData.time}
													setContactData={(data) => this.setContactDataAgain(data)}
													onChangeFlag={(change) => {
														!change && this.disableNotification();
														this.setState({ notificationFlag: change });
													}}
													sendArray={(data) => {
														this.setState({
															notificationList  : data,
															errorNotification : data.length <= 0,
														});
													}}
												/>
											</div>
										</Collapse>
										<div
											onClick={() => {
												this.setState({
													collapse3 : false,
													collapse2 : false,
													collapse1 : false,
													collapse  : false,
													collapse4 : !this.state.collapse4,
													type      : 0,
												});
											}}
											className="collapsable-list"
										>
											<div
												style={{
													display       : "flex",
													flexDirection : "row",
													margin        : "auto",
												}}
											>
												<label style={{
													margin      : "auto",
													paddingLeft : 24,
												}}
												>
													Gestisci le tue Lapidi Digitali
												</label>
												<div>{this.state.collapse4 ? <ExpandLess /> : <ExpandMore />}</div>
											</div>
										</div>
										<Collapse in={this.state.collapse4} timeout="auto" unmountOnExit>
											{this.newTable()}
										</Collapse>
									</div>
								)}
								<p>&nbsp;</p>
								{this.state.type !== 2 && (
									<p
										style={{
											textAlign  : "center",
											display    : "flex",
											alignSelf  : "center",
											marginTop  : 0,
											fontWeight : "normal",
											fontSize   : 30,
										}}
										className="admin-content-subtitle"
									>
										QrCode non attivati
									</p>
								)}
								<p>&nbsp;</p>
							</div>
						</div>
					)}
					{this.state.propertyModal && (
						<GiveProperty
							type="deceasedPage"
							deceasedID={this.state.selectedID}
							close={() => {
								this.setState({ propertyModal: false });
							}}
						/>
					)}
					{this.state.modalClear && (
						<DoYouWanToDelete
							close={() => {
								this.setState({ modalClear: false });
							}}
							submit={() => this.clearOperationSendData(this.state.modalClearIndex)}
							text={this.state.labelModalClear}
						/>
					)}
				</div>
			</>
		);
	}

	disableNotification() {
		const bodyData = {
			activeNotification: false,
		};
		requests("post", "modifyContactPreferece", bodyData)
			.then((data) => {
				sessionStorage.removeItem("userContactPreference");
				this.setState({ contactData: null });
			});
	}

	showSuccessTost() {
		this.setState({ successToastOpacity: 100 });
		setTimeout(() => {
			const interval = setInterval(() => {
				this.setState({
					successToastOpacity: this.state.successToastOpacity - 4,
				});
				if (this.state.successToastOpacity <= 0) clearInterval(interval);
			}, 25);
		}, 500);
	}

	newTable() {
		return (
			<ClientPanelTableWrapper
				onRowClick={(id) => {
					const win = window.open(`/memorial?000${id}`, "_blank");
					win.focus();
				}}
				filter={{}}
				history={this.props.history}
				deleteAllOperation={() => {
					alert("delete all");
				}}
				setChecked={(data) => {
					this.setState({ checked: data });
				}}
				setFilter={(data) => {
					this.setState({ filter: data });
				}}
				setNumber={() => {
				}}
				setAllNumber={() => {
				}}
				type={this.state.type}
				data={newRows}
				actionData={(
					<div>
						<CreateOutlined fontSize="small" className="dash-icon" />
						<VisibilityOutlined fontSize="small" className="dash-icon" />
						<DeleteOutlined fontSize="small" className="dash-icon" />
					</div>
				)}
			/>
		);
	}

	/* TABLE UTILS */

	activationDataTable() {
		this.setState({ page: 0 });
		rows    = [];
		newRows = [];

		activationStab.map((e) => {
			let expired = (
				<Button
					color="primary"
					variant="contained"
					onClick={(event) => {
						event.stopPropagation();
						const win = window.open("/memorial-activate", "_blank");
						win.focus();
						this.setState({ reload: !this.state.reload });
					}}
				>
					Attiva memorial
				</Button>
			);
			if (e.memorialExpiration && e.memorialExpiration !== "Non trovato") expired = e.memorialExpiration;
			
			//Leggi i dati della Lampada
			let lampadaData = null;
			requests("get", "lampadaData", parseInt(e.ID, 10)).then((data) => {
				if (data?.data?.result === "success") {
					lampadaData = data.data.lampadaData;
				}
				e.lampadaData = lampadaData;
			});

			newRows.push(
				createDeceasedDataClientTable(
					e.ID,
					`${e.name} ${e.surname}`,
					`${e.location} (${e.province}) ${e.graveAddress}`,
					`memorial?000${e.ID}`,
					expired,
					(data) => this.setState(data),
					null,
					user.user_id,
					e.lampadaData
				),
			);
		});
		this.setState({ loaded: true });
	}

	getContactData() {
		let data;
		try {
			data = JSON.parse(sessionStorage.getItem("userContactPreference"));
		} catch (e) {
			data = [];
		}
		return data;
	}

	setContactDataAgain(time) {
		let user;
		try {
			user = JSON.parse(sessionStorage.getItem("User"));
		} catch (e) {

		}
		let userId = null;
		try {
			userId = user.userdata.user_id;
		} catch (e) {
			userId = null;
		}
		if (userId) {
			requests("get", "contactData", userId)
				.then((result) => {
					const { data } = result;
					if (data.response === "success") {
						sessionStorage.setItem(
							"userContactPreference",
							JSON.stringify({
								zone: data.data,
								time,
							}),
						);
					}
				});
		}
	}
}

export default withContext(AdminPanel);
