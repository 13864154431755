let data = [{
    name: 'Mario',
    surname: 'Rossi',
    city: 'Roma',
    address:'Via Roma n. 1',
    email: 'sonounamail@mail.it',
    phone: '3333330003',
    deceasedName:'Demo Rossi',
    dedic:'asdasdasdasd',
    type:0
},
    {
        name: 'Marioa',
        surname: 'Rossis',
        city: 'Roma',
        address:'Via Roma n. 14',
        email: 'sonounamail@mail.it',
        phone: '3333330003',
        deceasedName:'Demo demo',
        dedic: 'asdasdasdasd',
        qrCode: '123123123123',
        link: "https://www.frasimania.it/wp-content/uploads/2019/02/frasi-cura-nascita-morte-intervallo-700x700.jpg",
        type:2,
    }
]
export default data;
