import React from "react";
export default function ShowInfo(props){
    let close = false
    return<div className={'bg-modal'} onClick={()=>{close?props.handleClose():close=true}}>
        <div id={'info-container'} onClick={()=>close=false}>
            <div className={'buy-form-paragraph'} style={{textAlign:"center",marginTop:0}}>Notifiche</div>
            <label style={{textAlign:"justify"}}><p>Abilitando le notifiche le invieremo automaticamente un messaggio, attraverso il metodo di contatto da lei scelto, ogni volta che:</p>
                <p>- Un utente interagisce con una pagina dove lei ha precedentemente interagito (Aggiungendo dediche, condoglianze ecc...) </p>
                <p>- Un utente interagisce con una pagina dove lei e` il proprietario</p>
                <p>- Viene aggiunta una lapide virtuale o un necrologio appartenente alla zona d'interesse da lei scelta</p>
            </label>
            <button onClick={()=>props.handleClose()}>Chiudi</button>
        </div>
    </div>
}
