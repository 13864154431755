import React from "react";

export default function DoYouWantToDelete(props) {
  return (
    <div className="bg-modal">
      <div className="main-container" style={{ width: 300 }}>
        <label
          className="buy-form-paragraph"
          style={{
            alignSelf: "center",
            margin: "0 auto",
            marginBottom: 20,
            fontSize: 15,
            color: "#4a4a4a",
            fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
          }}
        >
          Attenzione!
        </label>
        <label
          style={{
            fontSize: 12,
            color: "#4a4a4a",
            fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
          }}
        >
          Sei sicuro di voler eliminare {props.text}, l'operazione sara`
          irreversibile
        </label>
        <div id="button-field">
          <button
            onClick={() => {
              props.submit();
              props.close();
            }}
            id="submit"
          >
            Si
          </button>
          <button
            onClick={() => {
              props.close();
            }}
            id="submit"
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
}
