import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "bulma/css/bulma.css";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import MuiPickersUtilsProvider from "@material-ui/pickers/MuiPickersUtilsProvider";
import MomentUtils from "@date-io/moment";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import * as serviceWorker from "./serviceWorker";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#fff",
      main: "#ffb931",
      dark: "#000",
    },
    secondary: {
      main: "#00ff00",
    },
  },
  typography: {
    useNextVariants: true,
  },
});
ReactDOM.render(
  <>
    <MuiPickersUtilsProvider locale="IT" utils={MomentUtils}>
      <MuiThemeProvider theme={theme}>
        <GoogleReCaptchaProvider reCaptchaKey="6LePdzEaAAAAAInWf6ofZOvzadkM2wH4VuImVmAc">
          <App />
        </GoogleReCaptchaProvider>
      </MuiThemeProvider>
    </MuiPickersUtilsProvider>
  </>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
