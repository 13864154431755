import React, { useState } from "react";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from '@material-ui/core/Tooltip';
import { Switch } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import requests, { baseURL } from '../../../Services/HttpServices';
import "./LampadePopup.scss";

var lampadaData;

function saveLampada(secondiSenzaConnessione, giorniSenzaConnessione, secondiProssimaConnessione, modalità, interruttore) {
  var partsSenzaConnessione = secondiSenzaConnessione.split(":");
  var partsProssimaConnessione = secondiProssimaConnessione.split(":");
  var secSenzaConnessione = dateToSeconds(giorniSenzaConnessione, parseInt(partsSenzaConnessione[0]), parseInt(partsSenzaConnessione[1]), parseInt(partsSenzaConnessione[2]));
  var secProssimaConnessione = dateToSeconds(0, parseInt(partsProssimaConnessione[0]), parseInt(partsProssimaConnessione[1]), parseInt(partsProssimaConnessione[2]));
  if (secSenzaConnessione < 1) { secSenzaConnessione = 1; }
  if (secProssimaConnessione < 1) { secProssimaConnessione = 1; }

  const bodydata = {};
  bodydata["idDefunto"] = parseInt(lampadaData.idDefunto, 10);
  bodydata["secondiSenzaConnessione"] = parseInt(secSenzaConnessione, 10); lampadaData.secondiFunzionamentoSenzaConnessione = secSenzaConnessione;
  bodydata["secondiProssimaConnessione"] = parseInt(secProssimaConnessione, 10); lampadaData.secondiProssimaConnessione = secProssimaConnessione;
  if (interruttore == 1) { bodydata["statoLampadaImpostato"] = parseInt(modalità, 10); lampadaData.statoLampadaImpostato = modalità; } 
  else { bodydata["statoLampadaImpostato"] = parseInt(interruttore, 10); lampadaData.statoLampadaImpostato = modalità; }

	requests("post", "lampadaSet", bodydata).then((data) => {
		if (data?.result === "success") { console.log("Success:" + data?.log); }
		else { console.log("Fail: " + data?.log); }
	});
}

function saveSwitch(interruttore, modalita) {
  if (interruttore == 1) { interruttore = 0; } else { interruttore = 1; }
  if (lampadaData.statoLampadaImpostato == 0) { lampadaData.statoLampadaImpostato = modalita; }
  
  const bodydata = {};
  bodydata["idDefunto"] = parseInt(lampadaData.idDefunto, 10);
  bodydata["secondiSenzaConnessione"] = parseInt(lampadaData.secondiFunzionamentoSenzaConnessione, 10);
  bodydata["secondiProssimaConnessione"] = parseInt(lampadaData.secondiProssimaConnessione, 10);
  if (interruttore == 1) { bodydata["statoLampadaImpostato"] = parseInt(lampadaData.statoLampadaImpostato, 10); } 
  else { bodydata["statoLampadaImpostato"] = parseInt(interruttore, 10); }

  requests("post", "lampadaSet", bodydata).then((data) => {
    if (data?.result === "success") { console.log("Success:" + data?.log); }
    else { console.log("Fail: " + data?.log); }
  });
}

function secondsToDate(seconds) {
  var giorni = Math.trunc(seconds / 86400);
  var ore = Math.trunc((seconds-giorni*86400) / 3600);
  var minuti = Math.trunc((seconds-giorni*86400-ore*3600) / 60);
  var secondi = Math.trunc(seconds-giorni*86400-ore*3600-minuti*60);

  var date = {g: giorni, h: ore, min: minuti, sec: secondi};
  return date;
}
function dateToSeconds(g, h, min, sec) {
  var secondi = g*86400;
  secondi += h*3600;
  secondi += min*60;
  secondi += sec;

  return secondi;
}

function lampCollAttiva(idProduzione, attiva) {
  const bodydata = {};
  bodydata["idProduzione"] = parseInt(idProduzione, 10);
  bodydata["attiva"] = attiva ? 1 : 0;

	requests("post", "lampadeCollegateAttiva", bodydata).then((data) => {
		if (data?.result === "success") { console.log("Success:" + data?.log); }
		else { console.log("Fail: " + data?.log); }
	});
}
function lampCollDelete(idProduzione) {
  const bodydata = {};
  bodydata["idProduzione"] = parseInt(idProduzione, 10);

	requests("post", "lampadeCollegateDelete", bodydata).then((data) => {
		if (data?.result === "success") { console.log("Success:" + data?.log); }
		else { console.log("Fail: " + data?.log); }
	});
}

export default function LampadePopup(props) {
  let close = true;

  lampadaData = props.lampadaData;
  var parts2UltimoControllo = lampadaData.dataUltimoControllo.split(" ");
  var partsUltimoControllo = parts2UltimoControllo[1].split(":");
  var secUltimoControllo = dateToSeconds(0, parseInt(partsUltimoControllo[0]), parseInt(partsUltimoControllo[1]), parseInt(partsUltimoControllo[2]));

  var dateProssimaConnessione = secondsToDate(lampadaData.secondiProssimaConnessione);
  var dateConnessione = secondsToDate(parseInt(secUltimoControllo) + parseInt(lampadaData.secondiProssimaConnessione));
  var dateSenzaConnessione = secondsToDate(lampadaData.secondiFunzionamentoSenzaConnessione);

  //Variabili
  const [modalità, setModalità] = useState(lampadaData.statoLampadaImpostato); if (modalità == 0) { setModalità(1); }
  const [secondiSenzaConnessione, setSecondiSenzaConnessione] = useState(`${dateSenzaConnessione.h.toString().padStart(2, '0')}:${dateSenzaConnessione.min.toString().padStart(2, '0')}:${dateSenzaConnessione.sec.toString().padStart(2, '0')}`);
  const [giorniSenzaConnessione, setGiorniSenzaConnessione] = useState(dateSenzaConnessione.g);
  const [secondiProssimaConnessione, setSecondiProssimaConnessione] = useState(`${dateProssimaConnessione.h.toString().padStart(2, '0')}:${dateProssimaConnessione.min.toString().padStart(2, '0')}:${dateProssimaConnessione.sec.toString().padStart(2, '0')}`);
  const [interruttore, setInterruttore] = useState(lampadaData.statoLampadaImpostato != 0 ? 1 : 0)
  const [lampadeCollegate, setLampadeCollegate] = useState(props.lampadeCollegate);
  var dateConnessioneString = `${dateConnessione.h.toString().padStart(2, '0')}:${dateConnessione.min.toString().padStart(2, '0')}:${dateConnessione.sec.toString().padStart(2, '0')}`;

  //Stato
  var stato = 'In anomalia';
  if (lampadaData.statoLampadaEffettivo == 1) { stato = 'Accesa'; }
  if (lampadaData.statoLampadaEffettivo == 0) { stato = 'Spenta'; }

  return (
    <div className="lampade-main-container" onClick={() => { close ? props.close() : (close = true); }}>
      <div onClick={() => { close = false; }} className="lampade-card" style={{ maxWidth: 360 }}>

        <label className="ttitle">Gestisci Lampada</label>
        <label className="subbtitle">{props.nomeDefunto}</label>

        <div className="row">
          <div style={{ marginTop: 9, width: 120, textAlign: "left" }}>
            <label style={{ fontSize: 13, color: "#4a4a4a", transform: "translate(0px, 18px) scale(1)" }}>Seriale Lampada</label>
            <br /><div style={{ marginTop: 0, fontSize: 16, textAlign: "center" }}><b>{lampadaData.serialeLampada}</b></div>
          </div>
          <div className="lampstatus" style={{ width: 198, height: 45 }} >
            <img
    				  id="lamp-switch"
    				  src={`/api/images/switch-${interruttore == 1 ? 'on' : 'off'}.png`}
    				  style={{ width: 40, alignSelf: "center", marginRight: 15, marginLeft: 15 }}
   					  className="dash-icon"
              onClick={e => {
                e.stopPropagation();
                saveSwitch(interruttore, modalità);
                if (interruttore == 1) { setInterruttore(0); } else { setInterruttore(1); }
              }}
  			    />
            <div style={{ marginTop: 0 }}>
              <p>Stato: <b>{stato}</b></p>
              <p>Alimentazione: <b>{lampadaData.livelloBatteria}%</b></p>
            </div>
          </div>
        </div>

        <div className="row">
          <div style={{ marginTop: 0 }}>
            <InputLabel style={{fontSize: 13, color: "#4a4a4a", textAlign: "left", transform: "translate(0px, 18px) scale(1)"}} variant="filled" id="modalita-label">Modalità Lampada</InputLabel>
            <Select
              labelId="modalita-label"
					    id="modalita"
					    variant="outlined"
					    name="modalita"
					    value={modalità}
              style={{ width: 135, marginTop: 0}}
					    onChange={(event) => { setModalità(event.target.value); }}
					    input={<Input color="primary" />}
				    >
					    <MenuItem key="fissa" value={1}>Sempre Accesa</MenuItem>
					    <MenuItem key="mode2" value={2}>Effetto Fiamma</MenuItem>
					    <MenuItem key="mode3" value={3}>Evanescenza</MenuItem>
				    </Select>
          </div>
          <div style={{ marginTop: 0 }}>
            <InputLabel className="inputlabel" style={{fontSize: 12, color: "#4a4a4a", textAlign: "left", transform: "translate(0px, 18px) scale(1)"}} variant="filled" id="time-label">Tempo senza connessione</InputLabel>
            <Input
              labelId="time-label"
              className="field"
              type="int"
              name="giorniSenzaConnessioneGestore"
              style={{ width: 30, height: 37 }}
              value={giorniSenzaConnessione}
              onChange={(event) => { if (event.target.value == '') { setGiorniSenzaConnessione(0); } else { setGiorniSenzaConnessione(event.target.value); }}}
            />
            gg
            <Input
              labelId="time-label"
              className="field"
              type="time"
              step="1"
              name="secondiSenzaConnessioneGestore"
              style={{ marginLeft: 10, width: 100, height: 37 }}
              value={secondiSenzaConnessione}
              onChange={(event) => { setSecondiSenzaConnessione(event.target.value); }}
            />
          </div>
        </div>

        <div className="row">
          <div style={{ marginTop: 9, textAlign: "left" }}>
            <label style={{ fontSize: 13, color: "#4a4a4a", transform: "translate(0px, 18px) scale(1)" }}>Prossima connessione</label>
            <br /><div style={{ marginTop: 0, fontSize: 19, textAlign: "center" }}><b>{dateConnessioneString}</b></div>
          </div>
          <div style={{ marginTop: 0 }}>
            <InputLabel className="inputlabel" style={{fontSize: 12, color: "#4a4a4a", textAlign: "left", transform: "translate(0px, 18px) scale(1)"}} variant="filled" id="conn-label">Tempo ogni conessione</InputLabel>
            <Input
              labelId="conn-label"
              className="field"
              type="time"
              step="1"
              name="secondiProssimaConnessione"
              style={{ height: 37 }}
              value={secondiProssimaConnessione}
              onChange={(event) => { setSecondiProssimaConnessione(event.target.value); }}
            />
          </div>
        </div>
        
        <label className="titolotabella">Lampade Collegate</label>
        <div className="row">
          <div className="tabellalampadecollegate">
              {lampadeCollegate.map((lampCollegata, index) => (
                <div className="lampadecollegate">
                  <Tooltip title="ID Produzione"><p style={{ padding: 4, width: 200 }}>Lampada Numero {lampCollegata.idProduzione}</p></Tooltip>

                  <Tooltip title="Abilita Lampada">
                    <Switch className="lampadecollegateswitch" color="primary" defaultChecked={lampCollegata.lampadaAbilitataDaGestore == 1 ? true : false}
                      onChange={(e, checked) => { const c = [...lampadeCollegate]; c[index].lampadaAbilitataDaGestore = checked ? "1" : "0"; lampCollAttiva(lampCollegata.idProduzione, checked); setLampadeCollegate(c); }}
					          />
                  </Tooltip>

                  <Tooltip title="Cancella Lampada">
					          <IconButton size="small" className="icon-button"
                      style={{ height: 30, width: 30, padding: 8 }}
						          onClick={e => {
                        const confirmed = window.confirm("Sei sicuro di voler cancellare questa Lampada?");
                        if (confirmed) { const c = lampadeCollegate.filter((_, i) => i !== index); lampCollDelete(lampCollegata.idProduzione); setLampadeCollegate(c); }
                      }}
                    >
				            <img id="abilita-lamp" src={`/api/images/cancella.svg`} className="dash-icon" />
				            </IconButton>
                  </Tooltip>
                </div>
              ))}
          </div>
        </div>
        
        <div style={{alignSelf: "flex-end", marginTop: "auto" }}>
          <button style={{width: "auto", marginRight: "10px"}} onClick={() => { saveLampada(secondiSenzaConnessione, giorniSenzaConnessione, secondiProssimaConnessione, modalità, interruttore); }}>Salva</button>
          <button onClick={() => { props.close(); }}>Chiudi</button>
        </div>
      </div>
    </div>
  );
}