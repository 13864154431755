import React, { Component, Fragment } from "react";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import requests, { baseURL } from "../../../Services/HttpServices";
import FeedbackModal from "../../FormComponents/FeedbackModal";

class ContactVerify extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    this.setState({ token: params.get("token") });
  }

  send() {
    requests("get", "unsubscribe", this.state.token).then((data) => {
      if (data.data.response === "success") {
        this.setState({
          modalText: "Notifiche disattivate con successo",
          modalResult: "Successo",
        });
      } else
        this.setState({ modalText: data.data.message, modalResult: "Errore" });

      this.setState({ feedbackModal: true });
    });
  }

  render() {
    return (
      <>
        <div>
          {this.state.feedbackModal && (
            <FeedbackModal
              result={this.state.modalResult}
              close={() => {
                this.setState({ feedbackModal: false });
                window.location.href = `${baseURL}home?name=&region=&province=&location=&cap=&page=0`;
              }}
              label={this.state.modalText}
            />
          )}
          <div
            style={{
              height: window.innerHeight - 250,
              textAlign: "center",
            }}
          >
            <p
              className="death-data"
              style={{
                alignSelf: "center",
                marginTop: 20,
                marginBottom: 20,
                textAlign: "center",
              }}
            >
              Attenzione!
            </p>
            <p
              style={{
                alignSelf: "center",
                marginTop: 20,
                marginBottom: 20,
                textAlign: "center",
              }}
            >
              Sei sicuro di voler disattivare le notifiche per il defunto?
            </p>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                alignSelf: "center",
                maxWidth: 300,
                margin: "auto",
              }}
            >
              <button
                className="modal-button"
                onClick={(e) => {
                  this.send();
                }}
              >
                Si
              </button>
              <button
                className="modal-button"
                onClick={(e) => {
                  window.location.href = `${baseURL}home?name=&region=&province=&location=&cap=&page=0`;
                }}
              >
                Annulla
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ContactVerify;
