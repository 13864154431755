import React, {Component, Fragment} from "react";
import withContext from "../../../WithContextFile";
import ModifyAccount from "../ModifyAccount/ModifyAccount";



class ResetPassword extends Component {
    render() {
        return <Fragment>
                <ModifyAccount/>
            </Fragment>

    }
}

export default withContext(ResetPassword);
