import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';
import {
	CreateOutlined,
	DeleteOutlined,
	SwapHorizontalCircle,
	VisibilityOutlined
} from '@material-ui/icons';
import React from 'react';
import GeneralTable from '../../NewComponent/GeneralTable';
import requests from '../../../Services/HttpServices';
import book from '../../../assets/book.gif';

export default function ClientPanelTableWrapper(props) {
	const filter = { name: '', address: '', page: '', expired: '' };
	const tableHeaderName = [
		{ id: 'name', label: 'Anagrafica', align: 'center' },
		{ id: 'address', label: 'Indirizzo Cimitero', align: 'center' },
		{ id: 'page', label: 'Indirizzo pagina', align: 'center' },
		{ id: 'expired', label: 'Scadenza memorial', align: 'center' },
		{ id: 'action', label: 'Azioni', align: 'center' }
	];
	return (
		<GeneralTable
			client
			filter={filter}
			deleteAllOperation={props.deleteAllOperation}
			setChecked={props.setChecked}
			setFilter={props.setFilter}
			setNumber={() => {}}
			setAllNumber={() => {}}
			headData={tableHeaderName}
			onRowClick={props.onRowClick}
			clickable
			data={props.data}
			actionData={
				<div>
					<CreateOutlined fontSize="small" className="dash-icon" />
					<VisibilityOutlined fontSize="small" className="dash-icon" />
					<DeleteOutlined fontSize="small" className="dash-icon" />
				</div>
			}
		/>
	);
}
export function createDeceasedDataClientTable(id, name, address, page, expired, setState, deceasedData, userId, lampadaData) {
	const action = (
		<div>
			<Tooltip title="Cedi proprietà pagina">
				<IconButton
					size="small"
					onClick={e => {
						e.stopPropagation();
						setState({
							selectedID: id,
							propertyModal: true
						});
					}}
					className="icon-button"
				>
					<SwapHorizontalCircle color="primary" fontSize="small" className="dash-icon" />
				</IconButton>
			</Tooltip>
			<Tooltip title="Visualizza PDF libro delle condoglianze">
				<IconButton
					size="small"
					onClick={e => {
						e.stopPropagation();
						setState({ modalLoading: true, loadingLabel: 'Creazione del pdf...' });
						requests('get', 'pdfBook', id).then(data => {
							if (data?.data?.response === 'success') {
								setState({ modalLoading: false });
								if (window.showSaveFilePicker)
									fetch(data.data.data.pdf.replace('www.', ''), {
										method: 'GET',
										headers: {}
									})
										.then(response => {
											response.arrayBuffer().then(function (buffer) {
												const url = window.URL.createObjectURL(new Blob([buffer]));
												const link = document.createElement('a');
												link.href = url;

												link.setAttribute('download', `Libro-${id}.pdf`); // or any other extension
												link.setAttribute('target', `_blank`); // or any other extension
												document.body.appendChild(link);
												link.click();
												document.body.removeChild(link);
												setState({
													feedbackModal: true,
													feedbackModalLabel: 'Il pdf è stato scaricato con successo',
													feedbackModalResult: 'Successo',
													modalLoading: false
												});
											});
										})
										.catch(err => {
											console.log(err);
										});
								else {
									const win = window.open(data.data.data.pdf, '_blank');
									win.focus();
									setState({
										feedbackModal: true,
										feedbackModalLabel: 'Il pdf è stato scaricato con successo',
										feedbackModalResult: 'Successo',
										modalLoading: false
									});
								}
							} else {
								setState({
									feedbackModal: true,
									feedbackModalLabel: data?.data?.message || 'Errore generico... riprovare',
									feedbackModalResult: 'Errore',
									modalLoading: false
								});
							}
						});
					}}
					className="icon-button"
				>
					<img alt="pdf" src={book} style={{ width: 24 }} className="dash-icon" />
				</IconButton>
			</Tooltip>

			{lampadaData != null && userId == lampadaData?.idGestore && (
				<Tooltip title="Stato Lampada">
					<IconButton
						size="small"
						onClick={e => {
							e.stopPropagation();
							const bodydata = {};
  							bodydata["seriale"] = parseInt(lampadaData.serialeLampada, 10);
  							requests("post", "lampadeCollegateLoad", bodydata).then((data) => {
								if (data?.result === "success") {
      								setState({ lampadaDataState: lampadaData, lampadeCollegateState: data?.lampadeCollegate, showLampadePopupNome: name, showLampadePopup: true })
    							}
								else {
									setState({ lampadaDataState: lampadaData, lampadeCollegateState: [], showLampadePopupNome: name, showLampadePopup: true })
								}
							});
						}}
						className="icon-button"
					>
					<img
    					id="lamp-status"
    					src={`/api/images/lamp-${lampadaData.statoLampadaEffettivo == 1 ? 'accesa' : lampadaData.statoLampadaEffettivo == 0 ? 'spenta' : 'anomalia'}.png`}
    					style={{ height: 32 }}
   					 	className="dash-icon"
  					/>
					</IconButton>
				</Tooltip>
			)}
			{lampadaData == null && (
				<Tooltip title="Crea Lampada">
					<IconButton
						size="small"
						onClick={e => {
							e.stopPropagation();
							setState({ createLampadaDefunto: id, createLampadaGestore: userId, showLampadeCreatePopupNome: name, showLampadeCreatePopup: true })
						}}
						className="icon-button"
					>
					<img
    					id="lamp-status"
    					src={`/api/images/lamp-aggiungi.png`}
    					style={{ height: 32 }}
   					 	className="dash-icon"
  					/>
					</IconButton>
				</Tooltip>
			)}
		</div>
	);
	return { id, name, address, page, expired, action };
}
