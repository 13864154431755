import React, { useEffect, useState } from 'react';
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import AddressService, { buildCapNoLocation } from './AddressComponent';

export default function MultipleReferringPlace(props) {
	const [addressArray, setAddressArray] = useState([{}]);
	const [load, setLoad] = useState(false);
	function addRow() {
		const temp = addressArray;
		temp.push({});
		setAddressArray(temp);
		setLoad(!load);
	}
	useEffect(() => {
		const temp = [];
		if (props.referringPlace?.length > 0) {
			props.referringPlace.forEach((e, i) => {
				const newData = {
					region: e.region,
					province: e.province,
					city: e.location,
					location: e.location,
					cap: e.cap
				};
				if (e.location && e.location !== '') temp.push(newData);
			});
			setAddressArray(temp || [{}]);
		}
	}, [props.referringPlace, props.referringPlace?.length]);
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignContent: 'center',
				alignItems: 'center',
				width: '100%'
			}}
		>
			{addressArray.map((e, index) => (
				<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
					{props.obituary && index === 1 && (
						<div className="buy-form-paragraph">Altri luoghi da notificare</div>
					)}
					<div
						key={index}
						style={{
							display: 'flex',
							alignItems: 'flex-end',
							alignContent: 'flex-end',
							width: '100%'
						}}
					>
						<RemoveCircleOutline
							style={{
								color: 'red',
								display: index <= 0 && 'none',
								marginRight: 10
							}}
							className="pointer"
							onClick={() => {
								if (index === 1 && addressArray.length <= 2) {
									const temp = addressArray;
									temp[index] = {
										region: '',
										province: '',
										location: '',
										cap: ''
									};
									setAddressArray(temp);
									props.onChange(JSON.stringify(temp));
									setLoad(!load);
								} else {
									const temp = addressArray;
									temp[index] = {
										region: '',
										province: '',
										location: '',
										cap: ''
									};
									temp.splice(index, 1);
									setAddressArray(temp);
									props.onChange(JSON.stringify(temp));
									setLoad(!load);
								}
							}}
						/>
						<AddressService
							names="obituary-graveyard"
							form={
								addressArray[index] || {
									region: '',
									province: '',
									location: '',
									cap: ''
								}
							}
							error={props.error}
							req={props.req}
							length={addressArray.length}
							index={index}
							setToSend={(data, index) => {
								props.onChange(JSON.stringify(addressArray));
								setLoad(!load);
							}}
							fullWidth
							multiple
							obituary={props.obituary}
							addRow={props.obituary && addRow}
							forceLoad={() => setLoad(!load)}
							formError={props.formError || {}}
							province={
								props.referringPlace?.length > 0 &&
								props.referringPlace[index] &&
								props.referringPlace[index].provinceList
							}
							comuni={
								props.referringPlace?.length > 0 &&
								props.referringPlace[index] &&
								props.referringPlace[index].locationList
							}
							caps={
								props.referringPlace?.length > 0 && props.referringPlace[index]
									? buildCapNoLocation(props.referringPlace[index].capList) || [e.cap]
									: e.cap && [e.cap]
							}
							disableCap={false}
							disableRoute={false}
							forceDisable={props.disabled}
						/>
					</div>
					{props.error &&
						props.obituary &&
						e.region &&
						e.region !== '' &&
						index === addressArray.length - 1 && (
							<p style={{ color: 'red', margin: 'auto', textAlign: 'center', display: 'none' }}>
								Devi selezionare almeno un luogo di riferimento (Regione, provincia e citta)
							</p>
						)}
				</div>
			))}
			{(addressArray[addressArray.length - 1].city || addressArray[addressArray.length - 1].location) && (
				<AddCircleOutline
					color="primary"
					id="add-row"
					style={{
						visibility:
							(!addressArray[addressArray.length - 1].region ||
								(addressArray.length === 1 && props.obituary)) &&
							'hidden'
					}}
					className="mt-3 pointer"
					onClick={addRow}
				/>
			)}

			{props.error && !props.obituary && (
				<p style={{ color: 'red', margin: 'auto', textAlign: 'center' }}>
					Devi selezionare almeno un luogo di riferimento
				</p>
			)}
		</div>
	);
}
