import React, { useEffect } from "react";
import { Button, Typography } from "@material-ui/core";
import { clearCache } from "clear-cache";

export default function NewUpdate(props) {
	useEffect(() => {
		const meta = document.createElement("meta");
		meta.setAttribute("http-equiv", "cache-control");
		meta.setAttribute("content", "no-cache");
		document.head.appendChild(meta);
		setTimeout(() => {
			clearCache(true);
			props.update();
		}, 10);
	}, []);
	// return <div />;F
	return (
		<div
			className="fixed absolute-center min-w-48 min-h-64 justify-between align-center items-center flex bottom-0 border-1 shadow-3 p-20"
			style={{
				display      : "none",
				background   : "white",
				borderColor  : "#fa0",
				borderRadius : 10,
				zIndex       : 10000,
			}}
		>
			<Typography className="text-14" style={{ color: "#4c4c4c" }}>
				Attenzione abbiamo rilevato una nuova versione del sito, clicca sul
				pulsante per aggiornare
			</Typography>
			<Button
				onClick={() => {
					clearCache(true);
					props.update();
				}}
				className="ml-20 text-14"
				id="update-button"
				variant="contained"
				style={{ background: "#fa0" }}
				type="submit"
			>
				<span className="flex">Aggiorna</span>
			</Button>
		</div>
	);
}
