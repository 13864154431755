const data = [
  "Ci uniamo alla vostra famiglia in questo doloroso momento con commozione e affetto",
  "Mi unisco al vostro dolore per la grande perdita che vi ha colpiti. Le mie più sincere condoglianze",
  "Raggiunti dalla notizia della tragica scomparsa di (nome), vi abbracciamo forte e offriamo il nostro sostegno per qualsiasi cosa",
  "Sinceramente addolorati per questo triste evento, Vi porgiamo le nostre più sentite condoglianze e Vi offriamo tutto il nostro sostegno",
  "Ho appreso la notizia con un immenso dolore. Che il ricordo dei bei momenti passati insieme possa lenire il tuo dolore",
  "Piango la prematura scomparsa Il vostro dolore è anche il mio",
  "Abbiamo appreso con ritardo la tragica notizia, ma ci uniamo al vostro dolore esprimendovi un sincero cordoglio",
  "Colleghi e amici sono vicini al vostro dolore. Vogliate accettare le nostre più sentite condoglianze",
  "Ci stringiamo a voi nel ricordo e nel dolore per una persona speciale che è venuta a mancare troppo presto",
  "Il tempo lenirà il dolore, ma il vivo ricordo ci accompagnerà per sempre",
  "Sapendo bene il grande amore che vi univa, prego Dio affinché ti aiuti a superare questo dolore",
  "Preghiamo Dio che vi dia la forza di superare questo triste momento",
  "Vi siamo vicini in questo triste giorno",
  "Profondamente rattristati per la disgrazia, porgiamo le nostre più sentite condoglianze",
  "Non riusciamo ad esprimere con le parole il dolore avuto nell’apprendere la triste notizia, ma siamo partecipi al vostro cordoglio",
  "Condoglianze per il lutto che ha colpito la vostra famiglia",
  "Ci uniamo al vostro dolore. Sentite condoglianze",
  "Vi siamo vicini in un momento così difficile e ci uniamo al vostro dolore",
  "Le mie più sentite condoglianze",
  "Anche se le parole sono ben poca cosa in queste circostanze, vogliate accettare le mie più sincere condoglianze",
  "Siamo colpiti per la grave perdita e vi siamo vicini in questa circostanza di dolore",
  "Le nostre più sentite condoglianze",
];
export default data;
