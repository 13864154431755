import React from "react";

const MAX_SIZE_FILE = 15000000;
const ACCEPTED_VIDEO_FORMAT = [".mp4", ".avi", ".m4v"];

function checkFormat(string) {
  let check = false;
  for (const i in ACCEPTED_VIDEO_FORMAT) {
    check =
      check ||
      string.toLowerCase().includes(ACCEPTED_VIDEO_FORMAT[i].toLowerCase());
  }
  return check;
}

export default function VideoInput(props) {
  return (
    <input
      type="file"
      id={`${props.name}-video-upload`}
      name={`${props.name}-video`}
      accept="video/mp4, video/x-m4v, video/*"
      onChange={(event) => {
        const rightFormat = checkFormat(event.target.files[0].name);
        if (rightFormat) {
          if (event.target.files[0].size > (props.maxSize || MAX_SIZE_FILE)) {
            alert("Il video non puo superare 15MB");
            document.getElementById(`${props.name}-video-upload`).value = null;
          } else {
            props.setVideoName(
              event.target.files[0].name,
              event.target.files[0]
            );
            // this.setState({fileVideoName:event.target.files[0].name})
          }
        } else {
          alert("Formato video non valido");
          document.getElementById(`${props.name}-video-upload`).value = null;
        }
      }}
      style={{ display: "none" }}
    />
  );
}
