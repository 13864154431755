import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import './ModalModifyPartner.scss'
import {CloudUpload} from "@material-ui/icons";
import PhotoInput from "../FormComponents/PhotoInput";
import {object} from "prop-types";
import requests, {baseURL} from "../../Services/HttpServices";
import Axios from "axios";

let close = true
export default function ModalModifyLogo(props) {
    const [logoName, setLogoName] = useState('')

    function getCsrf() {
        return new Promise((result, err) => Axios.get(baseURL+'api/auth/securitytoken').then((resolve) => {
            if (resolve != null) {
                result(resolve.data._csrf)
            } else result({DATA: 'NO DATA'})
        }).catch(err => {
            return result({err});
        }))
    }

    function send(formdata) {
        getCsrf().then(csrf => {
            formdata.set('edit-type', 'edit-logo')
            formdata.set('partnerid', props.ID)
            formdata.set('csrf_token', csrf)
            requests('sendform', 'partnerEdit', formdata).then(data => {
                if (data.response === 'success') {
                    props.onSuccess()
                } else {
                    data.errorCode === "ACCESS_DENIED" && alert('Devi essere loggato come partner o amministratore per poter effettuare l\'operazione')
                    data.errorCode === "INVALID_PARTNER" && alert('l\'id del partner non e` valido')

                    data.errorCode === "INVALID_EDIT_TYPE" && alert('Operazione non consentita')

                    data.errorCode === "CANNOT_DELETE_FILE" && alert('Errore durante l\'eliminazione del file')
                    data.errorCode === "UPLOADING_ERROR" && alert('Errore durante l\'upload del file')

                    data.errorCode === "MAX_IMAGES_REACHED" && alert('Hai superato il limite delle 10 foto')
                    props.close()

                }
            })
        })

    }

    return <div className={'content-modify-partner'} onClick={() => {
        close ? props.close() : close = true

    }}>
        <form id={'container'} onSubmit={event => {
            event.preventDefault()
            var formData = new FormData(event.target)
            let object = {}
            formData.forEach(function (value, key) {
                object[key] = value;
            });
            send(formData)
        }} onClick={() => {
            close = false
        }}>
            <label id='titleLogo' htmlFor={'photo-partner-logo'}>{logoName ||
            <p>Carica logo <CloudUpload/></p>}  </label>

            <PhotoInput name={'partner-logo'}
                        setFileName={(name) => {
                            setLogoName(name)
                        }}/>
            <button id={'button-modify'}>Salva</button>
        </form>
    </div>

}
