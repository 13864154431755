/* eslint-disable react/style-prop-object,jsx-a11y/label-has-associated-control */
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { MenuItem } from '@material-ui/core';
import viewToPlainText from '@ckeditor/ckeditor5-clipboard/src/utils/viewtoplaintext';
import { dedicSentence } from './dedicSentenceProvider';

export default function CkEditorCustom(props) {
	const [value, setValue] = useState(null);
	const [plainText, setPlainText] = useState('');
	const [valueSetted, setValueSetted] = useState(null);
	if (!value && value !== props.initData && !valueSetted && props.initData !== undefined) {
		setValue(props.initData);
		setValueSetted(true);
	}
	const selectSentence = props.selectSentence || dedicSentence;
	return (
		<div
			className="Sono io -1"
			style={{
				marginTop: 20,
				textAlign: 'left',
				maxWidth: window.innerWidth - 40
			}} /* className="App" */
		>
			<label
				style={{
					textAlign: 'left',
					fontSize: 20,
					alignSelf: 'flex-start',
					marginBottom: 10
				}}
			>
				{props.title}
			</label>
			<p>&nbsp;</p>
			{(props.dedic || props.withSelect) && (
				<TextField
					style={{
						width: '100%',
						marginBottom: 20
					}}
					variant="outlined"
					label="Consigliami una frase"
					onChange={event => {
						setValue(
							`<p style="text-align:center;"><span style={props.dedic?"font-family:Cookie, sans-serif;":"font-family:Ubuntu, Arial, sans-serif"}>${event.target.value}</span></p>`
						);
					}}
					select
				>
					{selectSentence?.length > 0 && selectSentence.map(e => <MenuItem value={e}>{e}</MenuItem>)}
				</TextField>
			)}
			{
				<CKEditor
					onInit={editor => {
						// Insert the toolbar before the editable area.
						editor.ui
							.getEditableElement()
							.parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
						props.dedic && editor.execute('alignment', { value: 'center' });
						props.dedic && editor.execute('fontFamily', { value: 'Cookie, sans-serif' });
					}}
					onChange={(event, editor, data) => {
						props.onChange(editor);
						props.withPlain &&
							setTimeout(() => {
								setPlainText(viewToPlainText(editor.editing?.view?.document?.getRoot()));
							}, 100);
					}}
					editor={DecoupledEditor}
					data={value}
					config={{
						toolbar: [
							'heading',
							'Cut',
							'Copy',
							'Paste',
							'|',
							'alignment',
							'bold',
							'italic',
							'FontSize',
							'Fontfamily',
							'TextColor',
							'link',
							'numberedList',
							'bulletedList',
							'|',
							'undo',
							'redo',
							'|',
							'underline',
							'subscript',
							'superscript'
						],
						fontFamily: {
							options: [
								'Cookie, sans-serif',
								'Ubuntu, Arial, sans-serif',
								'Ubuntu Mono, Courier New, Courier, monospace'
							]
						},
						heading: {
							options: [
								{
									model: 'paragraph',
									title: 'Paragraph',
									class: 'ck-heading_paragraph'
								},
								{
									model: 'heading1',
									view: 'h1',
									title: 'Heading 1',
									class: 'ck-heading_heading1'
								},
								{
									model: 'heading2',
									view: 'h2',
									title: 'Heading 2',
									class: 'ck-heading_heading2'
								},
								{
									model: 'heading3',
									view: 'h3',
									title: 'Heading 3',
									class: 'ck-heading_heading3'
								}
							]
						}
					}}
				/>
			}
			{props.withPlain && (
				<input name="ckeditor-plain" value={plainText.replace(/\n/g, '%n%')} style={{ display: 'none' }} />
			)}
			{props.error && <label style={{ color: 'red' }}>Campo obbligatorio</label>}
		</div>
	);
}
