import React, { Component } from "react";
import "../../Activate/Activate.css";
import TextField from "@material-ui/core/TextField";
import FeedbackModal from "../../FormComponents/FeedbackModal";
import requests from "../../../Services/HttpServices";
import validate from "../../../formValidationService";

class AddAbbonatoPoster extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loggedUser: null,
			form: {
				nome: "",
				cognome: "",
				indirizzo: "",
				codiceFiscale: "",
				sdi: "",
				telefono: "",
				mail: "",
				mailPec: "",
			},
			formError: {},
		};
	}

	render() {
		if (!this.state.loggedUser && sessionStorage.getItem("User")) {
			try {
				this.setState({
					loggedUser: JSON.parse(sessionStorage.getItem("User")).userdata,
				});
			} catch (e) {}
		}
		return (
			<>
				<div className="contact-container" style={{ height: "inherit" }}>
					{this.state.feedbackModal && (
						<FeedbackModal
							result={this.state.modalResult}
							close={() => {
								this.setState({ feedbackModal: false });
								if (this.state.modalResult === "Successo") {
									window.history.pushState("object or string", "Title", "/lampade-panel#lampade");
									window.location.reload();
								}
							}}
							label={this.state.modalText}
						/>
					)}

					<div className="activate-form-div" style={{ marginTop: 30, minHeight: "auto" }}>
						<form
							className="activate-form"
							onSubmit={(event) => {
								event.preventDefault();
								this.send();
							}}
						>
							<label className="death-data">Dati Abbonato</label>

							<div className="isFlexRow justify-between">
								<TextField
									className="activate-field"
									label="Nome dell'Abbonato"
									name="abbonato-nome"
									value={this.state.form.nome}
									onChange={(event) => this.setAndValidateForm(event.target.value, "nome")}
									error={this.state.formError.nome}
									helperText={this.state.formError.nome && "Campo obbligatorio"}
								/>
								<TextField
									className="activate-field"
									label="Cognome dell'Abbonato"
									name="abbonato-cognome"
									value={this.state.form.cognome}
									onChange={(event) => this.setAndValidateForm(event.target.value, "cognome")}
									error={this.state.formError.cognome}
									helperText={this.state.formError.cognome && "Campo obbligatorio"}
								/>
							</div>

							<div className="isFlexRow justify-between">
								<TextField
									className="activate-field"
									label="Codice Fiscale dell'Abbonato"
									name="abbonato-codiceFiscale"
									value={this.state.form.codiceFiscale}
									onChange={(event) => this.setAndValidateForm(event.target.value, "codiceFiscale")}
									error={this.state.formError.codiceFiscale}
									helperText={this.state.formError.codiceFiscale && "Campo obbligatorio"}
								/>
								<TextField
									className="activate-field"
									label="SDI dell'Abbonato"
									name="abbonato-sdi"
									value={this.state.form.sdi}
									onChange={(event) => this.setAndValidateForm(event.target.value, "sdi")}
									error={this.state.formError.sdi}
									helperText={this.state.formError.sdi && "Campo obbligatorio"}
								/>
							</div>

							<TextField
								className="activate-field"
								label="Numero di Telefono dell'Abbonato"
								name="abbonato-telefono"
								value={this.state.form.telefono}
								onChange={(event) => this.setAndValidateForm(event.target.value, "telefono")}
								error={this.state.formError.telefono}
								helperText={this.state.formError.telefono && "Campo obbligatorio"}
							/>
							<TextField
								className="activate-field"
								label="Indirizzo dell'Abbonato"
								name="abbonato-indirizzo"
								value={this.state.form.indirizzo}
								onChange={(event) => this.setAndValidateForm(event.target.value, "indirizzo")}
								error={this.state.formError.indirizzo}
								helperText={this.state.formError.indirizzo && "Campo obbligatorio"}
							/>
							<TextField
								className="activate-field"
								label="Mail dell'Abbonato"
								name="abbonato-mail"
								value={this.state.form.mail}
								onChange={(event) => this.setAndValidateForm(event.target.value, "mail")}
								error={this.state.formError.mail}
								helperText={this.state.formError.mail && "Campo obbligatorio"}
							/>
							<TextField
								className="activate-field"
								label="Mail PEC dell'Abbonato"
								name="abbonato-mailPec"
								value={this.state.form.mailPec}
								onChange={(event) => this.setAndValidateForm(event.target.value, "mailPec")}
								error={this.state.formError.mailPec}
								helperText={this.state.formError.mailPec && "Campo obbligatorio"}
							/>

							<div className="bottom-activate-form">
								<button type="submit" onClick={(event) => {}} className="activate-submit">Procedi</button>
							</div>
						</form>
					</div>
				</div>
			</>
		);
	}

	send() {
		let { formError } = this.state;
		let error         = false;
		for (const property in this.state.form) {
			formError = validate(this.state.form[property], property, formError);
			error = error || formError[property];
		}
		
		if (!error) {
			const bodyData = {
				"nome": this.state.form.nome,
				"cognome": this.state.form.cognome,
				"indirizzo": this.state.form.indirizzo,
				"codiceFiscale": this.state.form.codiceFiscale,
				"sdi": this.state.form.sdi,
				"telefono": this.state.form.telefono,
				"mail": this.state.form.mail,
				"mailPec": this.state.form.mailPec,
			};

			requests("post", "lampadaAbbonatoCreate", bodyData).then((result) => {
				if (result.result === "success") {
					this.setState({
						feedbackModal : true,
						modalResult   : "Successo",
						modalText     : "Abbonato creato con successo",
					});
				}
				else { 
					this.setState({
						feedbackModal : true,
						modalResult   : "Successo",
						modalText     : "Errore nella creazione dell'abbonato, riprova.",
					});
				}
			});
		}
		else { this.setState({ errore: true }); }
	}

	setAndValidateForm(data, attribute) {
		const { form }    = this.state;
		let { formError } = this.state;
		form[attribute]   = data;
		this.setState({ form });
		formError = validate(data, attribute, formError);
		this.setState({ formError });
	}
}

export default AddAbbonatoPoster;
