/* eslint-disable no-useless-escape */
import React, { Component, Fragment } from "react";
import withContext from "../../WithContextFile";
import TextField from "@material-ui/core/TextField";
import "./Contact.css";
import ReCAPTCHA from "react-google-recaptcha/lib/esm/recaptcha-wrapper";
import validate from "../../formValidationService";
import Recaptcha from "../../Services/Recaptcha";
import requests from "../../Services/HttpServices";
import FeedbackModal from "../FormComponents/FeedbackModal";
import AcceptTerms from "../FormComponents/AcceptTerms";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      fieldActivate: false,
      nameField: "",
      form: { name: "", surname: "", email: "", object: "", content: "" },
      formError: {
        name: false,
        surname: false,
        email: false,
        object: false,
        content: false,
      },
    };
  }

  componentDidMount() {}

  render() {
    return (
      <>
        {this.state.modal && (
          <FeedbackModal
            close={() => {
              this.setState({ modal: false, showModalVerify: false });
              window.location.reload();
            }}
            result={this.state.insertResult}
            label={this.state.insertLabel}
          />
        )}
        <div className="contact-container">
          <label className="title-price"> Contatti</label>
          <span className="contact-main-text">
            <p>I.M.E. di Iacobucci Mimmo</p>
            <p>Via delle Fratte, snc</p>
            <p>86018 Toro (CB)</p>
            <p>Tel. 0874 461925 - 338 77 55 636</p>
            &nbsp;
            <p className="bold-underline">Supporto tecnico Tel. 389 8996166</p>
          </span>
          &nbsp; &nbsp;
          <div className="contact-form">
            <p className="contact-title">Contattaci tramite mail...</p>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.send(e);
              }}
            >
              <div className="field-group">
                <TextField
                  className="field"
                  label="Nome *"
                  name="contact_name"
                  error={this.state.formError.name}
                  onChange={(event) => {
                    this.setAndValidateForm(event.target.value, "name");
                  }}
                  helperText={
                    this.state.formError.name ? "Nome obbligatorio" : ""
                  }
                />
                <TextField
                  className="field"
                  label="Cognome *"
                  name="contact_surname"
                  error={this.state.formError.surname}
                  onChange={(event) => {
                    this.setAndValidateForm(event.target.value, "surname");
                  }}
                  helperText={
                    this.state.formError.surname ? "Cognome obbligatorio" : ""
                  }
                />
              </div>
              <div className="field-group">
                <TextField
                  className="field"
                  label="Email *"
                  name="contact_email"
                  error={this.state.formError.email}
                  onChange={(event) => {
                    this.setAndValidateForm(event.target.value, "email");
                  }}
                  helperText={
                    this.state.formError.email ? "Email non valida" : ""
                  }
                />
                <TextField
                  className="field"
                  label="Oggetto *"
                  name="contact-subject"
                  error={this.state.formError.object}
                  onChange={(event) => {
                    this.setAndValidateForm(event.target.value, "object");
                  }}
                  helperText={
                    this.state.formError.object ? "Oggetto obbligatorio" : ""
                  }
                />
              </div>
              <TextField
                className="text-area"
                name="contact_message"
                id="outlined-multiline-static"
                label="Scrivi qui il tuo messaggio *"
                multiline
                rows={8}
                variant="outlined"
                error={this.state.formError.content}
                onChange={(event) => {
                  this.setAndValidateForm(event.target.value, "content");
                }}
                helperText={
                  this.state.formError.content
                    ? "Messaggio obbligatorio"
                    : "" /* 'Caratteri ' + ((this.state.form.content && this.state.form.content.length) || 0) */
                }
              />
              <AcceptTerms
                checkBox={this.state.checkBox || false}
                termsError={this.state.termsError || false}
                setChecked={(data) => this.setState({ checkBox: data })}
              />
              <div className="bottom-contact-submit">
                <div className="recaptcha">
                  <Recaptcha onVerify />
                </div>
                <button
                  type="submit"
                  onClick={() => {
                    // this.send()
                  }}
                  className="activate-submit"
                >
                  Invia
                </button>
              </div>
            </form>
          </div>
          &nbsp;
        </div>
      </>
    );
  }

  action(data) {}

  send(event) {
    const { formError } = this.state;
    const formData = new FormData(event.target);
    const object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });
    let error = false;
    if (!this.state.checkBox) {
      this.setState({ termsError: true });
      error = true;
    }
    for (const property in this.state.form) {
      if (this.state.form[property] === "") {
        formError[property] = true;
        error = error || true;
      }
    }
    if (!error) {
      requests("post", "contact", object).then((data) => {
        if (data.response === "success") {
          this.setState({
            modal: true,
            insertResult: "Successo",
            insertLabel:
              "Messaggio inviato con successo la contatteremo al piu` presto",
          });
        } else
          this.setState({
            modal: true,
            insertResult: "Errore",
            insertLabel: data.message,
          });
      });
    }

    this.setState({ formError });
  }

  emailValidation(email) {
    const re = new RegExp(
      /(([<>()\[\]\\.,;:\s@"]+(\.[<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return !re.test(email);
  }

  setAndValidateForm(data, attribute) {
    const { form } = this.state;
    let { formError } = this.state;
    form[attribute] = data;
    this.setState({ form });
    formError = validate(data, attribute, formError);
    this.setState({ formError });
  }
}

export default withContext(Contact);
