import React from 'react';
import { PayPalButton } from 'react-paypal-button-v2';
import { Spinner } from 'react-activity';
import { baseURL } from './HttpServices';

export const defaultID = 'AVfuTYt59be7cdzYndbKA-64V-L1ALSzzwX3FRfI39COtKTP7SfOwkmrVfS2KAVTMwSGHbGDY6MNrkiF';
let id = null;
const openedWindows = [];
export default class PayPalBtn extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loaded: false
		};
	}

	componentDidMount() {}

	componentWillUnmount() {}

	render() {
		const { amount, onSuccess } = this.props;
		// props.onSuccess()
		id = this.props.clientId;
		return (
			<div>
				{!this.state.loaded && <Spinner />}
				<PayPalButton
					// id="asd"
					amount={amount}
					currency="EUR"
					onSuccess={(details, data) => {
						onSuccess(details, data);
						// setTimeout(()=>{window.location.reload()},1000)
					}}
					// catchError={(err) => {
					//     this.props.history.push({
					//         pathname: '/paypal-error',
					//         query: {orderId: this.props.orderID, type: this.props.paymentType}
					//     });
					//
					//     console.log({err})
					// }}
					onButtonReady={() => this.setState({ loaded: true })}
					onError={err => {
						this.props.history.push({
							pathname: '/paypal-error',
							query: {
								orderId: this.props.orderID,
								type: this.props.paymentType
							}
						});
					}}
					onApprove={err => {}}
					onClick={() => {
						setTimeout(() => {
							this.props.onClick();
						}, 500);
					}}
					onCancel={(err, data) => {
						this.props.history.push({
							pathname: '/paypal-error',
							query: {
								orderId: this.props.orderID,
								type: this.props.paymentType
							}
						});
					}}
					style={{
						layout: 'horizontal'
					}}
					// components={}
					options={{
						clientId: (window.location.hash === '#testDLG' && 'sb') || id || defaultID,
						currency: 'EUR'
					}}
				/>
			</div>
		);
	}
}
