import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import './ModalModifyPartner.scss'
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import CKEditor from "@ckeditor/ckeditor5-react";
import requests from "../../Services/HttpServices";

let close = true
let width = window.innerWidth > 500 ? window.innerWidth - 250 : window.innerWidth - 20
export default function ModalLabelModifyPartner(props) {
    const [value, setValue] = useState();
    const [valueSetted, setValueSetted] = useState(false);
    if (!value && props.value && !valueSetted) {
        setValue(props.value)
        setValueSetted(true)
    }

    function send(formdata) {
        if (!value) alert('Non pui lasciare vuoto il campo');
        else
            requests('post', 'partnerEdit', formdata).then(result => {
                if (result.response === 'fail') {
                    result.errorCode === "ACCESS_DENIED" && alert('Devi essere loggato come partner o amministratore per poter effettuare l\'operazione')
                    result.errorCode === "INVALID_PARTNER" && alert('l\'id del partner non e` valido')

                    result.errorCode === "INVALID_EDIT_TYPE" && alert('Operazione non consentita')

                    result.errorCode === "CANNOT_DELETE_FILE" && alert('Errore durante l\'eliminazione del file')
                    result.errorCode === "UPLOADING_ERROR" && alert('Errore durante l\'upload del file')

                    result.errorCode === "MAX_IMAGES_REACHED" && alert('Hai superato il limite delle 10 foto')

                    props.close()
                } else if (result.response === 'success') props.onSuccess()
            })
    }

    return <div className={'content-modify-partner'} onClick={() => {
        close ? props.close() : close = true

    }}>
        <form onSubmit={event => {
            var formData = new FormData(event.target);
            let object = {}
            formData.set('edit-type', props.field)
            formData.set('partnerid', props.ID)
            formData.set('content', value)
            formData.forEach(function (value, key) {
                object[key] = value;
            });
            event.preventDefault()
            send(object)
        }} style={{width: props.ckeditor && width}} id={'container'} onClick={() => {
            close = false
        }}>
            <label id='title'>Modifica campo</label>
            {!props.ckeditor ?
                <TextField value={value} autoFocus name={props.field} onChange={event => setValue(event.target.value)}
                           label={'Immetti il nuovo campo'} variant={"outlined"}>

                </TextField> : <div className={'ckeditor-activate'} style={{maxWidth: window.innerWidth - 50}}>
                    <CKEditor

                        onInit={editor => {
                            // Insert the toolbar before the editable area.
                            editor.ui.getEditableElement().parentElement.insertBefore(
                                editor.ui.view.toolbar.element,
                                editor.ui.getEditableElement()
                            );
                        }}
                        onChange={(event, editor, data) => {
                            setValue(editor.getData())
                        }}
                        editor={DecoupledEditor}
                        data={value}
                        config={{
                            toolbar: ['heading', 'Cut', 'Copy', 'Paste', '|', 'alignment', 'bold', 'italic', 'FontSize', 'Fontfamily', 'TextColor', 'link', 'numberedList', 'bulletedList', '|', 'undo', 'redo', '|', 'underline', 'subscript', 'superscript'],
                            heading: {
                                options: [
                                    {model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph'},
                                    {
                                        model: 'heading1',
                                        view: 'h1',
                                        title: 'Heading 1',
                                        class: 'ck-heading_heading1'
                                    },
                                    {
                                        model: 'heading2',
                                        view: 'h2',
                                        title: 'Heading 2',
                                        class: 'ck-heading_heading2'
                                    },
                                    {
                                        model: 'heading3',
                                        view: 'h3',
                                        title: 'Heading 3',
                                        class: 'ck-heading_heading3'
                                    }
                                ]
                            }
                        }}
                    /></div>}

            <button id={'button-modify'}>Salva</button>
        </form>
    </div>

}
