import React, { Component, Fragment } from 'react';
import './Login.scss';
import { Redirect } from 'react-router';
import TextField from '@material-ui/core/TextField';
import withContext from '../../../WithContextFile';
import AddressService from '../../FormComponents/AddressComponent';
import ModifyAccountNotification from '../ModfyAccountNotification/ModifyAccountNotification';
import validate from '../../../formValidationService';
import requests from '../../../Services/HttpServices';
import 'react-phone-number-input/style.css';
import PhoneNumber from '../../FormComponents/PhoneNumber';
import ContactPreferenceForm from '../../FormComponents/ContactPreferencesForm';
import FeedbackModal from '../../FormComponents/FeedbackModal';
import { suggestUsername } from '../../../Services/formServices';
import AcceptTerms from '../../FormComponents/AcceptTerms';

let timerUserCheck = null;

class Registrazione extends Component {
	constructor(props) {
		super(props);
		this.state = {
			form: {
				email: '',
				name: '',
				surname: '',
				telephone: '',
				username: ''
			},
			activeNotification: true,
			errorNotification: true,
			formError: {}
		};
	}

	componentDidMount() {}

	handleChange = e => {
		this.setAndValidateForm(e.target.value, e.target.name);
		e.target.name == 'email' && this.state.radioValue === 'email' && this.setState({ radioValue: null });
		e.target.name == 'telephone' &&
			(this.state.radioValue === 'wa' || this.state.radioValue === 'sms') &&
			this.setState({ radioValue: null });
	};

	handleNameSurnameUpdate = e => {
		this.setAndValidateForm(e.target.value, e.target.name);
		if (this.state.form.name && this.state.form.surname) {
			if (timerUserCheck) {
				clearTimeout(timerUserCheck);
				timerUserCheck = null;
			}
			timerUserCheck = setTimeout(() =>
					suggestUsername(
						this.state.form.name.replace(/[^a-zA-Z0-9]/g, ""),
						this.state.form.surname.replace(/[^a-zA-Z0-9]/g, "")
					).then(result => {
						const { form } = this.state;
						form.username = result.replace(/[^a-zA-Z0-9]/g, "");
						this.setState({ form });
					}),
				500
			);
		}
	};

	setAndValidateForm(data, attribute, minLenght, matchLenght) {
		const { form } = this.state;
		let { formError } = this.state;
		form[attribute] = data;
		this.setState({ form });
		formError = validate(data, attribute, formError);
		if (attribute === 'email' && !data) formError[attribute] = false;
		if (attribute === 'telephone' && !this.state.form.telephone) formError[attribute] = false;
		if (minLenght) {
			if (form[attribute] && form[attribute].length < minLenght) {
				formError[attribute] = true;
			}
		}
		if (matchLenght) {
			if (form[attribute] && form[attribute].length != matchLenght) {
				formError[attribute] = true;
			}
		}
		this.setState({ formError });
	}

	send(data) {
		let { formError } = this.state;
		let error = false;
		for (const property in this.state.form) {
			if (property !== 'email' && property !== 'telephone') {
				formError = validate(this.state.form[property], property, formError);
				error = error || formError[property];
			}
		}
		if (!this.state.checkBox) {
			this.setState({ termsError: true });
			error = true;
		}
		if (
			this.state.form.telephone &&
			this.state.form.telephone.length > 4 &&
			this.state.form.telephone.length < 12
		) {
			error = true;
			const phoneError = this.state.formError;
			phoneError.telephone = true;
			this.setState({ formError: phoneError });
		}
		if (this.state.form.email) {
			formError = validate(this.state.form.email, 'email', formError);
			error = error || formError.email;
		}
		let { errorNotification } = this.state;
		if (!this.state.activeNotification) {
			errorNotification = false;
		}
		if (!this.state.form.email && !this.state.form.telephone) {
			this.setState({ regError: true });
			error = true;
		}
		if (!this.state.radioValue) {
			this.setState({ radioError: true });
			error = true;
		} else {
			this.setState({ radioError: false });
		}
		if (this.state.form.username.length < 6) {
			const modalFunction = () => {
				document.getElementById('username').focus();
			};
			error = true;
			this.setState({
				feedbackModal: true,
				modalResult: 'Errore',
				modalText: "Errore l'username deve contenere almeno 6 caratteri",
				modalFunction
			});
		}
		!errorNotification &&
			!error &&
			requests('post', 'registration', data).then(data => {
				if (data.response === 'success') {
					const contact =
						this.state.radioValue === 'email' ? this.state.form.email : this.state.form.telephone;
					window.location.href = `./esito-registrazione?${contact}`;
				} else if (data.message) {
					let modalFunction = null;
					const temp = this.state.formError;

					if (data.errorCode.indexOf('USERNAME') > -1) {
						modalFunction = () => {
							document.getElementById('username').focus();
						};
						temp.username = true;
					}
					if (data.errorCode.indexOf('TELEPHONE') > -1) {
						modalFunction = () => {
							document.getElementsByClassName('reg-phone')[0].focus();
						};
						temp.telephone = true;
					}
					if (data.errorCode.indexOf('EMAIL') > -1) {
						modalFunction = () => {
							document.getElementById('email').focus();
						};
						temp.email = true;
					}
					this.setState({
						feedbackModal: true,
						modalResult: 'Errore',
						modalText: data.message,
						modalFunction,
						formError: temp
					});
				} else
					this.setState({
						feedbackModal: true,
						modalResult: 'Errore',
						modalText: 'Errore imprevisto riprovare piu` tardi'
					});
			});
		this.setState({ formError });
	}

	render() {
		return !this.props.context.user ? (
			<>
				<div className="login-page columns is-mobile is-centered" style={{ height: 'auto' }}>
					{this.state.feedbackModal && (
						<FeedbackModal
							result={this.state.modalResult}
							close={() => {
								this.setState({ feedbackModal: false });
								// this.setState({name: '',number:'',selectedValue:''})
								this.state.modalFunction && this.state.modalFunction();
							}}
							label={this.state.modalText}
						/>
					)}
					<form
						className="column media-fix registration"
						onSubmit={event => {
							event.preventDefault();
							const formData = new FormData(event.target);
							const object = {};
							if (this.state.activeNotification) {
								// formData.set('notificationList',(this.state.notificationList))
								formData.set('activeNotification', true);
							}
							formData.forEach(function (value, key) {
								object[key] = value;
							});
							object.notificationList = JSON.stringify(this.state.notificationList);

							this.send(object);
						}}
						style={{ maxWidth: '100%' }}
					>
						<label className="death-data">Benvenuto</label>
						<p>
							Iscriviti per velocizzare le funzioni base e abilitando le notifiche potrai rimanere sempre
							aggiornato per eventuali manifesti nella zona di interesse
						</p>
						<TextField
							onChange={this.handleNameSurnameUpdate}
							name="name"
							className="login-form mb-20"
							style={{ width: '100%', marginTop: 8 }}
							label="Nome"
							variant="outlined"
							error={this.state.formError.name}
							helperText={this.state.formError.name && 'Campo obbligatorio'}
						/>
						<TextField
							style={{ width: '100%' }}
							onChange={this.handleNameSurnameUpdate}
							type="surname"
							name="surname"
							className="login-form "
							label="Cognome"
							variant="outlined"
							error={this.state.formError.surname}
							helperText={this.state.formError.surname && 'Campo obbligatorio'}
						/>
						<TextField
							style={{ display: 'none' }}
							id="username"
							onChange={event =>
								this.setAndValidateForm(
									event.target.value.replace(/[^a-zA-Z0-9]/g, ""),
									'username',
									null
								)
							}
							type="username"
							name="username"
							className="login-form "
							value={this.state.form.username}
							label="Nome utente"
							variant="outlined"
							error={this.state.formError.username}
							helperText={this.state.formError.username && 'Campo non valido'}
						/>
						<TextField
							style={{ width: '100%' }}
							id="username"
							onChange={event =>
								this.setAndValidateForm(
									event.target.value.replace(/[^a-zA-Z0-9]/g, ""),
									'username',
									null
								)
							}
							disabled
							type="username"
							name="username"
							className="login-form "
							value={this.state.form.username}
							label="Nome utente"
							variant="outlined"
							error={this.state.formError.username}
							helperText={this.state.formError.username && 'Campo non valido'}
						/>
						<PhoneNumber
							name="telephone"
							setValue={data => this.setAndValidateForm(data, 'telephone', 12, null)}
							error={
								this.state.form.telephone &&
								this.state.form.telephone.length > 3 &&
								this.state.formError.telephone
							}
						/>
						<TextField
							style={{ width: '100%' }}
							onChange={this.handleChange}
							name="email"
							id="email"
							className="login-form mb-20"
							label="Email"
							variant="outlined"
							error={this.state.formError.email}
							helperText={this.state.formError.email && 'Campo non valido'}
						/>
						{this.state.regError &&
							!this.state.form.email &&
							(!this.state.form.telephone ||
								(this.state.form.telephone && this.state.form.telephone.length < 7)) && (
								<label
									style={{
										color: 'red',
										fontSize: 13,
										marginTop: -14,
										marginBottom: 8,
										textAlign: 'start',
										alignSelf: 'flex-start'
									}}
								>
									Devi inserire una mail o un numero di telefono valido per poterti registrare
								</label>
							)}
						{/* <TextField style={{width: '100%'}} onChange={this.handleChange} type={'password'} */}
						{/*           name={'password'} */}
						{/*           className={'login-form '} */}
						{/*           label={'Password'} variant={'outlined'} error={this.state.formError.password} */}
						{/*           helperText={this.state.formError.password && 'Campo obbligatorio'} */}
						{/* /> */}
						<TextField
							style={{ width: '100%' }}
							name="agent"
							className="login-form "
							label="Codice promozione (Facoltativo)"
							variant="outlined"
						/>
						<div
							style={{
								display: 'flex',
								alignSelf: 'flex-start',
								width: '100%',
								padding: '0 5px'
							}}
						>
							<ContactPreferenceForm
								email={this.state.form.email}
								phone={this.state.form.telephone}
								error={!this.state.radioValue && this.state.radioError}
								handler={(event, value) => {
									this.setState({ radioValue: value });
								}}
								label="Scegli come ricevere la conferma di registrazione"
							/>
						</div>
						{/* <div className={'buy-form-paragraph'}>Dati indirizzo</div> */}
						{/* <AddressService names={'registration'} fullWidth={true} comuni={this.state.comuni} */}
						{/*                province={this.state.provincie} */}
						{/*                form={this.state.form} formError={this.state.formError}/> */}
						&nbsp;
						<ModifyAccountNotification
							registration
							submit={this.state.submit}
							onChangeFlag={change => {
								this.setState({
									activeNotification: change,
									notificationList: null,
									errorNotification: true
								});
							}}
							sendArray={data => {
								this.setState({
									notificationList: data,
									errorNotification: data.length <= 0
								});
							}}
							type="registration"
						/>
						<AcceptTerms
							checkBox={this.state.checkBox || false}
							termsError={this.state.termsError || false}
							setChecked={data => this.setState({ checkBox: data })}
						/>
						<div className="field is-clearfix" style={{ marginTop: 20 }}>
							<button
								className="button is-primary is-outlined is-pulled-right"
								onClick={() => {
									this.setState({ submit: true });
								}}
							>
								Procedi
							</button>
						</div>
					</form>
				</div>
			</>
		) : (
			<Redirect to="/products" />
		);
	}
}

export default withContext(Registrazione);
