import React, { Component, Fragment } from "react";
import withContext from "../../WithContextFile";
import { Redirect } from "react-router";
import "./How-it-works.css";
import "../../../node_modules/video-react/dist/video-react.css";
import { Player, BigPlayButton } from "video-react";

class HowItWorks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
    };
  }

  componentDidMount() {}

  render() {
    return !this.props.context.user ? (
      <>
        <div className="main-div">
          <div className="text-container-tutorial">
            <p className="title-price">COME FUNZIONA</p>
            <label>
              <p className="text-how">Necrologi</p>
              <p className="text-how" style={{ marginTop: 0 }}>
                Il DigitalMemorial.it oltre alle lapidi digitali e i suoi
                servizi, contiene la sezione “necrologi” che dà la possibilità
                di consultazione degli stessi e di ricevere, eventualmente,{" "}
                <strong className="underline">
                  notifiche di nuovi necrologi
                </strong>
                . Inoltre dà anche la possibilità di lasciare le proprie{" "}
                <strong className="underline">condoglianze</strong> qualora il
                necrologio è abilitato.
              </p>
              &nbsp;
              <p className="text-how">Lapide Digitale / Memorial / Dediche</p>
              <p className="text-how" style={{ marginTop: 0 }}>
                Dopo aver acquistato una Lapide Digitale / QrCode, avrai la
                possibilità di attivarla sul portale www.digitalmemorial.it,
                entrando nella sezione{" "}
                <strong className="underline">"Attiva Lapide Digitale"</strong>{" "}
                e seguendo la semplice procedura. Potrai quindi apporre la
                targhetta QrCode sulla lapide e dare la possibilità a chiunque
                di accedervi direttamente tramite un qualsiasi dispositivo
                (smartphone, tablet, ecc.) leggendo il codice; inoltre la lapide
                digitale sarà anche raggiungibile visitando il sito
                www.digitalmemorial.it e digitando nei campi di ricerca i dati
                del defunto.
              </p>
              <p className="text-how" style={{ marginTop: 0 }}>
                {" "}
                Chiunque visita la pagina potrà visualizzare tutti i contenuti
                disponibili e inoltre avrà anche la possibilità di{" "}
                <strong className="underline">
                  donare cero/fiori virtuali, foto, video, con eventuale dedica
                  associata
                </strong>
                , che sarà visibile a tutti solo dopo l'avallo del proprietario
                della pagina; nel caso in cui quest'ultimo non avalli la
                richiesta, il dono cero/fiori sarà pubblicato in forma anonima,
                mentre nel caso di foto e video il dono verrà sostituito con
                fiori virtuali anch'essi pubblicati in forma anonima. Chiunque
                interagisce con la pagina "donando", potrà ricevere notifiche su
                successive interazioni di altri utenti. Nel caso non le vorrà
                ricevere potrà disattivarle seguendo la semplice procedura
                descritta in esse.
              </p>
              <p className="text-how" style={{ marginTop: 0 }}>
                {" "}
                La visibilità della pagina non è legata ad altri costi.
              </p>
              <p className="text-how" style={{ marginTop: 0 }}>
                {" "}
                Il proprietario della pagina potrà, poi, usufruire anche del
                servizio "Memorial". Con questo servizio avrà la possibilità di
                inserire nella parte iniziale della pagina del defunto 10 foto,
                1 audio/video, 1 biografia al costo totale di € 6,00; tale
                servizio avrà la durata di 1 anno ed eventualmente potrà essere
                rinnovato.
              </p>
            </label>
            {/* <p className={'title-what'}>GUARDA IL VIDEO TUTORIAL</p>
                        <Player

                            playsInline
                            poster="/assets/poster.png"
                            src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                        >
                            <BigPlayButton position="center" />
                        </Player> */}
            &nbsp;
          </div>
        </div>
      </>
    ) : (
      <Redirect to="/products" />
    );
  }
}

export default withContext(HowItWorks);
