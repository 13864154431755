import React, { Component } from "react";
import requests from "../../Services/HttpServices";
import TextField from "@material-ui/core/TextField";
import "./Login/Login.scss";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { VisibilityOff } from "@material-ui/icons";
import FeedbackModal from "../FormComponents/FeedbackModal";

export default class RecoverPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      password: "",
      newPassword: "",
    };
  }

  onSubmit = (event) => {
    event.preventDefault();
    const bodyData = { recoverToken: this.state.token };
    bodyData["new-password"] = this.state.password;
    bodyData["confirm-new-password"] = this.state.newPassword;
    if (this.state.password !== this.state.newPassword) {
      this.setState({
        feedbackModal: true,
        modalResult: "Errore",
        modalText: "Le password non coincidono",
      });
    } else {
      requests("post", "completeReset", bodyData).then((data) => {
        if (data.response === "success") {
          this.setState({
            feedbackModal: true,
            modalResult: "Successo",
            modalText: "Password modificata con successo",
          });
          // this.props.history.push('./login')
        } else if (data.message)
          this.setState({
            feedbackModal: true,
            modalResult: "Errore",
            modalText: data.message,
          });
        else
          this.setState({
            feedbackModal: true,
            modalResult: "Errore",
            modalText: "Errore imprevisto",
          });
      });
    }
  };

  componentDidMount() {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const token = params.get("token");
    this.setState({ token });
    requests("post", "recoverVerify", { recoverToken: token }).then((data) => {
      if (data.response === "success") {
        this.setState({ error: false });
      } else {
        this.setState({ error: true });
      }
    });
  }

  render() {
    return (
      <div
        style={{ minHeight: window.innerHeight - 100 }}
        className="recover-password-bg"
      >
        {this.state.feedbackModal && (
          <FeedbackModal
            result={this.state.modalResult}
            close={() => {
              this.setState({ feedbackModal: false });
              this.state.modalResult === "Successo" &&
                this.props.history.push("./login");
              // this.setState({name: '',number:'',selectedValue:''})
            }}
            label={this.state.modalText}
          />
        )}
        {this.state.error ? (
          <label>Errore! Codice di verifica non valido</label>
        ) : (
          <form
            onSubmit={this.onSubmit}
            className="recover-password-form"
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
            }}
          >
            <label
              className="buy-form-paragraph"
              style={{ margin: "0 auto", marginBottom: 20 }}
            >
              Password reset
            </label>

            <TextField
              label="Inserisci la nuova password"
              name="password"
              InputProps={{
                endAdornment: this.state.visible ? (
                  <VisibilityIcon
                    style={{ cursor: "pointer", color: "black" }}
                    onClick={() => this.setState({ visible: false })}
                  />
                ) : (
                  <VisibilityOff
                    style={{ cursor: "pointer", color: "black" }}
                    onClick={() => this.setState({ visible: true })}
                  />
                ),
              }}
              type={!this.state.visible && "password"}
              className="text-field-reset"
              variant="outlined"
              value={this.state.password}
              onChange={(event) => {
                this.setState({ password: event.target.value });
              }}
            />
            <TextField
              label="Ripeti password"
              className="text-field-reset"
              InputProps={{
                endAdornment: this.state.visible ? (
                  <VisibilityIcon
                    style={{ cursor: "pointer", color: "black" }}
                    onClick={() => this.setState({ visible: false })}
                  />
                ) : (
                  <VisibilityOff
                    style={{ cursor: "pointer", color: "black" }}
                    onClick={() => this.setState({ visible: true })}
                  />
                ),
              }}
              type={!this.state.visible && "password"}
              name="new-password"
              variant="outlined"
              value={this.state.newPassword}
              onChange={(event) => {
                this.setState({ newPassword: event.target.value });
              }}
            />
            <div className="field is-clearfix" style={{ marginTop: 20 }}>
              <button
                className="button is-primary is-outlined is-pulled-right white-text"
                style={{ color: "white" }}
                onClick={() => {
                  this.setState({ submit: true });
                }}
              >
                Procedi
              </button>
            </div>
          </form>
        )}
      </div>
    );
  }
}
