import React, {useState} from 'react'
import './modalP.scss'
import {Typography} from "@material-ui/core";


export default function UserData(props) {

    const [userData, setUserData] = useState({
        name: null,
        surname: '',
        CF: '',
        email: ''
})

    !userData.name && setUserData( props.userData||{
        name: 'bob',
        surname: 'asd',
        CF: 'bobasd12asd12a213d',
        email: 'sonounaMail'
    })
    return (
        <div className={'w-full'}>
            <div className={'flex-row-justify-between-w-full padding-x-20'}>
                <Typography className={'p-y-10'}>
                    <strong>Nome completo:</strong>
                    {' ' + userData.surname + ' '}
                </Typography>
                &nbsp;
                <Typography className={'p-y-10'}>
                    {' '}{userData.name}{' '}
                </Typography>

            </div>
            <div className={'flex-row-justify-between-w-full padding-x-20'}>
                <Typography style={{textAlign:'start'}} className={'p-y-10'}>
                    <strong>Codice Fiscale:</strong>
                </Typography>
                &nbsp;
            </div>
            <Typography style={{textAlign:'start'}} className={'padding-x-20'}>{userData.CF}</Typography>
            <div className={'flex-row-justify-between-w-full padding-x-20'}>
                <Typography className={'p-y-10'}>
                    <strong>Email:</strong>
                </Typography>
                &nbsp;
                <Typography className={'p-y-10'}>
                    {userData.email}
                </Typography>

            </div>
        </div>
    )
}
