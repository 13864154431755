import React, { useState } from "react";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import moment from "moment";
import requests from '../../../Services/HttpServices';
import "./AbbonatoPopup.scss";

function aggiungiPagamento(idAbbonato, serialeLampada, date, type) {
  const bodydata = {};
  bodydata["abbonato"] = parseInt(idAbbonato, 10);
  bodydata["seriale"] = parseInt(serialeLampada, 10);
  bodydata["date"] = date;
  bodydata["type"] = type;

  requests("post", "lampadaPagamentoCreate", bodydata).then((data) => {
    if (data?.result === "success") { console.log("Success:" + data?.log); }
    else { console.log("Fail: " + data?.log); }
  });
}

export default function AddPagamentoPopup(props) {
  let close = true;

  //VARIABILI
  var idAbbonato = props.idAbbonato;
  var serialeLampada = props.serialeLampada;
  const [type, setType] = useState("Contanti");
  const [date, setDate] = useState(moment().format('YYYY-MM-DDTHH:mm'));

  return (
    <div className="abbonato-main-container" onClick={() => { close ? props.close() : (close = true); }}>
      <div onClick={() => { close = false; }} className="abbonato-card" style={{ minWidth: 280, minHeight: 260 }}>

        <label className="ttitle">Aggiungi Pagamento</label>

        <div className="row" style={{ flexDirection: "column" }}>
            <InputLabel className="inputlabel" variant="filled" id="type-label">Tipo di Pagamento</InputLabel>
            <Input
              labelId="type-label"
              className="field"
              name="type"
              value={type}
              onChange={(event) => { setType(event.target.value); }}
            />
        </div>

        <div className="row" style={{ flexDirection: "column", marginTop: 5 }}>
            <InputLabel className="inputlabel" variant="filled" id="date-label">Data Pagamento</InputLabel>
            <Input
              labelId="date-label"
              className="field"
              type="datetime-local"
              name="date"
              value={date}
              onChange={(event) => { setDate(event.target.value); }}
            />
        </div>
        
        <div style={{alignSelf: "flex-end", marginTop: "auto" }}>
          <button style={{width: "auto", marginRight: "10px"}} onClick={() => { aggiungiPagamento(idAbbonato, serialeLampada, date, type); props.close(); }}>Conferma</button>
          <button onClick={() => { props.close(); }}>Chiudi</button>
        </div>
      </div>
    </div>
  );
}