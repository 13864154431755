import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { RemoveCircleOutline } from "@material-ui/icons";
import addressServiceGetData from "../../FormComponents/addressService";
import { buildCap } from "../../FormComponents/AddressComponent";
import requests from "../../../Services/HttpServices";

export default function ModifyAccountNotificationAddress(props) {
	const [region, setRegion]                             = useState("");
	const [province, setProvince]                         = useState("");
	const [location, setLocation]                         = useState("");
	const [selectedRegion, setSelectedRegion]             = useState("");
	const [selectedProvince, setSelectedProvince]         = useState("");
	const [selectedLocation, setSelectedLocation]         = useState("");
	const [prevData, setPrevData]                         = useState(null);
	const [caps, setCaps]                                 = useState([]);
	const [initHandleChangeData, setInitHandleChangeData] = useState();
	const [selectedCap, setSelectedCap]                   = useState();
	function handleSetCaps(e) {
		let caps = [e.cap];
		if (e.capList?.length > 0) {
			caps = [...caps, ...buildCap(e)];
		}
		setCaps(caps);
	}
	if (props.sessionData && props.sessionData !== prevData) {
		const data = props.sessionData;
		setPrevData(props.sessionData);
		if (data.zoneType === "cap") {
			props.handleChange({ cap: data.value });
			requests("get", "locationFromCap", data.value).then((result) => {
				const resultData = result.data.data;
				setSelectedRegion(resultData.region);
				addressServiceGetData("province", resultData.region).then((province) => {
					setProvince(province);
					if (resultData.province) {
						setSelectedProvince(resultData.province);
						addressServiceGetData("location", resultData.province).then((comuni) => {
							setLocation(comuni);
							if (resultData.location) {
								handleSetCaps(comuni.find((el) => el.ID === resultData.location));
								setSelectedLocation(resultData.location);
							}
						});
					}
				});
			});
		} else {
			setSelectedRegion(data.region);
			console.log("entro qui", data.region);
			addressServiceGetData("province", data.region).then((province) => {
				setProvince(province);
				if (data.province) {
					setSelectedProvince(data.province);
					addressServiceGetData("location", data.province).then((comuni) => {
						setLocation(comuni);
						if (data.location) {
							handleSetCaps(comuni.find((el) => el.ID === data.location));
							setSelectedLocation(data.location);
						}
					});
				}
			});
		}
	}
	!region
		&& addressServiceGetData("region").then((data) => {
			setRegion(data);
		});

	function handleChange(data) {
		props.handleChange(data);
	}

	return (
		<div
			className="address-notification"
			// key={`div${props.index} ${Math.random()}`}
		>
			<div
				// key={`innerdiv${props.index} ${Math.random()}`}
				className="first-div"
				style={{ display: "flex", alignItems: "flex-end", width: "30%" }}
			>
				{props.index + 1 == props.number && props.index > 0 ? (
					<RemoveCircleOutline
						style={{ cursor: "pointer", color: "red", marginRight: 10 }}
						onClick={() => {
							props.handleRemove();
						}}
					/>
				) : (
					<div
						// key={`INNERdiv${props.index} ${Math.random()}`}
						style={{ height: 20, width: 48 }}
						className="no-mobile"
					/>
				)}
				<TextField
					select
					style={{ width: "100%" }}
					label="Regione"
					className="field-region"
					error={props.submit && selectedRegion == ""}
					helperText={props.submit && selectedRegion == "" && "Devi effettuare una scelta"}
					// required
					value={selectedRegion}
					onChange={(event) => {
						setSelectedRegion(event.target.value);
						setSelectedProvince("");
						setSelectedLocation("");
						handleChange({ region: event.target.value });
						addressServiceGetData("province", event.target.value).then((data) => {
							setProvince(data);
						});
					}}
				>
					{region
						&& region.map((e, i) => (
							<MenuItem key={`${e.ID} ${props.number}`} value={e.ID}>
								{e.name}
							</MenuItem>
						))}
				</TextField>
			</div>
			<TextField
				select
				disabled={!selectedRegion}
				label="Provincia"
				className="text-field-address"
				value={selectedProvince}
				onChange={(event) => {
					setSelectedProvince(event.target.value);
					setSelectedLocation("");
					if (event.target.value !== "") {
						handleChange({ province: event.target.value });
						addressServiceGetData("location", event.target.value).then((data) => {
							setLocation(data);
						});
					} else handleChange({ region: selectedRegion });
				}}
			>
				<MenuItem value="">Nessuno</MenuItem>
				{province
					&& province.map((e, i) => (
						<MenuItem key={`${e.ID} ${props.number}`} value={e.ID}>
							{e.name}
						</MenuItem>
					))}
			</TextField>
			<TextField
				select
				disabled={!selectedProvince}
				label="Comune"
				className="text-field-address"
				value={selectedLocation}
				onChange={(event) => {
					setSelectedCap("");
					setSelectedLocation(event.target.value);
					if (event.target.value !== "") {
						handleChange({ location: event.target.value });
					} else handleChange({ province: selectedProvince });
				}}
			>
				<MenuItem value="">Nessuno</MenuItem>
				{location
					&& location.map((e, i) => (
						<MenuItem key={`${e.ID} ${props.number}`} onClick={() => handleSetCaps(e)} value={e.ID}>
							{e.name}
						</MenuItem>
					))}
			</TextField>
			<TextField
				select
				style={{ width: window.innerWidth > 701 && 80 }}
				disabled={!selectedLocation}
				label="Cap"
				className="text-field-address"
				value={selectedCap}
				onChange={(event) => {
					setSelectedCap(event.target.value);
					if (event.target.value !== "") {
						handleChange({
							location : selectedLocation,
							cap      : event.target.value,
						});
					} else handleChange({ location: selectedLocation });
				}}
			>
				<MenuItem value="">Nessuno</MenuItem>
				{caps
					&& caps.map((e, i) => (
						<MenuItem key={`${e} ${props.number}`} value={e}>
							{e}
						</MenuItem>
					))}
			</TextField>
		</div>
	);
}
