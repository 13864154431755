import React, {useState} from "react";
import {Link, useHistory} from "react-router-dom";

export default function PurchaseSuccessComponent(props) {
    let history = useHistory()
    let register = props.register || props.history.location.query.register
    let account=props.account|| props.history.location.query.account
    let data= props.history.location.query.data
    if(!data)history.push('/home')

    const [view] = useState(register)
    return (<div style={{display: "flex", flexDirection: "column",minHeight:700}}>
        <label className={'death-data success-title-fix'}>Grazie dell'acquisto</label>
        <label className={'purchase-shipping'} style={{marginBottom: 15}}>Il Qr-code verra' spedito all'indirizzo da lei
            fornito il prima possibile</label>
        {(view || account) ? <div>
            {!account && <p>Le abbiamo inviato un messaggio, attraverso il metodo di contatto da lei scelto, con i dati necessari per effettuare l'accesso.</p>}
            <p> Una volta ricevuto il Qr-Code si prega di procedere all'attivazione attraverso il proprio pannello di
                controllo o al form dedicato</p>
        </div> : <div>
            <label className={'purchase-shipping'} style={{marginBottom: 15}}>Una volta ricevuto, si prega di procedere
                all'attivazione della pagina attraverso il form che puo' trovare<Link
                    to={'./activate-qrCode'}> qui</Link></label>
        </div>}
    </div>)
}
