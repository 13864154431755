import { Link } from "react-router-dom";
import React from "react";

/**/
export default function FormLabel() {
  return (
    <label
      htmlFor="term-box"
      style={{ cursor: "pointer" }}
      className="activate-checkbox font-bold"
    >
      'DICHIARO di aver preso visione del documento{" "}
      <Link target="blank" className="terms-link" to="./terms">
        “TERMINI E CONDIZIONI” e di autorizzare il trattamento dei miei dati
        personali ai sensi del Regolamento Europeo 679/2016 e del Decreto
        Legislativo 196/2003 e successive modifiche.
      </Link>
      '{" "}
    </label>
  );
}
