import React, { useEffect, useRef, useState } from "react";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { parse, text } from "@fortawesome/fontawesome-svg-core";

export default function TooLongTextWrapper(props) {
  const [line, setLine] = useState(props.line || 4);
  const [visible, setVisible] = useState(false);
  const ref = useRef();
  const ref2 = useRef();
  const textStyle = {
    maxWidth: "100%",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: line,
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
  useEffect(() => {
    setVisible(
      ref2.current?.getBoundingClientRect &&
        parseInt(ref2.current?.getBoundingClientRect().height) >
          parseInt(ref.current?.getBoundingClientRect().height)
    );
  }, [ref.current, ref2.current]);
  return (
    <div>
      <div
        ref={ref}
        style={{
          ...textStyle,
          marginBottom:
            parseInt(ref2.current?.getBoundingClientRect().height, 10) * -1,
        }}
      >
        {props.children && props.children}
      </div>
      <div ref={ref2} id="all-height" style={{ visibility: "hidden" }}>
        {props.children && props.children}
      </div>
      {visible && props.children && (
        <button
          className="readMore"
          onClick={() => {
            if (line !== 2000) setLine(2000);
            else setLine(props.line || 4);
          }}
        >
          {line !== 2000 ? (
            <label
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              Continua a leggere
              <ArrowDropDown />
            </label>
          ) : (
            <label
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              Comprimi
              <ArrowDropUp />
            </label>
          )}
        </button>
      )}
    </div>
  );
}
