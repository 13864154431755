import React, {useEffect, useState} from 'react';
import './menuStyle.scss'
import {ArrowDropDown} from "@material-ui/icons";
// let spanRef = null
let entered = false
export default function CustomizedMenus(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [top, setTop] = React.useState(null);
    const [left, setLeft] = React.useState(null);
    const [bottom, setBottom] = React.useState(null);
    const [open, setOpen] = React.useState(null);
    const [spanRef, setSpanRef] = useState(null)
    const [close,setClose] = useState(false)
    useEffect(() => {
        window.onscroll = () => {
            setTop(spanRef.getBoundingClientRect().top)
            setLeft(spanRef.getBoundingClientRect().left)
            setBottom(spanRef.getBoundingClientRect().bottom)
        }
    }, );

    return (
        <div onMouseLeave={() => !entered && setOpen(false)} style={{display: "flex", flexDirection: "column", marginLeft: 18, justifyContent: "center"}}>
            <span
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                id={props.id}
                ref={(ref) => setSpanRef(ref)}
                color="dark"
                className={'menuButton'}
                style={{...props.style, alignItems: 'center', display: 'flex'}}
                onClick={() => {
                    props.forceClick && openModal(spanRef)
                }}
                onMouseEnter={(e) => {
                    !props.forceClick  && openModal(spanRef)
                }}
                onMouseLeave={(e) => {
                    spanRef.style['color'] = 'white'
                }}

            >{props.title}<ArrowDropDown/>
            </span>
            {open && <div onMouseLeave={() => {
                setOpen(false)
            }} style={{
                position: 'fixed',
                top: bottom,
                left:  window.innerWidth>500 ?left :window.innerWidth/10,
                backgroundColor: props.backgroundColorMenu,
                width: "auto",
                height: 'auto',
                zIndex: 9000000,
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
                padding: '10px ',
                border: '1px solid #fa0'
            }}>
                {props.menuItem && props.menuItem.map((e, i) =>
                    <div id={e.title + i} key={e.title + i} style={{...props.menuStyle, cursor: 'pointer'}}
                         onMouseEnter={event => {
                             entered = true
                             document.getElementById(e.title + i).style.backgroundColor = props.itemHoverColor || '#fa0'
                         }}
                         onMouseLeave={event => {
                             entered = false
                             document.getElementById(e.title + i).style.backgroundColor = props.backgroundColor || 'black'

                         }}
                         onClick={() => {
                             e.handler && e.handler()
                             setOpen(false)
                         }}
                         className={'text-row'}>
                        {e.icon && e.icon}
                        <label style={{
                            marginLeft: e.icon && 20, margin: !e.icon && '0 20px', cursor: 'pointer',
                        }}>{e.title}</label>
                    </div>
                )}
            </div>}
        </div>
    );

    function openModal() {
        if (spanRef) {
            spanRef.style.color = 'rgb(225, 128, 43)'
            setTop(spanRef.getBoundingClientRect().top)
            setLeft(spanRef.getBoundingClientRect().left)
            setBottom(spanRef.getBoundingClientRect().bottom)
            setOpen(true)
        }
    }
}
