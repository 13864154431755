import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import withContext from '../../WithContextFile';
import './How-it-costs.css';
import demo from '../../assets/imgStore/demo.png';
import fiori from '../../assets/imgStore/fiori.png';
import lumino from '../../assets/imgStore/lumino.png';
import memorial from '../../assets/imgStore/Memorial.png';
import play from '../../assets/imgStore/play.png';
import qrCode from '../../assets/imgStore/qrCode.png';
import requests from '../../Services/HttpServices';

const images = [qrCode, memorial, lumino, fiori, demo, play];

class HowItCosts extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		requests('get', 'getProductPrices', '').then(data => {
			if (data.data?.data?.products?.length > 0)
				data.data.data.products.forEach(e => {
					this.setState({ [e.name]: e.price });
				});
		});
	}

	render() {
		return !this.props.context.user ? (
			<>
				<div className="main-div">
					<div className="text-container">
						<p className="title-price">Prodotti</p>
						<label>
							<p className="desc">
								In questa sezione è possibile visionare i nostri servizi ed i relativi costi. Contattati
								per avere più infomazioni!
							</p>
						</label>
						<div className="card-row">
							{this.priceCard(
								this.state['qr-code'],
								'QrCode e Pagina-Ricordo',
								'',
								0,
								'Buy-qrCode',
								null
							)}
							{this.priceCard(
								this.state['servizio-memorial'],
								'Servizio Memorial',
								'     per 1 anno',
								1,
								'servizio-memorial',
								null
							)}
						</div>
						<div className="card-row">
							{this.priceCard(
								this.state['dedica-cero'],
								'Dedica',
								'e cero',
								2,
								'dedica e cero',
								'dedica-cero'
							)}
							{this.priceCard(
								this.state['dedica-fiori'],
								'Dedica',
								'e fiori',
								3,
								'dedica e fiori',
								'dedica-fiori'
							)}
							{this.priceCard(
								this.state['dedica-foto'],
								'Dedica',
								'e foto',
								4,
								'dedica e foto',
								'dedica-foto'
							)}
							{this.priceCard(
								this.state['dedica-video'],
								'Dedica',
								'e video',
								5,
								'dedica e video',
								'dedica-video'
							)}
						</div>
						<label className="warning">
							Attenzione! La dedica associata, la foto e il video saranno visibili a tutti solo dopo
							l'avallo del proprietario della pagina; nel caso in cui quest'ultimo non avalli la
							richiesta, il dono cero/fiori sarà pubblicato in forma anonima, mentre nel caso di foto e
							video il dono verrà sostituito con fiori virtuali anch'essi pubblicati in forma anonima.
						</label>
						&nbsp;
					</div>
				</div>
			</>
		) : (
			<Redirect to="/products" />
		);
	}

	priceCard(price, title, subTitle, img, message, type) {
		subTitle = subTitle || '';
		return (
			<div className="pricing-card">
				<div className="inner-card">
					<img className="inner-image" src={images[img]} alt="dedic" />
				</div>
				<div className="second-half-card">
					<div className="div-label-card">
						<label className="card-label">
							<p>
								<b>{title}</b> {` ${subTitle}`}
							</p>
						</label>
					</div>
					<label className="card-label-cost">al costo di:</label>
					<div className="pricing">
						<label className="price">
							€{' '}
							{parseFloat(price || '1')
								?.toFixed(2)
								?.replace('.', ',')}
						</label>
						{message === 'Buy-qrCode' || message === 'servizio-memorial' ? (
							<a href={`/${message}`} className="add-item">
								Aggiungi
							</a>
						) : (
							<Link
								to={{
									pathname: '/flowers',
									query: { message, id: message, price, type },
									search: type
								}}
								className="add-item"
							>
								Aggiungi
							</Link>
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default withContext(HowItCosts);
