import React from "react";
import Tooltip from '@material-ui/core/Tooltip';
import moment from "moment";
import "./AbbonatoPopup.scss";


export default function AbbonatoPopup(props) {
  let close = true;

  //VARIABILI
  var abbonatoData = props.abbonatoData;
  var storicoPagamenti = props.storicoPagamenti;
  var serialeLampada = props.serialeLampada;
  var lampadaTermine = props.lampadaTermine;

  return (
    <div className="abbonato-main-container" onClick={() => { close ? props.close() : (close = true); }}>
      <div onClick={() => { close = false; }} className="abbonato-card" style={{ maxWidth: 360 }}>

        <label className="ttitle">Gestisci Abbonato</label>

        <div className="row">
          <div className="ablock">
            <label className="atitle">Nome e Cognome</label><br />
            <div className="atext">{abbonatoData.nome + " " + abbonatoData.cognome}</div>
          </div>
          <div className="ablock">
            <label className="atitle">Codice Fiscale</label><br />
            <div className="atext">{abbonatoData.codiceFiscale}</div>
          </div>
        </div>

        <div className="row">
          <div className="amulti">
            <label className="atitle">Indirizzo</label><br />
            <div className="atext">{abbonatoData.indirizzo}</div>
          </div>
        </div>

        <div className="row">
          <div className="amulti">
            <b>SDI:</b> {abbonatoData.sdi}<br />
            <b>Telefono:</b> {abbonatoData.telefono}<br />
            <b>Mail:</b> {abbonatoData.mail}<br />
            <b>Mail PEC:</b> {abbonatoData.mailPec}
          </div>
        </div>

        <div className="row">
          <div className="amulti">
            <label className="atitle">Data Scadenza Abbonamento Lampada</label><br />
            <div className="atext">{moment(lampadaTermine).format("DD/MM/YYYY HH:mm")}</div>
          </div>
        </div>
        
        <label className="titolotabella">Storico Pagamenti Lampada</label>
        <div className="row">
          <div className="tabellastoricopagamenti">
              {storicoPagamenti.map((pagamento, index) => (
                <div className="storicopagamenti">
                  <Tooltip title="Numero Pagamento"><p style={{ padding: 4, width: 200 }}>{pagamento.idPagamento + ": " + pagamento.protocolloServizio.charAt(0).toUpperCase() + pagamento.protocolloServizio.slice(1)}</p></Tooltip>
                  <Tooltip title="Data Pagamento"><p style={{ padding: 4, width: 125 }}>{moment(pagamento.dataPagamento).format("DD/MM/YYYY HH:mm")}</p></Tooltip>
                </div>
              ))}
          </div>
        </div>
        
        <div style={{alignSelf: "flex-end", marginTop: "auto" }}>
          <button style={{width: "auto", marginRight: "10px"}} onClick={() => { props.addPagamento(abbonatoData.idAbbonato, serialeLampada); props.close(); }}>Aggiungi Pagamento</button>
          <button onClick={() => { props.close(); }}>Chiudi</button>
        </div>
      </div>
    </div>
  );
}