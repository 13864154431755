import React, { Component, Fragment } from "react";
import { Redirect } from "react-router";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DateFnsIt from "date-fns/locale/it";
import Axios from "axios";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import { Spinner } from "react-activity";
import withContext from "../../../WithContextFile";
import "../../Activate/Activate.css";
import validate from "../../../formValidationService";
import AddressService from "../../FormComponents/AddressComponent";
import addressServiceGetData from "../../FormComponents/addressService";
import UserFormData from "../../FormComponents/userFormData";
import PhotoInput from "../../FormComponents/PhotoInput";
import requests, { baseURL } from "../../../Services/HttpServices";
import FileUpload10 from "../../FormComponents/FileUpload10";
import FileNamesComponent, { readURL } from "../../FormComponents/FileNamesComponent";
import VideoInput from "../../FormComponents/VideoInput";
import { Button, Typography } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import CkEditorCustom from "../../FormComponents/CkEditor";
import FeedbackModal from "../../FormComponents/FeedbackModal";
import { RemoveCircleOutline } from "@material-ui/icons";
import MultipleReferringPlace from "../../FormComponents/MultipleReferringPlace";

let partners;
const fileIndex = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
let globalData  = null;
const disable   = false;

class AddDeceased extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loggedUser : null,
			radioValue : "",
			checkBox   : "",
			files      : [],
			form       : {
				name              : "",
				surname           : "",
				CF                : "",
				phone             : "",
				email             : "",
				qrCode            : "",
				nameTo            : "",
				surnameTo         : "",
				bornDate          : null,
				deathDate         : null,
				graveyardCity     : "",
				graveyardRegion   : "",
				graveyardProvince : "",
				nationality       : "105",
			},
			formError : {},
			aphorism  : "",
		};
	}

	componentDidMount() {
		this.getPartner();
	}

	getPartner(value) {
		requests("get", "getPartner")
			.then((data) => {
				partners = [];
				partners = data.data.partnerlist;
				// value && this.partnerDataTable()
				this.setState({ load: !this.state.load });
			})
			.catch((e) => {
				this.setState({ load: !this.state.load });
			});
	}

	setInitialData() {
		const temp = this.state.form;

		temp.name     = this.state.loggedUser.name;
		temp.username = this.state.loggedUser.username;
		temp.surname  = this.state.loggedUser.surname || "Cognome";
		temp.email    = this.state.loggedUser.email;
		temp.phone    = this.state.loggedUser.telephone;
		temp.CF       = this.state.loggedUser.cf;
		let radioContact;
		try {
			radioContact = this.state.loggedUser.preferred_contact.type;
		} catch (e) {
			radioContact = "";
		}

		this.setState({ form: temp, radioValue: radioContact });
	}

	render() {
		if (!this.state.loggedUser && sessionStorage.getItem("User")) {
			try {
				this.setState({
					loggedUser: JSON.parse(sessionStorage.getItem("User")).userdata,
				});
			} catch (e) {

			}
		}
		if (this.state.loggedUser && this.state.form.name == "") {
			this.setInitialData();
			this.state.userAddress
				&& addressServiceGetData("province", this.state.userAddress.region).then((resolve) => {
					this.setState({ provincie: resolve });
					addressServiceGetData("location", this.state.userAddress.province).then((resolve) => {
						this.setState({ comuni: resolve });
					});
				});
		}
		return !this.props.context.user ? (
			<>
				{this.state.uploading && (
					<div className="div-upload">
						<Spinner size={100} />
						<Typography className="label-upload">Attendi il caricamento dei files</Typography>
					</div>
				)}
				{this.state.successModal && this.successModal()}
				{this.state.openModalPhoto && this.photoLapideModal(this.state.formData)}
				<div className="contact-container">
					{this.state.feedbackModal && (
						<FeedbackModal
							result={this.state.modalResult}
							close={() => {
								this.setState({ feedbackModal: false });
							}}
							label={this.state.modalText}
						/>
					)}
					<div className="activate-form-div" style={{ marginTop: 30 }}>
						<form
							className="activate-form"
							onSubmit={(event) => {
								event.preventDefault();
								const formData = new FormData(event.target);
								const object   = {};
								formData.forEach((value, key) => {
									object[key] = value;
								});
								if (!object["photo-lapide"]) {
									this.setState({ formData });
									this.send(formData, true);
								} else this.send(formData);
							}}
						>
							<div style={{ display: "none" }}>
								<UserFormData
									nationalityChange={() => this.setState({ reload: true })}
									names="admin-add"
									form={this.state.form}
									formError={this.state.formError}
								/>
							</div>
							<label className="death-data">Dati Defunto</label>
							<input style={{ display: "none" }} name="csrf_token" value={this.state.csrf_token} />
							<TextField
								className="activate-field"
								label="Codice QrCode *"
								name="admin-add-deceased-code"
								value={this.state.form.qrCode}
								onChange={(event) => this.setAndValidateForm(event.target.value, "qrCode")}
								error={this.state.formError.qrCode}
								placeholder="Ad esempio 0001"
								helperText={this.state.formError.qrCode && "Campo obbligatorio"}
								disabled={disable}
							/>
							<TextField
								className="activate-field"
								label="Nome del Defunto *"
								name="admin-add-deceased-name"
								value={this.state.form.nameTo}
								onChange={(event) => this.setAndValidateForm(event.target.value, "nameTo")}
								error={this.state.formError.nameTo}
								helperText={this.state.formError.nameTo && "Campo obbligatorio"}
							/>
							<TextField
								className="activate-field"
								label="Cognome del Defunto *"
								name="admin-add-deceased-surname"
								value={this.state.form.surnameTo}
								onChange={(event) => this.setAndValidateForm(event.target.value, "surnameTo")}
								error={this.state.formError.surnameTo}
								helperText={this.state.formError.surnameTo && "Campo obbligatorio"}
							/>
							<div className="activate-date-field">
								<MuiPickersUtilsProvider locale={DateFnsIt} utils={DateFnsUtils}>
									<KeyboardDatePicker
										className=" ml--5 date "
										inputVariant="filled"
										format="dd/MM/yyyy"
										margin="normal"
										onChange={(event, asd) => {
											this.setAndValidateForm(event, "bornDate");
										}}
										style={{ backgroundColor: "white" }}
										id="date"
										name="admin-add-born-date"
										label="Data di nascita"
										value={this.state.form.bornDate}
									/>
									<KeyboardDatePicker
										className=" m-12 date"
										inputVariant="filled"
										format="dd/MM/yyyy"
										margin="normal"
										name="admin-add-death-date"
										onChange={(event, asd) => {
											this.setAndValidateForm(event, "deathDate");
										}}
										id="date"
										label="Data di morte"
										value={this.state.form.deathDate}
									/>
								</MuiPickersUtilsProvider>
							</div>
							<div className="buy-form-paragraph">Dati del Cimitero</div>
							<div className="Partner-row-fields">
								<TextField
									className="partner-field "
									helperText="ad esempio Cimitero di Roma"
									label="Nome cimitero"
									name="admin-add-graveyard-name"
								/>
							</div>
							<AddressService
								names="admin-add-graveyard"
								form={this.state.form}
								formError={this.state.formError}
								province={this.state.provincie}
								comuni={this.state.comuni}
								disableCap={false}
								disableRoute={false}
								graveyardData
							/>
							<TextField
								className="partner-field "
								label="Indirizzo"
								name="admin-add-graveyard-address"
							/>
							<CkEditorCustom
								ckeditor
								title="Aforismi"
								onChange={(editor) => {
									this.setState({ aphorism: editor.getData() });
								}}
								data={this.state.form.aphorism}
							/>
							<TextField
								name="admin-add-deceased-aphorism"
								className="text-area"
								id="outlined-multiline-static"
								value={this.state.aphorism}
								style={{ display: "none" }}
								label="Aforismi"
								multiline
								rows={8}
								variant="outlined"
							/>
							<div className="buy-form-paragraph">Luogo di Riferimento</div>
							<MultipleReferringPlace
								onChange={(sendData) => this.setState({ "referring-place": JSON.parse(sendData) })}
							/>
							<PhotoInput
								name="lapide"
								setFileName={(name, photo) => {
									this.setState({ filename: name });
									readURL(photo).then((data) => this.setState({ photod: data }));
								}}
							/>
							<div className="file-upload">
								<label className="pointer" htmlFor="photo-lapide" color="blue" title="">
									<p className="pointer-label">
										{this.state.filename
											|| "Carica foto del defunto per la Lapide Digitale (Max 15MB per foto)"}
										{" "}
									</p>
								</label>
							</div>
							{this.state.photod && (
								<div className="is-relative">
									<img
										style={{
											maxHeight : 300,
											margin    : "20px auto auto",
											maxWidth  : 208,
											minHeight : 300,
											minWidth  : 200,
										}}
										src={this.state.photod}
										className=" memorial-page-photo"
									/>
									<RemoveCircleOutline
										className="remove-photo"
										onClick={() => {
											this.setState({
												filename : "",
												photod   : null,
												erased   : true,
											});
											document.getElementById("photo-lapide").value = null;
										}}
									/>
								</div>
							)}
							<TextField
								variant="outlined"
								name="partnerid"
								onChange={(e) => {
									this.setState({ partnerid: e.target.value });
								}}
								label="Assegna defunto ad un partner"
								style={{ marginTop: 40 }}
								select
							>
								<MenuItem value="-1">Nessuno</MenuItem>
								{partners && partners.map((e) => <MenuItem value={e.ID}>{e.name}</MenuItem>)}
							</TextField>
							<FormControlLabel
								style={{ marginTop: 20 }}
								className="activate-checkbox"
								control={(
									<Checkbox
										color="primary"
										name="memorial-flag"
										id="memorial-flag"
										onChange={(event, checked) => this.setState({ checkBox: checked })}
									/>
								)}
								label="Spunta per inserire il servizio memorial"
							/>
							{this.state.checkBox && (
								<div>
									<div className="buy-form-paragraph">Dati servizio memorial</div>
									<CkEditorCustom
										title="Biografia"
										onChange={(editor) => {
											const temp     = this.state.form;
											temp.biografic = editor.getData();
											this.setState({ form: temp });
										}}
										data={this.state.form.biografic}
										error={this.state.formError.biografic}
									/>
									<TextField
										className="text-area"
										id="outlined-multiline-static"
										name="admin-add-deceased-bio"
										label="Biografia"
										style={{ display: "none" }}
										multiline
										rows={8}
										variant="outlined"
										onChange={(event) => {
											this.setAndValidateForm(event.target.value, "biografic");
										}}
										helperText={this.state.formError.biografic ? "Campo obbligatorio" : ""}
										error={this.state.formError.biografic}
									/>
									<div>
										<FileUpload10
											setFileIndex={() => {
												fileIndex.shift();
											}}
											setFile={(temp) => this.setState({ files: temp })}
											name="memorial"
											files={this.state.files}
										/>
										<div className="file-upload">
											<label
												className="pointer"
												htmlFor={`memorial-file${fileIndex[0]}`}
												color="blue"
												title=""
											>
												<p className="pointer-label">
													{this.state.files.length + 1 > 10
														? "Tutti i file caricati"
														: `Carica immagine ${this.state.files.length + 1} di 10`
														  + " (Max 15MB per foto)"}
												</p>
											</label>
										</div>
										<FileNamesComponent
											name="memorial"
											files={this.state.files}
											setFile={(temp) => this.setState({ files: temp })}
											setFileIndex={(temp, i) => fileIndex.unshift(temp[i].id)}
										/>
									</div>
									<div>
										<div className="file-upload">
											<label
												className="pointer"
												htmlFor="memorial-video-upload"
												color="blue"
												title=""
											>
												<p className="pointer-label">
													{" "}
													{this.state.fileVideoName || "Carica Video (Max 15MB)"}
												</p>
											</label>
										</div>
										{this.state.fileError
											&& !this.state.fileVideoName
											&& this.state.files.length < 1 && (
												<label style={{ color: "red" }}> Carica almeno un file</label>
											)}
									</div>
								</div>
							)}
							&nbsp;
							<VideoInput
								name="memorial"
								setVideoName={(videoName) => {
									this.setState({ fileVideoName: videoName });
								}}
							/>
							&nbsp;
							<div className="bottom-activate-form">
								<button onClick={(event) => {}} className="activate-submit">
									Procedi
								</button>
							</div>
						</form>
					</div>
					&nbsp;
				</div>
			</>
		) : (
			<Redirect to="/products" />
		);
	}

	photoLapideModal(formdata) {
		let close2 = true;
		return (
			<div
				className="modal-bg"
				onClick={() => {
					if (close2) {
						this.setState({ openModalPhoto: false });
						// this.props.history.push('admin-panel')
					} else close2 = !close2;
				}}
			>
				<div
					className="inner"
					onClick={() => {
						close2 = false;
					}}
				>
					<Typography style={{ fontSize: 17 }}>Attenzione!!</Typography>
					<Typography style={{ fontSize: 12, marginTop: 15 }}>
						Non hai caricato la foto della lapide, desideri continuare?
					</Typography>
					<div
						style={{
							flexDirection  : "row",
							display        : "flex",
							justifyContent : "space-between",
						}}
					>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								this.setState({ openModalPhoto: false });
								this.sendData(globalData);
							}}
						>
							SI
						</Button>
						<Button
							color="primary"
							style={{ marginLeft: 20 }}
							onClick={() => {
								this.setState({ openModalPhoto: false });
								// this.props.history.push('admin-panel')
							}}
							variant="contained"
						>
							NO
						</Button>
					</div>
				</div>
			</div>
		);
	}

	send(formdata, notLapide) {
		let { formError } = this.state;
		let error         = false;
		for (const property in this.state.form) {
			if (
				property !== "province"
				&& property !== "address"
				&& property !== "city"
				&& property !== "location"
				&& property !== "region"
				&& property !== "civic"
				&& property !== "route"
				&& property !== "cap"
				&& property !== "complete_address"
				&& property !== "CF"
			) {
				formError = validate(this.state.form[property], property, formError);
				error     = error || formError[property];
			}
		}
		this.setState({ formError });
		let fileError = false;
		if (this.state.checkBox) {
			fileError = !this.state.filename || !this.state.files.length > 0;
			this.setState({ fileError });
		}
		if (!(error || fileError)) {
			globalData = formdata;
			notLapide ? this.setState({ openModalPhoto: true, formdata }) : this.sendData(formdata);
		}
	}

	sendData(formdata) {
		getCsrf().then((csrf) => {
			formdata.set("csrf_token", csrf);
			if (this.state["referring-place"]) {
				const toSend = [...this.state["referring-place"]];
				if (!this.state["referring-place"][this.state["referring-place"].length - 1].region) toSend.pop();
				formdata.set("referring-place", JSON.stringify(toSend));
			}

			this.setState({ uploading: true });

			requests("sendform", "addDeceased", formdata).then((data) => {
				this.setState({ uploading: false });
				if (data.response === "success") {
					this.setState({ successModal: true });
				} else if (data.message) {
 this.setState({
 	feedbackModal : true,
 	modalResult   : "Errore",
 	modalText     : data.message,
 });
				} else {
 this.setState({
 	feedbackModal : true,
 	modalResult   : "Errore",
 	modalText     : "Errore imprevisto riprovare piu` tardi",
 });
				}
			});
		});
	}

	successModal() {
		let close = true;
		return (
			<div
				className="modal-bg"
				onClick={() => {
					if (close) {
						this.setState({ successModal: false });
						window.scrollTo(0, 0);
						window.location.reload();
						window.scrollTo(0, 0);
					} else close = !close;
				}}
			>
				<div
					className="inner"
					style={{ width: 350 }}
					onClick={() => {
						close = false;
					}}
				>
					<Typography style={{ fontSize: 19 }}>Successo</Typography>
					<Typography style={{ fontSize: 12, marginTop: 15 }}>
						Il defunto verra' caricato al piu' presto
					</Typography>
					<div
						style={{
							flexDirection  : "row",
							display        : "flex",
							justifyContent : "space-between",
							marginTop      : 20,
						}}
					>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								window.scrollTo(0, 0);
								window.location.reload();
								window.scrollTo(0, 0);
							}}
						>
							Aggiungine un altro
						</Button>
						<Button
							color="primary"
							style={{ marginLeft: 20 }}
							onClick={() => {
								this.setState({ successModal: false });
								window.scrollTo(0, 0);
								window.location.reload();
								window.scrollTo(0, 0);
								// this.props.history.push('admin-panel')
							}}
							variant="contained"
						>
							Chiudi
						</Button>
					</div>
				</div>
			</div>
		);
	}

	setAndValidateForm(data, attribute) {
		const { form }    = this.state;
		let { formError } = this.state;
		form[attribute]   = data;
		this.setState({ form });
		formError = validate(data, attribute, formError);
		this.setState({ formError });
	}
}

function getCsrf() {
	return new Promise((result, err) => Axios.get(`${baseURL}api/auth/securitytoken`)
			.then((resolve) => {
				if (resolve != null) {
					result(resolve.data._csrf);
				} else result({ DATA: "NO DATA" });
			})
			.catch((err) => result({ err })));
}

export default withContext(AddDeceased);
