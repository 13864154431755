import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { Link } from 'react-router-dom';
import requests from './Services/HttpServices';

let session;
let drawerElements;

export default class NavDrawer extends React.Component {
	originalDrawerElements = [
		{ link: '/registrazione', label: 'REGISTRATI' },
		{ link: '/login', label: 'ACCEDI' },
		{ link: '/home', label: 'HOME' },
		{ link: '/information', label: "COS'E'" },
		{ link: '/how-it-works', label: 'COME FUNZIONA' },
		{ link: '/pricing', label: 'PRODOTTI' },
		// {link: '/terms', label: 'TERMINI E CONDIZIONI'},
		{ link: '/memorial?1000', label: 'DEMO' },
		{ link: '/activate-qrCode', label: 'ATTIVA QRCODE' },
		{ link: '/contact', label: 'CONTATTI' },
		{ link: '/partner', label: 'LAVORA CON NOI' }
	];

	constructor(props) {
		super(props);
		this.state = {
			path: ''
		};
		this.setState({ path: '' });
	}

	drawDrawer() {
		return drawerElements.map((e, i) => {
			if (e.label === 'DEMO') {
				return (
					<a href={e.link} key={e.label + i} className="drawerComponent">
						<div
							onClick={() => {
								this.setState({ path: e.link });
								this.props.routename.pathname = '';
								this.props.close();
							}}
							style={{
								backgroundColor:
									((this.props.routename && this.props.routename.pathname) === e.link ||
										this.state.path === e.link) &&
									'#ffaa00'
							}}
							className="drawer-inner-component"
						>
							{e.label}
						</div>
					</a>
				);
			}
			return (
				<Link key={e.label + i} to={e.link} className="drawerComponent">
					<div
						onClick={() => {
							this.props.close();
							this.setState({ path: e.link });
							this.props.routename.pathname = '';
						}}
						style={{
							backgroundColor:
								((this.props.routename && this.props.routename.pathname) === e.link ||
									this.state.path === e.link) &&
								'#ffaa00'
						}}
						className="drawer-inner-component"
					>
						{e.label}
					</div>
				</Link>
			);
		});
	}

	changeDrawer(type) {
		let route = '';
		switch (type) {
			case 0:
				route = '/client-panel#account';
				break;
			case 1:
				//Cambia Pannello Partner in base al tipo
				if (session.userdata.partnertype == "gestorelampade") { route = '/lampade-panel#lampade'; }
				else { route = '/partner-panel#deceased'; }
				break;
			case 2:
				route = '/admin-panel#qrcode';
				break;
			default:
				route = '/';
				break;
		}
		// if (type === 1) {
		// 	drawerElements.splice(4, 0, {
		// 		link: '/partner-panel#aggiungi-defunto',
		// 		label: 'AGGIUNGI DEFUNTO'
		// 	});
		// }
		drawerElements.shift();
		drawerElements.shift();
		drawerElements.push({ link: route, label: 'PANNELLO DI CONTROLLO' });
		drawerElements.unshift({
			label: `CIAO ${session.userdata.name.toUpperCase()}`
		});
	}

	render() {
		drawerElements = this.originalDrawerElements.slice();
		try {
			session = JSON.parse(sessionStorage.getItem('User'));
		} catch (e) {
			session = '';
		}
		if (session === null) {
			session = '';
		} else {
			if (session.userdata && session.userdata.permissions.platformAccess == 1) session.type = 2;
			if (session.userdata && session.userdata.permissions.platformAccess == 2) session.type = 1;
			if (session.userdata && session.userdata.permissions.platformAccess == 0) session.type = 0;
		}
		session !== '' ? this.changeDrawer(session.type) : (drawerElements = this.originalDrawerElements.slice());
		return (
			<Drawer
				anchor="right"
				open={this.props.drawerOpened}
				onClose={() => {
					this.setState({ path: '' });
				}}
				onTransitionEnd={() => {
					this.setState({ path: '' });
				}}
				onTransitionEndCapture={() => {
					this.setState({ path: '' });
				}}
				onAnimationStart={() => {
					this.setState({ path: '' });
				}}
			>
				<div
					onClick={() => {
						this.setState({ changed: true });
					}}
					style={{
						width: 'auto',
						height: window.innerHeight + 300,
						display: 'flex',
						flexDirection: 'column',
						backgroundColor: '#000',
						paddingTop: window.innerWidth > 510 ? 18 : 10,
						paddingRight: 60
					}}
				>
					{this.drawDrawer(session)}
					{session !== '' && (
						<Link
							to="/home"
							className="drawerComponent"
							onClick={() => {
								sessionStorage.removeItem('user');
							}}
						>
							<div
								onClick={() => {
									this.props.routename.pathname = '';
									this.props.close();
									requests('get', 'logout').then(data => {
										if (data.data.response == 'success') {
											sessionStorage.removeItem('User');
											sessionStorage.removeItem('user');
											sessionStorage.removeItem('UserAddress');
											sessionStorage.removeItem('ExpiringMemorial');
											this.setState({ path: '/home' });
										} else alert('Errore durante il logout');
									});
								}}
								className="drawer-inner-component"
							>
								ESCI
							</div>
						</Link>
					)}
				</div>
			</Drawer>
		);
	}
}
