// NEI FORM SI DEVE PRENDERE IN AUTOMATICO I VALORI DALLA SESSIONE MENTRE NELLA REGISTRAZIONE ALL'INSERIMENTO DI UN VALORE(EMAIL,WA) CON PREFERENZA SU WA
import React, { useEffect, useState } from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import AreYouSure from "./AreYouSureModal";
import requests from "../../Services/HttpServices";
import { emailValidation } from "../../formValidationService";

export default function ContactPreferenceForm(props) {
	const [clicked, setClickesd]          = useState();
	const [label]                         = useState(props.label);
	const [modal, setModal]               = useState(false);
	const [reload, setReload]             = useState(false);
	const [selected, setSelected]         = useState(null);
	const [phoneLabel, setPhoneLabel]     = useState(null);
	const [defaultValue, setDefaultValue] = useState(null);

	useEffect(() => {
		setReload(!reload);
	}, [props.phone, props.email]);
	!selected && props.value && setSelected(props.value);

	useEffect(() => {
		if (!emailValidation(props.email)) {
      props.value !== "wa"
        ? !phoneLabel && setSelected("email")
        : setSelected("wa");
      !props.logged && props.value !== "wa" && props.handler(null, "email");
		}
	}, [props.email, props.value]);

	useEffect(() => {
		let phone = "";
		if (props.phone && props.phone.indexOf("-") !== -1) {
			phone = props.phone.split("-")[1];
			setPhoneLabel(phone);
		} else setPhoneLabel(props.phone || "");
		if (phone.length >= 6 && props.value !== "email") {
			if (!props.logged) {
				setSelected("wa");
        props.handler(null, "wa");
			}
		}
	}, [props.phone, props.value]);

	return (
		<div style={{ display: "flex", flexDirection: "column" }}>
			<label className="activate-radio-title">{label}</label>

			<RadioGroup
				aria-label="preferenxe contatto"
				name="contact-preference"
				value={selected}
				onChange={(event, value) => {
					if (!props.setting) {
						if (props.logged && selected) {
							setClickesd(value);
							setModal(true);
						} else {
              props.handler(event, value);
              if (props.logged) {
              	const bodydata                 = {};
                bodydata["contact-preference"] = value;
                requests("post", "changePreferece", bodydata).then((result) => {
                  result.response === "success"
                    && sessionStorage.setItem(
                    	"User",
                      JSON.stringify(result.session),
                    );
                  props.handler(event, value);
                  setSelected(value);
                  // props.closeHandler()
                });
              } else {
                props.handler(event, value);
                setSelected(value);
              }
              setSelected(value);
						}
					} else {
						setClickesd(value);
						setSelected(value);
					}
				}}
			>
				<FormControlLabel
					value="email"
					disabled={emailValidation(props.email)}
					control={<Radio id="emailRadio" color="primary" />}
					label={(
						<label
							style={{ cursor: "pointer", margin: "auto" }}
							htmlFor="emailRadio"
							className="activate-checkbox"
						>
							"Tramite una MAIL
							{" "}
							{props.email && `a ${props.email}`}
							"
						</label>
					)}
				/>
				<FormControlLabel
					disabled={
						!props.phone || !phoneLabel || (phoneLabel && phoneLabel.length < 6)
					}
					value="wa"
					control={<Radio id="waRadio" color="primary" />}
					label={(
						<label
							style={{ cursor: "pointer", margin: "auto" }}
							htmlFor="waRadio"
							className="activate-checkbox"
						>
							"Tramite un messaggio WHATSAPP
							{" "}
							{props.phone && `al ${phoneLabel}`}
							"
						</label>
					)}
				/>
				{/* <FormControlLabel disabled={!props.phone} value="sms" control={<Radio id={'smsRadio'} color={"primary"}/>}
                              label={<label style={{cursor: "pointer", margin:"auto"}} htmlFor={'smsRadio'}
                                            className={'activate-checkbox'}>"Tramite un
                                  messaggio SMS {props.phone && 'al ' + props.phone}"</label>}/> */}
			</RadioGroup>
			{props.error && (props.value === "" || !props.value) && (
				<label
					style={{
						color      : "red",
						textAlign  : "left",
						margin     : 10,
						marginLeft : 30,
					}}
				>
					Devi effettuare una scelta
				</label>
			)}
			{modal && (
				<AreYouSure
					handler={(value, value1) => {
            props.handler(value, value1);
            setSelected(clicked);
					}}
					data={clicked}
					closeHandler={() => setModal(false)}
				/>
			)}
			{props.setting && (
				<div
					className="field is-clearfix"
					style={{ margin: "0 auto", marginTop: 20 }}
				>
					<button
						className="button is-primary is-outlined is-pulled-right"
						onClick={() => {
							setModal(true);
						}}
					>
						Salva
					</button>
				</div>
			)}
		</div>
	);
}
