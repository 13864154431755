import React from "react";
export default function RedirectAllURL(props){
    if( props.history.location && props.history.location.pathname) {
        let idMemorial = props.history.location.pathname.slice(1, props.history.location.pathname.length)
        if (idMemorial.indexOf('.html')>=0)idMemorial.replace('.html','')
        props.history.push('/memorial?' + idMemorial)
    }
    return <div>

    </div>
}
