import React, { useState } from 'react';
import './QrCodeRequest';
import { TextField } from '@material-ui/core';
import requests from '../../../Services/HttpServices';

const menuValues = [1, 5, 10, 25, 50, 100];
let edit = false;
const qrCodesRequested = [];
export default function RequestForm(props) {
	const [selectedValue, setSelectedValue] = useState('');
	return (
		<form
			onSubmit={e => {
				document.getElementById('sendQR').disabled = true;

				e.preventDefault();
				if (selectedValue > 500) {
					document.getElementById('sendQR').disabled = false;

					alert('Il numero massimo di qrCode inseribili e` 500');
				}
				if (selectedValue <= 0 || selectedValue == '') {
					document.getElementById('sendQR').disabled = false;
					alert('Devi inserire un numero superiore allo 0');
				} else
					requests('get', 'qrcodeRequest', selectedValue).then(data => {
						if (data.data.response === 'success') {
							data.data.qrcodeData.map((e, i) => {
								const temp = {
									name: '',
									qrcodeAttivazione: e.ID,
									city: e.verification_code
								};
								document.getElementById('sendQR').disabled = false;
								props.handlerData(temp);
							});
							alert('QrCode creati correttamente');
							window.location.reload();
						}
					});
			}}
			id="request-form"
		>
			<TextField
				className="request-field"
				value={selectedValue}
				variant="outlined"
				type="number"
				inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
				onKeyDown={event => {
					const temp = selectedValue;
					event.key === 'Backspace' ? (edit = true) : (edit = false);
					if (!isNaN(parseInt(event.key))) setSelectedValue(`${selectedValue}${event.key}`);
					else setSelectedValue(temp);
				}}
				onChange={e => {
					// !selectedValue && e.preventDefault()
					if (isNaN(parseInt(selectedValue))) {
						setSelectedValue('');
					}
					edit && setSelectedValue(e.target.value);
				}}
				label="Scrivere il numero di QrCode da generare (max 500)"
			/>
			<button className="search-button" id="sendQR" style={{ fontSize: 20 }} onClick={() => {}}>
				Genera nuovi QrCode
			</button>
		</form>
	);
}
