import React, { Component, Fragment } from "react";

class PostDeceased extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
    };
  }

  render() {
    return (
      <>
        <div style={{ height: window.innerHeight - 250 }}>
          <p
            className="death-data"
            style={{
              alignSelf: "center",
              marginTop: 20,
              marginBottom: 20,
              textAlign: "center",
            }}
          >
            Successo
          </p>
          <p> Il defunto verra' caricato al piu' presto</p>
          &nbsp;
        </div>
      </>
    );
  }
}

export default PostDeceased;
