import { Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { VisibilityOff } from "@material-ui/icons";
import HttpServices from "../../Services/HttpServices";

let closeExistModal = false;
export default function ExistsModal(props) {
  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [email] = useState(props.email);
  const [username] = useState(props.username);
  const [visible, setVisible] = useState(false);
  return (
    <div
      className="modal-bg"
      onClick={() => {
        closeExistModal && props.deniedModal();
        closeExistModal = true;
      }}
    >
      <div
        className="inner"
        onClick={() => {
          closeExistModal = false;
        }}
      >
        <Typography style={{ marginBottom: 12 }} align="center">
          Attenzione!
        </Typography>
        <Typography style={{ marginBottom: 17 }}> {props.label}</Typography>
        <TextField
          label="Password"
          InputProps={{
            endAdornment: visible ? (
              <VisibilityIcon
                style={{ cursor: "pointer", color: "black" }}
                onClick={() => setVisible(false)}
              />
            ) : (
              <VisibilityOff
                style={{ cursor: "pointer", color: "black" }}
                onClick={() => setVisible(true)}
              />
            ),
          }}
          type={!visible && "password"}
          variant="outlined"
          onChange={(event) => setPassword(event.target.value)}
        />
        <div className="submit-container">
          <div style={{ width: 80 }} onClick={(event) => props.deniedModal()}>
            Annulla
          </div>
          <div
            style={{ width: 80 }}
            onClick={() => {
              const loginData = {
                email: username || email,
                password,
                _submitLogin: 1,
              };
              HttpServices("post", "login", loginData).then((data) => {
                if (data.result !== "fail" && data.result == "success") {
                  // sessionStorage.setItem('user', JSON.stringify(data.session))
                  window.location.hash = "form";
                  window.location.reload();
                  // this.sendPurchaseData(this.state.object)
                  // props.success()
                } else {
                  alert("Credenziali errate");
                }
              });
            }}
          >
            Procedi
          </div>
        </div>
      </div>
    </div>
  );
}
