import React, { useEffect, useState } from "react";
import { BigPlayButton, Player } from "video-react";
import moment from "moment";
import { Create, RemoveCircleOutline } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import { useHistory } from "react-router";
import requests from "../../../Services/HttpServices";
import TooLongTextWrapper from "../../FormComponents/TooLongText";
import fiori from "../../../assets/imgStore/fiori.png";
import lumino from "../../../assets/imgStore/lumino.png";
import ModifyGiftAdmin from "./ModifyGiftAdmin";
import FeedbackModal from "../../FormComponents/FeedbackModal";
import AreYouSureGeneral from "../../FormComponents/AreYouSureModalGeneral";

const dedicsImages = [fiori, lumino];
const dedicsTypeArray = [
  "dedica-fiori",
  "dedica-cero",
  "dedica-foto",
  "dedica-video",
];

export default function ViewDedicList(props) {
  const [dedicList, setDedicList] = useState(null);
  const [selectedDedic, setSelectedDedic] = useState(null);
  const [deceasedData, setDeceasedData] = useState(null);
  const [modify, setModify] = useState(false);
  const [choicheModal, setChoicheModal] = useState(false);
  const [modalResult, setModalResult] = useState(false);
  const [modalValue, setModalValue] = useState(false);
  const [modalText, setModalText] = useState("");

  const history = useHistory();
  useEffect(() => {
    // let deceasedID=history.location.search.split('=')[1]
    getData();
  }, []);

  function deleteDedic(dedicId) {
    requests("post", "deleteDedic", { dedicID: dedicId })
      .then((data) => {
        if (data.result === "success" || data.response === "success") {
          setModalResult("Successo");
          setModalText("Elemento eliminato con successo");
        } else {
          setModalResult("Errore");
          setModalText(data?.message || "Errore imprevisto");
        }
      })
      .finally(() => {
        setModalValue(true);
        setChoicheModal(false);
      });
  }

  function getData() {
    const deceasedID =
      props.deceasedID || history.location.search.split("=")[1];
    requests("get", "deceasedData", deceasedID || -1).then((data) => {
      if (data.data.response === "success" || data.data.result === "success") {
        setDedicList(data.data.dedic.reverse());
        if (data.data.dedic.length <= 0) {
          window.location.href = "/admin-panel#pages";
        }
        const toSet = data.data.deceasedData;
        toSet["photo-lapide"] = data.data["photo-lapide"];
        setDeceasedData(toSet);
      } else
        window.history.pushState(
          "object or string",
          "Title",
          "/admin-panel#pages"
        );
    });
  }

  return (
    <div
      style={{
        textAlign: "center",
        maxWidth: 1000,
        alignSelf: "center",
        paddingBottom: 20,
      }}
    >
      {modalValue && (
        <FeedbackModal
          label={modalText}
          result={modalResult}
          close={() => {
            setModalValue(false);
            getData();
          }}
        />
      )}
      {choicheModal && (
        <AreYouSureGeneral
          text="Sei sicuro di voler eliminare la dedica selezionata?"
          accept={() => {
            deleteDedic(selectedDedic.ID);
            setChoicheModal(false);
          }}
          closeHandler={() => setChoicheModal(false)}
        />
      )}
      <p>&nbsp;</p>
      {modify && (
        <ModifyGiftAdmin
          dedicData={selectedDedic}
          deceased={deceasedData}
          handleClose={() => setModify(false)}
          getDedicData={() => getData()}
        />
      )}
      <span className="admin-content-subtitle my-2">
        Lista dediche di{" "}
        {deceasedData && `${deceasedData.name} ${deceasedData.surname}`}
      </span>
      <p>&nbsp;</p>
      {dedicList &&
        dedicList.map((e, index) => (
          <div
            key={e.time + index}
            className="memorial-dedic-content is-relative"
          >
            <div
              className="is-flex"
              style={{
                right: 20,
                top: 28,
                position: "absolute",
                cursor: "pointer",
              }}
            >
              <Tooltip title="Modifica dedica">
                <Create
                  onClick={() => {
                    setSelectedDedic(e);
                    setModify(true);
                  }}
                />
              </Tooltip>
              <Tooltip title="Elimina dedica">
                <RemoveCircleOutline
                  style={{ color: "red" }}
                  onClick={() => {
                    setSelectedDedic(e);
                    setChoicheModal(true);
                  }}
                />
              </Tooltip>
            </div>
            <label className="memorial-dedic-date">
              {e.time && moment(e.time).format("DD/MM/YYYY HH:mm:ss")}
            </label>
            {dedicsTypeArray.indexOf(e.type) < 2 ? (
              <img
                alt="dedic"
                className="memorial-dedic-image"
                src={dedicsImages[dedicsTypeArray.indexOf(e.type)]}
              />
            ) : dedicsTypeArray.indexOf(e.type) === 2 ? (
              <img
                alt="uploaded"
                className="memorial-dedic-uploaded-image"
                style={{ marginTop: 25 }}
                src={e.media.filename}
              />
            ) : (
              <div style={{ margin: "0 auto" }}>
                <Player
                  fluid={false}
                  style={{ margin: "auto" }}
                  height={
                    window.innerWidth > 1000
                      ? 500
                      : window.innerWidth > 600
                      ? 300
                      : 140
                  }
                  playsInline
                  poster="/assets/poster.png"
                  src={e.media.filename}
                >
                  <BigPlayButton position="center" />
                </Player>
              </div>
            )}
            <TooLongTextWrapper line={4}>
              {e.status == "pending" && (
                <label className="chip-style dedic-chip">
                  In attesa di accettazione
                </label>
              )}
              {e.text && (
                <label
                  className="memorial-bio"
                  dangerouslySetInnerHTML={{ __html: e.text }}
                />
              )}
            </TooLongTextWrapper>
            {e.showAuthor && (
              <label className="memorial-bio text-end">{e.showAuthor}</label>
            )}
            <div className="divider" />
          </div>
        ))}
    </div>
  );
}
