export const dedicSentence = [
  "Il tuo sorriso è il nostro regalo più bello. Continueremo ad amarti incessantemente",
  "Persona buona e onesta. La tua assenza sarà per noi tristezza senza fine",
  "Sei andato via ma in realtà non ti abbiamo perduto. Ora sei vicino al Signore e splendi nella luce di Dio",
  "Il Signore ti ha ricoperto della sua luce. Resta nel nostro cuore per indicarci la via del Regno dei Cieli",
  "La tua gioia e il tuo entusiasmo continueranno ad alimentare il tuo ricordo nella nostra quotidianità",
  "La tua improvvisa morte ha lasciato un grande vuoto in quanti ti hanno amato. Il tuo ricordo sarà impresso nel loro animo",
  "Ci amerai dal Cielo come hai amato sulla terra",
  "Il tuo esempio sarà fonte di ispirazione per chi ti ha amato",
  "Insieme a te è volato in cielo un pezzo del nostro cuore. Continueremo a vivere con gioia sicuri che un giorno ci ritroveremo",
  "Hai terminato il tuo cammino terreno per approdare nella beatitudine del Signore. Continueremo a vivere nel tuo ricordo",
  "Sei stato esempio di vita e coraggio. Con rimpianto e affetto vivremo e pregheremo nel tuo ricordo",
  "Ricorderemo per sempre l’amore e il bene che hai donato tutti i giorni della vostra vita",
  "Adesso vivi nel Regno dei Cieli. Che il Signore possa concederti sollievo e donarti la serenità che meriti",
  "La sua essenza e il tuo ricordo sopravviveranno nella memoria di quanti ti hanno conosciuto e amato",
  "La tua forza, la tua volontà e il tuo amore ci accompagnino nel cammino della vita. Noi pregheremo per te",
  "Ti ricorderemo sempre con gioia",
  "La vita senza di te non ha più lo stesso sapore. Pregheremo Dio e il tuo ricordo sarà per sempre vivo in noi",
  "Sarai vivo nei nostri pensieri ogni giorno. Ti ameremo per sempre",
  "Tanto facile amarti quanto difficile dimenticarti",
  "Ringraziamo Dio per averci donato la possibilità di viverti. Il tuo ricordo sarà eterno nei nostri cuori",
  "Il nostro amore vincerà la morte. Il tuo ricordo darà, a chi ti ha voluto bene, la forza di andare avanti e vivere serenamente.",
  "La morte è solo un’ombra sul sentiero verso il paradiso.",
  "Ricordarti è facile. Lo faccio tutti i giorni. Ma c’è un dolore nel mio cuore che non andrà mai via.",
  "Non ci sono addii per noi. Ovunque tu sia, sarai sempre nel mio cuore.",
  "Il tuo amore illuminerà la nostra strada. La tua memoria sarà per sempre con noi.",
  "La tua stella brilla come nessun’altra. Continuerai a vivere per sempre nei nostri ricordi. Ci manchi tantissimo.",
  "Il mio cuore continua a battere per te.",
  "Nel momento in cui mi hai lasciato, il mio cuore si è diviso in due. Un lato era pieno di ricordi, mentre l’altro è morto con te.",
  "La vita dei morti dura nella memoria dei vivi. (Cicerone)",
  "La morte non prende i propri cari. Li salva e li nobilita nella memoria. (François Mauriac)",
  "Vivere nei cuori che ci lasciamo alle spalle non è morire. (Thomas Campbell)",
  "Le persone care non muoiono mai. Perché l’amore è l’immortalità. (Emily Dickinson)",
  "La morte non è altro che un viaggio nell’eternità. (William Penn)",
  "Ci vuole un minuto per trovare una persona speciale, un’ora per apprezzarla e un giorno per amarla, ma ci vuole un’intera vita per dimenticarla.",
  "Perché la morte non è altro che un mutamento di noi dal tempo all’eternità.",
  "Anche se siamo addolorati per la perdita dei nostri cari, troviamo speranza attraverso la risurrezione di Gesù e la sua promessa che c’è vita dopo la morte. (Anne Cetas)",
  "Ed è morendo che nasciamo alla vita eterna. (San Francesco d’Assisi)",
  "La morte lascia un dolore che nessuno può guarire, l’amore lascia un ricordo che nessuno può rubare.",
  "La vita di ogni uomo finisce nello stesso modo. Sono i particolari del modo in cui è vissuto e in cui è morto che differenziano un uomo da un altro. (Ernest Hemingway)",
  "A volte per i morti si fanno cose che non si sarebbero fatte per i vivi. (Marcel Proust)",
];
