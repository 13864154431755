import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Radio, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { InfoRounded } from "@material-ui/icons";
import InfoModal from "../FormComponents/InfoModal";

export default function DedicNotifyFrequency(props) {
  const [value, setValue] = useState("daily");
  const [showInfo, setShowInfo] = useState(false);
  const [timeLabels, setTimeLabels] = useState("");
  useEffect(() => {
    const timeLabel = JSON.parse(sessionStorage.getItem("timeLabel"));
    setTimeLabels(timeLabel || {});
  }, []);
  return (
    <div className="relative">
      {showInfo && (
        <InfoModal
          label="Riceverai una notifica ogni volta che verrà attivato un nuovo dono"
          handleClose={() => setShowInfo(false)}
        />
      )}
      <InfoRounded
        onClick={() => setShowInfo(true)}
        className="info-dedic"
        color="primary"
      />
      <div className="dedic-info-container">
        <Typography style={{ textAlign: "left" }}>
          Notificami Interazioni su
        </Typography>
        <Typography
          style={{ textAlign: "left" }}
        >{`${props.name} ${props.surname}`}</Typography>
      </div>
      <RadioGroup
        value={value || "daily"}
        name="dedicNotificationFrequency"
        onChange={(event, value) => {
          setValue(value);
        }}
      >
        <FormControlLabel
          value="realtime"
          control={<Radio id="alwaysRadio" color="primary" />}
          label={
            <label
              style={{ cursor: "pointer" }}
              htmlFor="alwaysRadio"
              className="activate-checkbox"
            >
              In tempo reale
            </label>
          }
        />
        <FormControlLabel
          value="daily"
          control={<Radio id="dayRadio" color="primary" />}
          label={
            <label
              style={{ cursor: "pointer" }}
              htmlFor="dayRadio"
              className="activate-checkbox"
            >
              1 Volta al giorno, alle ore{" "}
              {timeLabels.dedicationDailyNotificationTime}
            </label>
          }
        />
        <FormControlLabel
          value="never"
          control={<Radio id="weekRadio" color="primary" />}
          label={
            <label
              style={{ cursor: "pointer" }}
              htmlFor="weekRadio"
              className="activate-checkbox"
            >
              Non voglio ricevere le notifiche
            </label>
          }
        />
      </RadioGroup>
    </div>
  );
}
