import React from "react";

export default function SuccessToast(props){
    return <div className={'success-toast'} style={{
        position: "fixed",
        width: 300,
        top: '49%',
        zIndex:20,
        backgroundColor: "lightgreen",
        opacity: props.successToastOpacity ? (props.successToastOpacity / 100) : 0,
        display: ((!props.successToastOpacity) || (props.successToastOpacity <= 0)) && 'none'
    }}>
        <label>Elemento {props.label} correttamente!</label>
    </div>
}
