import React from "react";
import Twitter from "../../assets/twitter.png";
import Youtube from "../../assets/youtube.png";
import Facebook from "../../assets/facebook.png";
import Instagram from "../../assets/instagram.png";
import Linkedin from "../../assets/linkedin.png";

export default function PartnerPageSocials(props) {
  function getSite(site) {
    let newSite;
    if (site.indexOf("http") < 0) {
      newSite = `https://${site}`;
    } else newSite = site;
    return newSite;
  }
  if (
    !props.azienda?.instagram &&
    !props.azienda?.facebook &&
    !props.azienda?.twitter &&
    !props.azienda?.youtube &&
    !props.azienda?.linkedin
  )
    return null;
  return (
    <div
      style={{
        maxWidth: 320,
        alignSelf: "center",
        margin: "10px auto",
        display: "flex",
        gap: 12,
      }}
    >
      {props.azienda.instagram && (
        <a
          href={getSite(props.azienda.instagram)}
          target="blank"
          className="hover-scale"
        >
          <img src={Instagram} alt="insta" style={{ width: 40, height: 40 }} />
        </a>
      )}{" "}
      {props.azienda.facebook && (
        <a
          href={getSite(props.azienda.facebook)}
          target="blank"
          className="hover-scale"
        >
          <img src={Facebook} alt="face" style={{ width: 40, height: 40 }} />
        </a>
      )}{" "}
      {props.azienda.youtube && (
        <a
          href={getSite(props.azienda.youtube)}
          target="blank"
          className="hover-scale"
        >
          <img src={Youtube} alt="YT" style={{ width: 40, height: 40 }} />
        </a>
      )}{" "}
      {props.azienda.twitter && (
        <a
          href={getSite(props.azienda.twitter)}
          target="blank"
          className="hover-scale"
        >
          <img src={Twitter} alt="twitter" style={{ width: 40, height: 40 }} />
        </a>
      )}{" "}
      {props.azienda.linkedin && (
        <a
          href={getSite(props.azienda.linkedin)}
          target="blank"
          className="hover-scale"
        >
          <img
            src={Linkedin}
            alt="Linkedin"
            style={{ width: 40, height: 40 }}
          />
        </a>
      )}{" "}
    </div>
  );
}
