let nav = true

export default class navConfigService{
   static getNav(){
       return nav
   }

    static setNav(visible) {
        nav = visible;
    }
}

