import React from "react";

export default function ForgotPasswordReset(props) {
    return <div className={'bg-modal'}>
        <div className={'main-container'}>
            <label className={'buy-form-paragraph'} style={{margin: '0 auto', marginBottom: '20px'}}>Successo</label>
            <label style={{textAlign: "center"}}>Le abbiamo inviato le istruzioni per effettuare il reset della password
                attraverso il metodo di contatto da lei selezionato</label>
            <div id={'button-field'}>
                <button onClick={() => {
                    props.closeModal()
                }}>Chiudi
                </button>
            </div>
        </div>
    </div>
}
