import React, { Component, Fragment } from "react";
import { Spinner } from "react-activity";
import requests from "./HttpServices";

class PayPalNotCompleted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathError: false,
      load: false,
    };
  }

  componentDidMount() {
    if (
      this.props.history.location &&
      this.props.history.location.query &&
      !this.state.isLoading
    ) {
      this.setState({ isLoading: true });
      if (this.props.history.location.query.type.toLowerCase() === "qrcode") {
        requests("post", "cancelQR", {
          orderid: this.props.history.location.query.orderId,
        }).then((result) => {
          this.setState({ load: true });
          this.setState({ isLoading: false });
        });
      }
      if (this.props.history.location.query.type.toLowerCase() === "dedic") {
        requests("post", "cancelDedic", {
          orderid: this.props.history.location.query.orderId,
        }).then((result) => {
          this.setState({ load: true });
          this.setState({ isLoading: false });
        });
      }
      if (this.props.history.location.query.type.toLowerCase() === "memorial") {
        requests("post", "cancelMemorial", {
          orderid: this.props.history.location.query.orderId,
        }).then((result) => {
          this.setState({ load: true });
          this.setState({ isLoading: false });
        });
      }
      if (!this.props.history.location.query.type) {
        this.setState({ load: true });
      }
    } else {
      this.setState({ pathError: true, load: true });
      setTimeout(() => {
        this.props.history.push("./home");
      }, 3000);
    }
  }

  render() {
    return (
      <>
        {this.state.load ? (
          !this.state.pathError ? (
            <div style={{ height: 700 }}>
              <p
                className="death-data"
                style={{
                  alignSelf: "center",
                  textAlign: "center",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                Pagamento annullato
              </p>
              <p> Il pagamento e' stato annullato correttamente</p>
              &nbsp;
            </div>
          ) : (
            <div style={{ height: 700 }}>
              <p
                className="death-data"
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  textAlign: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                Ordine non trovato{" "}
              </p>
              <p>Ordine non trovato verra` reinderizzato alla home</p>
              &nbsp;
            </div>
          )
        ) : (
          <div
            style={{
              height: 700,
              display: "flex",
              alignContent: "center",
              alignItem: "center",
              justifyContent: "center",
            }}
          >
            <Spinner />
          </div>
        )}
      </>
    );
  }
}

export default PayPalNotCompleted;
