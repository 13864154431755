import React, { Component, Fragment } from "react";
import { Redirect } from "react-router";
import withContext from "../../WithContextFile";
import "./Whats.css";

class Information extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
    };
  }

  componentDidMount() {}

  render() {
    return !this.props.context.user ? (
      <>
        <div className="main-div">
          <div className="text-container-what">
            <p className="title-price" style={{ marginBottom: 0 }}>
              COS'E'
            </p>
            <label>
              <span className="what-text-info">
                Quante volte ci è capitato di condividere il ricordo di una
                persona defunta con un amico o con un familiare, parlando dei
                momenti vissuti insieme…
                <p className="what-text-info" style={{ margin: 0 }}>
                  altre volte abbiamo semplicemente avuto il desiderio di
                  manifestare quanto sia stata importante e cosa ha
                  rappresentato per noi…
                </p>
              </span>

              <p className="what-text-info">
                Per questo abbiamo pensato ad un servizio utile e innovativo.
                Una targhetta QrCode da apporre sulla lapide collegata ad una
                lapide digitale su un portale web, richiamabile inquadrandola
                con il lettore di QrCode.
              </p>

              <p className="what-text-info">
                La Lapide Digitale è strutturata in una sezione gallery dove
                poter inserire foto e video, una scheda biografica personale e
                una sezione dediche, dove gli amici e parenti possono lasciare
                una dedica, accendere un lumino virtuale o lasciare un mazzo di
                fiori virtuali, il tutto acquistabile e consultabile tramite un
                semplice gesto da smartphone e computer
              </p>

              <p className="what-text-info sign">Digital Memorial</p>

              <span className="what-text-info citation">
                <p className="what-text-info">“Più dolce sarebbe la morte</p>
                <p className="what-text-info m-0" style={{ margin: 0 }}>
                  {" "}
                  se il mio sguardo avesse come ultimo orizzonte
                </p>
                <p className="what-text-info" style={{ margin: 0 }}>
                  il tuo volto,
                </p>
                <p className="what-text-info" style={{ margin: 0 }}>
                  e se così fosse…
                </p>
                <p className="what-text-info" style={{ margin: 0 }}>
                  mille volte vorrei nascere
                </p>
                per mille volte ancor morire.”
              </span>
              <p className="what-text-info sign">William Shakespeare</p>
            </label>
          </div>
        </div>
      </>
    ) : (
      <Redirect to="/products" />
    );
  }
}

export default withContext(Information);
