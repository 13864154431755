import requests from './HttpServices';

export const handleSession = (data, setState) => {
	if (data.result !== 'fail') {
		const address = {};
		sessionStorage.setItem('user', JSON.stringify(data.session));
		sessionStorage.setItem('User', JSON.stringify(data.session));
		sessionStorage.setItem('ExpiringMemorial', JSON.stringify(data.expiring_memorial || false));
		if (data.session.userdata.addresses && data.session.userdata.addresses.length > 0) {
			data.session.userdata.addresses.map(e => {
				if (e.type == 'invoice') {
					address.address = e.address;
					address.number = e.number;
					address.region = e.region;
					address.city = e.location;
					address.province = e.province;
					address.cap = e.cap;
					address.nationality = e.nationID;
					address.complete_address = e.completeAddress;
				}
			});
		}
		sessionStorage.setItem('UserAddress', JSON.stringify(address));
		setState && setState({ dataload: true });
		return true;
		// this.props.history.push('./home')
	}
	logout(false, setState);
	return false;
};
export const logout = (walert, setData, reload) => {
	requests('get', 'logout').then(data2 => {
		sessionStorage.removeItem('user');
		sessionStorage.removeItem('User');
		sessionStorage.removeItem('UserAddress');
		if (setData) setData({ session: null });
		if (walert) {
			alert(
				'Ci sono problemi a reperire le informazioni del vostro account, si prega di contattare gli amministratori di sistema, verra` reinderizzato al form per i contatti'
			);
			window.location.push('contact');
		}
		if (reload) window.location.reload();
	});
};
export const changeRouteForSession = (setState, state) => {
	let route;
	let session;
	try {
		session = JSON.parse(sessionStorage.getItem('User'));
	} catch (e) {
		session = '';
	}
	if (session === null) session = '';
	if (session !== '') {
		if (session.userdata.permissions) {
			route =
				session === ''
					? '/login'
					: session.userdata && session.userdata.permissions.platformAccess == 0
					? '/client-panel#account'
					: session.userdata && session.userdata.permissions.platformAccess == 2 && session.userdata.partnertype == "gestorelampade"
					? '/lampade-panel#lampade'
					: session.userdata && session.userdata.permissions.platformAccess == 2
					? '/partner-panel#deceased'
					: session.userdata && session.userdata.permissions.platformAccess == 1 && '/admin-panel#qrcode';
			session.userdata &&
				state.isPartner &&
				session.userdata.permissions.platformAccess == 2 &&
				setState({ isPartner: true });
			if (state.session) {
				setState({ session });
			}
		} else {
			logout(true, setState);
		}
	} else route = '/login';
	return { route, session };
};
