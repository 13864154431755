import React, { Component, Fragment } from "react";
import withContext from "../../../WithContextFile";
import { Redirect } from "react-router";
import "../../Activate/Activate.css";
import TextField from "@material-ui/core/TextField";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DateFnsIt from "date-fns/locale/it";
import Axios from "axios";
import { Spinner } from "react-activity";
import { Button, Typography } from "@material-ui/core";
import { Clear, RemoveCircleOutline } from "@material-ui/icons";
import MenuItem from "@material-ui/core/MenuItem";
import { parse } from "@fortawesome/fontawesome-svg-core";
import CkEditorCustom from "../../FormComponents/CkEditor2";
import requests, { baseURL } from "../../../Services/HttpServices";
import PhotoInput from "../../FormComponents/PhotoInput";
import UserFormData from "../../FormComponents/userFormData";
import addressServiceGetData from "../../FormComponents/addressService";
import AddressService, { buildCap } from "../../FormComponents/AddressComponent";
import validate from "../../../formValidationService";
import MultipleReferringPlace from "../../FormComponents/MultipleReferringPlace";
import FeedbackModal from "../../FormComponents/FeedbackModal";

let partners;
let globalData = null;

class ModifyAddDeceased extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loggedUser : null,
			radioValue : "",
			checkBox   : "",
			files      : [],
			load       : true,
			aphorism   : "",
			partnerid  : "",
			form       : {
				name              : "",
				surname           : "",
				CF                : "",
				phone             : "",
				email             : "",
				nameTo            : "",
				surnameTo         : "",
				bornDate          : null,
				deathDate         : null,
				graveyardCity     : "",
				graveyardRegion   : "",
				graveyardProvince : "",
			},
			formError: {},
		};
	}

	componentDidMount() {
		this.getPartner();
	}

	getPartner(value) {
		requests("get", "getPartner")
			.then((data) => {
				partners = [];
				partners = data.data.partnerlist;
				this.setState({ load: !this.state.load });
			})
			.catch((e) => {
				this.setState({ load: !this.state.load });
			});
		this.props.modify && this.setModifyData(this.props.modify);
	}

	setInitialData() {
		const temp = this.state.form;

		temp.name     = this.state.loggedUser.name;
		temp.username = this.state.loggedUser.username;
		temp.surname  = this.state.loggedUser.surname || "Cognome";
		temp.email    = this.state.loggedUser.email;
		temp.phone    = this.state.loggedUser.telephone;
		temp.CF       = this.state.loggedUser.cf;
		let radioContact;
		try {
			radioContact = this.state.loggedUser.preferred_contact.type;
		} catch (e) {
			radioContact = "";
		}

		this.setState({
			form       : temp,
			radioValue : radioContact,
		});
	}

	setModifyData(data) {
		const { form }         = this.state;
		form.surnameTo         = data.deceasedSurname;
		form.nameTo            = data.deceasedName;
		form.graveyardRegion   = data.graveyardRegion;
		form.graveyardProvince = data.graveyardProvince;
		form.graveyardCity     = data.graveyardCity;
		// form.graveyardName = data.graveyardName
		this.setState({ deceasedID: data.ID });
		if (data.bornDate != "0000-00-00" && data.bornDate != "" && data.bornDate != false) {
			form.bornDate = data.bornDate != "0000-00-00" && data.bornDate != "" && data.bornDate != false
				? new Date(data.bornDate).getTime()
				: null;
		}
		if (data.deathDate != "0000-00-00" && data.deathDate != "" && data.deathDate != false) {
			form.deathDate = data.deathDate != "0000-00-00" && data.deathDate != "" && data.deathDate != false
				? new Date(data.deathDate).getTime()
				: null;
		}
		this.setState({
			age              : data.staticAge,
			aphorism         : data.aphorism,
			oldAphorism      : data.aphorism,
			editor           : data.editorData?.toString(),
			graveyardName    : data.graveyardName,
			graveyardAddress : data.graveyardAddress,
			filename         : data.filename,
			gender           : data.gender,
			fileurl          : data.fileurl,
			photod           : data.fileurl,
			partnerid        : data.partnerID,
		});
		this.setState({ load: false });
		// editor = data.editorData.toString()
	}

	render() {
		if (!this.state.loggedUser && sessionStorage.getItem("User")) {
			try {
				this.setState({
					loggedUser: JSON.parse(sessionStorage.getItem("User")).userdata,
				});
				this.setState({
					userAddress: JSON.parse(sessionStorage.getItem("UserAddress")),
				});
			} catch (e) {

			}
		}
		if (this.state.loggedUser && this.state.form.name == "") {
			this.setInitialData();
			this.props.modify
			&& addressServiceGetData("province", this.props.modify.graveyardRegion)
				.then((resolve) => {
					this.setState({ provincie: resolve });
					addressServiceGetData("location", this.props.modify.graveyardProvince)
						.then((resolve) => {
							const locSelected = resolve.find(
								(x) => x.ID === (this.state.form.graveyardLocation || this.state.form.graveyardCity),
							);
							let capArr        = [];
							if (locSelected.capList) capArr = buildCap(locSelected);
							capArr.unshift(locSelected.cap);
							this.setState({
								comuni : resolve,
								caps   : capArr,
							});
							if (this.props.modify.graveyardCap) {
								const { form }    = this.state;
								form.graveyardCap = this.props.modify.graveyardCap;
								this.setState(form);
							}
						});
				});
		}
		return !this.props.context.user ? (
			<>
				{this.state.uploading && (
					<div className="div-upload">
						<Spinner size={100} />
						<Typography className="label-upload">Attendi il caricamento dei files</Typography>
					</div>
				)}
				{this.state.feedbackModal && (
					<FeedbackModal
						label={this.state.feedbackModalText}
						result={this.state.feedbackModalResult}
						close={() => {
							this.setState({ feedbackModal: false });
							this.state.feedbackModalResult === "successo" && window.location.reload();
						}}
					/>
				)}
				{this.state.openModalPhoto && this.photoLapideModal(this.state.formData)}
				<div className="contact-container" style={{ backgroundColor: "transparent" }}>
					<div className="activate-form-div">
						<form
							className="activate-form"
							onSubmit={(event) => {
								event.preventDefault();
								const formData = new FormData(event.target);
								const object   = {};
								formData.forEach((value, key) => {
									object[key] = value;
								});
								formData.set("notPhoto", this.state.ereased);
								formData.set("deceasedID", this.state.deceasedID);
								if (!object["photo-lapide"] && !this.state.fileurl) {
									this.setState({ formData });
									this.send(formData, true);
								} else {
									this.send(formData);
								}
							}}
						>
							<div style={{ display: "none" }}>
								<UserFormData
									names="admin-add"
									form={this.state.form}
									formError={this.state.formError}
								/>
							</div>
							<label className="death-data">Dati Defunto</label>
							<input style={{ display: "none" }} name="csrf_token" value={this.state.csrf_token} />
							<TextField
								className="activate-field"
								label="Nome del Defunto *"
								name="admin-add-deceased-name"
								value={this.state.form.nameTo}
								onChange={(event) => this.setAndValidateForm(event.target.value, "nameTo")}
								error={this.state.formError.nameTo}
								helperText={this.state.formError.nameTo && "Campo obbligatorio"}
							/>
							<TextField
								className="activate-field"
								label="Cognome del Defunto *"
								name="admin-add-deceased-surname"
								value={this.state.form.surnameTo}
								onChange={(event) => this.setAndValidateForm(event.target.value, "surnameTo")}
								error={this.state.formError.surnameTo}
								helperText={this.state.formError.surnameTo && "Campo obbligatorio"}
							/>
							<div className="isFlexRow justify-between">
								<TextField
									className="activate-field"
									label="Età del defunto"
									name="obituary-deceased-age"
									value={this.state.age}
									onChange={(event) => {
										this.setState({ age: event.target.value });
									}}
								/>
								<TextField
									className="activate-field"
									select
									onChange={(event) => {
										this.setState({ gender: event.target.value });
									}}
									label="Sesso *"
									name="gender"
									value={this.state.gender || ""}
									error={!this.state.gender && this.state.genderError}
									helperText={!this.state.gender && this.state.genderError && "Campo obbligatorio"}
								>
									<MenuItem value="M">M</MenuItem>
									<MenuItem value="F">F</MenuItem>
								</TextField>
							</div>
							<div className="activate-date-field">
								<MuiPickersUtilsProvider locale={DateFnsIt} utils={DateFnsUtils}>
									<KeyboardDatePicker
										className=" ml--5 date "
										inputVariant="filled"
										format="dd/MM/yyyy"
										margin="normal"
										onChange={(event, asd) => {
											this.setAndValidateForm(event, "bornDate");
										}}
										style={{ backgroundColor: "white" }}
										id="date"
										name="admin-add-born-date"
										label="Data di nascita"
										value={this.state.form.bornDate}
									/>
									<KeyboardDatePicker
										className=" m-12 date"
										inputVariant="filled"
										format="dd/MM/yyyy"
										margin="normal"
										name="admin-add-death-date"
										onChange={(event, asd) => {
											this.setAndValidateForm(event, "deathDate");
										}}
										id="date"
										label="Data di morte"
										value={this.state.form.deathDate}
									/>
								</MuiPickersUtilsProvider>
							</div>
							<div className="buy-form-paragraph">Dati del Cimitero</div>
							<div className="Partner-row-fields">
								<TextField
									className="partner-field "
									label="Nome cimitero"
									value={this.state.graveyardName}
									onChange={(event) => {
										this.setState({ graveyardName: event.target.value });
									}}
									helperText="ad esempio Cimitero di Roma"
									name="admin-add-graveyard-name"
								/>
							</div>
							<AddressService
								names="admin-add-graveyard"
								form={this.state.form}
								formError={this.state.formError}
								province={this.state.provincie}
								comuni={this.state.comuni}
								disableCap={false}
								caps={this.state.caps}
								disableRoute={false}
								graveyardData
							/>
							<TextField
								className="partner-field "
								label="Coordinate di sepoltura"
								value={this.state.graveyardAddress}
								onChange={(event) => {
									this.setState({ graveyardAddress: event.target.value });
								}}
								name="admin-add-graveyard-address"
							/>
							{this.state.load && (
								<CkEditorCustom
									title="Aforismi"
									onChange={(editor) => {
										this.setState({ aphorism: editor.getData() });
									}}
									initData={this.state.oldAphorism || ""}
								/>
							)}
							<TextField
								name="admin-add-deceased-aphorism"
								className="text-area"
								id="outlined-multiline-static"
								style={{ display: "none" }}
								label="Aforismi"
								value={this.state.aphorism}
								onChange={(e) => {
									this.setState({ aphorism: e.target.value });
								}}
								multiline
								rows={8}
								variant="outlined"
							/>
							{/* <div className="buy-form-paragraph">Luogo di Riferimento</div>
              <MultipleReferringPlace
                disabled
                onChange={(sendData) =>
                  this.setState({ "referring-place": sendData })
                }
                referringPlace={this.props.modify?.referringPlace}
              /> */}
							<div className="file-upload">
								<label className="pointer" htmlFor="photo-lapide" color="blue" title="">
									<p className="pointer-label">
										{this.state.filename || (
											<>
												<b className="bold-underline" style={{ color: "black" }}>
													Carica foto del defunto
												</b>
												{" "}
												per la lapide digitale (Max 15MB per foto)'
											</>
										)}
									</p>
								</label>
							</div>
							<PhotoInput
								name="lapide"
								setFileName={(name, file) => {
									const url = URL.createObjectURL(file);
									this.setState({
										filename : name,
										fileurl  : url,
										photod   : url,
										ereased  : false,
									});
								}}
							/>
							{this.state.photod && (
								<div className="is-relative">
									<img
										style={{
											maxHeight : 300,
											margin    : "20px auto auto",
											maxWidth  : 208,
											minHeight : 300,
											minWidth  : 200,
										}}
										src={this.state.photod}
										className=" memorial-page-photo"
									/>
									<RemoveCircleOutline
										className="remove-photo"
										onClick={() => {
											this.setState({
												filename : "",
												photod   : null,
												ereased  : true,
											});
											document.getElementById("photo-lapide").value = null;
										}}
									/>
								</div>
							)}
							{!this.props.partner && (
								<TextField
									variant="outlined"
									name="partnerid"
									value={parseInt(this.state.partnerid)}
									onChange={(e) => {
										this.setState({ partnerid: e.target.value });
									}}
									label="Assegna defunto ad un partner"
									style={{ marginTop: 40 }}
									select
								>
									<MenuItem value="-1">Nessuno</MenuItem>
									{partners
									&& partners.map((e) => <MenuItem value={parseInt(e.ID)}>{e.name}</MenuItem>)}
								</TextField>
							)}
							&nbsp;
							<div className="bottom-activate-form">
								<button
									onClick={(event) => {
									}}
									className="activate-submit"
								>
									Procedi
								</button>
							</div>
						</form>
					</div>
					&nbsp;
				</div>
			</>
		) : (
			<Redirect to="/products" />
		);
	}

	photoLapideModal(formdata) {
		let close2 = true;
		return (
			<div
				className="modal-bg"
				onClick={() => {
					if (close2) {
						this.setState({ openModalPhoto: false });
						// this.props.history.push('admin-panel')
					} else {
						close2 = !close2;
					}
				}}
			>
				<div
					className="inner"
					onClick={() => {
						close2 = false;
					}}
				>
					<Typography style={{ fontSize: 17 }}>Attenzione!!</Typography>
					<Typography style={{
						fontSize  : 12,
						marginTop : 15,
					}}
					>
						Non hai caricato la foto della lapide, desideri continuare?
					</Typography>
					<div
						style={{
							flexDirection  : "row",
							display        : "flex",
							justifyContent : "space-between",
						}}
					>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								this.setState({ openModalPhoto: false });
								this.sendData(globalData);
							}}
						>
							SI
						</Button>
						<Button
							color="primary"
							style={{ marginLeft: 20 }}
							onClick={() => {
								this.setState({ openModalPhoto: false });
								// this.props.history.push('admin-panel')
							}}
							variant="contained"
						>
							NO
						</Button>
					</div>
				</div>
			</div>
		);
	}

	send(formdata, notLapide) {
		let { formError } = this.state;
		let error         = false;
		for (const property in this.state.form) {
			if (property !== "phone" && property !== "email" && property !== "CF") {
				formError = validate(this.state.form[property], property, formError);
				error     = error || formError[property];
			}
		}
		if (!this.state.gender) {
			error = true;
			this.setState({ genderError: true });
		}
		this.setState({ formError });
		if (!error) {
			globalData = formdata;
			notLapide ? this.setState({
				openModalPhoto: true,
				formdata,
			}) : this.sendData(formdata);
		}
	}

	sendData(formdata) {
		getCsrf()
			.then((csrf) => {
				formdata.set("csrf_token", csrf);
				if (this.state["referring-place"]) formdata.set("referring-place", this.state["referring-place"]);
				this.setState({ uploading: true });
				requests("sendform", "deceasedModify", formdata)
					.then((result) => {
						if (result.response === "success") {
							this.setState({
								uploading           : false,
								feedbackModal       : true,
								feedbackModalResult : "successo",
								feedbackModalText   : "Elemento modificato con successo",
							});
						} else {
							this.setState({
								uploading           : false,
								feedbackModal       : true,
								feedbackModalResult : "Errore",
								feedbackModalText   : result.message,
							});
						}
					})
					.finally(() => this.setState({ uploading: false }));
			});
	}

	setAndValidateForm(data, attribute) {
		const { form }    = this.state;
		let { formError } = this.state;
		form[attribute]   = data;
		this.setState({ form });
		formError = validate(data, attribute, formError);
		this.setState({ formError });
	}
}

function getCsrf() {
	return new Promise((result, err) => Axios.get(`${baseURL}api/auth/securitytoken`)
		.then((resolve) => {
			if (resolve != null) {
				result(resolve.data._csrf);
			} else {
				result({ DATA: "NO DATA" });
			}
		})
		.catch((err) => result({ err })));
}

export default withContext(ModifyAddDeceased);
