import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Spinner } from "react-activity";
import requests from "../../Services/HttpServices";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};
export default function SearchBarMemorial(props) {
  const [text, setText] = useState("");
  const [open, setOpen] = useState(false);
  const [searchdata, setSearchdata] = useState("");
  const [searching, setSearching] = useState(false);
  const [selected, setSelected] = useState(false);
  const [data, setData] = useState(null);
  if (props.emailAccount && !data && !searching && !props.owner) {
    setSearching(true);
    requests("get", "getDeceasedByOwner", props.emailAccount).then((data2) => {
      setSearching(false);
      const temp = [];
      data2.data &&
        data2.data.deceased_list &&
        data2.data.deceased_list.map((e, i) => {
          !props.activate
            ? e.showPage == "1" && temp.push(e)
            : e.showPage != "1" && temp.push(e);
        });
      setData(temp);
      setSearchdata(temp);
    });
  }
  useEffect(() => {
    if (props.defaultValue && data && !searching) {
      const selectedValue = data.find(
        (el) => parseInt(el.ID, 10) === parseInt(props.defaultValue, 10)
      );
      setSelected(selectedValue);
      props.setData && props.setData(selectedValue);
    }
  }, [props.defaultValue, data, searching]);

  if (props.owner && !data && !searching) {
    setSearching(true);
    requests("get", "getDeceasedByID", props.owner).then((data2) => {
      setSearching(false);
      const temp = [];
      data2.data &&
        data2.data.deceased_list &&
        data2.data.deceased_list.map((e, i) => {
          !props.activate
            ? e.showPage == "1" && temp.push(e)
            : e.showPage != "1" && temp.push(e);
        });
      setData(temp);
      props.setData && props.setData(temp);
      setSearchdata(temp);
    });
  }
  !props.emailAccount && !props.owner && data !== null && setData(null);
  // da levare dopo
  return (
    <div>
      <div className="dedic-search-container" style={{ position: "relative" }}>
        {searching ? (
          <div
            className="w-full flex"
            style={{
              height: "100%",
              justifyItems: "center",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              minHeight: 122,
            }}
          >
            <div style={{ margin: "auto" }}>
              <Spinner />
            </div>
          </div>
        ) : (
          <div>
            {props.defaultValue && (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  right: 0,
                  left: 0,
                  top: 0,
                  zIndex: 20000,
                  backgroundColor: "rgba(0,0,0,0.5)",
                }}
              />
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {!props.activate ? (
                <label
                  className="buy-form-paragraph"
                  style={{ marginTop: 0, color: !props.emailAccount && "grey" }}
                >
                  {props.defaultValue ? "Rinnova memorial" : "Ricerca defunto"}
                </label>
              ) : (
                <label
                  className="buy-form-paragraph"
                  style={{ marginTop: 0, color: !props.emailAccount && "grey" }}
                >
                  Ricerca necrologi
                </label>
              )}
              <label style={{ marginBottom: 10 }}>{props.emailAccount}</label>
            </div>
            {!(props.emailAccount || props.owner) && (
              <div
                style={{
                  color: "white",
                  fontWeight: "bold",
                  alignItems: "center",
                  padding: 45,
                  justifyItems: "center",
                }}
                className="obscure"
              >
                <label style={{ margin: "auto 0", fontSize: 22 }}>
                  Inserisci una mail o loggati per continuare
                </label>
              </div>
            )}
            <Autocomplete
              className="dedic-search-field"
              id="combo-box-demo"
              key="search-text"
              onOpen={(event, reasom) => {
                // event.stopPropagation()
                if (event.type === "mousedown") setOpen(false);
                else setOpen(true);
              }}
              noOptionsText={"Nessun caro associato all' email inserita"}
              open={open}
              name={`${props.name}-search`}
              value={selected}
              defaultValue={selected}
              onClick={(event) => event.preventDefault()}
              options={searchdata}
              autoSelect={false}
              disabled={props.defaultValue}
              onClose={() => {
                setOpen(false);
              }}
              onChange={(event, value, reason, details) => {
                setSelected(value);
                setOpen(false);
                props.setData(value);
              }}
              getOptionLabel={(option) => {
                if (option.name)
                  return `${option.name || ""} ${option.surname || ""}`;
                return "";
              }}
              renderOption={(option) => (
                <>
                  <img
                    style={{ width: 50, height: 50 }}
                    alt="file-not-found"
                    src={
                      option.lapide ||
                      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                    }
                  />
                  &nbsp;
                  <label>{option.name}</label>
                  &nbsp;
                  <label>{option.surname}</label>
                </>
              )}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Cerca il tuo caro"
                  variant="outlined"
                />
              )}
            />
            {selected && selected.memorialID != 0 && !props.defaultValue && (
              <label style={{ color: "red" }}>
                Il defunto scelto ha gia` un servizio memorial attivo, il
                servizio verra` prolungato di un anno
              </label>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
