import React, { useEffect, useState } from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import { Radio } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export default function NotificationTiming(props) {
  const [value, setValue] = useState(null);
  const [setted, setSetted] = useState(null);
  const [timeLabels, setTimeLabels] = useState({});
  if (props.sessionData && value !== props.sessionData && !setted) {
    setValue(props.sessionData);
    setSetted(true);
  }
  useEffect(() => {
    const timeLabel = JSON.parse(sessionStorage.getItem("timeLabel"));
    setTimeLabels(timeLabel || {});
  }, []);
  return (
    <div>
      <label
        className="buy-form-paragraph"
        style={{ margin: 0, marginTop: 20 }}
      >
        Come vuoi ricevere le notifiche?
      </label>
      <div className="row-between" style={{ justifyContent: "center" }}>
        <RadioGroup
          value={value || "realtime"}
          name="notificationFrequency"
          onChange={(event, value) => {
            setValue(value);
            props.onChange(value);
          }}
        >
          <FormControlLabel
            value="realtime"
            control={<Radio id="alwaysRadio" color="primary" />}
            label={
              <label
                style={{ cursor: "pointer" }}
                htmlFor="alwaysRadio"
                className="activate-checkbox"
              >
                In tempo reale
              </label>
            }
          />
          <FormControlLabel
            value="daily"
            control={<Radio id="dayRadio" color="primary" />}
            label={
              <label
                style={{ cursor: "pointer" }}
                htmlFor="dayRadio"
                className="activate-checkbox"
              >
                1 Volta al giorno, alle ore {timeLabels.dailyNotificationTime}
              </label>
            }
          />
          <FormControlLabel
            value="weekly"
            control={<Radio id="weekRadio" color="primary" />}
            label={
              <label
                style={{ cursor: "pointer" }}
                htmlFor="weekRadio"
                className="activate-checkbox"
              >
                1 Volta a settimana, ogni {timeLabels.weeklyNotificationTime}
              </label>
            }
          />
        </RadioGroup>
      </div>
    </div>
  );
}
