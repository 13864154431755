import React, { Component, Fragment } from "react";
import { Redirect } from "react-router";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import withContext from "../../../WithContextFile";
import users from "../../../assets/Stab/Users";
import requests from "../../../Services/HttpServices";
import ForgotPasswordModal from "./ForgotPasswordModal";
import FeedbackModal from "../../FormComponents/FeedbackModal";

const user = users;

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
    };
  }

  componentDidMount() {}

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, error: "" });
  };

  resetPassword = () => {
    const loggedIn = false;
    const { username } = this.state;
    if (!username) {
      return this.setState({ error: "Campo vuoto" });
    }
    // eslint-disable-next-line array-callback-return
    requests("post", "recover", { email: this.state.username }).then((data) => {
      if (data.response === "success") {
        this.setState({ success: true });
        // alert('Abbiamo inviato una email a ' + this.state.username + ' contenente le istruzioni per effettuare il recupero password')
        // this.props.history.push('./recupera-password')
      } else
        this.setState({
          feedbackModal: true,
          modalResult: "Errore",
          modalText: data.message,
        });
    });
  };

  render() {
    return (
      <>
        {this.state.success && (
          <ForgotPasswordModal
            closeModal={() => {
              this.setState({ success: false });
              this.props.history.push("./home");
            }}
          />
        )}
        <div className="login-page columns is-mobile is-centered">
          {this.state.feedbackModal && (
            <FeedbackModal
              result={this.state.modalResult}
              close={() => {
                this.setState({ feedbackModal: false });
              }}
              label={this.state.modalText}
            />
          )}
          <div className="column media-fix">
            <label className="death-data">Recupera password</label>
            <p>
              Inserisci il tuo username e una nuova password verra' inviata al
              vostro metodo di contatto preferito
            </p>
            <TextField
              onChange={this.handleChange}
              name="username"
              className="login-form "
              label="Nome utente"
              variant="outlined"
            />
            &nbsp;
            {this.state.error && (
              <div className="has-text-danger" style={{ marginBottom: 13 }}>
                {this.state.error}
              </div>
            )}
            <div
              id="bottom-login"
              style={{
                marginTop: -28,
                marginBottom: 8,
              }}
            >
              <Link to="/recover-user">
                <label
                  style={{ textAlign: "center", paddingTop: 12 }}
                  id="click-here"
                >
                  Non ricordi il nome utente?
                </label>
              </Link>
            </div>
            <div className="field is-clearfix">
              <button
                className="button is-primary is-outlined is-pulled-right"
                onClick={this.resetPassword}
              >
                Invia password
              </button>
            </div>
            &nbsp;
            <div style={{ maxHeight: 16 }}>
              <Link to="/login">
                <p id="click-here" className="m-0">
                  Torna alla login
                </p>
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withContext(ForgotPassword);
