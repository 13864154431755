import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function RegisterSuccess(props) {
  const [contact, setContact] = useState();
  useEffect(() => {
    setContact(window.location.href.split("?")[1]);
  }, []);
  return (
    <div
      style={{ width: "100%", padding: 20, height: window.innerHeight - 250 }}
      className="bg-white"
    >
      <label className="buy-form-paragraph">Successo</label>
      <div
        style={{
          width: window.innerWidth > 720 ? 700 : "auto",
          alignSelf: "center",
          margin: "15px auto",
          textAlign: "center",
        }}
      >
        <p style={{ textAlign: "center" }}>
          Registrazione completata con successo.
        </p>
        <p style={{ textAlign: "center" }}>
          Riceverai un messaggio, sul recapito {contact}, con i dati per
          l’accesso.
        </p>
        <Link to="/login"> Effettua l`accesso per cominciare </Link>
      </div>
    </div>
  );
}
