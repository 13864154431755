/* eslint-disable react/self-closing-comp */
import React, { Component, Fragment } from "react";
import { Redirect } from "react-router";
import withContext from "../../WithContextFile";

import "./Terms.css";

class SellTerms extends Component {
	constructor(props) {
		super(props);
		this.state = {
			username : "",
			password : "",
		};
	}

	componentDidMount() {
	}

	render() {
		return !this.props.context.user ? (
			<>
				<div className="terms-container">
					<div className="terms-inner-container">
						&nbsp; &nbsp;
						<span
							className="text-center"
							style={{
								fontWeight     : "bold",
								textDecoration : "underline",
							}}
						>
							<p>CONDIZIONI DI VENDITA E DIRITTO DI RECESSO</p>
						</span>
						&nbsp;
						<span className="text-justify">
							<p>
								L’offerta e la vendita sul nostro sito www.digitalmemorial.it
								sono regolate da queste condizioni generali di vendita. I
								prodotti sono venduti da I.M.E. di Iacobucci Mimmo, con sede
								legale in Toro, via delle Fratte, s.n., partita iva n.
								01494450701.
							</p>
							<p>
								Potrai chiedere qualsiasi informazione contattando il venditore
								al seguente indirizzo di posta elettronica:
								info@digitalmemorial.it
							</p>
							<p>
								Per le informazioni legali potrai consultare le sezioni:
								Condizioni di Vendita e Termini e Condizioni d’Uso.
							</p>
							<p>
								Il venditore offre in vendita i prodotti e svolge la propria
								attività di commercio elettronico nei confronti degli utenti
								finali consumatori e non consumatori.
							</p>
							<p>
								Le norme del codice del consumo saranno applicabili solo agli
								utenti consumatori.
							</p>
							<p>
								Per concludere il contratto di acquisto di uno o più prodotti su
								www.digitalmemorial.it, dovrai compilare il modulo d’ordine in
								formato elettronico e trasmetterlo al Venditore, per via
								telematica, seguendo le relative istruzioni.
								{" "}
							</p>
							<p>
								Prima di procedere all’acquisto dei prodotti, tramite la
								trasmissione del modulo d’ordine, ti sarà chiesto di leggere
								attentamente le condizioni di vendita, potrai stamparne una
								copia attraverso il comando di stampa o memorizzarla per i tuoi
								usi personali.
							</p>
							<p>
								Nel modulo d’ordine, visualizzato prima della conclusione del
								contratto di acquisto, ti forniremo informazioni di sintesi
								circa le caratteristiche essenziali di ciascun prodotto ordinato
								ed il relativo prezzo.
								{" "}
							</p>
							<p>
								Il contratto è concluso quando il Venditore riceve, per via
								telematica, il tuo modulo d’ordine, previa verifica della
								completezza dei dati relativi al tuo ordine. Il modulo d’ordine
								sarà archiviato presso la nostra banca dati per il periodo di
								tempo necessario alla evasione degli ordini, nei limiti di
								legge.
							</p>
							<p>
								Al momento di procedere alla trasmissione del modulo d’ordine
								sarai avvertito che tale inoltro implica l’obbligo di
								corrispondere il prezzo indicato. Prima di procedere alla
								trasmissione del modulo d’ordine ti sarà richiesto di
								individuare e di correggere eventuali errori di inserimento dei
								dati.
							</p>
							<p>
								La lingua per concludere il contratto con il Venditore è
								l’italiano.
							</p>
							<p>
								Concluso il contratto, il Venditore prenderà in carico il tuo
								ordine di acquisto. Il Venditore potrà non dar corso ai tuoi
								ordini di acquisto che non diano sufficienti garanzie di
								solvibilità o che risultino incompleti o non corretti. In questi
								casi provvederemo ad informarti per posta elettronica che il
								contratto non è concluso e che il Venditore non ha dato seguito
								al tuo ordine di acquisto specificandone i motivi. Qualora i
								prodotti presentati sul sito www.digitalmemorial.it non siano
								più disponibili o in vendita al momento del tuo ultimo accesso
								al sito, ovvero dell’invio del modulo d’ordine, sarà cura del
								Venditore comunicarti, tempestivamente ed in ogni caso entro 30
								giorni a decorrere dal giorno successivo a quello in cui avrai
								trasmesso il tuo ordine, l’eventuale indisponibilità dei
								prodotti ordinati. In caso di inoltro del modulo d’ordine e
								pagamento del prezzo, il Venditore provvederà a rimborsare,
								senza ritardo, quanto da te già anticipato ed il contratto si
								intenderà risolto tra le parti. Si precisa, altresì, che i
								prodotti ed i servizi oggetto delle presenti condizioni sono
								legati alla esistenza del sito.
							</p>
							<p>
								Con la trasmissione telematica del modulo d’ordine, accetti
								senza condizioni e ti impegni ad osservare, nei rapporti con il
								Venditore queste condizioni di vendita. Se non condividi alcuni
								termini riportati in esse, ti invitiamo a non inoltrare il
								modulo d’ordine per l’acquisto dei prodotti.
							</p>
							<p>
								Con la trasmissione del modulo d’ordine confermi di conoscere ed
								accettare le Condizioni di vendita e i Termini e condizioni
								d’uso. Concluso il contratto il Venditore ti fornirà via mail
								una ricevuta dell’ordine di acquisto.
							</p>
							<p>
								Le caratteristiche essenziali dei prodotti sono presentate sul
								sito www.digitalmemorial.it.
								{" "}
							</p>
							<p>
								I prezzi dei prodotti potrebbero essere soggetti ad
								aggiornamenti. Accertati del prezzo finale di vendita prima di
								inoltrare il relativo modulo d’ordine. Il venditore, in caso di
								esercizio del tuo diritto di recesso, ha la facoltà di non
								rimborsare le somme corrisposte nei casi indicati nelle
								condizioni di esercizio del diritto di recesso.
							</p>
							<p>
								Per il pagamento del prezzo dei prodotti potrai seguire una
								delle modalità indicate nel modulo d’ordine.
							</p>
							<p>
								In caso di pagamento mediante carta di credito, le informazioni
								finanziarie (ad esempio il numero della carta di credito/debito
								o la data della scadenza della carta) saranno inoltrate alla
								banca o ad altre banche che forniscono i relativi servizi di
								pagamento elettronico a distanza, senza che terzi possano
								accedervi in alcun modo. Tali informazioni non saranno mai
								utilizzate dal Venditore se non per completare le procedure
								relative al tuo acquisto e per emettere i relativi rimborsi in
								caso di esercizio del tuo diritto di recesso. Il prezzo per
								l’acquisto dei prodotti, come indicato sul modulo d’ordine,
								saranno addebitati sul tuo conto corrente.
							</p>
							<p>
								Potrai ottenere informazioni su come trattiamo i tuoi dati
								personali accedendo alla sezione Termini e condizioni d’uso.
							</p>
							<p>
								In caso di problemi connessi all’acquisto di un prodotto, il
								consumatore potrà inoltrare le proprie osservazioni direttamente
								all’azienda al seguente indirizzo mail info@digitalmemorial.it o
								contattare i seguenti numeri di telefono: 0874 461925 – 338
								7755636
							</p>
						</span>
						&nbsp;
						<span className="terms-owner">
							<p style={{ fontWeight: "bold" }}>Informazioni su: </p>
							<p style={{ fontWeight: "bold" }}>I.M.E. DI IACOBUCCI MIMMO</p>
							<p>sede legale: Toro (CB), via delle Fratte, s.n.</p>
							<p>partita iva n. 01494450701; N. REA CB-112790</p>
							<p>tel. 0874 461925 - cell. 338 7755636</p>
						</span>
						&nbsp;
						<span
							style={{
								width          : "100%",
								textAlign      : "center",
								textDecoration : "underline",
								fontWeight     : "bold",
							}}
						>
							DIRITTO DI RECESSO
						</span>
						<span className="text-justify">
							<p>
								Lo scopo di I.M.E. di Iacobucci Mimmo è garantire la tua
								completa soddisfazione. Se per qualche ragione non sei
								soddisfatto del tuo ordine puoi esercitare il tuo diritto di
								recesso entro 14 giorni dal giorno in cui hai acquistato. Per
								l’esercizio del diritto di recesso occorre redigere ed inviare
								al Venditore dichiarazione esplicita della decisione di recedere
								dal contratto. In tal caso resterà a tuo carico l’onere di
								provare il corretto e tempestivo esercizio del diritto di
								recesso. Il Venditore ti invierà conferma a mezzo mail
								dell’avvenuto ricevimento della richiesta di recesso trasmessa
								mediante tale modulo.
							</p>
							<p style={{ fontWeight: "bold" }}>
								Condizioni per l’esercizio del diritto di recesso.
							</p>
							<p>
								Il diritto di recesso si intende esercitato correttamente
								qualora siano interamente rispettate le seguenti condizioni:
							</p>
							<p>
								a) la richiesta di recesso venga correttamente compilata e
								trasmessa al Venditore entro quattordici giorni
								dall’acquisizione dei prodotti.
							</p>
							<p>
								Se hai rispettato tutte le condizioni richieste, il Venditore ti
								rimborserà il prezzo dei prodotti acquistati.
							</p>
							<p style={{ fontWeight: "bold" }}>Tempi e modalità di rimborso</p>
							<p>
								Dopo che il Venditore avrà ricevuto la tua dichiarazione di
								recesso e controllato che tutti i requisiti siano stati
								rispettati, riceverai una e-mail di conferma e sarà attivata la
								procedura di rimborso indicata. Qualunque sia la modalità di
								pagamento da te utilizzata il rimborso è attivato dal Venditore
								nel più breve tempo possibile e comunque entro quattordici
								giorni dalla data in cui è venuto a conoscenza dell’esercizio
								del tuo diritto di recesso, una volta verificato che il recesso
								sia avvenuto nel rispetto delle condizioni sopra riportate.
							</p>
						</span>
					</div>
				</div>
			</>
		) : (
			<Redirect to="/products" />
		);
	}
}

export default withContext(SellTerms);
