import React, { useState } from 'react';
import './modalP.scss';
import { Typography } from '@material-ui/core';
import addressServiceGetData from '../FormComponents/addressService';

export default function ViewAddress(props) {
	const [address, setAddress] = useState({ address: '', cap: '', city: '', province: '', civic: '' });
	address.city === '' &&
		setAddress(
			props.address || {
				address: 'via Larino',
				cap: '86100',
				city: 'Campobasso',
				province: 'CB',
				civic: '15'
			}
		);
	const [stringAddress, setStringAddress] = useState({});
	const [loaded, setLoaded] = useState(null);
	if (
		(!stringAddress.region || !stringAddress.city || !stringAddress.province) &&
		address.city != '' &&
		!props.noSearch
	) {
		addressServiceGetData('region').then(data => {
			const temp = stringAddress;
			temp.region = data.find(x => x.ID === address.region).name;
			setStringAddress(temp);
			if (!stringAddress.province)
				addressServiceGetData('province', address.region).then(data => {
					const temp = stringAddress;
					temp.province = data.find(x => x.ID === address.province).name;
					setStringAddress(temp);
					if (!stringAddress.city)
						addressServiceGetData('location', address.province).then(data => {
							const temp = stringAddress;
							temp.city = data.find(x => x.ID === address.city).name;
							setStringAddress(temp);
							!stringAddress.city && setLoaded(true);
							!stringAddress.region && setLoaded(true);
							!stringAddress.province && setLoaded(true);
							setLoaded(true);
						});
				});
		});
	}
	if (props.noSearch && (!stringAddress.region || !stringAddress.city || !stringAddress.province)) {
		setStringAddress({
			region: address.region,
			province: address.province,
			location: address.location,
			city: address.city,
			civic: address.civic,
			cap: address.cap
		});
	}
	return stringAddress.region && stringAddress.city && stringAddress.province ? (
		<div className="w-full">
			{address.name && (
				<div className="flex-row-justify-between-w-full padding-x-20">
					<Typography className="p-y-10">
						<strong>Nome completo:</strong>
						{` ${address.surname} `}
					</Typography>
					&nbsp;
					<Typography className="p-y-10"> {address.name} </Typography>
				</div>
			)}
			<div className="flex-row-justify-between-w-full padding-x-20" style={{ justifyContent: 'space-between' }}>
				<div
					style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
					className="p-y-10"
				>
					<strong style={{ marginRight: 20, justifyContent: 'space-between', alignItems: 'center' }}>
						Localita`:
					</strong>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<p>{` ${stringAddress.region} `}</p>
						<p>{` ${stringAddress.city} `}</p>
					</div>
				</div>
				&nbsp;
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Typography className="p-y-10"> ({stringAddress.province}) </Typography>
					&nbsp;
					<Typography className="p-y-10"> {address.cap}</Typography>
				</div>
			</div>
			<div className="flex-row-justify-between-w-full padding-x-20">
				<Typography className="p-y-10">
					<strong>Indirizzo:</strong>
					{` ${address.address} `}
				</Typography>
				&nbsp;
				<Typography className="p-y-10">
					{' N. '}
					{address.civic}{' '}
				</Typography>
			</div>
		</div>
	) : null;
}
