// eslint-disable-next-line import/prefer-default-export
import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export function setDataForControlPanel(e) {
	return {
		ID: e.deceased && e.deceased.ID,
		gender: e.deceased?.gender,
		name: e.owner && e.owner.name,
		partnerID: e.partnerid,
		surname: e.owner && e.owner.surname,
		link: e.url.replace('api//', 'api/'),
		email: e.owner && e.owner.username,
		city: e.verification_code,
		verified: e.verified,
		qrcodeAttivazione: e.qrcodeAttivazione,
		phone: e.owner?.telephone,
		deceasedName: e.deceased?.name,
		enabled: e.deceased?.enabled,
		deceasedSurname: e.deceased?.surname,
		deceasedMemorial: e.deceased?.memorialID,
		dedicCounter: e.dedicCounter,
		contoVendita: e.deceased?.contoVendita === '0' ? 'No' : 'Si',
		time: moment(e.deceased?.time, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss'),
		date: e.date,
		referringPlace: e.referringPlace,
		address:
			e.owner && e.owner.address && e.owner.address.region
				? `${e.owner.address.region} ${e.owner.address.location} (${e.owner.address.province}) ${e.owner.address.address}`
				: e.owner && e.owner.address && e.owner.address.completeAddress,
		orderID: e.order?.ID,
		staticAge: e.deceased && e.deceased.staticAge,
		bornDate: e.deceased && e.deceased.dateOfBirth,
		aphorism: e.deceased && e.deceased.aphorism,
		deathDate: e.deceased && e.deceased.dateOfDeath,
		graveyardRegion: e.deceased && e.deceased.graveRegion,
		condolence: e.deceased && e.deceased.condolenceCounter,
		graveyardProvince: e.deceased && e.deceased.graveProvince,
		graveyardCity: e.deceased && e.deceased.graveLocation,
		graveyardName: e.deceased && e.deceased.graveyardName,
		graveyardAddress: e.deceased && e.deceased.graveAddress,
		graveyardCap: e.deceased && e.deceased.graveCap,
		pending: e.deceased && e.deceased.ownerPending,
		editorData: e.deceased && e.deceased.obituaryNote,
		fileurl: e.deceased && e.deceased.lapide,
		filename: e.deceased && e.deceased.lapideName
	};
}

export function mergeObjs(obj1, obj2) {
	const merged = {};
	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);

	keys1.forEach(k1 => {
		if (`${obj1.showPage}` === '0' && k1 === 'manifest') {
			merged[k1] = obj2.manifest;
		} else if (`${obj2.showPage}` === '0' && k1 === 'manifest') {
			merged[k1] = obj1.manifest;
		} else if (obj1[k1] && obj2[k1]) {
			merged[k1] = JSON.stringify(obj1[k1]).length > JSON.stringify(obj2[k1]).length ? obj1[k1] : obj2[k1];
		} else merged[k1] = obj2[k1] || obj1[k1]; // replace values from 2nd object, if any
	});
	keys2.forEach(k1 => {
		if (`${obj1.showPage}` === '0' && k1 === 'manifest') {
			merged[k1] = obj2.manifest;
		} else if (`${obj2.showPage}` === '0' && k1 === 'manifest') {
			merged[k1] = obj1.manifest;
		} else if (obj1[k1] && obj2[k1]) {
			merged[k1] = JSON.stringify(obj1[k1]).length > JSON.stringify(obj2[k1]).length ? obj1[k1] : obj2[k1];
		} else merged[k1] = obj1[k1] || obj2[k1]; // replace values from 2nd object, if any
	});
	Object.keys(obj2).forEach(k2 => {
		if (!keys1.includes(k2)) merged[k2] = obj2[k2]; // add additional properties from second object, if any
	});
	Object.keys(obj1).forEach(k2 => {
		if (!keys2.includes(k2)) merged[k2] = obj2[k2]; // add additional properties from second object, if any
	});
	if (`${obj1.showPage}` === '1') {
		merged.idLapideManifest = obj1.ID;
		merged.graveyardCap = obj1.graveyardCap;
		merged.graveyardRegion = obj1.graveyardRegion;
		merged.graveyardProvince = obj1.graveyardProvince;
		merged.graveyardCity = obj1.graveyardCity;
		merged.graveyardName = obj1.graveyardName;
		merged.graveyardAddress = obj1.graveyardAddress;
		merged.fileurl = obj1.fileurl;
		merged.refCap = obj2.graveyardCap;
	} else {
		merged.refCap = obj1.graveyardCap;
		merged.graveyardCap = obj2.graveyardCap;
		merged.graveyardRegion = obj2.graveyardRegion;
		merged.graveyardProvince = obj2.graveyardProvince;
		merged.graveyardCity = obj2.graveyardCity;
		merged.graveyardName = obj2.graveyardName;
		merged.fileurl = obj2.fileurl;
		merged.graveyardAddress = obj2.graveyardAddress;
		merged.idLapideManifest = obj2.ID;
	}
	return merged;
}

export const provinces = [
	{
		ID: '1',
		name: 'AQ',
		idregione: '2'
	},
	{
		ID: '2',
		name: 'PE',
		idregione: '2'
	},
	{
		ID: '3',
		name: 'TE',
		idregione: '2'
	},
	{
		ID: '4',
		name: 'CH',
		idregione: '2'
	},
	{
		ID: '5',
		name: 'PZ',
		idregione: '3'
	},
	{
		ID: '6',
		name: 'MT',
		idregione: '3'
	},
	{
		ID: '7',
		name: 'CZ',
		idregione: '4'
	},
	{
		ID: '8',
		name: 'RC',
		idregione: '4'
	},
	{
		ID: '9',
		name: 'CS',
		idregione: '4'
	},
	{
		ID: '10',
		name: 'VV',
		idregione: '4'
	},
	{
		ID: '11',
		name: 'KR',
		idregione: '4'
	},
	{
		ID: '12',
		name: 'SA',
		idregione: '5'
	},
	{
		ID: '13',
		name: 'NA',
		idregione: '5'
	},
	{
		ID: '14',
		name: 'AV',
		idregione: '5'
	},
	{
		ID: '15',
		name: 'CE',
		idregione: '5'
	},
	{
		ID: '16',
		name: 'BN',
		idregione: '5'
	},
	{
		ID: '17',
		name: 'RA',
		idregione: '6'
	},
	{
		ID: '18',
		name: 'MO',
		idregione: '6'
	},
	{
		ID: '19',
		name: 'PC',
		idregione: '6'
	},
	{
		ID: '20',
		name: 'FE',
		idregione: '6'
	},
	{
		ID: '21',
		name: 'RE',
		idregione: '6'
	},
	{
		ID: '22',
		name: 'FC',
		idregione: '6'
	},
	{
		ID: '23',
		name: 'BO',
		idregione: '6'
	},
	{
		ID: '24',
		name: 'PR',
		idregione: '6'
	},
	{
		ID: '25',
		name: 'RN',
		idregione: '6'
	},
	{
		ID: '26',
		name: 'UD',
		idregione: '7'
	},
	{
		ID: '27',
		name: 'PN',
		idregione: '7'
	},
	{
		ID: '28',
		name: 'TS',
		idregione: '7'
	},
	{
		ID: '29',
		name: 'GO',
		idregione: '7'
	},
	{
		ID: '30',
		name: 'LT',
		idregione: '8'
	},
	{
		ID: '31',
		name: 'RI',
		idregione: '8'
	},
	{
		ID: '32',
		name: 'RM',
		idregione: '8'
	},
	{
		ID: '33',
		name: 'FR',
		idregione: '8'
	},
	{
		ID: '34',
		name: 'VT',
		idregione: '8'
	},
	{
		ID: '35',
		name: 'GE',
		idregione: '9'
	},
	{
		ID: '36',
		name: 'IM',
		idregione: '9'
	},
	{
		ID: '37',
		name: 'SV',
		idregione: '9'
	},
	{
		ID: '38',
		name: 'SP',
		idregione: '9'
	},
	{
		ID: '39',
		name: 'LO',
		idregione: '10'
	},
	{
		ID: '40',
		name: 'LC',
		idregione: '10'
	},
	{
		ID: '41',
		name: 'VA',
		idregione: '10'
	},
	{
		ID: '42',
		name: 'MI',
		idregione: '10'
	},
	{
		ID: '43',
		name: 'SO',
		idregione: '10'
	},
	{
		ID: '44',
		name: 'BS',
		idregione: '10'
	},
	{
		ID: '45',
		name: 'CR',
		idregione: '10'
	},
	{
		ID: '46',
		name: 'MN',
		idregione: '10'
	},
	{
		ID: '47',
		name: 'CO',
		idregione: '10'
	},
	{
		ID: '48',
		name: 'BG',
		idregione: '10'
	},
	{
		ID: '49',
		name: 'PV',
		idregione: '10'
	},
	{
		ID: '50',
		name: 'PU',
		idregione: '11'
	},
	{
		ID: '51',
		name: 'MC',
		idregione: '11'
	},
	{
		ID: '52',
		name: 'AP',
		idregione: '11'
	},
	{
		ID: '53',
		name: 'AN',
		idregione: '11'
	},
	{
		ID: '54',
		name: 'TO',
		idregione: '13'
	},
	{
		ID: '55',
		name: 'AL',
		idregione: '13'
	},
	{
		ID: '56',
		name: 'CN',
		idregione: '13'
	},
	{
		ID: '57',
		name: 'AT',
		idregione: '13'
	},
	{
		ID: '58',
		name: 'BI',
		idregione: '13'
	},
	{
		ID: '59',
		name: 'NO',
		idregione: '13'
	},
	{
		ID: '60',
		name: 'VB',
		idregione: '13'
	},
	{
		ID: '61',
		name: 'VC',
		idregione: '13'
	},
	{
		ID: '62',
		name: 'LE',
		idregione: '14'
	},
	{
		ID: '63',
		name: 'FG',
		idregione: '14'
	},
	{
		ID: '64',
		name: 'BA',
		idregione: '14'
	},
	{
		ID: '65',
		name: 'TA',
		idregione: '14'
	},
	{
		ID: '66',
		name: 'BR',
		idregione: '14'
	},
	{
		ID: '67',
		name: 'OR',
		idregione: '15'
	},
	{
		ID: '68',
		name: 'SS',
		idregione: '15'
	},
	{
		ID: '69',
		name: 'NU',
		idregione: '15'
	},
	{
		ID: '70',
		name: 'CA',
		idregione: '15'
	},
	{
		ID: '71',
		name: 'RG',
		idregione: '16'
	},
	{
		ID: '72',
		name: 'CT',
		idregione: '16'
	},
	{
		ID: '73',
		name: 'PA',
		idregione: '16'
	},
	{
		ID: '74',
		name: 'ME',
		idregione: '16'
	},
	{
		ID: '75',
		name: 'CL',
		idregione: '16'
	},
	{
		ID: '76',
		name: 'EN',
		idregione: '16'
	},
	{
		ID: '77',
		name: 'AG',
		idregione: '16'
	},
	{
		ID: '78',
		name: 'TP',
		idregione: '16'
	},
	{
		ID: '79',
		name: 'SR',
		idregione: '16'
	},
	{
		ID: '80',
		name: 'SI',
		idregione: '17'
	},
	{
		ID: '81',
		name: 'PT',
		idregione: '17'
	},
	{
		ID: '82',
		name: 'PI',
		idregione: '17'
	},
	{
		ID: '83',
		name: 'MS',
		idregione: '17'
	},
	{
		ID: '84',
		name: 'GR',
		idregione: '17'
	},
	{
		ID: '85',
		name: 'LU',
		idregione: '17'
	},
	{
		ID: '86',
		name: 'AR',
		idregione: '17'
	},
	{
		ID: '87',
		name: 'FI',
		idregione: '17'
	},
	{
		ID: '88',
		name: 'LI',
		idregione: '17'
	},
	{
		ID: '89',
		name: 'PO',
		idregione: '17'
	},
	{
		ID: '90',
		name: 'BZ',
		idregione: '18'
	},
	{
		ID: '91',
		name: 'TN',
		idregione: '18'
	},
	{
		ID: '92',
		name: 'PG',
		idregione: '19'
	},
	{
		ID: '93',
		name: 'TR',
		idregione: '19'
	},
	{
		ID: '94',
		name: 'AO',
		idregione: '20'
	},
	{
		ID: '95',
		name: 'PD',
		idregione: '21'
	},
	{
		ID: '96',
		name: 'BL',
		idregione: '21'
	},
	{
		ID: '97',
		name: 'RO',
		idregione: '21'
	},
	{
		ID: '98',
		name: 'VR',
		idregione: '21'
	},
	{
		ID: '99',
		name: 'VI',
		idregione: '21'
	},
	{
		ID: '100',
		name: 'TV',
		idregione: '21'
	},
	{
		ID: '101',
		name: 'VE',
		idregione: '21'
	},
	{
		ID: '102',
		name: 'CB',
		idregione: '12'
	},
	{
		ID: '103',
		name: 'IS',
		idregione: '12'
	}
];
export const regions = [
	{ ID: '2', name: 'Abruzzo' },
	{ ID: '3', name: 'Basilicata' },
	{ ID: '4', name: 'Calabria' },
	{ ID: '5', name: 'Campania' },
	{ ID: '6', name: 'Emilia-Romagna' },
	{ ID: '7', name: 'Friuli-Venezia Giulia' },
	{ ID: '8', name: 'Lazio' },
	{ ID: '9', name: 'Liguria' },
	{ ID: '10', name: 'Lombardia' },
	{ ID: '11', name: 'Marche' },
	{ ID: '12', name: 'Molise' },
	{ ID: '13', name: 'Piemonte' },
	{ ID: '14', name: 'Puglia' },
	{ ID: '15', name: 'Sardegna' },
	{ ID: '16', name: 'Sicilia' },
	{ ID: '17', name: 'Toscana' },
	{ ID: '18', name: 'Trentino-Alto Adige' },
	{ ID: '19', name: 'Umbria' },
	{ ID: '20', name: "Valle d'Aosta" },
	{ ID: '21', name: 'Veneto' }
];
export function generatePassword(len) {
	const length = len || 10;
	const string = 'abcdefghijklmnopqrstuvwxyz'; // to upper
	const numeric = '0123456789';
	const punctuation = '!@#$&*()_+~|}{[]:?><,/-=';
	let password = '';
	let character = '';
	const crunch = true;
	while (password.length < length) {
		const entity1 = Math.ceil(string.length * Math.random() * Math.random());
		const entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
		const entity3 = Math.ceil(punctuation.length * Math.random() * Math.random());
		let hold = string.charAt(entity1);
		hold = password.length % 2 == 0 ? hold.toUpperCase() : hold;
		character += hold;
		character += numeric.charAt(entity2);
		character += punctuation.charAt(entity3);
		password = character;
	}
	password = password
		.split('')
		.sort(function () {
			return 0.5 - Math.random();
		})
		.join('');
	return password.substr(0, len);
}
