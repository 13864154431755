const RegionData = [
  {
    ID: "2",
    name: "Abruzzo",
  },
  {
    ID: "3",
    name: "Basilicata",
  },
  {
    ID: "4",
    name: "Calabria",
  },
  {
    ID: "5",
    name: "Campania",
  },
  {
    ID: "6",
    name: "Emilia-Romagna",
  },
  {
    ID: "7",
    name: "Friuli-Venezia Giulia",
  },
  {
    ID: "8",
    name: "Lazio",
  },
  {
    ID: "9",
    name: "Liguria",
  },
  {
    ID: "10",
    name: "Lombardia",
  },
  {
    ID: "11",
    name: "Marche",
  },
  {
    ID: "12",
    name: "Molise",
  },
  {
    ID: "13",
    name: "Piemonte",
  },
  {
    ID: "14",
    name: "Puglia",
  },
  {
    ID: "15",
    name: "Sardegna",
  },
  {
    ID: "16",
    name: "Sicilia",
  },
  {
    ID: "17",
    name: "Toscana",
  },
  {
    ID: "18",
    name: "Trentino-Alto Adige",
  },
  {
    ID: "19",
    name: "Umbria",
  },
  {
    ID: "20",
    name: "Valle d'Aosta",
  },
  {
    ID: "21",
    name: "Veneto",
  },
];
export default RegionData;
