let acquisti = [{
    name:'ZMarzio',
    surname:'Rossi',
    CF:'mrrrss12p12a312l',
    route:'Via Roma',
    civic:'123',
    city:'Roma',
    province:'RM',
    phoneNumber:'333333333',
    email:'asd@asd.it',
    type:4,
    deceased:null
},
    {
        name:'Mario',
        surname:'Rossi',
        CF:'mrrrss12p12a312l',
        route:'Via Roma',
        civic:'123',
        city:'Roma',
        province:'RM',
        phoneNumber:'333333333',
        type:5,
        deceased: {qrcode:'123123123',name:'Demo',surname:'Demo',bio:'Demo demo era una demo di un sito web'}
    },
    {
        name:'Mario',
        surname:'Rossi',
        CF:'mrrrss12p12a312l',
        route:'Via Roma',
        civic:'123',
        city:'Roma',
        province:'RM',
        phoneNumber:'333333333',
        email:'asd@asd.it',
        type:0,
        deceased: {qrcode:'123123123',name:'Demo',surname:'Demo',dedic:'dedica'}

    },
]
export default acquisti
