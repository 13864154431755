import React from "react";
import { Clear, SwapHorizontalCircle } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";

export default function AbsoluteRouter(props) {
  return (
    <div className="qrcode-request-footer">
      <label>
        Elementi selezionati: {props.num}
        <div>
          {/* <Tooltip title='Elimina qrCode'>
                    <Clear />
                </Tooltip> */}
          {/* &nbsp; */}
          <Tooltip title="Cedi qrCode">
            <SwapHorizontalCircle
              onClick={() => {
                props.openModal();
              }}
            />
          </Tooltip>
        </div>
      </label>
    </div>
  );
}
