let azienda = {
    nome: 'I.M.E. di Iacobucci Mimmo',
    tipologia: 'Produzioni Apparecchiature Elettroniche',
    civic: '12',
    city: '7587',
    province: '102',
    region: 12,
    cap: '86100',
    descrizione: '<p class="font-size-19 text-center">I.M.E.</p>\n' +
        '<p class="font-size-19 text-justify" >L\'azienda I.M.E. nasce nel 2003, occupandosi del montaggio di schede elettroniche in conto lavorazione, principalmente nell’ambito degli elettrodomestici, dei sismografi, delle insegne a led computerizzate, delle automobili alimentate a idrogeno, metano e gpl, degli elettromedicali e della domotica, realizzando anche cablaggi elettrici e cavetteria. Realizziamo anche prodotti con il nostro marchio come corpi illuminanti led per settori specifici (Votiva – Luna Park – Industriale e Civile). Offriamo anche servizio di riparazione corpi illuminanti con tecnologia led (Lampioni, Fari, ecc.)</p>\n' +
        '&nbsp;' +
        '<p class="font-size-19 text-justify">Il processo produttivo adottato è tale da soddisfare sia le piccole che le grandi produzioni in SMT, ASSIALE, RADIALE E PTH.</p>\n' +
        '&nbsp;' +
        '<p class="font-size-19">Siamo strutturati nei seguenti reparti:</p>' +
        '<ul class="font_3 color_11">\n' +
        '<li>\n' +
        '<h3 class="font_3"><span class="color_11">Area Magazzino in ingresso, preparazione commessa e smistamento;</span></h3>\n' +
        '</li>\n' +
        '<li>\n' +
        '<h3 class="font_3"><span class="color_11">Area Cablaggio/cavi;</span></h3>\n' +
        '</li>\n' +
        '<li>\n' +
        '<h3 class="font_3"><span class="color_11">Area SMD</span></h3>\n' +
        '</li>\n' +
        '<li>\n' +
        '<h3 class="font_3"><span class="color_11">Area Inserimento automatico Assiale Radiale</span></h3>\n' +
        '</li>\n' +
        '<li>\n' +
        '<h3 class="font_3"><span class="color_11">Area Preformatura manuale e semiautomatica;</span></h3>\n' +
        '</li>\n' +
        '<li>\n' +
        '<h3 class="font_3"><span class="color_11">Area Inserimento componenti manuali;</span></h3>\n' +
        '</li>\n' +
        '<li>\n' +
        '<h3 class="font_3"><span class="color_11">Area Saldatura ad onda di stagno;</span></h3>\n' +
        '</li>\n' +
        '<li>\n' +
        '<h3 class="font_3"><span class="color_11">Area Controllo visivo e saldature manuali;</span></h3>\n' +
        '</li>\n' +
        '<li>\n' +
        '<h3 class="font_3"><span class="color_11">Area Collaudo;</span></h3>\n' +
        '</li>\n' +
        '<li>\n' +
        '<h3 class="font_3"><span class="color_11">Area Riparazione;</span></h3>\n' +
        '</li>\n' +
        '<li>\n' +
        '<h3 class="font_3"><span class="color_11">Area Controllo Qualità;</span></h3>\n' +
        '</li>\n' +
        '<li>\n' +
        '<h3 class="font_3"><span class="color_11">Area Imballaggio e Spedizioni (possibilità di consegna della merce anche con nostro mezzo</span></h3>\n' +
        '\n' +
        '<h3 class="font_3"><span class="color_11">aziendale).</span></h3>\n' +
        '\n' +
        '<h3 class="font_3"><span class="color_11">Le nostre principali attrezzature:</span></h3>\n' +
        '</li>\n' +
        '</ul>' +
        '<ul class="font_3 color_11">\n' +
        '<li>\n' +
        '<h3 class="font_3"><span class="color_11">macchina per montaggio automatico pht assiale e radiale: FUJI FBA-8560;</span></h3>\n' +
        '</li>\n' +
        '<li>\n' +
        '<h3 class="font_3"><span class="color_11">macchine preformatrici semi-automatiche assiali e radiali: OLAMEF;</span></h3>\n' +
        '</li>\n' +
        '<li>\n' +
        '<h3 class="font_3"><span class="color_11">linee sequenziali inserimento manuale: ESSEPI, SALDATRICE A DOPPIA ONDA DI</span></h3>\n' +
        '\n' +
        '<h3 class="font_3"><span class="color_11">STAGNO LEAD FREE SPRAY FLUX CON CROGIOLO DA 420 mm MODELLO S/400;</span></h3>\n' +
        '</li>\n' +
        '<li>\n' +
        '<h3 class="font_3"><span class="color_11">Macchine per serigrafia: “MPM AP HiE EFFICIENCY”, per montaggio smt: “PICK</span></h3>\n' +
        '\n' +
        '<h3 class="font_3"><span class="color_11">PLACE PHILIPS TOPAZ”, “PICK PLACE PHILIPS EMERALD”, per saldatura: “forno a rifusione ELECTROVERT OMNIFLO 5” tutte in linea con carico, scarico e inversione dei circuiti stampati gestita in maniera completamente automatica.</span></h3>\n' +
        '</li>\n' +
        '<li>\n' +
        '<h3 class="font_3"><span class="color_11">Macchine per controllo: “in-circuit TECNOS TEC 4000”, microscopio VISION LYNX, profilatori termici.</span></h3>\n' +
        '</li>\n' +
        '<li>\n' +
        '<h3 class="font_3"><span class="color_11">Macchine per realizzazioni cavetteria: taglia/spella automatica MAKFIL TS90, pressa per PLUG POWERSONIC 5IK40GN, macchina CABLE MEMORY TEST 256,</span></h3>\n' +
        '\n' +
        '<h3 class="font_3"><span class="color_11">La nostra metodologia di produzione just in time permette di:</span></h3>\n' +
        '</li>\n' +
        '</ul>' +
        '<ul class="font_3 color_11">\n' +
        '<li>\n' +
        '<h3 class="font_3"><span class="color_11">ridurre al minimo i tempi di attesa tra le richieste del mercato e le nostre risposte;</span></h3>\n' +
        '</li>\n' +
        '<li>\n' +
        '<h3 class="font_3"><span class="color_11">produrre quindi solo i prodotti necessari perché richiesti;</span></h3>\n' +
        '</li>\n' +
        '<li>\n' +
        '<h3 class="font_3"><span class="color_11">offrirli nella quantità e qualità necessari (uno scrupoloso controllo in linea durante la</span></h3>\n' +
        '\n' +
        '<h3 class="font_3"><span class="color_11">produzione, un controllo visivo e il collaudo in-circuit alla fine del processo garantiscono la funzionalità totale dei prodotti finiti);</span></h3>\n' +
        '</li>\n' +
        '</ul>' +
        '&nbsp;' +
        '<p class="font-size-19  text-justify">Ci distinguono: Serietà, Qualità, Disponibilità, Basso Costo per il Cliente\n' +
        'Sempre a disposizione per proporvi un nostro preventivo gratuito e pre-serie a costi contenuti per\n' +
        'quantificare con accurata precisione il costo definitivo di un’eventuale lavorazione in serie.\n' +
        '&nbsp;</p> &nbsp;',
    photos: [{
        altTag: 'd95383_3378d5f93ea14bf6a9cfa1d83696c623~mv2_d_1985_2369_s_2.png',
        type: 'photo',
        url: 'https://static.wixstatic.com/media/d95383_3378d5f93ea14bf6a9cfa1d83696c623~mv2_d_1985_2369_s_2.png/v1/fill/w_904,h_1080,fp_0.50_0.50,q_95/d95383_3378d5f93ea14bf6a9cfa1d83696c623~mv2_d_1985_2369_s_2.png'
    },
        {
            altTag: 'd95383_3378d5f93ea14bf6a9cfa1d83696c623~mv2_d_1985_2369_s_2.png',
            type: 'photo',
            url: 'https://static.wixstatic.com/media/d95383_3378d5f93ea14bf6a9cfa1d83696c623~mv2_d_1985_2369_s_2.png/v1/fill/w_904,h_1080,fp_0.50_0.50,q_95/d95383_3378d5f93ea14bf6a9cfa1d83696c623~mv2_d_1985_2369_s_2.png'
        }
        ,
        {
            altTag: 'd95383_3378d5f93ea14bf6a9cfa1d83696c623~mv2_d_1985_2369_s_2.png',
            type: 'photo',
            url: 'https://static.wixstatic.com/media/d95383_3378d5f93ea14bf6a9cfa1d83696c623~mv2_d_1985_2369_s_2.png/v1/fill/w_904,h_1080,fp_0.50_0.50,q_95/d95383_3378d5f93ea14bf6a9cfa1d83696c623~mv2_d_1985_2369_s_2.png'
        }
        , {
            altTag: 'd95383_3378d5f93ea14bf6a9cfa1d83696c623~mv2_d_1985_2369_s_2.png',
            type: 'photo',
            url: 'https://static.wixstatic.com/media/d95383_3378d5f93ea14bf6a9cfa1d83696c623~mv2_d_1985_2369_s_2.png/v1/fill/w_904,h_1080,fp_0.50_0.50,q_95/d95383_3378d5f93ea14bf6a9cfa1d83696c623~mv2_d_1985_2369_s_2.png'
        }
        , {
            altTag: 'd95383_3378d5f93ea14bf6a9cfa1d83696c623~mv2_d_1985_2369_s_2.png',
            type: 'photo',
            url: 'https://static.wixstatic.com/media/d95383_3378d5f93ea14bf6a9cfa1d83696c623~mv2_d_1985_2369_s_2.png/v1/fill/w_904,h_1080,fp_0.50_0.50,q_95/d95383_3378d5f93ea14bf6a9cfa1d83696c623~mv2_d_1985_2369_s_2.png'
        },
        {
            altTag: 'd95383_3378d5f93ea14bf6a9cfa1d83696c623~mv2_d_1985_2369_s_2.png',
            type: 'photo',
            url: 'https://static.wixstatic.com/media/d95383_3378d5f93ea14bf6a9cfa1d83696c623~mv2_d_1985_2369_s_2.png/v1/fill/w_904,h_1080,fp_0.50_0.50,q_95/d95383_3378d5f93ea14bf6a9cfa1d83696c623~mv2_d_1985_2369_s_2.png'
        },
        {
            altTag: 'd95383_3378d5f93ea14bf6a9cfa1d83696c623~mv2_d_1985_2369_s_2.png',
            type: 'photo',
            url: 'https://static.wixstatic.com/media/d95383_3378d5f93ea14bf6a9cfa1d83696c623~mv2_d_1985_2369_s_2.png/v1/fill/w_904,h_1080,fp_0.50_0.50,q_95/d95383_3378d5f93ea14bf6a9cfa1d83696c623~mv2_d_1985_2369_s_2.png'
        },
        {
            altTag: 'd95383_3378d5f93ea14bf6a9cfa1d83696c623~mv2_d_1985_2369_s_2.png',
            type: 'photo',
            url: 'https://static.wixstatic.com/media/d95383_3378d5f93ea14bf6a9cfa1d83696c623~mv2_d_1985_2369_s_2.png/v1/fill/w_904,h_1080,fp_0.50_0.50,q_95/d95383_3378d5f93ea14bf6a9cfa1d83696c623~mv2_d_1985_2369_s_2.png'
        },
        {
            altTag: 'd95383_3378d5f93ea14bf6a9cfa1d83696c623~mv2_d_1985_2369_s_2.png',
            type: 'photo',
            url: 'https://static.wixstatic.com/media/d95383_3378d5f93ea14bf6a9cfa1d83696c623~mv2_d_1985_2369_s_2.png/v1/fill/w_904,h_1080,fp_0.50_0.50,q_95/d95383_3378d5f93ea14bf6a9cfa1d83696c623~mv2_d_1985_2369_s_2.png'
        },
        {
            altTag: 'd95383_3378d5f93ea14bf6a9cfa1d83696c623~mv2_d_1985_2369_s_2.png',
            type: 'photo',
            url: 'https://static.wixstatic.com/media/d95383_3378d5f93ea14bf6a9cfa1d83696c623~mv2_d_1985_2369_s_2.png/v1/fill/w_904,h_1080,fp_0.50_0.50,q_95/d95383_3378d5f93ea14bf6a9cfa1d83696c623~mv2_d_1985_2369_s_2.png'
        }
    ],

    route: 'Via delle Fratte, s.n.c. 86018 – Toro (CB)',
    PI: '01494450701',
    CF: 'CBCMMM71S10B519R',
    telefono: '0874/461925',
    cellulare: '338/7755636',
    sitoWeb: 'www.ime-elettronica.it',
    Email: 'info@ime-elettronica.it'
}
export default azienda;
