import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Crop, Fullscreen } from "@material-ui/icons";

export default function SinglePhotoViewer(props) {
  const [img, setImg] = useState(props.img);
  let close = true;
  useEffect(() => {
    setImg(props.img);
  }, [props.img]);
  function toggleFullScreen() {
    if (!document.webkitIsFullScreen) {
      if (document.documentElement.webkitRequestFullScreen)
        document.documentElement.webkitRequestFullScreen();
    } else if (document.webkitCancelFullScreen)
      document.webkitCancelFullScreen();
    if (!document.fullscreenElement) {
      if (document.documentElement.requestFullscreen)
        document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
  const notRenderButton =
    document.webkitIsFullScreen === undefined &&
    document.fullscreenElement === undefined;
  return (
    <div
      className="bg-modal "
      style={{ backgroundColor: "black" }}
      onClick={() => {
        if (close) {
          props.handleClose();
          if (document.exitFullscreen && document.fullscreenElement) {
            document.exitFullscreen();
          }
          if (document.webkitCancelFullScreen && document.webkitIsFullScreen)
            document.webkitCancelFullScreen();
        } else close = true;
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          padding: 20,
          position: "relative",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          src={img}
          onClick={() => (close = false)}
          className={
            window.innerWidth > window.innerHeight
              ? "photo-a4-pc"
              : "photo-a4-mobile"
          }
          alt="Caricamento non riuscito"
        />
        {!(document.fullscreenElement || document.webkitIsFullScreen) &&
          !notRenderButton && (
            <CloseIcon
              style={{
                position: "absolute",
                right: 20,
                color: "white",
                filter: "drop-shadow(0px 0px 2px black)",
                top: 20,
                fontSize: 40,
                marginTop: window.innerWidth <= window.innerHeight && 30,
                // color: window.innerWidth <= window.innerHeight && "white",

                cursor: "pointer",
              }}
            />
          )}
        {!(document.fullscreenElement || document.webkitIsFullScreen) &&
          !notRenderButton && (
            <Fullscreen
              style={{
                position: "absolute",
                right: 60,
                color: "white",
                filter: "drop-shadow(0px 0px 2px black)",
                top: 20,
                fontSize: 40,
                marginTop: window.innerWidth <= window.innerHeight && 30,
                // color: window.innerWidth <= window.innerHeight && "white",

                cursor: "pointer",
              }}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                toggleFullScreen();
              }}
            />
          )}
      </div>
    </div>
  );
}
