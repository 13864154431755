import React, {Component, Fragment} from "react";
import withContext from "../../WithContextFile";
import {Redirect} from "react-router";

import "./Terms.css"

class Terms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: ""
        };
    }

    componentDidMount() {
    }

    render() {
        return !this.props.context.user ? (
            <Fragment>
                <div className={"terms-container"}>
                    <div className={"terms-inner-container"}>
                        <span className={'text-center'}>
                            &nbsp;
                            &nbsp;

                            <p>INFORMATIVA PRIVACY POLICY</p><p>
                            AGLI UTENTI del sito www.digitalmemorial.it</p><p>
                            in materia di protezione dei dati personali</p><p>
                            ai sensi dell’art. 13 del Regolamento U.E. 2016/679</p>
                        </span>
                        &nbsp;
                        <span className={'text-justify'}>
                            <p className={'text-justify'}>Gentili Utenti,</p>
                            premesso che la nostra Azienda fornisce servizi Web per condividere affetti verso Defunti
                            che hanno rappresentato Persone care nella nostra vita. Il Sito, previo registrazione,
                            consente di poter creare una Pagina Dedicata ed acquisirne il controllo e la gestione come
                            Responsabili della richiesta. Da questa pagina, i visitatori potranno inserire una dedica
                            che sarà monitorata da un Controllo di gestione per appurarne la lecita pubblicazione.
                            In osservanza a quanto previsto dall’art. 13 del Regolamento U.E. 2016/679 (il “GDPR”) ed in
                            applicazione dei principi posti dal GDPR medesimo, Vi invitiamo, prima di intraprendere la
                            navigazione del nostro sito web(il “Sito web"), a prendere visione della presente
                            informativa sul trattamento dei dati personali, al fine di renderVi consapevoli delle
                            caratteristiche e delle modalità del trattamento (il “Trattamento”) che andremo ad operare
                            rispetto a qualsiasi informazione da noi acquisita a seguito della navigazione da parte di
                            qualsiasi soggetto (l’“Utente”) sul Sito web, nonché da esso fornitaci attraverso il Sito
                            web medesimo e riguardanti una persona fisica (l’“Interessato”) identificata o
                            identificabile (i “Dati personali”). A norma dell’art. 4.1. GDPR, “si considera
                            identificabile la persona fisica che può essere identificata, direttamente o indirettamente,
                            con particolare riferimento a un identificativo come il nome, un numero di identificazione,
                            dati relativi all’ubicazione, un identificativo online o a uno o più elementi caratteristici
                            della sua identità fisica, fisiologica, genetica, psichica, economica, culturale o sociale”.
                        </span>
                        &nbsp;
                        <span className={'text-justify'}>
                            <p>1. Finalità del trattamento dei dati (le “Finalità”) e base giuridica</p>
                            I dati raccolti dal presente Sito web verranno trattati dal Titolare, secondo principi di
                            necessità, liceità, correttezza, proporzionalità e trasparenza, con le seguenti finalità:
                            <p>- permettere la fruizione del Sito web;</p><p>
                            - rispondere alle richieste dell’Utente (ivi incluse, a titolo esemplificativo, candidature
                            spontanee inviate dalla apposita sezione dedicata, ovvero mediante invio di e-mail,
                            compilazione degli appositi form e previa apposizione dei relativi flag richiesti);
                            - adempiere agli obblighi precontrattuali e contrattuali nei Vostri confronti;</p><p>
                            - adempiere ed esigere l’adempimento di specifici obblighi derivanti da leggi e
                            regolamenti.</p>
                        </span>
                        &nbsp;
                        <span className={'text-justify'}>
                            <p>Base giuridica del Trattamento:</p><p>
                            • necessità di dare esecuzione ad un contratto di cui l’Interessato è parte ovvero a misure
                            precontrattuali adottate su richiesta dello stesso;</p><p>
                            • necessità di adempiere un obbligo legale;</p><p>
                            • interesse legittimo del Titolare (art. 6 lett. f) GDPR).</p>
                        </span>
                        &nbsp;
                        <span className={'text-justify'}><p>
                            2. Modalità di rilascio dell’informativa e sue successive modifiche</p><p>
                            La presente informativa è resa esclusivamente con riferimento al Sito web e non anche con
                            riguardo altri siti web eventualmente consultati dall’Utente tramite link o ai quali si
                            accede
                            mediante social sharing button presenti sul Sito web, rispetto ai quali il Titolare non
                            assume
                            alcuna responsabilità.</p><p>
                            Qualsiasi modifica o aggiornamento alla presente informativa sarà disponibile per gli Utenti
                            nella apposita sezione del Sito web e si applicherà a far data dalla relativa pubblicazione.
                            Qualora l’Interessato non intenda accettare le eventuali modifiche, potrà interrompere
                            l’utilizzo del Sito web. Si invitano, pertanto, gli Interessati a consultare periodicamente
                            la
                            predetta sezione.
                        </p></span>
                        &nbsp;
                        <span className={"text-justify"}>
                            <p>
                                3. Trattamento di “categorie particolari di dati personali” e “di dati relativi a
                                condanne
                                penali” </p><p>

                            Ai sensi dell’art. 9 Regolamento U.E. n. 2016/679, a mero titolo esemplificativo, per
                            categorie
                            particolari di dati personali si intendono: i dati idonei a rivelare l’origine etnica e
                            razziale, i dati relativi allo stato di salute, all’adesione ad un sindacato e/o ad un
                            partito,
                            o, comunque, idonei a rivelare le convinzioni politiche e religiose, ovvero concernenti
                            l’esercizio di funzioni, attività o incarichi sindacali, così come i dati biometrici o
                            relativi
                            all’orientamento sessuale.
                        </p><p>
                            Ai sensi dell’art. 10 Regolamento U.E. n. 2016/679, a mero titolo esemplificativo, si
                            intendono
                            dati personali relativi a condanne penali, quelli relativi a condanne penali, reati e misure
                            di
                            sicurezza.
                        </p><p>
                            Tutti i dati riconducibili alle categorie di cui agli artt. 9 e 10 del Regolamento, relativi
                            sia
                            all’interessato vengono trattati esclusivamente per adempiere agli obblighi già indicati al
                            punto 1), fatta sempre salva la possibilità per l’interessato di esercitare i diritti di cui
                            agli artt. 15,16,17,18, 20 e 21 del Regolamento U.E. n. 2016/679 nelle modalità meglio
                            precisata
                            ai successivi punti.
                        </p></span>
                        &nbsp;
                        <span className={'text-justify'}>
                            <p>
                                4. Modalità del trattamento
                            </p><p>
                            Il Trattamento avviene con l’ausilio di strumenti elettronici e/o cartacei e, comunque,
                            adottando procedure e misure organizzative e informatiche idonee a tutelarne la sicurezza,
                            la
                            riservatezza, la pertinenza e la non eccedenza.
                        </p>
                        </span>
                        &nbsp;
                        <span className={'text-justify'}>

                            5. Natura obbligatoria o facoltativa del conferimento dei dati
                            <p>
                                La comunicazione da parte Vostra – anche mediante invio di e-mail, compilazione di
                                appositi form
                                e apposizione dei flag richiesti – dei Dati personali è facoltativa, ma necessaria, in
                                quanto
                                l’eventuale rifiuto al rilascio, così come l’errata comunicazione dei dati medesimi,
                                comporta
                                l’impossibilità per il Titolare di instaurare il rapporto o di dare attuazione alle
                                varie
                                Finalità per cui i Dati personali sono raccolti.
                            </p><p>
                            Per le stesse ragioni, oltre che al fine di una corretta gestione del rapporto in essere, Vi
                            chiediamo, altresì, di comunicarci eventuali variazioni dei Dati personali già raccolti, non
                            appena le stesse si siano verificate.
                        </p>
                        </span>
                        &nbsp;
                        <span className={'terms-owner'}>
                       <p>
                        6. Titolare del Trattamento
                       </p><p>
                            I.M.E. di Iacobucci Mimmo
                        </p><p>
                            Via delle Fratte, snc
                        </p><p>
                        Tel. e Fax.0874/461925
                        </p><p>
                            86018 Toro (CB)
                        </p><p>
                            Cell. 0039 338 7755636
                        </p><p>
                        C.F. CBCMMM 71S10 B519R
                        </p><p>
                        P.IVA 01494450701
                        </p>
                        </span>
                        &nbsp;
                        <p className={'text-justify'}>
                            In particolare, per ogni comunicazione e/o informazione inerente al trattamento dei dati
                            personali, il Titolare pone a disposizione il seguente indirizzo info@digitalmemorial.it,
                            nonché
                            l’indirizzo di posta elettronica certificata postacertificata@pec.ime-elettronica.it
                            Presso la sede legale del Titolare è disponibile un elenco aggiornato dei responsabili
                            esterni
                            incaricati al trattamento da porre a disposizione dell’interessato previa richiesta ai
                            suddetti
                            indirizzi.
                        </p>
                        &nbsp;
                        <span className={'text-justify'}>
                        <p>
                        7. Comunicazione e diffusione dei dati raccolti
                        </p><p>
                            I Dati personali sono trattati al nostro interno da soggetti autorizzati al Trattamento (gli
                        “Autorizzati”) sotto la responsabilità del Titolare per le Finalità sopra riportate.
                        </p><p>
                            I Dati personali potranno essere comunicati a soggetti a noi esterni, incaricati del compimento
                        di funzioni strumentali e/o accessorie allo svolgimento della nostra attività aziendale, i quali
                        tratteranno detti dati per nostro conto.
                        </p><p>
                        Tali soggetti saranno da noi nominati Responsabili Esterni del Trattamento (i “Responsabili
                        Esterni”), conformemente a quanto disposto dall’art. 28 GDPR. Presso la sede del Titolare è
                        disponibile un elenco aggiornato dei Responsabili Esterni, che sarà fornito all’Interessato
                        previa richiesta scritta ai suddetti recapiti.
                        </p><p>
                        Al di fuori dei casi che precedono, i Dati personali potranno, altresì, essere comunicati ad
                        ulteriori destinatari e/o categorie di destinatari (i “Destinatari” e le “Categorie di
                        Destinatari”), solo per l’espletamento delle attività inerenti al rapporto precontrattuale e/o
                        contrattuale tra noi instaurato e/o per adempiere ad obblighi di legge e/o ad ordini delle
                        Autorità, e comunque sempre nel rispetto delle garanzie previste dal GDPR e dalle linee guida
                        dell’Autorità Garante italiana, nonché dalla Commissione istituita in ottemperanza al predetto
                        GDPR.
                        </p><p>
                            Fatto salvo quanto precede, i Dati personali non saranno in alcun caso oggetto di diffusione e/o
                        comunicazione a terzi, salvo consenso specifico dell’Interessato e comunque sempre solo ove
                        necessario per l’espletamento delle Finalità.
                        </p>
                        </span>
                        &nbsp;
                        <span className={'text-justify'}>
                        <p>
                        8. Periodo di conservazione
                        </p><p>
                        I Dati personali verranno conservati dal Titolare per il periodo strettamente necessario al
                        perseguimento delle Finalità, ed in particolare fino alla cessazione dei rapporti
                        precontrattuali e contrattuali tra noi in essere, fatto salvo l’ulteriore periodo di
                        conservazione che potrà essere imposto da norme di legge.
                        </p><p>
                        Per quanto concerne i CV spontaneamente trasmessi, essi saranno conservati per un periodo non
                        superiore a 3 anni dall’invio, ovvero il diverso periodo massimo indicato dall’Autorità Garante
                        per la protezione dei dati personali.
                        </p><p>
                            Al fine di gestire eventuali contestazioni o contenziosi, e comunque per l’accertamento,
                        l’esercizio o la difesa di un diritto in sede giudiziaria, i Dati personali potranno essere
                        conservati per un ulteriore periodo, pari a quello di prescrizione del diritto medesimo.
                        </p>

                        </span>
                        &nbsp;
                        <span className={'text-justify'}>
                      <p>
                        9. Diritti degli interessati
                      </p><p>
                        L’Interessato, in ogni momento, potrà esercitare i diritti riconosciutigli dal GDPR (i “Diritti
                        dell’Interessato”), ed in particolare:
                        </p><p>
                            • Art. 15 – Diritto di accesso dell’interessato:l’Interessato ha il diritto di accedere ai
                        propri dati e ai relativi Trattamenti. Tale diritto si sostanza nella possibilità di ottenere la
                        conferma se sia o meno in corso un Trattamento dei propri Dati personali, ovvero nella
                        possibilità di richiedere e ricevere una copia dei dati oggetto di trattamento;
                        </p><p>
                        • Art. 16 – Diritto di rettifica: l’Interessato ha il diritto di ottenere dal Titolare la
                        rettifica dei Dati personali inesatti che lo riguardano senza ingiustificato ritardo. Tenuto
                        conto delle Finalità, l’Interessato ha il diritto di ottenere l’integrazione dei Dati personali
                        incompleti, anche fornendo una dichiarazione integrativa;
                        </p><p>
                            • Art. 17 – Diritto alla cancellazione («diritto all’oblio»): l’Interessato ha il diritto di
                        richiedere al Titolare che siano cancellati e non più sottoposti a Trattamento i Dati personali
                        che lo riguardano e in alcuni casi, ove ve ne siano gli estremi, di ottenere la cancellazione
                        senza ingiustificato ritardo quando è esaurita la finalità del Trattamento, è stato revocato il
                        consenso, è stata fatta opposizione al Trattamento o quando il Trattamento dei suoi Dati
                        personali non sia altrimenti conforme al GDPR;
                        </p><p>
                        • Art. 18 – Diritto di limitazione di trattamento: l’Interessato ha il diritto di limitare il
                        Trattamento dei propri Dati personali in caso di inesattezze, di contestazione o come misura
                        alternativa alla cancellazione;
                        </p><p>
                        • Art. 20 – Diritto alla portabilità dei dati: l’Interessato, ad eccezione dell’ipotesi in cui i
                        dati siano archiviati mediante trattamenti non automatizzati (es. in formato cartaceo), ha il
                        diritto di ricevere in un formato strutturato, di uso comune e leggibile da dispositivo
                        automatico i Dati personali che lo riguardano, ove si faccia riferimento a dati forniti
                        direttamente dall’Interessato, con espresso consenso o sulla base di un contratto, e di
                        richiedere che gli stessi siano trasmessi a un altro titolare del trattamento, se tecnicamente
                        fattibile;
                        </p><p>
                            • Art. 21 – Diritto di opposizione: l’Interessato ha il diritto di opporsi in qualsiasi momento,
                        per motivi connessi alla sua situazione particolare, al Trattamento dei Dati personali che lo
                        riguardano.
                        </p><p>
                            L’Interessato ove voglia esercitare uno dei diritti sopra elencati dovrà rivolgere la sua
                        richiesta direttamente al Titolare ai recapiti sopra indicati, salvo il diritto di proporre
                        reclamo da inviarsi all’Autorità Garante o di presentare ricorso avanti all’Autorità Giudiziaria
                        competente di Campobasso.
                        </p><p>
                        Il termine per la risposta all’Interessato da parte del Titolare è, per tutti i diritti
                        (compreso il diritto di accesso) ed anche in caso di diniego, 1 mese, estendibile fino a 3 mesi
                        in casi di particolare complessità.
                        </p><p>
                            Trova, comunque, applicazione l’art. 12 GDPR.
                        </p>
                        </span>
                        <p className={'text-justify'}>
                            10. Revoca del consenso
                            Nei casi in cui il Trattamento debba avvenire solo a seguito di consenso dell’Interessato e
                            quest’ultimo lo abbia fornito, egli ha il diritto di revocare il consenso prestato in
                            qualsiasi
                            momento mediante invio di richiesta scritta al Titolare ai recapiti sopra indicati.
                            La revoca del consenso non pregiudica la liceità del trattamento basata sul consenso
                            prestato
                            prima della revoca.
                        </p>
                        &nbsp;
                        <span className={'text-justify'}>
                            <p>
                        11. Minori
                        Il titolare non effettua alcuna operazione di trattamento dei dati personali di minorenni senza
                        il consenso dei genitori.
                            </p>  </span>
                        &nbsp;
                        <p className={'text-justify'}>
                            12. Dati trattati
                            Il Titolare raccoglie i dati liberamente forniti dall’Utente mediante la compilazione
                            volontaria
                            di form di contatto e/o mediante l’invio di e-mail o altri strumenti di comunicazione.
                            Mediante la navigazione del presente Sito web vengono acquisiti alcuni dati personali
                            relativi
                            all’uso di protocolli di comunicazione internet (“Dati di Navigazione”).
                        </p>
                        &nbsp;
                        <span className={'text-justify'}>
                        <p>

                    A titolo esemplificativo rientrano tra i Dati di Navigazione i seguenti dati:
                        </p><p>
                            • Indirizzi IP: Internet Protocol address (un’etichetta numerica che identifica univocamente un
                        dispositivo detto host collegato a una rete informatica che utilizza l’Internet Protocol come
                        protocollo di rete);
                        </p><p>
                            • i nomi a dominio dei computer utilizzati dagli utenti che si connettono al sito;
                        </p><p>
                        • gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse richieste;
                        </p><p>
                            • l’orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server;
                        </p><p>
                            • la dimensione del file ottenuto in risposta;
                        </p><p>
                            • il codice numerico indicante lo stato della risposta data dal server (buon fine, errore,
                        ecc.);
                        </p><p>
                            ed altri parametri relativi al sistema operativo e all’ambiente informatico dell’utente.
                        Tali dati sono acquisiti con l’esclusiva finalità di consentire la fruibilità del Sito web e
                        vengono raccolti in forma aggregata, ma potrebbero, attraverso elaborazioni ed associazioni con
                        dati detenuti da terzi, permettere di identificare gli utenti.
                        </p><p>
                            I dati di navigazione vengono utilizzati per finalità statistiche sull’uso del Sito web e per
                        controllare il corretto funzionamento del medesimo.
                        </p><p>
                            In particolare si specifica che non vengono usati cookie con scopo di profilazione, ma solo
                        cookie tecnici, con lo scopo di migliorare la qualità della navigazione dell’utente all’interno
                        del sito in questione (Privacy Cookies).
                        </p>
                        </span>
                        &nbsp;
                    </div>
                </div>
            </Fragment>
        ) : (
            <Redirect to="/products"/>
        );
    }
}

export default withContext(Terms);
