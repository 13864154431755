import React, { Component, Fragment } from 'react';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import requests from '../../../Services/HttpServices';

class ContactVerify extends Component {
	constructor(props) {
		super(props);
		this.state = {
			username: '',
			password: ''
		};
	}

	render() {
		const { search } = window.location;
		const params = new URLSearchParams(search);
		const foo = params.get('token');
		let verified = null;
		if (verified === null && !this.state.loaded && !this.state.load) {
			this.setState({ load: true });
			requests('get', 'verifyToken', foo).then(data => {
				verified = data.data.result == 'success';
				this.setState({ loaded: true, verified });
			});
			// verified = foo == 'token1'
		}
		return (
			<>
				{this.state.loaded && (
					<div>
						{this.state.verified ? (
							<div
								style={{
									height: window.innerHeight - 250,
									textAlign: 'center'
								}}
							>
								<p
									className="death-data"
									style={{
										alignSelf: 'center',
										marginTop: 20,
										marginBottom: 20,
										textAlign: 'center'
									}}
								>
									Contatto verificato
								</p>
								<p style={{ textAlign: 'center' }}>
									{' '}
									La vostra pagina verra' caricata automaticamente, grazie per averci scelto
								</p>
							</div>
						) : (
							<div style={{ height: window.innerHeight - 250 }}>
								<p
									className="death-data"
									style={{
										alignSelf: 'center',
										textAlign: 'center',
										marginTop: 20,
										marginBottom: 20
									}}
								>
									Attenzione!
								</p>
								<p style={{ marginBottom: 20, textAlign: 'center' }}>
									Il codice di verifica da lei utilizzato non è più valido
								</p>
								<label className="technic-support" style={{ paddingTop: 20, textAlign: 'center' }}>
									<p>Per un eventuale supporto tecnico è possibile contattare</p>
									<p>
										{' '}
										il numero telefonico <strong>389 89 96 166</strong>
									</p>
									<p style={{ textAlign: 'center', marginTop: 12 }}>
										<b>
											<p>DAL LUNEDÌ AL VENERDÌ</p>
											<p> DALLE ORE 09:00 ALLE 13:00 E</p>
											<p>DALLE 15:00 ALLE 18:00</p>
										</b>
									</p>
								</label>
							</div>
						)}
					</div>
				)}
			</>
		);
	}
}

export default ContactVerify;
