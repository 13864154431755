import React, { useEffect, useState } from "react";
import {
  ArrowDropDown,
  ArrowDropUp,
  RemoveCircleOutline,
  Repeat,
} from "@material-ui/icons";
import { Collapse, Tooltip, Typography } from "@material-ui/core";
import { Spinner } from "react-activity";

export default function FileNamesComponent(props) {
  const [clientX, setClientX] = useState(null);
  const [clientY, setClientY] = useState(null);
  const [files, setFiles] = useState([]);
  const [imagePreview, setImagePreview] = useState(false);
  const [load, setLoad] = useState(false);
  const reload = () => {
    setTimeout(() => setLoad(!load), 200);
  };
  const [removed, setRemoved] = useState([]);
  const [removedSection, setRemovedSection] = useState(false);
  useEffect(() => {
    props.setRemoved && props.setRemoved(removed);
  }, [removed.length]);
  useEffect(() => {
    const temp = props.files;
    let counter = 0;
    props.files.map((e, i) => {
      if (!temp[i].url)
        readURL(e.image).then((result) => {
          counter++;
          temp[i].url = result;
          if (i === props.files.length - 1) {
            setFiles(temp);
            reload();
          }
          reload();
        });
      else {
        setFiles(temp);
        reload();
      }
    });
  }, [props.files.length]);
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        className="files-name-div"
        onMouseLeave={() => {
          setClientX(null);
          setClientY(null);
          setImagePreview(null);
        }}
      >
        {/* {imagePreview && clientX && clientY &&
            <div className={'modalPreview'} style={{position: "fixed", left: clientX + 20, top: clientY + 15}}><img
                src={imagePreview} alt={'.'}/></div>} */}
        {files.map((e, i) => (
          <div
            onMouseMove={(ev) => {
              setClientX(ev.clientX);
              setClientY(ev.clientY);
              setImagePreview(e.url);
            }}
            className="files-name-column"
          >
            <div className="file-name-div ">
              <RemoveCircleOutline
                onClick={(event) => {
                  event.preventDefault();
                  const temp = files;
                  if (
                    props.modifyMemorial &&
                    temp[i].url.indexOf("data:image/") < 0
                  ) {
                    const remTemp = removed;
                    remTemp.push(temp[i]);
                    setRemoved(remTemp);
                  }
                  props.setFileIndex(temp, i);
                  document.getElementById(
                    `${props.name}-file${temp[i].id}`
                  ).value = null;

                  temp.splice(i, 1);
                  props.setFile(temp);
                }}
                className="remove-file-icon"
              />
              {e.url ? (
                <img
                  className="image-preview"
                  style={{
                    width: "auto",
                    maxWidth: "calc(100% - 48px)",
                    maxHeight: 200,
                    height: "auto",
                  }}
                  src={e.url}
                  alt="cannot load"
                />
              ) : (
                <div style={{ margin: "auto" }}>
                  <Spinner />
                </div>
              )}
              {/* <Typography aria-label={e.file} className={'file-name-label'}>{e.file}</Typography></div> */}
            </div>
          </div>
        ))}
      </div>
      {removed.length > 0 && (
        <label
          className="is-flex"
          style={{
            alignItems: "center",
            textAlign: "center",
            width: "100%",
            cursor: "pointer",
            justifyContent: "center",
            marginTop: 16,
          }}
          onClick={() => setRemovedSection(!removedSection)}
        >
          Immagini rimosse{removedSection ? <ArrowDropUp /> : <ArrowDropDown />}
        </label>
      )}

      <Collapse in={removedSection}>
        <div className="files-name-div">
          {removed.map((e, i) => (
            <div className="files-name-column">
              <div className="file-name-div ">
                <Tooltip title="Ripristina">
                  <Repeat
                    style={{ color: "green" }}
                    onClick={(event) => {
                      if (files.length < 10) {
                        const remTemp = removed;
                        props.addFileIndex(remTemp.splice(i, 1)[0]);
                        setRemoved(remTemp);
                        reload();
                      } else alert("hai gia' caricato 10 immagini");
                    }}
                    className="remove-file-icon"
                  />
                </Tooltip>
                {e.url ? (
                  <img
                    className="image-preview"
                    style={{
                      width: "auto",
                      maxWidth: "calc(100% - 48px)",
                      maxHeight: 200,
                      height: "auto",
                    }}
                    src={e.url}
                    alt="cannot load"
                  />
                ) : (
                  <div style={{ margin: "auto" }}>
                    <Spinner />
                  </div>
                )}
                {/* <Typography aria-label={e.file} className={'file-name-label'}>{e.file}</Typography></div> */}
              </div>
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  );
}
export const readURL = (file) => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onload = (e) => res(e.target.result);
    reader.onerror = (e) => rej(e);
    reader.readAsDataURL(file);
  });
};
