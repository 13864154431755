/* eslint-disable react/no-access-state-in-setstate,jsx-a11y/label-has-associated-control,react/no-unused-state,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { Component, Fragment } from "react";
import { Redirect } from "react-router";
import "./Memorial-activate.css";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Axios from "axios";
import { Spinner } from "react-activity";
import { Button, Typography } from "@material-ui/core";
import listner from "reactjs-eventemitter";
import { Link } from "react-router-dom";
import UserFormData from "../FormComponents/userFormData";
import AddressService, { buildCap } from "../FormComponents/AddressComponent";
import addressServiceGetData from "../FormComponents/addressService";
import ContactPreferenceForm from "../FormComponents/ContactPreferencesForm";
import FormLabel from "../FormComponents/TermFormComponents";
import FileNamesComponent from "../FormComponents/FileNamesComponent";
import FileUpload10 from "../FormComponents/FileUpload10";
import VideoInput from "../FormComponents/VideoInput";
import ExistsModal from "../FormComponents/ExistsModal";
import SearchBarAccount from "./SearchBarAccount";
import PaymentModal from "../PaymentModal/PaymentModal";
import CkEditorCustom from "../FormComponents/CkEditor";
import AcceptBuyTerms from "../FormComponents/AcceptBuyTerms";
import withContext from "../../WithContextFile";
import requests, { baseURL } from "../../Services/HttpServices";
import validate from "../../formValidationService";

let memorialType;
let closeModalFixed;
const fileIndex = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
let payType     = "newActivation";
const payID     = "AVfuTYt59be7cdzYndbKA-64V-L1ALSzzwX3FRfI39COtKTP7SfOwkmrVfS2KAVTMwSGHbGDY6MNrkiF";
let globalData  = {};

class MemorialActivate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			files         : [],
			loggedUser    : null,
			radioValue    : "",
			fileError     : false,
			checkBox      : "",
			memorialID    : "",
			buyTermsError : false,
			buyTerms      : false,
			form          : {
				name        : "",
				surname     : "",
				CF          : "",
				phone       : "",
				city        : "",
				province    : "",
				route       : "",
				civic       : "",
				region      : "",
				email       : "",
				nameTo      : "",
				surnameTo   : "",
				qrCodeN     : "",
				cap         : "",
				secureCode  : "",
				nationality : "105",
				biografic   : "",
			},
			formError: {},
		};
	}

	componentDidMount() {
		[...document.getElementById("appBody").children].forEach((e) => {
			if (
				e.src?.indexOf("paypal") >= 0
				&& e.src
				!== "https://www.paypal.com/sdk/js?client-id=AVfuTYt59be7cdzYndbKA-64V-L1ALSzzwX3FRfI39COtKTP7SfOwkmrVfS2KAVTMwSGHbGDY6MNrkiF&currency=EUR"
			) {
				const temp = e;
				console.log("trovato lo script adesso lo levo");
				document.getElementById("appBody")
					.removeChild(e);
				temp.src = "https://www.paypal.com/sdk/js?client-id=AVfuTYt59be7cdzYndbKA-64V-L1ALSzzwX3FRfI39COtKTP7SfOwkmrVfS2KAVTMwSGHbGDY6MNrkiF&currency=EUR";
				document.getElementById("appBody")
					.append(temp);
			}
		});
		memorialType           = this.props.history.location
			&& this.props.history.location.search.slice(1, this.props.history.location.search.length);
		const { search }       = window.location;
		const params           = new URLSearchParams(search);
		const deceasedSelected = params.get("deceasedSelected");
		if (deceasedSelected) {
			this.setState({
				defaultValue: deceasedSelected,
			});
			const tempForm   = this.state.form;
			tempForm.qrCodeN = deceasedSelected;
			this.setState({ form: tempForm });
		}
		listner.events.upload3 = [];
		listner.events.upload3.length < 1
			&& listner.subscribe("upload3", (callback) => {
				this.setState({ percentage: callback });
			});
		/* servizio-memorial */
		requests("get", "getProductPrice", "servizio-memorial").then((data) => {
			if (data.data.product_details) {
				this.setState({ mPrice: data.data.product_details.price });
			}
		});
	}

	setInitialData() {
		const temp            = this.state.form;
		temp.username         = this.state.loggedUser.username;
		temp.name             = this.state.loggedUser.name;
		temp.surname          = this.state.loggedUser.surname || "Cognome";
		temp.email            = this.state.loggedUser.email;
		temp.phone            = this.state.loggedUser.telephone;
		temp.CF               = this.state.loggedUser.cf;
		temp.route            = this.state.userAddress.address;
		temp.civic            = this.state.userAddress.number;
		temp.city             = this.state.userAddress.city;
		temp.province         = this.state.userAddress.province;
		temp.cap              = this.state.userAddress.cap;
		temp.region           = this.state.userAddress.region;
		temp.nationality      = this.state.userAddress.nationality;
		temp.complete_address = this.state.userAddress.complete_address;
		let radioContact;
		try {
			radioContact = this.state.loggedUser.preferred_contact.type || "wa";
		} catch (e) {
			radioContact = "wa";
		}

		this.setState({ form: temp, radioValue: radioContact });
	}

	render() {
		memorialType =			this.props.history.location
			&& this.props.history.location.search.slice(1, this.props.history.location.search.length);
		// eslint-disable-next-line eqeqeq
		memorialType = this.state.memorialID != 0 ? "rinnovo" : "newActivation";
		if (memorialType === "rinnovo" || memorialType === "rinnovos") {
			payType = "renewal";
		} else {
			if (this.state.checkBox2) this.setState({ checkBox2: false });
			payType = "newActivation";
		}
		try {
			if (!this.state.loggedUser && sessionStorage.getItem("User")) {
				this.setState({
					loggedUser: JSON.parse(sessionStorage.getItem("User")).userdata,
				});
				this.setState({
					userAddress: JSON.parse(sessionStorage.getItem("UserAddress")),
				});
			}
		} catch (e) {}

		if (this.state.loggedUser && this.state.form.name == "") {
			this.setInitialData();
			this.state.userAddress
				&& addressServiceGetData("province", this.state.userAddress.region).then((resolve) => {
					this.setState({ provincie: resolve });
					addressServiceGetData("location", this.state.userAddress.province).then((resolve) => {
						const locSelected = resolve.find(
							(x) => x.ID === (this.state.form.location || this.state.form.city),
						);
						let capArr        = [];
						if (locSelected.capList) capArr = buildCap(locSelected);
						capArr.unshift(locSelected.cap);
						this.setState({ comuni: resolve, caps: capArr });
					});
				});
		}
		return !this.props.context.user ? (
			<>
				{this.state.uploading && (
					<div className="div-upload">
						<Spinner size={100} />
						<Typography className="label-upload">
							Attendi il caricamento dei files
							{" "}
							{parseInt(parseFloat(this.state.percentage) * 100, 10)}
							%
						</Typography>
					</div>
				)}
				{this.state.openModalPhoto && this.fileMissingModal(globalData)}
				<div className="contact-container overflow-x-hidden">
					<div className="memorial-container memorial-container-activate">
						&nbsp;
						<label className="activate-title"> Attiva Memorial</label>
						&nbsp;
						<label className="memorial-activate-subtitle">
							<p>
								Questo servizio è subordinato all’attivazione di una lapide digitale ed è attivabile
								solo dal proprietario della stessa
							</p>
							&nbsp;
							<p>
								Con l'acquisto di questo servizio potrai inserire nella parte iniziale della pagina del
								defunto 10 foto, 1 video, 1 biografia al costo totale di €
								{parseFloat(this.state.mPrice || "6")
									.toFixed(2)
									.replace(".", ",") || "6,00"}
								; tale servizio avrà durata di 1 anno ed eventualmente potrà essere rinnovato.
							</p>
						</label>
						&nbsp;
						<div style={{ position: "relative" }} className="activate-form-div">
							<label className="death-data">Dati del Proprietario della lapide digitale</label>
							<form
								id="#form"
								className="activate-form"
								onSubmit={(event) => {
									event.preventDefault();
									const formData = new FormData(event.target);
									const formKeys = formData.keys();
									if (this.state.extraData) {
										formData.set("purchase-shipping-flag", 1);
									}
									const object = {};
									formData.forEach((value, key) => {
										object[key] = value;
									});
									this.send(formData);
								}}
							>
								<UserFormData
									partnerModify={this.state.loggedUser?.platformAccess == "2"}
									nationalityChange={() => {
										this.setState({ reload: true });
									}}
									load={() => {
										this.setState({ load: true });
									}}
									isAccount={!!this.state.loggedUser}
									openModalEmail={(data) => {
										this.setState({ existModal: true, modalname: data });
									}}
									names="memorial-activation"
									form={this.state.form}
									formError={this.state.formError}
									disable={this.state.loggedUser !== null}
								/>
								{this.state.existModal /* this.existsModal() */ && (
									<ExistsModal
										username={this.state.modalname}
										email={this.state.form.email}
										deniedModal={() => {
											this.setState({ existModal: false });
											const temp = this.state.form;
											// temp.CF = '';
											// temp.email = '';
											temp.username = "";
											this.setState({ form: temp });
										}}
										label={
											"Un account per l' indirizzo email inserito e` gia` esistente, si prega di inserire una password"
										}
									/>
								)}
								<label className="buy-form-paragraph">Dati indirizzo</label>
								<AddressService
									nationality={this.state.form.nationality}
									names="memorial-activation"
									form={this.state.form}
									formError={this.state.formError}
									province={this.state.provincie}
									comuni={this.state.comuni}
									caps={this.state.caps}
								/>
								<label className="death-data">Dati Lapide Digitale</label>
								<SearchBarAccount
									defaultValue={this.state.defaultValue}
									emailAccount={!this.state.formError.email && this.state.form.email}
									owner={this.state.loggedUser && this.state.loggedUser.user_id}
									setData={(selected) => {
										if (selected) {
											this.setState({ selected });
											const temp          = this.state.form;
											const { formError } = this.state;
											temp.nameTo         = selected.name;
											formError.nameTo    = false;
											formError.surnameTo = false;
											formError.qrCodeN   = false;

											temp.surnameTo = selected.surname;
											if (selected.ID) temp.qrCodeN = `000${selected.ID}`;
											this.setState({
												form       : temp,
												formError,
												memorialID : selected.memorialID,
											});
										} else {
											this.setState({ selected });
											const temp          = this.state.form;
											const { formError } = this.state;
											temp.nameTo         = "";
											temp.qrCodeN        = "";
											temp.surnameTo      = "";
											formError.nameTo    = true;
											formError.qrCodeN   = true;
											formError.surnameTo = true;
											this.setState({ form: temp, formError });
										}
									}}
								/>
								{this.state.formError.nameTo && !this.state.form.nameTo && (
									<label style={{ color: "red" }}>Scegli un defunto per proseguire</label>
								)}
								{!this.state.loggedUser && (
									<div className="no-register-overlay">
										<Link to="/login">Effettua l'accesso per continuare</Link>
									</div>
								)}
								<TextField
									className="activate-field"
									name="deceased-name"
									label="Nome del Defunto *"
									value={this.state.form.nameTo}
									style={{ display: "none" }}
									onChange={(event) => {
										event.preventDefault();
										// this.setAndValidateForm(event.target.value, 'nameTo')
									}}
									helperText={this.state.formError.nameTo ? "Campo obbligatorio" : ""}
									error={this.state.formError.nameTo}
								/>
								<TextField
									className="activate-field"
									name="deceased-surname"
									label="Cognome del Defunto *"
									style={{ display: "none" }}
									value={this.state.form.surnameTo}
									onChange={(event) => {
										event.preventDefault();
										// this.setAndValidateForm(event.target.value, 'surnameTo')
									}}
									helperText={this.state.formError.surnameTo ? "Campo obbligatorio" : ""}
									error={this.state.formError.surnameTo}
								/>
								<TextField
									className="activate-field"
									label="Codice qrCode *"
									name="deceased-qrCode"
									style={{ display: "none" }}
									onChange={(event) => {
										// this.setAndValidateForm(event.target.value, 'qrCodeN')
									}}
									value={this.state.form.qrCodeN}
									helperText={this.state.formError.qrCodeN ? "Campo obbligatorio" : ""}
									error={this.state.formError.qrCodeN}
								/>
								<TextField
									className="activate-field"
									label="Codice segreto defunto *"
									name="deceased-secureCode"
									style={{ display: this.state.loggedUser && "none" }}
									disabled={!!this.state.loggedUser}
									onChange={(event) => {
										this.setAndValidateForm(event.target.value, "secureCode");
									}}
									value={this.state.form.secureCode}
									helperText={this.state.formError.secureCode ? "Campo obbligatorio" : ""}
									error={this.state.formError.secureCode}
								/>
								<input style={{ display: "none" }} name="csrf_token" value={this.state.csrf_token} />
								{memorialType !== "newActivation" && (
									<FormControlLabel
										control={(
											<Checkbox
												color="primary"
												name="checkedC"
												id="term-box"
												onChange={(event, checked) => this.setState({ checkBox2: checked })}
											/>
										)}
										label="Sostituisci materiale"
									/>
								)}
								{(memorialType !== "rinnovo" || this.state.checkBox2) && (
									<div>
										<CkEditorCustom
											title="Biografia defunto"
											onChange={(editor) => {
												const temp     = this.state.form;
												temp.biografic = editor.getData();
												this.setState({ form: temp });
											}}
											data={this.state.form.biografic}
											// error={this.state.formError.biografic}
										/>
										<TextField
											className="text-area"
											id="outlined-multiline-static"
											name="deceased-bio"
											style={{ display: "none" }}
											value={this.state.form.biografic}
											label="Biografia"
											multiline
											rows={8}
											variant="outlined"
											onChange={(event) => {
												this.setAndValidateForm(event.target.value, "biografic");
											}}
											// helperText={this.state.formError.biografic ? "Campo obbligatorio" : ""}
											// error={this.state.formError.biografic}
										/>
									</div>
								)}
								{/* File Uploaddd */}
								{(memorialType !== "rinnovo" || this.state.checkBox2) && (
									<div>
										<FileUpload10
											setFileIndex={() => {
												fileIndex.shift();
											}}
											setFile={(temp) => this.setState({ files: temp })}
											name="memorial"
											files={this.state.files}
										/>
										<div className="file-upload">
											<label
												className="pointer"
												htmlFor={`memorial-file${fileIndex[0]}`}
												color="blue"
												title=""
											>
												<p className="pointer-label">
													{this.state.files.length + 1 > 10
														? "Tutti i file caricati"
														: `Carica immagine ${this.state.files.length + 1} di 10`
														  + " (Max 15MB per foto)"}
												</p>
											</label>
										</div>
										<FileNamesComponent
											name="memorial"
											files={this.state.files}
											setFile={(temp) => this.setState({ files: temp })}
											setFileIndex={(temp, i) => fileIndex.unshift(temp[i].id)}
										/>
									</div>
								)}
								{(memorialType !== "rinnovo" || this.state.checkBox2) && (
									<div>
										<div className="file-upload">
											<label
												className="pointer"
												htmlFor="memorial-video-upload"
												color="blue"
												title=""
											>
												<p className="pointer-label">
													{" "}
													{this.state.fileVideoName || "Carica Video (Max 15MB)"}
												</p>
											</label>
										</div>
									</div>
								)}
								&nbsp;
								<VideoInput
									name="memorial"
									setVideoName={(videoName) => {
										this.setState({ fileVideoName: videoName });
									}}
								/>
								<ContactPreferenceForm
									email={this.state.form.email}
									phone={this.state.form.phone}
									handler={(event, value) => {
										this.setState({ radioValue: value });
									}}
									logged={this.state.loggedUser !== null}
									value={this.state.radioValue}
									error={this.state.radioError}
									label="Per eventuali comunicazioni"
								/>
								&nbsp;
								<FormControlLabel
									className="activate-checkbox"
									control={(
										<Checkbox
											color="primary"
											name="checkedC"
											id="term-box"
											onChange={(event, checked) => this.setState({ checkBox: checked })}
										/>
									)}
									label={FormLabel()}
								/>
								{!this.state.checkBox && this.state.termsError && (
									<label
										style={{
											color      : "red",
											textAlign  : "left",
											margin     : 10,
											marginLeft : 30,
										}}
									>
										Devi accettare i termini
									</label>
								)}
								<AcceptBuyTerms
									checkBox={this.state.buyTerms}
									termsError={this.state.buyTermsError}
									setChecked={(c) => {
										this.setState({ buyTerms: c });
									}}
								/>
								<div className="bottom-activate-form">
									<label>
										L'ATTIVAZIONE DI QUESTO SERVIZIO AVVERRA' SOLO DOPO LA CONFERMA DEI DATI VIA
										<b> E-MAIL</b>
										{" "}
										O
										<b>WHATSAPP</b>
									</label>
									<button
										type="submit"
										onClick={() => {
											// this.send()
										}}
										className="activate-submit"
									>
										Procedi
									</button>
									{/* <button type={"submit"} onClick={(e) => { */}
									{/*    e.preventDefault() */}
									{/*    this.setState({paymentModal:true}) */}
									{/* }} className={'activate-submit'}>Temp */}
									{/* </button> */}
								</div>
							</form>
						</div>
						<label style={{ marginTop: 10, marginBottom: 10 }} className="technic-support">
							Per un eventuale supporto tecnico è possibile contattare il numero telefonico 389 89 96 166
							<p>
								<b>DAL LUNEDÌ AL VENERDÌ DALLE ORE 09:00 ALLE 13:00 E DALLE 15:00 ALLE 18:00</b>
							</p>
						</label>
					</div>
				</div>
				{this.state.paymentModal && (
					<div
						onClick={() => {
							if (!closeModalFixed) {
								this.state.paymentNotError
									? this.setState({ modal: false })
									: this.props.history.push({
										pathname : "/paypal-error",
										query    : { orderId: this.state.orderId, type: "memorial" },
									  });
							} else closeModalFixed = false;
						}}
						className="modalPurchase"
					>
						<div
							onClick={() => {
								closeModalFixed = true;
							}}
						>
							<PaymentModal
								paymentType="memorial"
								setError={() => this.setState({ paymentNotError: true })}
								shippingAddress={{
									address  : this.state.form.route,
									civic    : this.state.form.civic,
									city     : this.state.form.city,
									region   : this.state.form.region,
									province : this.state.form.province,
									cap      : this.state.form.cap,
								}}
								memorialType={payType}
								deceasedId={parseInt(`${this.state.form.qrCodeN}`, 10)}
								price={this.state.mPrice || 6}
								showAddress={false}
								successComponent={(a, b, history) => {
									setTimeout(() => {
										history.push({
											pathname : "Conferma",
											query    : { isMemorial: true },
										});
									}, 200);
								}}
								// isRegistering={this.state.register}
								// isAccount={!!this.state.loggedUser}
								taxAddress={this.state.fatturazione}
								userData={{
									name    : this.state.form.name,
									surname : this.state.form.surname,
									CF      : this.state.form.CF,
									email   : this.state.form.email,
								}}
								orderId={this.state.orderId}
								quantity={1}
								purchase="Servizio memorial"
							/>
						</div>
					</div>
				)}
			</>
		) : (
			<Redirect to="/products" />
		);
	}

	send(formData) {
		let { formError } = this.state;
		let error         = false;
		if (this.state.loggedUser) {
			const { form }  = this.state;
			form.secureCode = "true";
			this.setState({ form });
		}
		for (const property in this.state.form) {
			if (this.state.loggedUser && property === "email") {
			} else if (
				(property !== "phone" || (this.state.form.phone && this.state.form.phone.length >= 9))
				&& (property !== "email" || this.state.form.email !== "")
				&& (property !== "CF" || this.state.form.nationality == "105")
			) {
				if (
					!(property === "address" || property === "location" || property === "complete_address")
					|| this.state.form.nationality != "105"
				) {
					formError = validate(this.state.form[property], property, formError);
					if (this.state.loggedUser?.platformAccess == "2") {
						formError.CF = this.state.form.CF !== "" && this.state.form.CF.length !== 16;
					}
					if (property !== "biografic") {
						error = error || formError[property];
					}
				}
			}
		}
		this.setState({ formError });
		if (!this.state.radioValue) {
			this.setState({ radioError: true });
		}

		if (!this.state.checkBox) {
			this.setState({ termsError: true });
			error = true;
		}
		formData.set("memorialType", payType);
		let fileError;
		if (memorialType !== "rinnovo" || this.state.checkBox2) {
			fileError = !this.state.fileVideoName && this.state.files.length < 1;
			this.setState({ fileError });
			// error = error || fileError
		}
		// formData.set('deceasedID', parseInt(this.state.form.qrCodeN+''))
		if (!this.state.buyTerms) {
			error = true;
			this.setState({ buyTermsError: true });
		}
		if (!error) {
			globalData = formData;
			fileError ? this.setState({ openModalPhoto: true }) : this.sendData(formData);
		}
		// error && alert('errore campi non validi')
	}

	fileMissingModal(formdata) {
		let close2 = true;
		return (
			<div
				className="modal-bg"
				onClick={() => {
					if (close2) {
						this.setState({ openModalPhoto: false });
						// this.props.history.push('admin-panel')
					} else close2 = !close2;
				}}
			>
				<div
					className="inner"
					onClick={() => {
						close2 = false;
					}}
				>
					<Typography style={{ fontSize: 17 }}>Attenzione!!</Typography>
					<Typography style={{ fontSize: 12, marginTop: 15 }}>
						Non hai caricato nessun media, desideri continuare?
					</Typography>
					<div
						style={{
							flexDirection  : "row",
							display        : "flex",
							justifyContent : "space-between",
						}}
					>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								this.setState({ openModalPhoto: false });
								this.sendData(globalData);
							}}
						>
							SI
						</Button>
						<Button
							color="primary"
							style={{ marginLeft: 20 }}
							onClick={() => {
								this.setState({ openModalPhoto: false });
								// this.props.history.push('admin-panel')
							}}
							variant="contained"
						>
							NO
						</Button>
					</div>
				</div>
			</div>
		);
	}

	sendData(formData) {
		if (!this.state.form.phone) {
			formData.delete("phone");
			formData.delete("memorial-activation-telephone");
		}
		getCsrf().then((csrf) => {
			this.setState({ csrf_token: csrf });
			formData.set("csrf_token", csrf);
			this.setState({ uploading: true });
			requests("sendform", "memorial", formData).then((data) => {
				this.setState({ uploading: false });
				if (data.response === "success") {
					this.setState({ paymentModal: true });
					this.setState({ orderId: data.orderid });
				} else alert(data.message);
			});
		});
	}

	setAndValidateForm(data, attribute) {
		const { form }    = this.state;
		let { formError } = this.state;
		form[attribute]   = data;
		this.setState({ form });
		formError = validate(data, attribute, formError);
		this.setState({ formError });
	}
}

function getCsrf() {
	return new Promise((result, err) => Axios.get(`${baseURL}api/auth/securitytoken`)
			.then((resolve) => {
				if (resolve != null) {
					result(resolve.data._csrf);
				} else result({ DATA: "NO DATA" });
			})
			.catch((err) => result({ err })));
}

export default withContext(MemorialActivate);
