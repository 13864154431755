import React, { useState } from "react";
import "./modalP.scss";
import { Typography } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import Collapse from "@material-ui/core/Collapse";
import { Levels } from "react-activity";
import { useHistory } from "react-router";
import ViewAddress from "./ViewAddress";
import PaypalBtn from "../../Services/PaypalBtn";
import UserData from "./userData";
import requests from "../../Services/HttpServices";
import "react-activity/dist/react-activity.css";

const defaultID = "AVfuTYt59be7cdzYndbKA-64V-L1ALSzzwX3FRfI39COtKTP7SfOwkmrVfS2KAVTMwSGHbGDY6MNrkiF";
export default function ReactPayPal(props) {
	let taxAddress;
	let taxAddressNationalityFlag               = false;
	let shippingNationalityFlag                 = false;
	const history                               = useHistory();
	const [price, setPrice]                     = useState(null);
	const [label, setLabel]                     = useState(null);
	const [loading, setLoading]                 = useState(null);
	const [buttonClicked, setButtonClicked]     = useState(null);
	const [success, setSuccess]                 = useState(null);
	const [addressVisible, setAddressVisible]   = useState(false);
	const [userDataVisible, setUserDataVisible] = useState(false);
	const [dataVisible, setDataVisible]         = useState(false);

	!price && setPrice(props.price);
	!label && setLabel("QrCode + Pagina Ricordo + Spedizione\n Quantità: 1");
	if (props.taxAddress && props.taxAddress.city !== "") taxAddress = props.taxAddress;
	else taxAddress = props.shippingAddress;

	if (
		props.shippingAddress
		&& props.shippingAddress.address !== ""
		&& props.shippingAddress.address
		&& props.shippingAddress.address === props.shippingAddress.civic
	) {
		shippingNationalityFlag = true;
	}
	if (taxAddress && taxAddress.address !== "" && taxAddress.address && taxAddress.address === taxAddress.civic) {
		taxAddressNationalityFlag = true;
	}
	return (
		<div className="payment-main-modal">
			{!loading ? (
				<div>
					<Typography className="header">Riepilogo ordine</Typography>
					<div id="purchase-detail">
						<div id="purchase-description">
							<Typography style={{ textTransform: "capitalize" }}>{props.purchase}</Typography>
							<Typography>
								Quantità:
								{props.quantity}
							</Typography>
						</div>
						<div id="price">
							<Typography>
								{parseFloat(price || "1")
									.toFixed(2)
									.replace(".", ",")}
								€
							</Typography>
						</div>
					</div>
					<div id="collapsable-container" style={{ display: "flex", flexDirection: "column" }}>
						<div
							onClick={() => {
								setUserDataVisible(!userDataVisible);
							}}
							className="collapsable-list"
						>
							<div
								style={{
									display       : "flex",
									flexDirection : "row",
									margin        : "auto",
								}}
							>
								<label style={{ margin: "auto", paddingLeft: 24 }}>Dati utente</label>
								<div>{userDataVisible ? <ExpandLess /> : <ExpandMore />}</div>
							</div>
						</div>

						<Collapse in={userDataVisible} timeout="auto" unmountOnExit>
							<div style={{ display: "flex", justifyContent: "center" }}>
								<UserData userData={props.userData} />
							</div>
						</Collapse>
					</div>
					{props.showAddress && (
						<div id="collapsable-container" style={{ display: "flex", flexDirection: "column" }}>
							<div
								onClick={() => {
									setAddressVisible(!addressVisible);
								}}
								className="collapsable-list"
							>
								<div
									style={{
										display       : "flex",
										flexDirection : "row",
										margin        : "auto",
									}}
								>
									<label style={{ margin: "auto", paddingLeft: 24 }}>
										Dati indirizzo (spedizione)
									</label>
									<div>{addressVisible ? <ExpandLess /> : <ExpandMore />}</div>
								</div>
							</div>

							<Collapse in={addressVisible} timeout="auto" unmountOnExit>
								<div
									style={{
										display        : "flex",
										justifyContent : "center",
									}}
								>
									{shippingNationalityFlag ? (
										<div>
											{" "}
											<Typography className="p-y-10">
												<strong>Indirizzo:</strong>
												{` ${props.shippingAddress.address}`}
											</Typography>
										</div>
									) : (
										<ViewAddress noSearch={props.noSearch} address={props.shippingAddress} />
									)}
								</div>
							</Collapse>
						</div>
					)}
					<div
						id="collapsable-container"
						className="mb-40"
						style={{
							display       : "flex",
							flexDirection : "column",
						}}
					>
						<div
							onClick={() => {
								setDataVisible(!dataVisible);
							}}
							className="collapsable-list"
						>
							<div
								style={{
									display       : "flex",
									flexDirection : "row",
									margin        : "auto",
								}}
							>
								<label style={{ margin: "auto", paddingLeft: 24 }}>Dati indirizzo (fatturazione)</label>
								<div>{dataVisible ? <ExpandLess /> : <ExpandMore />}</div>
							</div>
						</div>

						<Collapse in={dataVisible} timeout="auto" unmountOnExit>
							<div style={{ display: "flex", justifyContent: "center" }}>
								{taxAddressNationalityFlag ? (
									<div>
										{" "}
										<Typography className="p-y-10">
											<strong>Indirizzo:</strong>
											{` ${taxAddress.address}`}
										</Typography>
									</div>
								) : (
									<ViewAddress address={taxAddress} noSearch={props.noSearch} />
								)}
							</div>
						</Collapse>
					</div>
				</div>
			) : (
				!success && (
					<div
						style={{
							display        : "flex",
							flexDirection  : "column",
							justifyContent : "center",
							alignItems     : "center",
							alignContent   : "center",
							height         : 250,
						}}
					>
						<Levels size={100} />
					</div>
				)
			)}

			<div style={{ display: loading && "none" }} className="footer-modal">
				<PaypalBtn
					history={history}
					orderID={props.orderId}
					paymentType={props.paymentType}
					amount={props.price}
					onClick={() => {
						setLoading(true);
						setButtonClicked(true);
						props.setError(false);
					}}
					clientId={props.payClientId || defaultID}
					currency="EUR"
					onSuccess={(details, data) => {
						const paypal_payer    = {
							email_address : details.payer.email_address,
							payer_id      : details.payer.payer_id,
						};
						const paypal_captures = {
							status      : details.purchase_units[0].payments.captures[0].status,
							id          : details.purchase_units[0].payments.captures[0].id,
							create_time : details.purchase_units[0].payments.captures[0].create_time,
							amount      : details.purchase_units[0].payments.captures[0].amount,
						};
						const bodyData        = {
							orderid         : props.orderId,
							paypal_payer    : JSON.stringify(paypal_payer),
							paypal_captures : JSON.stringify(paypal_captures),
						};
						if (props.memorialType) {
							bodyData.memorialType = props.memorialType;
							bodyData.deceasedID   = props.deceasedId;
						}
						requests("post", "paypalUpdate", bodyData).then((data) => {
							if (data.response === "success") {
								setSuccess(true);
							} else {
								props.setError(false);
							}
						});
					}}
				/>
			</div>
			{success && props.successComponent(props.isRegistering, props.isAccount, history)}
		</div>
	);
}
