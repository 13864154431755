/* eslint-disable no-shadow */
// eslint-disable no-unused-expressions

import Tooltip from "@material-ui/core/Tooltip";
import { Button, IconButton, Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import {
	AssignmentInd,
	Create,
	CreateOutlined,
	DeleteOutlined,
	Fireplace,
	ImageSearch,
	ImageSearchTwoTone,
	PictureAsPdf,
	Receipt,
	SwapHorizontalCircle,
	VisibilityOutlined,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useHistory } from "react-router";
import GeneralTable from "../../NewComponent/GeneralTable";
import requests, { baseURL, getUrl, handleSaveImg } from "../../../Services/HttpServices";
import PaymentModal from "../../PaymentModal/PaymentModal";
import { getLabelledAddress } from "../../FormComponents/addressService";
import book from "../../../assets/book.gif";
import FeedbackModal from "../../FormComponents/FeedbackModal";
import deceased from "../../../assets/Stab/Defunti";

let closeModalFixed = false;

export default function PartnerPanelTableWrapper(props) {
	const [price, setPrice]     = useState(50);
	const [success, setSuccess] = useState(false);
	const paypalclientId        = "AX-JToydoHdJt309EJDeRzShWrHhqNWwfN_oNqGy9zaxLmyQUROur8Wag3DsK9oooSMZ0fPHvV43dhss";
	useEffect(() => {
		[...document.getElementById("appBody").children].map((e) => {
			if (
				e.src?.indexOf("paypal") >= 0
				&& e.src
				!== "https://www.paypal.com/sdk/js?client-id=AX-JToydoHdJt309EJDeRzShWrHhqNWwfN_oNqGy9zaxLmyQUROur8Wag3DsK9oooSMZ0fPHvV43dhss&currency=EUR"
			) {
				const temp = e;
				console.info("trovato lo script adesso lo levo");
				document.getElementById("appBody")
					.removeChild(e);
				temp.src = "https://www.paypal.com/sdk/js?client-id=AX-JToydoHdJt309EJDeRzShWrHhqNWwfN_oNqGy9zaxLmyQUROur8Wag3DsK9oooSMZ0fPHvV43dhss&currency=EUR";
				document.getElementById("appBody")
					.append(temp);
			}
		});
	}, []);
	const filter = [
		{
			name  : "",
			grave : "",
			live  : "",
		},
		{
			name     : "",
			deceased : "",
			type     : "",
		},
		{
			name    : "",
			type    : "",
			address : "",
			iva     : "",
		},
		{
			name    : "",
			address : "",
			details : "",
		},
		{},
		{
			code : "",
			type : "",
		},
	];
	let user;
	try {
		user = JSON.parse(sessionStorage.getItem("User"));
	} catch (e) {

	}
	const [userInvoiceAddress, setUserInvoiceAddress]   = useState({});
	const [userShippingAddress, setUserShippingAddress] = useState({});
	useEffect(() => {
		requests("get", "getProductPrice", "qrcode-partner")
			.then((data) => {
				if (data.data.product_details) {
					setPrice(data.data.product_details.price);
				}
			});
		if (user?.userdata?.addresses?.length > 0) {
			user.userdata.addresses.forEach((el) => {
				if (el.type === "invoice") {
					setUserInvoiceAddress({
						region   : el.regionName,
						province : el.provinceName,
						location : el.locationName,
						address  : el.address,
						civic    : el.number,
						cap      : el.cap,
						city     : el.locationName,
					});
				} else {
					setUserShippingAddress({
						region   : el.regionName,
						province : el.provinceName,
						location : el.locationName,
						address  : el.address,
						civic    : el.number,
						cap      : el.cap,
						city     : el.locationName,
					});
				}
			});
		}
	}, []);
	const tableHeaderName = [
		[
			{
				id       : "name",
				maxWidth : 187,
				label    : "Anagrafica",
				align    : "center",
			},
			{
				id       : "grave",
				maxWidth : 187,
				label    : "Dati Cimitero",
				align    : "center",
			},
			{
				id       : "live",
				maxWidth : 187,
				label    : "Data di morte",
				align    : "center",
			},
			{
				id    : "qrcode",
				label : "Qrcode lapide",
				align : "center",
			},
			{
				id    : "qrcodeB",
				label : "Qrcode manifesto",
				align : "center",
			},
			{
				id       : "status",
				minWidth : 180,
				label    : "Stato",
				align    : "center",
			},
			{
				id    : "pageID",
				label : "Lapide digitale",
				align : "center",
			},
			{
				id    : "action",
				label : "Azioni",
				align : "center",
			},
			{
				id    : "book",
				label : "N° condoglianze",
				align : "center",
			},
		],
		[
			{
				id    : "name",
				label : "Anagrafica",
				align : "center",
			},
			{
				id    : "deceased",
				label : "Dati defunto",
				align : "center",
			},
			{
				id    : "type",
				label : "Tipologia di dedica",
				align : "center",
			},
			{
				id    : "action",
				label : "Azioni",
				align : "center",
			},
		],
		[
			{
				id    : "name",
				label : "Dati partner",
				align : "center",
			},
			{
				id    : "type",
				label : "Tipologia azienda",
				align : "center",
			},
			{
				id    : "address",
				label : "Indirizzo",
				align : "center",
			},
			{
				id    : "iva",
				label : "Partita Iva",
				align : "center",
			},
			{
				id    : "action",
				label : "Azioni",
				align : "center",
			},
		],
		[
			{
				id    : "name",
				label : "Anagrafica",
				align : "center",
			},
			{
				id    : "address",
				label : "Indirizzo",
				align : "center",
			},
			{
				id    : "details",
				label : "Dettagli aquisto",
				align : "center",
			},
			{
				id    : "action",
				label : "Azioni",
				align : "center",
			},
		],
		[],
		[
			{
				id    : "qrcode",
				label : "Qrcode lapide",
				align : "center",
			},
			{
				id    : "code",
				label : "Codice",
				align : "center",
			},
			{
				id    : "qrcodeB",
				label : "Qrcode libro delle condoglianze",
				align : "center",
			},
			{
				id    : "type",
				label : "Tipologia",
				align : "center",
			},
			{
				id    : "action",
				label : "Azioni",
				align : "center",
			},
		],
	];
	let { type }          = props;
	const qrIndex         = tableHeaderName[0].findIndex((e) => e.id === "qrcode");
	const qrBIndex        = tableHeaderName[0].findIndex((e) => e.id === "qrcodeB");
	const pageIDIndex     = tableHeaderName[0].findIndex((e) => e.id === "pageID");
	const statIndex       = tableHeaderName[0].findIndex((e) => e.id === "status");
	if (props.type === 6) {
		type = 0;
		if (qrIndex === -1) {
			tableHeaderName[0].splice(3, 0, {
				id    : "qrcode",
				label : "Qrcode lapide",
				align : "center",
			});
		}
		if (qrBIndex === -1) {
			tableHeaderName[0].splice(4, 0, {
				id    : "qrcodeB",
				label : "Qrcode manifesto",
				align : "center",
			});
		}
		if (statIndex === -1) {
			tableHeaderName[0].splice(4, 0, {
				id    : "status",
				label : "Stato",
				align : "center",
			});
		}
		if (pageIDIndex !== -1) {
			tableHeaderName[0].splice(pageIDIndex, 1);
		}
	} else if (props.type === 0) {
		if (qrIndex > -1) {
			tableHeaderName[0].splice(qrIndex, 1);
			tableHeaderName[0].splice(qrIndex, 1);
			tableHeaderName[0].splice(qrIndex, 1);
		}
		if (pageIDIndex === -1) {
			tableHeaderName[0].splice(3, 0, {
				id    : "pageID",
				label : "Lapide digitale",
				align : "center",
			});
		}
	}
	const [orderID, setOrderID]             = useState(props.orderID);
	const [error, setError]                 = useState(false);
	const [cardModal, setCardModal]         = useState(false);
	const [cardModalFunc, setCardModalFunc] = useState(null);
	useEffect(() => {
		setOrderID(props.orderID);
	}, [props.orderID]);
	const history  = useHistory();
	const location = useLocation();
	return (
		<div>
			<a href="#" id="tempLink" style={{ display: "none" }} target="_blank" />
			{orderID && (
				<div
					onClick={() => {
						if (success) window.location.reload();
						if (!closeModalFixed) {
							!error
								? setOrderID(null)
								: history.push({
									pathname : "/paypal-error",
									query    : {
										orderId : orderID,
										type    : "qrcode-partner",
									},
								});
						} else {
							closeModalFixed = false;
						}
					}}
					className="modalPurchase"
				>
					<div
						onClick={() => {
							closeModalFixed = true;
						}}
					>
						{!success && (
							<PaymentModal
								noSearch
								paymentType="qrcode-partner"
								payClientId={paypalclientId}
								setError={() => console.log("Errore ecc")}
								shippingAddress={userShippingAddress}
								price={price}
								showAddress
								taxAddress={userInvoiceAddress}
								successComponent={() => setSuccess(true)}
								isAccount={!!user}
								userData={{
									name    : user?.userdata.name,
									surname : user?.userdata.surname,
									CF      : user?.userdata.cf,
									email   : user?.userdata.email,
								}}
								orderId={orderID}
								quantity={1}
								purchase="Attivazione qrCode (Offerta partner)"
							/>
						)}
						{success && (
							<div className="success-pp">
								<Typography className="pp-header">Successo</Typography>
								<Typography>Acquisto avvenuto con successo</Typography>
								<Button
									variant="contained"
									style={{ marginLeft: "auto" }}
									color="primary"
									onClick={() => window.location.reload()}
								>
									Chiudi
								</Button>
							</div>
						)}
					</div>
				</div>
			)}
			<GeneralTable
				initOrder="live"
				partner
				type={props.type}
				filter={filter[type]}
				deleteAllOperation={props.deleteAllOperation}
				setChecked={props.setChecked}
				setFilter={props.setFilter}
				setNumber={() => {
				}}
				setAllNumber={() => {
				}}
				headData={tableHeaderName[type]}
				onRowClick={props.onRowClick}
				clickable
				data={props.data}
				actionData={(
					<div>
						<CreateOutlined fontSize="small" className="dash-icon" />
						<VisibilityOutlined fontSize="small" className="dash-icon" />
						<DeleteOutlined fontSize="small" className="dash-icon" />
					</div>
				)}
			/>
		</div>
	);
}

export function createObituaryDataPartnerTable(id, name, grave, live, setState, data, modify) {
	const action = (
		<div>
			<Tooltip title="Rimuovi necrologio">
				<IconButton
					size="small"
					onClick={() => setState({
						obituaryID      : id,
						modalClear      : true,
						labelModalClear : `il necrologio di ${name}`,
					})}
					className="icon-button"
				>
					<ClearIcon color="error" fontSize="small" className="dash-icon" />
				</IconButton>
			</Tooltip>
			<Tooltip title="Modifica pagina">
				<IconButton size="small" onClick={() => modify()} className="icon-button">
					<Create color="primary" fontSize="small" className="dash-icon" />
				</IconButton>
			</Tooltip>
		</div>
	);
	let PageID   = "-";
	if (data.pageID) {
		PageID = (
			<Tooltip title="Gestione lapide">
				<span
					style={{ color: "dodgerblue" }}
					onClick={() => {
						window.history.pushState({ deceasedName: name }, "title", "/partner-panel#deceased");
						setState({ load: name });
					}}
				>
					{data.pageID}
				</span>
			</Tooltip>
		);
	}

	return {
		id,
		name,
		grave,
		live,
		action,
		pageID: PageID,
	};
}

export function createDeceasedDataPartnerTable(
	id,
	name,
	grave,
	live,
	setState,
	data,
	modify,
	qrcode,
	qrcodeB,
	showSucces,
	qrcodeid,
	onUpdateContoVendita,
	setLoadingModal,
	setCardModalFunc,
) {
	console.log({ data });
	const action = (
		<div>
			{`${data.contoVendita}` !== "1" && (
				<Tooltip title="Cedi proprietà pagina">
					<IconButton
						size="small"
						onClick={() => setState({
							selectedID    : id,
							propertyModal : true,
						})}
						className="icon-button"
					>
						<SwapHorizontalCircle color="disabled" fontSize="small" className="dash-icon" />
					</IconButton>
				</Tooltip>
			)}
			<Tooltip title="Modifica pagina">
				<IconButton size="small" onClick={() => modify()} className="icon-button">
					<Create color="primary" fontSize="small" className="dash-icon" />
				</IconButton>
			</Tooltip>
			<Tooltip title="Invia dati pagina">
				<IconButton
					size="small"
					onClick={() => {
						setLoadingModal(true, "Invio dei dati...");
						requests("get", "partnerSend", id)
							.then((data) => {
								if (data?.data?.response === "success") {
									showSucces();
								} else {
									alert(data?.data?.message);
								}
								setLoadingModal(false, "Invio dei dati...");
							});
					}}
					className="icon-button"
				>
					<Receipt style={{ color: "#ff8300" }} fontSize="small" className="dash-icon" />
				</IconButton>
			</Tooltip>
			<Tooltip title="Scarica Cartoncino delle condoglianze">
				<IconButton
					size="small"
					onClick={() => {
						setCardModalFunc(
							id,
							`${name}`,
							() => {
								setLoadingModal(true, "Creazione del cartoncino...");
								requests("get", "deceasedCard", id)
									.then((response) => {
										if (response.data.response === "success") {
											const url  = response.data.data.pdf;
											const link = document.getElementById("tempLink");
											link.href  = url;
											setLoadingModal(false, "Creazione del cartoncino...");
											if (window.showSaveFilePicker) link.setAttribute("download", `Cartoncino-${id}`); // or any other extension
											// document.body.appendChild(link);
											link.click();
											// document.body.removeChild(link);
											setState({
												feedbackModal       : true,
												feedbackModalLabel  : "Il Cartoncino è stato scaricato con successo",
												feedbackModalResult : "Successo",
												modalLoading        : false,
											});
										} else {
											setState({
												feedbackModal       : true,
												feedbackModalLabel  : response?.data?.message || "Errore imprevisto",
												feedbackModalResult : "Errore",
												modalLoading        : false,
											});
										}
									})
									.catch((err) => {
										console.log(err);
									});
							},
							() => {
								setLoadingModal(true, "Creazione del cartoncino...");
								requests("get", "deceasedCard2", id)
									.then((response) => {
										if (response.data.response === "success") {
											const url  = response.data.data.pdf;
											const link = document.getElementById("tempLink");
											link.href  = url;
											setLoadingModal(false, "Creazione del cartoncino...");
											if (window.showSaveFilePicker) link.setAttribute("download", `Cartoncino-${id}`); // or any other extension
											// document.body.appendChild(link);
											link.click();
											// document.body.removeChild(link);
											setState({
												feedbackModal       : true,
												feedbackModalLabel  : "Il Cartoncino è stato scaricato con successo",
												feedbackModalResult : "Successo",
												modalLoading        : false,
											});
										} else {
											setState({
												feedbackModal       : true,
												feedbackModalLabel  : response?.data?.message || "Errore imprevisto",
												feedbackModalResult : "Errore",
												modalLoading        : false,
											});
										}
									})
									.catch((err) => {
										console.log(err);
									});
							},
						);
					}}
					className="icon-button"
				>
					<Fireplace style={{ color: "#ff8d17" }} fontSize="small" className="dash-icon" />
				</IconButton>
			</Tooltip>
			<Tooltip title="Scarica manifesto">
				<IconButton
					size="small"
					onClick={() => {
						fetch(data.linkManifesto, {
							method  : "GET",
							headers : {},
						})
							.then((response) => {
								response.arrayBuffer()
									.then((buffer) => {
										const url  = window.URL.createObjectURL(new Blob([buffer]));
										const link = document.createElement("a");
										link.href  = url;
										link.setAttribute("download", `Manifesto-${name}-${id}.png`); // or any other extension
										document.body.appendChild(link);
										link.click();
										document.body.removeChild(link);
									});
							})
							.catch((err) => {
								console.log(err);
							});
					}}
					className="icon-button"
				>
					<AssignmentInd style={{ color: "#be6000" }} fontSize="small" className="dash-icon" />
				</IconButton>
			</Tooltip>
			<Tooltip title="Scarica PDF libro delle condoglianze ">
				<IconButton
					size="small"
					onClick={() => {
						if (data.contoVendita !== "1") {
							setState({
								modalLoading : true,
								loadingLabel : "Download del pdf...",
							});
							requests("get", "pdfBook", id)
								.then((data) => {
									if (data?.data?.response === "success") {
										setState({ modalLoading: false });
										if (window.showSaveFilePicker) {
											fetch(data.data.data.pdf.replace("www.", ""), {
												method  : "GET",
												headers : {},
											})
												.then((response) => {
													response.arrayBuffer()
														.then((buffer) => {
															const url  = window.URL.createObjectURL(new Blob([buffer]));
															const link = document.createElement("a");
															link.href  = url;

															link.setAttribute("download", `Libro-${id}.pdf`); // or any other extension
															link.setAttribute("target", "_blank"); // or any other extension
															document.body.appendChild(link);
															link.click();
															document.body.removeChild(link);
															setState({
																feedbackModal       : true,
																feedbackModalLabel  : "Il pdf è stato scaricato con successo",
																feedbackModalResult : "Successo",
																modalLoading        : false,
															});
														});
												})
												.catch((err) => {
													console.log(err);
												});
										} else {
											const isSafari = window.safari !== undefined;
											if (isSafari) {
												const url  = data.data.data.pdf;
												const link = document.getElementById("tempLink");
												link.href  = url;
												setLoadingModal(false, "Creazione del pdf...");
												link.setAttribute("download", `Cartoncino-${id}`); // or any other extension
												// document.body.appendChild(link);
												link.click();
												// document.body.removeChild(link);
												setState({
													feedbackModal       : true,
													feedbackModalLabel  : "Il PDF è stato scaricato con successo",
													feedbackModalResult : "Successo",
													modalLoading        : false,
												});
											} else {
												const win = window.open(data.data.data.pdf, "_blank");
												win.focus();
												setState({
													feedbackModal       : true,
													feedbackModalLabel  : "Il pdf è stato scaricato con successo",
													feedbackModalResult : "Successo",
													modalLoading        : false,
												});
											}
										}
									} else {
										setState({
											feedbackModal       : true,
											feedbackModalLabel  : data?.data?.message || "Errore generico... riprovare",
											feedbackModalResult : "Errore",
											modalLoading        : false,
										});
									}
								});
						} else {
							setState({
								feedbackModal      : true,
								feedbackModalLabel : (
									<span>
										<p>
											Per poter stampare il libro delle condoglianze è necessario perfezionare
											l’acquisto della lapide digitale
										</p>
										&nbsp;
										<p>clicca qui per la </p>
										<a href={`${baseURL}api/pdf/Librodellecondoglianze-1000.pdf`} target="_blank">
											DEMO
										</a>
									</span>
								),
								feedbackModalResult : "Errore",
								modalLoading        : false,
							});
						}
					}}
					className="icon-button"
				>
					<img
						alt="pdf"
						src={book}
						style={{
							filter : data.contoVendita !== "1" ? "none" : "grayscale()",
							width  : 24,
						}}
						className="dash-icon"
					/>
				</IconButton>
			</Tooltip>
		</div>
	);
	const status = `${data.contoVendita}` === "1" ? (
		<Button
			variant="contained"
			style={{
				padding    : 4,
				fontSize   : 10,
				color      : "black",
				fontWeight : "bold",
			}}
			color="primary"
			onClick={() => requests("POST", "orderContoVendita", { qrcodeid: data.qrcodeID })
				.then((response) => {
					if (response.response === "success") {
						onUpdateContoVendita(response.data.orderid);
					} else {
						alert(response.message);
					}
				})}
		>
			Procedi all'acquisto
		</Button>
	) : (
		""
	);
	return {
		id,
		name,
		grave,
		live,
		qrcode,
		qrcodeB,
		action,
		status,
		book: `${data.condolenceCounter || data.condolence || 0}`,
	};
}

export function createDedicDataPartnerTable(id, name, deceased, type, setState, data, acceptOP) {
	const action = (
		<div>
			<Tooltip title={`Accetta richiesta di ${name}`}>
				<IconButton
					size="small"
					onClick={() => {
						setState({
							dedicToken  : data.dedic.token,
							dedicDataID : data.dedic.ID,
						});
						acceptOP();
					}}
					className="icon-button"
				>
					<CheckIcon fontSize="small" style={{ color: "green" }} className="dash-icon" />
				</IconButton>
			</Tooltip>
			<Tooltip title={`Rifiuta la richiesta di ${name}`}>
				<IconButton
					size="small"
					onClick={() => {
						setState({
							modalClear      : true,
							labelModalClear : "la richiesta dedica",
							dedicToken      : data.dedic.token,
							dedicDataID     : data.dedic.ID,
						});
					}}
					className="icon-button"
				>
					<ClearIcon fontSize="small" color="error" className="dash-icon" />
				</IconButton>
			</Tooltip>
			<Tooltip title={`Visualizza dedica di ${name}`}>
				<IconButton
					size="small"
					onClick={() => setState({
						dedicData  : data,
						dedicModal : true,
					})}
					className="icon-button"
				>
					<VisibilityIcon fontSize="small" color="primary" className="dash-icon" />
				</IconButton>
			</Tooltip>
		</div>
	);
	return {
		id,
		name,
		deceased,
		type,
		action,
	};
}

export function createQRDataPartnerTable(id, qrcode, code, qrcodeB, type, setState, data, sold) {
	const action = (
		<div>
			{sold === "1" && "-"}
			{sold !== "1" && (
				<div style={{ display: "flex" }}>
					<Link
						to={{
							pathname : "/activate-qrCode",
							query    : {
								user:
									sessionStorage.getItem("User") && sessionStorage.getItem("User") !== "undefined"
									&& JSON.parse(sessionStorage.getItem("User")).userdata,
								data: {
									secondField : code,
									type        : data.verification_code,
								},
								address:
									sessionStorage.getItem("UserAddress") && sessionStorage.getItem("UserAddress") !== "undefined"
									 && JSON.parse(sessionStorage.getItem("UserAddress")),
							},
						}}
						style={{
							marginLeft  : "auto",
							marginRight : "auto",
						}}
					>
						<Tooltip title="Attiva il QrCode">
							<div id="activate-button-table" style={{ margin: "0 auto" }}>
								Attiva
							</div>
						</Tooltip>
					</Link>
					<Tooltip title="Imposta come assegnato">
						<div
							onClick={() => {
								const bodyData = {
									"qrcode-id": code.replace("000", ""),
								};
								setState({
									areUSure      : true,
									sureData      : bodyData,
									indexAreUSure : data,
								});
							}}
							id="activate-button-table"
							style={{ margin: "0 auto" }}
						>
							Scarica
						</div>
					</Tooltip>
				</div>
			)}
		</div>
	);
	return {
		id,
		qrcode,
		code,
		qrcodeB,
		type: `${type}` === "1" ? "Temporaneo" : "-",
		action,
		sold,
	};
}
