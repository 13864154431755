import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";

export default function ModifyAccountPartnerExtraField(props) {
  const [webSite, setWebSite] = useState("");
  const [VAT, setVAT] = useState("");
  const [telephone, setTelephone] = useState("");
  const [SDI, setSDI] = useState("");
  const [instagram, setInstagram] = useState("");
  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [youtube, setYoutube] = useState("");
  const [vatError, setVatError] = useState(false);
  useEffect(() => {
    if (props.azienda) {
      setWebSite(props.azienda.website);
      setVAT(props.azienda.VAT);
      props.setVat(props.azienda.VAT);
      setSDI(props.azienda.SDI);
      setTelephone(props.azienda.telephone);
      setFacebook(props.azienda.facebook);
      setInstagram(props.azienda.instagram);
      setLinkedin(props.azienda.linkedin);
      setTwitter(props.azienda.twitter);
      setYoutube(props.azienda.youtube);
    }
  }, [props.azienda]);
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <TextField
        label="Sito web"
        value={webSite}
        onChange={(e) => setWebSite(e.target.value)}
        style={{ marginTop: 30 }}
        name="modify-website"
      />
      <TextField
        label="Partita IVA *"
        value={VAT}
        onChange={(e) => {
          setVAT(e.target.value);
          props.setVat(e.target.value);
          if (e.target.value === "") {
            setVatError(true);
          } else setVatError(false);
        }}
        style={{ marginTop: 30 }}
        error={vatError}
        helperText={vatError && "Campo obbligatorio"}
        name="modify-VAT"
      />
      <TextField
        label="Tel/Fax"
        value={telephone}
        style={{ marginTop: 30 }}
        onChange={(e) => setTelephone(e.target.value)}
        name="modify-telephone"
      />
      <TextField
        label="SDI"
        value={SDI}
        style={{ marginTop: 30 }}
        onChange={(e) => setSDI(e.target.value)}
        name="modify-SDI"
      />
      <TextField
        label="Link Instagram"
        value={instagram}
        style={{ marginTop: 30 }}
        onChange={(e) => setInstagram(e.target.value)}
        name="modify-instagram"
      />
      <TextField
        label="Link Facebook"
        value={facebook}
        style={{ marginTop: 30 }}
        onChange={(e) => setFacebook(e.target.value)}
        name="modify-facebook"
      />
      <TextField
        label="Link Twitter"
        value={twitter}
        style={{ marginTop: 30 }}
        onChange={(e) => setTwitter(e.target.value)}
        name="modify-twitter"
      />
      <TextField
        label="Link Linkedin"
        value={linkedin}
        style={{ marginTop: 30 }}
        onChange={(e) => setLinkedin(e.target.value)}
        name="modify-linkedin"
      />{" "}
      <TextField
        label="Link Youtube"
        value={youtube}
        style={{ marginTop: 30 }}
        onChange={(e) => setYoutube(e.target.value)}
        name="modify-youtube"
      />
    </div>
  );
}
