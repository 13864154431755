import React, { Component, Fragment } from "react";
import requests from "../../../Services/HttpServices";

class TransferAccept extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
    };
  }

  render() {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const foo = params.get("token");

    let verified = null;
    if (verified === null && !this.state.loaded && !this.state.requesting) {
      this.setState({ requesting: true });
      requests("get", "acceptTransfer", foo).then((data) => {
        verified = data.data.response == "success";
        this.setState({ loaded: true, verified });
        this.setState({ requesting: false });
      });
      // verified = foo == 'token1'
    }
    return (
      <>
        {this.state.verified ? (
          <div
            style={{ height: window.innerHeight - 250, textAlign: "center" }}
          >
            <p
              className="death-data"
              style={{
                alignSelf: "center",
                textAlign: "center",
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              Trasferimento completato
            </p>
          </div>
        ) : (
          <div style={{ height: 700, textAlign: "center" }}>
            <p
              className="death-data"
              style={{
                alignSelf: "center",
                textAlign: "center",
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              Attenzione!
            </p>
            <p style={{ marginBottom: 20, textAlign: "center" }}>
              Il codice di verifica da lei utilizzato non è più valido
            </p>
            &nbsp;
            <label
              className="technic-support"
              style={{ paddingTop: 20, textAlign: "center" }}
            >
              <p>Per un eventuale supporto tecnico è possibile contattare</p>
              <p>
                {" "}
                il numero telefonico <strong>389 89 96 166</strong>
              </p>
              <p style={{ textAlign: "center", marginTop: 12 }}>
                <b>
                  <p>DAL LUNEDÌ AL VENERDÌ</p>
                  <p> DALLE ORE 09:00 ALLE 13:00 E</p>
                  <p>DALLE 15:00 ALLE 18:00</p>
                </b>
              </p>
            </label>
          </div>
        )}
      </>
    );
  }
}

export default TransferAccept;
