/* eslint-disable no-restricted-globals,react/no-access-state-in-setstate,no-shadow */
import React from "react";
import ClearCache from "react-clear-cache";
import {
	Switch, Route, Link, BrowserRouter as Router, Redirect,
} from "react-router-dom";
import UseAnimations from "react-useanimations";
import menu2 from "react-useanimations/lib/menu2";
import CookieConsent from "react-cookie-consent";
import { NotificationImportant } from "@material-ui/icons";
import { CircularProgress, Tooltip } from "@material-ui/core";
import Context from "./Context";
import adminPanel from "./components/ControlPanel/AdminPanel";
import "./App.css";
import navConfigService from "./NavConfigService";
import Home from "./components/Home/Home";
import logo from "./assets/logo";
import Whats from "./components/Whats/Whats";
import HowItWorks from "./components/How-it-works/How-it-works";
import HowItCosts from "./components/How-it-costs/How-it-costs";
import Terms from "./components/Terms/Terms";
import Contact from "./components/Contact/Contact";
import Partner from "./components/Partner/Partner";
import Activate from "./components/Activate/Activate";
import BuyQrCode from "./components/Buy-qrcode/Buy-qrCode";
import MemorialActivate from "./components/Memorial-activate/Memorial-activate";
import flowers from "./components/How-it-costs/flowers";
import MemorialPage from "./components/memorial-page/Memorial-page";
import MemorialBook from "./components/memorial-book/Memorial-book";
import NavDrawer from "./navDrawer";
import PartnerPage from "./components/Partner-page/Partner-page";
import PartnerPanel from "./components/ControlPanel/PartnerPanel";
import LampadePanel from "./components/ControlPanel/LampadePanel";
import ClientPanel from "./components/ControlPanel/ClientPanel";
import Login from "./components/Auth/Login/Login";
import ResetPassword from "./components/Auth/Password-reset/ResetPassword";
import ForgotPassword from "./components/Auth/Password-reset/ForgotPassword";
import ContactVerify from "./components/Auth/ContactVerify/ContactVerify";
import PostActivate from "./components/Auth/ContactVerify/PostActivate";
import HttpService from "./Services/HttpServices";
import DedicVerify from "./components/Auth/ContactVerify/DedicVerify";
import DedicAbort from "./components/Auth/ContactVerify/DedicAbort";
import PostPartner from "./components/Auth/ContactVerify/PostPartner";
import Registrazione from "./components/Auth/Registrazione/Registrazione";
import RedirectAllURL from "./components/RedirectOldUrl";
import AddDeceased from "./components/ControlPanel/AdminPanelPages/AddDeceased";
import PostDeceased from "./components/Auth/ContactVerify/PostDeceased";
import QrCodeRequest from "./components/ControlPanel/QrCodeRequest/QrCodeRequest";
import PurchaseSuccessComponent from "./components/Buy-qrcode/PurchaseSuccessComponent";
import BuySuccess from "./components/FormComponents/BuySuccess";
import AddDeceasedPoster from "./components/ControlPanel/PartnerPanelPages/AddDeceasedPoster";
import AddGiftAdmin from "./components/ControlPanel/AdminPanelPages/AddGiftAdmin";
import requests from "./Services/HttpServices";
import MemorialAdmin from "./components/ControlPanel/AdminPanelPages/AddMemorialAdmin";
import PayPalNotCompleted from "./Services/PayPalNotCompleted";
import ProdottiNav from "./components/Home/prodottiNav";
import RecoverPassword from "./components/Auth/Recover-password";
import RegisterSuccess from "./components/Auth/Registrazione/registerSuccess";
import { GoogleRecaptchaExample } from "./Services/test";
import ForgotUsername from "./components/Auth/ForgotUsername/ForgotUsername";
import TransferAccept from "./components/Auth/ContactVerify/TransferVerify";
import ViewDedicList from "./components/ControlPanel/AdminPanelPages/ViewDedicList";
import ModifyMemorialAdmin from "./components/ControlPanel/AdminPanelPages/ModifyMemorialAdmin";
import addressServiceGetData from "./components/FormComponents/addressService";
import AbortNotify from "./components/Auth/ContactVerify/AbortNotify";
import RememberMemorial from "./components/Auth/RememberMemorial/RememberMemorial";
import AlertModal from "./components/FormComponents/AlertModal";
import NewUpdate from "./NewUpdate";
import SellTerms from "./components/Terms/SellTerms";
import RedirectAllURLAttivazione from "./components/RedirectOldUrlAttivazione";
import BookVerify from "./components/Auth/ContactVerify/BookVeify";
import { changeRouteForSession, handleSession, logout } from "./Services/HandleSession";
import PostRegistration from "./components/Auth/ContactVerify/PostRegistration";

const nav        = true;
let session      = "";
let route;
const bodyData   = {
	email        : "username",
	password     : "password",
	_submitLogin : 1,
};
const whiteColor = { color: "white" };

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user             : null,
			cart             : "",
			nav              : "",
			drawerOpened     : false,
			session          : "",
			activeRoute      : "",
			alertModal       : false,
			alertClosed      : false,
			expiringMemorial : null,
			load             : false,
		};
		this.setState({ nav: true });
		this.routerRef = React.createRef();
		this.setState({ user: null });
	}

	componentDidMount() {
		const bodyCopy = JSON.parse(JSON.stringify(bodyData));
		// const warningRead = localStorage.getItem('warning');
		// if (`${warningRead}` === 'false') {
		// 	this.setState({ warningModal: true });
		// 	localStorage.setItem('warning', true);
		// }

		requests("get", "timeNotLabel", "")
			.then((data) => {
				sessionStorage.setItem("timeLabel", JSON.stringify(data?.data?.data) || "errore");
			});
		addressServiceGetData("region")
			.then((data) => {
				sessionStorage.setItem("regions", JSON.stringify(data));
			});

		this.checkOnOpenSession(bodyData);
		const data = changeRouteForSession((data) => this.setState(data), this.state);
		route      = data.route;
		session    = data.session;
		this.routerRef.current.history.listen((location, action) => {
			this.handleChangePath(location);
			const data = changeRouteForSession((data) => this.setState(data), this.state);
			route      = data.route;
			session    = data.session;
			this.setState({ load: !this.state.load });
			window.location.search.length < 60 && this.checkOnOpenSession(JSON.parse(JSON.stringify(bodyCopy)));
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		this.checkMemorial();
		if (window.location.hash == "" || `${window.location.hash}` === "#newLogin") {
			window.scrollTo(0, 0);
		}
		if (
			((window.location.hash === "#newLogin" && !this.state.alertClosed) || this.state.buttonModal)
			&& !this.state.alertModal
			&& this.state.expiringMemorial?.length > 0
		) {
			this.setState({ alertModal: true });
		}
		if (window.location.hash === "#Doni") {
			setTimeout(() => {
				try {
					document.getElementById("Doni")
						.scrollIntoView();
				} catch (e) {
					console.log("e");
				}
			}, 500);
		}
	}

	render() {
		return (
			<Context.Provider
				className="App main-container"
				value={{
					...this.state,
					clearCart : this.clearCart,
					checkout  : this.checkout,
					Nav       : navConfigService.getNav(),
				}}
			>
				{!this.state.sessionChecked && (
					<div
						style={{
							position       : "absolute",
							zIndex         : 2000000,
							background     : "black",
							left           : 0,
							right          : 0,
							bottom         : 0,
							top            : 0,
							display        : "flex",
							justifyContent : "center",
							alignItems     : "center",
						}}
					>
						<CircularProgress size={64} color="primary" style={{ margin: "auto" }} />
					</div>
				)}

				{this.state.alertModal && (
					<AlertModal
						onClose={() => {
							this.setState({
								alertClosed : true,
								alertModal  : false,
								buttonModal : false,
							});
						}}
						setData={(index) => {
							const temp            = this.state.expiringMemorial;
							temp[index].notNotify = "1";
							sessionStorage.setItem("ExpiringMemorial", JSON.stringify(temp));
							this.setState({
								load             : !this.state.load,
								expiringMemorial : null,
							});
						}}
						data={this.state.expiringMemorial}
					/>
				)}
				<CookieConsent
					location="bottom"
					cookieName="digital memorial"
					buttonText="ACCETTO"
					style={{
						background : "#2B373B",
						zIndex     : 999999,
					}}
					buttonStyle={{
						bacgroudColor : "#ffaa00",
						fontSize      : "13px",
					}}
					containerClasses="cookie"
					overlay
				>
					Questo sito utilizza cookie tecnici e di profilazione, propri e di terze parti, per fornirti una
					migliore esperienza di navigazione, per scopi pubblicitari o anche solo per fini statistici.
					Proseguendo la navigazione o cliccando sul pulsante "Accetto" acconsenti all'utilizzo dei cookie e a
					un più completo utilizzo dei servizi.
				</CookieConsent>
				<Router ref={this.routerRef}>
					<div className="App main-container z1" id="main-container">
						<ClearCache>
							{({
								isLatestVersion,
								emptyCacheStorage,
							}) => (
								<div className="w-full">
									{!isLatestVersion ? <NewUpdate update={emptyCacheStorage} /> : (
										<>
											<UseAnimations
												className="navbar-burger burger"
												wrapperStyle={{
													position : "absolute",
													zIndex   : 2000,
												}}
												reverse={this.state.drawerOpened}
												strokeColor="#fff"
												size={18}
												pathCss="asd"
												id="navBurgerAnim"
												onClick={(e) => {
													e.preventDefault();
													this.setState({ drawerOpened: !this.state.drawerOpened });
												}}
												animation={menu2}
											/>
											<nav
												className="navbar container z1"
												role="navigation"
												aria-label="main navigation"
											>
												<div className="App-link">
													<div className="title-div" style={{ position: "relative" }}>
														{this.state.expiringMemorial?.length > 0 && window.innerWidth < 1024 && (
															<div
																style={{
																	position  : "absolute",
																	left      : 10,
																	top       : 25,
																	marginTop : window.innerWidth < 415 && -7,
																}}
															>
																<Tooltip
																	title={`Attenzione hai ${this.state.expiringMemorial.length} memorial in scadenza`}
																>
																	<NotificationImportant
																		onClick={() => {
																			this.setState({ buttonModal: true });
																		}}
																		style={{
																			cursor : "pointer",
																			color  : "red",
																		}}
																	/>
																</Tooltip>
															</div>
														)}
														<div id="logo">
															<Link
																to="/home?name=&region=&province=&location=&cap=&page=0"
															>
																{logo()}
															</Link>
															<label id="subtitle-logo">
																<p>Quando un pensiero è per sempre!</p>
															</label>
															{session && (
																<label id="subtitle-logo2" style={{ display: "none" }}>
																	Ciao
																	{" "}
																	{window.innerWidth < 480
																		? session.userdata.name.length > 22
																			? `${session.userdata.name.slice(0, 22)}...`
																			: session.userdata.name
																		: session.userdata.name}
																</label>
															)}
														</div>
													</div>

													<div
														style={{ visibility: !this.state.sessionChecked && "hidden" }}
														className={`navbar-menu ${this.state.showMenu ? "is-active" : ""}`}
													>
														<Link
															to="/home?name=&region=&province=&location=&cap=&page=0"
															style={{
																color:
																	this.state.activeRoute && this.state.activeRoute.indexOf("home") >= 0
																		? "#E1802B"
																		: "white",
															}}
															className="navbar-item"
														>
															HOME
														</Link>

														<Link
															to="/information"
															style={{
																color:
																	this.state.activeRoute
																	&& this.state.activeRoute.indexOf("information") >= 0
																		? "#E1802B"
																		: "white",
															}}
															className="navbar-item"
														>
															COS'E'
														</Link>
														<Link
															to="/how-it-works"
															style={{
																color:
																	this.state.activeRoute
																	&& this.state.activeRoute.indexOf("how-it-works") >= 0
																		? "#E1802B"
																		: "white",
															}}
															className="navbar-item"
														>
															COME FUNZIONA
														</Link>
														<ProdottiNav isPartner={this.state.isPartner} />
														<a
															href="/memorial?1000"
															style={{
																color:
																	this.state.activeRoute
																	&& this.state.activeRoute.indexOf("memorial") >= 0
																	&& this.state.activeRoute.indexOf("memorial?") < 0
																	&& this.state.activeRoute.indexOf("memorial-") < 0
																		? "#E1802B"
																		: "white",
															}}
															className="navbar-item"
														>
															DEMO
														</a>
														<Link
															to="/contact"
															style={{
																color:
																	this.state.activeRoute && this.state.activeRoute.indexOf("contact") >= 0
																		? "#E1802B"
																		: "white",
															}}
															className="navbar-item"
														>
															CONTATTI
														</Link>
														<Link
															to="/partner"
															style={{
																color:
																	this.state.activeRoute && this.state.activeRoute == "partner"
																		? "#E1802B"
																		: "white",
															}}
															className="navbar-item"
														>
															LAVORA CON NOI
														</Link>
														<Link
															to={route}
															style={{
																color:
																	this.state.activeRoute
																	&& this.state.activeRoute.indexOf(route.slice(1, route.length)) >= 0
																		? "#E1802B"
																		: "white",
															}}
															className="navbar-item"
														>
															{route === "/login" ? "ACCEDI" : "PANNELLO DI CONTROLLO"}
														</Link>
														{route === "/login" && (
															<Link
																to="/registrazione"
																style={{
																	color:
																		this.state.activeRoute
																		&& this.state.activeRoute.indexOf("registrazione") >= 0
																			? "#E1802B"
																			: "white",
																}}
																className="navbar-item"
															>
																REGISTRATI
															</Link>
														)}
														{session && (
															<Link
																to="/home"
																onClick={(e) => {
																	e.preventDefault();
																	logout(false, (data) => this.setState(data), true);
																}}
																className="navbar-item"
															>
																ESCI
															</Link>
														)}
														{session && (
															<label
																className="navbar-item navbar-item2"
																style={{ cursor: "unset" }}
															>
																CIAO
																{" "}
																{session.userdata.name.toUpperCase()}
																!
																{this.state.expiringMemorial?.length > 0 && (
																	<Tooltip
																		title={`Attenzione hai ${this.state.expiringMemorial.length} memorial in scadenza`}
																	>
																		<NotificationImportant
																			onClick={() => {
																				this.setState({ buttonModal: true });
																			}}
																			style={{
																				cursor : "pointer",
																				color  : "red",
																			}}
																		/>
																	</Tooltip>
																)}
															</label>
														)}
													</div>
												</div>
											</nav>
											{this.state.sessionChecked && (
												<Switch>
													<Route exact path="/" component={Home} />
													<Route exact path="/([0-9]*)(.html)" component={RedirectAllURL} />
													<Route exact path="/([0-9][0-9]*)" component={RedirectAllURL} />
													<Route
														exact
														path="/attiva-qr-code.html"
														component={RedirectAllURLAttivazione}
													/>
													<Route exact path="/login" component={Login} />
													<Route exact path="/home" component={Home} />
													<Route exact path="/information" component={Whats} />
													<Route exact path="/login-renewal" component={RememberMemorial} />
													<Route exact path="/how-it-works" component={HowItWorks} />
													<Route exact path="/pricing" component={HowItCosts} />
													<Route exact path="/unsubscribe" component={AbortNotify} />
													<Route exact path="/test" component={GoogleRecaptchaExample} />
													<Route
														exact
														path="/esito-registrazione"
														component={RegisterSuccess}
													/>
													<Route exact path="/recupera-password" component={RecoverPassword} />
													<Route exact path="/lista-dediche" component={ViewDedicList} />
													<Route exact path="/terms" component={Terms} />
													<Route exact path="/buy-terms" component={SellTerms} />
													<Route exact path="/contact" component={Contact} />
													<Route exact path="/registerComplete" component={PostRegistration} />
													<Route exact path="/partner" component={Partner} />
													<Route exact path="/post-add" component={PostDeceased} />
													<Route exact path="/partner-page" component={PartnerPage} />
													<Route exact path="/bookconfirm" component={BookVerify} />
													<Route exact path="/activate-qrCode" component={Activate} />
													<Route exact path="/richiedi-qrCode" component={QrCodeRequest} />
													<Route exact path="/recover-user" component={ForgotUsername} />
													<Route
														exact
														path="/servizio-memorial"
														component={MemorialActivate}
													/>
													<Route exact path="/buy-qrCode" component={BuyQrCode} />
													<Route
														exact
														path="/memorial-activate"
														component={MemorialActivate}
													/>
													<Route
														exact
														path="/flowers"
														key={Math.random() * Math.random() + Math.random()}
														component={flowers}
													/>
													<Route
														name="memorial"
														exact
														path="/memorial"
														component={MemorialPage}
													/>
													<Route exact path="/verifica-contatto" component={ContactVerify} />
													<Route exact path="/accetta-dono" component={DedicVerify} />
													<Route exact path="/rifiuta-dono" component={DedicAbort} />
													<Route
														exact
														path="/accetta-trasferimento"
														component={TransferAccept}
													/>
													<Route exact path="/memorial-book" component={MemorialBook} />
													<Route exact path="/partner-panel" component={PartnerPanel} />
													<Route exact path="/lampade-panel" component={LampadePanel} />
													<Route exact path="/client-panel" component={ClientPanel} />
													<Route exact path="/partner-registrato" component={PostPartner} />
													<Route
														exact
														path="/nuovo-necrologio"
														component={AddDeceasedPoster}
													/>
													<Route exact path="/successo" component={PurchaseSuccessComponent} />
													<Route exact path="/Conferma" component={BuySuccess} />
													<Route exact path="/admin-panel" component={adminPanel} />
													<Route exact path="/reset-password" component={ResetPassword} />
													<Route exact path="/post-activate" component={PostActivate} />
													<Route exact path="/registrazione" component={Registrazione} />
													<Route exact path="/forgot-password" component={ForgotPassword} />
													<Route exact path="/aggiungi-defunto" component={AddDeceased} />
													<Route exact path="/aggiungi-dono-admin" component={AddGiftAdmin} />
													<Route
														exact
														path="/aggiungi-memorial-admin"
														component={MemorialAdmin}
													/>
													<Route
														exact
														path="/modifica-memorial-admin"
														component={ModifyMemorialAdmin}
													/>
													<Route exact path="/paypal-error" component={PayPalNotCompleted} />
													<Redirect from="*" to="/" />
												</Switch>
											)}
											<NavDrawer
												close={() => {
													document.getElementById("navBurgerAnim")
														.click();
												}}
												drawerOpened={this.state.drawerOpened}
												toggleDrawer={this.toggleDrawer}
												routename={this.routerRef?.current?.history?.location}
											/>
											{nav && window.location.href.indexOf("registerComplete") < 0 && (
												<div
													className="bottom bottom-fix-margin"
													style={{
														boxShadow:
															this.routerRef?.current?.history?.location?.pathname === "/home" && "none",
													}}
												>
													<div className="contact">
														<div className="termini">
															<label
																style={{
																	display       : "flex",
																	flexDirection : window.innerWidth > 600 ? "row" : "column",
																}}
															>
																© 2017-
																{new Date().getFullYear()}
																{" "}
																I.M.E. &nbsp;
																<p className="email " style={whiteColor}>
																	<Link to="/terms" style={whiteColor}>
																		Termini e condizioni d'uso
																	</Link>
																</p>
																{window.innerWidth > 600 && <p>/</p>}
																<p className="email" style={whiteColor}>
																	<Link to="/buy-terms" style={whiteColor}>
																		Condizioni di vendita
																	</Link>
																</p>
															</label>
														</div>
													</div>
												</div>
											)}
										</>
									)}
								</div>
							)}
						</ClearCache>
					</div>

				</Router>
			</Context.Provider>
		);
	}

	toggleDrawer = (booleanValue) => () => {
		this.setState({
			drawerOpened: booleanValue,
		});
	};

	handleChangePath(location) {
		this.setState({ activeRoute: location.pathname });
		if (window.location.hash === "#Doni") {
			setTimeout(() => {
				try {
					document.getElementById("Doni")
						.scrollIntoView();
				} catch (e) {
				}
			}, 500);
		}

		if (window.location.hash == "") {
			window.scrollTo(0, 0);
		}
	}

	checkMemorial(force) {
		let memorials;
		const rightMemorial = [];
		try {
			memorials = JSON.parse(sessionStorage.getItem("ExpiringMemorial"));
		} catch (e) {
			memorials = [];
		}
		if (
			(!this.state.expiringMemorial && memorials?.length > 0)
			|| (!this.state.expiringMemorial && memorials?.length > 0 && force)
		) {
			memorials.forEach((e) => {
				if (e.notNotify != "1") {
					rightMemorial.push(e);
				}
			});
			if (rightMemorial?.length > 0) {
			} else {
				this.setState({
					buttonModal : false,
					alertModal  : false,
				});
			}
			this.setState({ expiringMemorial: rightMemorial });
		} else if (force) {
			this.setState({
				expiringMemorial : null,
				buttonModal      : false,
				alertModal       : false,
			});
		}
	}

	checkOnOpenSession(bodyData) {
		window.location.pathname !== "/partner" && window.location.pathname.indexOf("bookconfirm") < 0
			? HttpService("Post", "login", bodyData)
				.then((data) => {
					this.setState({ sessionChecked: true });
					const result = handleSession(data, (data) => this.setState(data));
					if (!result) session = null;
				})
				.catch((err) => {
				})
			: this.setState({ sessionChecked: true });
	}
}

export default App;
